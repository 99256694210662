/**
* VUEshop
* ============================================================================
* * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
* 网站地址: http://www.vueshop.com.cn
* ----------------------------------------------------------------------------
* 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
* 不允许对程序代码以任何形式任何目的的再发布。
* ============================================================================
* $Author: soubao-java 2020-07-22 $
*/<template>
	<div id="app">
		<TopNav :ishide="ishide"></TopNav>
		<keep-alive>
		    <router-view v-if="isRouterAlive && $route.meta.keepAlive" />
		</keep-alive>
		<router-view v-if="isRouterAlive && !$route.meta.keepAlive" />

	</div>
</template>

<script>
	import {getConfigs,getMenuList,} from "@/utils/api";
	import TopNav from "@/components/TopNav"
	import {
		mapState,
		mapMutations,
        mapActions
	} from 'vuex';
	import axios from 'axios'
	import { getToken } from "@/utils/auth.js";
	import getmenu from "@/mixins/getmenu.js";
    import setSymbol from "@/mixins/setSymbol.js";
	export default {
		name: 'app',
		provide() { //父组件中通过provide来提供变量，在子组件中通过inject来注入变量。
			return {
				reload: this.reload
			}
		},
		mixins:[getmenu,setSymbol],
		data() {
			return {
				isRouterAlive: true, //控制视图是否显示的变量
			}
		},
		components:{
			TopNav
		},
		computed: {
			...mapState(['openFlag','menuList']),
			ishide(){
				let flag = true
				if(this.$route.path!='/login' && this.$route.path!='/deploy_pages') {
					flag = true
					return flag
				}else{
					flag = false
					return flag
				}
			}
		},
		created() {
			if (process.env.VUE_APP_ISOPENSOURCE=='true') {
				this.getVersion();
			}
			this.getConfig()
			if(getToken()) {
				this.getMenuList()
			}

		},
        mounted() {
            this.getCurrency()
        },
		methods: {
			...mapMutations(['updateOpenFlag','setMenuList']),
            ...mapActions(['getCurrency']),
			reload() {
				this.isRouterAlive = false; //先关闭，
				this.$nextTick(function() {
					this.isRouterAlive = true; //再打开
				})
			},
			getConfig() {
				getConfigs({ inc_type: "shop_info" }).then(function(res) {
					// that.storeLogo = res.shop_info.store_logo;
					var link = document.querySelector("link[rel*='icon']") || document.createElement('link');
					link.type = 'image/x-icon';
					link.rel = 'shortcut icon';
					link.href = res.shop_info.store_ico;
					document.getElementsByTagName('head')[0].appendChild(link);
				});
			},
			getVersion() { //获取开源提示
				axios.get('http://www.vueshop.com.cn/index.php?version=java').then(res => {
					if (res.data.status == 1) {
						if (res.data.version != process.env.VUE_APP_VERSION) {
							this.updateOpenFlag(true);
							this.$confirm(res.data.msg, '提示', {
								confirmButtonText: '确定',
								cancelButtonText: '取消',
								type: 'warning'
							}).then(() => {
								window.location.href = "http://www.vueshop.com.cn/"
							}).catch(() => {});
						}
					}
				})
			}
		},
	}
</script>

<style>
	@import url("../src/static/admin-font/iconfont.css");
	#app {
		height: 100%;
		overflow: hidden;
	}
</style>
