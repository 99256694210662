export const en = {
	"topNav": {
		"home": "Home",
		"setUp": "Set up",
		"page": "Page",
		"shop": "Shop",
		"order": "Order",
		"market": "Marketing",
		"distribution": "Distribution",
		"statistics": "Statistics",
		"business": "Business",
		"jobs": "Jobs",
		"msg": "message",
		"productTips": "Product tips",
		"approved": "Goods to be approved",
		"refundOrder": "Refund order",
		"return": "Return after sale",
		"tips": "Business audit tips",
		"storeApply": "Shop application",
		"storeReopen": "Contract application",
		"businessCat": "Business category",
		"businessWithdrawal": "Business withdrawal",
		"userReminder": "Member reminder",
		"userWithdrawal": "Member withdrawal application",
		"complaint": "Complaint reminder",
		"expose": "Report reminder",
		"activity": "Activity reminder",
		"flashSale": "Purchase audit",
		"team": "Group audit",
		"preSell": "Pre sale activities",
		"help": "Help",
		"password": "Change Password",
		"novice": "Novice guide",
		"quit": "Exit the system",
		"guide": "Novice Guide",
		"systemSettings": "System Settings",
		"basicSettings": "Basic Settings",
		"basicStatement": "The basic setting is used to set the basic information of the mall. You can fill in the mall name, logo, domain name and other information here. Please fill in truthfully to avoid subsequent adverse effects.",
		"nowFillIn": "Fill in now",
		"mallDecoration": "Mall Decoration",
		"decorationStatement1": "The TPshop system has built-in multiple sets of exquisite templates. You can choose your favorite template or use the default template.",
		"decorationStatement2": "TPshop has a wealth of decoration components in the backend, let's use your imagination and DIY freely",
		"nowDecoration": "Now Decoration",
		"noShow": "Do not show this guide next time",
		"prev": "Previous step",
		"next": "Next",
		"commodityData": "Commodity Data",
		"productList": "Product List",
		"listStatement": "By viewing the product list, you can get a comprehensive understanding of the general situation of the products on the entire platform, which is beneficial for merchants to control the operation of the platform.",
		"nowRelease": "Release Now",
		"commoditySpec": "Commodity Model Specification",
		"specStatement": "The product model and specifications are available for customers to view, such as the type of shoes and the size and color for customers to choose when buying.",
		"nowAdd": "Now Add",
		"nowSetup": "Now Setup",
		"nowCheck": "Check now",
		"addBrand": "Add Brand",
		"brandStatement": "Brand positioning can effectively establish the difference between the brand and competing products, and enhance consumers' buying impression.",
		"setUpShop": "Set Up Shop",
		"setUpStatement": "A complete multi-merchant platform center can be established by setting store classification and level information.",
		"marketPromotion": "Marketing Promotion",
		"promotionManagement": "Promotion Management",
		"marketStatement": "Through careful management of various promotional activities of merchants, platform store traffic and sales can develop faster.",
		"advPromotion": "Advertising Promotion",
		"advStatement": "Through careful management of various promotional activities of merchants, platform store traffic and sales can develop faster.",
		"distributionSystem": "Distribution System",
		"distributeStatement": "Set up a membership distribution system to motivate more people to promote purchases through member commissions.",
		"businessManagement": "Business Management",
		"paySettings": "Payment Configuration",
		"payStatement": "The TPshop system has built-in Alipay, WeChat Pay, UnionPay and other payment methods for users to choose.",
		"seoSettings": "SEO Settings",
		"seoStatement": "Search engine optimization settings are an important tool for enriching the business circulation of a comprehensive mall store.",
		"logisticsSettings": "logistics Settings",
		"logisticsStatement": "The TPshop system has built-in interfaces for a variety of major domestic logistics companies, which is convenient for merchants to choose the most comfortable logistics company.",
		"orderManagement": "Order Management",
		"orderStatement": "Commodity order management can quickly view mall orders in batches, and quickly view the order status of the entire platform.",
		"complete": "Complete",
		"completeStatement": "Congratulations! You have completed the basic settings of the mall system, you can go to the front desk to see if the shopping process is smooth",
		"roleManagement": "Role Management",
		"roleStatement": "Help merchants allocate mall management staff more reasonably and improve management efficiency",
		"memberLevel": "Member Level",
		"levelStatement": "The membership level setting is a measure to give members a sense of accomplishment and increase stickiness",
		"inventoryLog": "Inventory Log",
		"logStatement": "Analyze the inventory data of the merchants on the entire platform, control the merchants' sales risks",
		"smsTemplate": "SMS Template",
		"smsStatement": "You can set up in the background to automatically send news dynamics to members via SMS or email",
		"memberCard": "Member Card",
		"cardStatement": "Setting up different membership cards and corresponding benefits can effectively promote customer consumption",
		"dataStatistics": "Data Statistics",
		"dataStatement": "Traffic statistics, sales statistics, business analysis, etc., shopping mall data at a glance",
		"news": "News",
		"newsStatement": "Editing news and articles can increase the activity of the entire platform",
		"oldPassword": "Old Password",
		"newPassword": "New Password",
		"confirmPassword": "Confirm Password",
		"cancel": "Cancel",
		"confirm": "Confirm",
		"pendingMatters": "Pending Matters",
		"checkPendingMatters": "Check PendingMatters",
	}
}

export const zh = {
	"topNav": {
		"home": "首页",
		"setUp": "设置",
		"page": "页面",
		"shop": "商城",
		"order": "订单",
		"market": "营销",
		"distribution": "分销",
		"statistics": "统计",
		"business": "商家",
		"jobs": "调度中心",
		"msg": "消息",
		"productTips": "商品提示",
		"approved": "待审核商品",
		"refundOrder": "退款单",
		"return": "售后退货",
		"tips": "商家审核提示",
		"storeApply": "开店申请",
		"storeReopen": "签约申请",
		"businessCat": "经营类目申请",
		"businessWithdrawal": "商家提现申请",
		"userReminder": "会员提醒",
		"userWithdrawal": "会员提现申请",
		"complaint": "投诉提醒",
		"expose": "举报提醒",
		"activity": "活动提醒",
		"flashSale": "抢购审核",
		"team": "拼团审核",
		"preSell": "预售活动",
		"help": "帮助",
		"password": "修改密码",
		"novice": "新手向导",
		"quit": "退出系统",
		"guide": "新手向导",
		"systemSettings": "系统设置",
		"basicSettings": "基本设置",
		"basicStatement": "基本设置用来设置商城的基本信息，你可以在这里填写商城名称、标志、域名等信息，请务必如实填写以免后续造成不良影响。",
		"nowFillIn": "现在填写",
		"mallDecoration": "商城装修",
		"decorationStatement1": "TPshop系统内置了多套精美模版，你可以挑选最喜欢的模板，也可以使用默认模板。",
		"decorationStatement2": "TPshop后台拥有丰富的装修组 件，发挥想象力自由DIY吧",
		"nowDecoration": "现在装修",
		"noShow": "下次不再显示此向导",
		"prev": "上一步",
		"next": "下一步",
		"commodityData": "商品数据",
		"productList": "商品列表",
		"listStatement": "通过查看商品列表，可以全面的了解到整个平台商品的大致情况，有利于商家掌控平台的运营。",  
		"nowRelease": "现在发布",
		"commoditySpec": "商品模型规格",
		"specStatement": "商品模型和规格可供顾客查看，如鞋子的种类和供客户购买时选择的如码数、颜色等。",
		"nowAdd": "现在添加",
		"nowSetup": "现在设置",
		"nowCheck": "现在查看",
		"addBrand": "添加品牌",
		"brandStatement": "品牌定位可以有效地建立品牌与竞品的差异性，提升消费者的购买印象。",
		"setUpShop": "设置商家店铺",
		"setUpStatement": "通过设置店铺分类和等级等信息，才能建立一个完整的多商家平台中心。",
		"marketPromotion": "营销推广",
		"promotionManagement": "促销管理",
		"marketStatement": "通过细心管理商家的各类促销活动，能够让平台店铺流量和销量发展得更加快速。",
		"advPromotion": "广告推广",
		"advStatement": "通过细心管理商家的各类促销活动，能够让平台店铺流量和销量发展得更加快速。",
		"distributionSystem": "分销系统",
		"distributeStatement": "设置会员分销体系，通过会员提成激励更多人来推广购买商品。",
		"businessManagement": "业务管理",
		"paySettings": "支付配置",
		"payStatement": "TPshop系统内置支付宝、微信支付、银联支付等多种支付方式供用户选择。",
		"seoSettings": "SEO设置",
		"seoStatement": "搜索引擎优化设置，是丰富一个综合商城店铺业务流转的重要工具。",
		"logisticsSettings": "物流设置",
		"logisticsStatement": "TPshop系统已内置了国内多种主要流物流公司接口，方便商家选择心怡的物流公司。",
		"orderManagement": "订单管理",
		"orderStatement": "商品订单管理可以批量快捷查看商城订单，可以快速查看整个平台的订单情况。",
		"complete": "完成",
		"completeStatement": "恭喜您！您已经完成了商城系统的基本设置，您可以去前台看看购物流程是否顺畅",
		"roleManagement": "角色管理",
		"roleStatement": "有助于商家更合理的分配商城 管理人手，提升管理效率",
		"memberLevel": "会员等级",
		"levelStatement": "会员等级的设置是一种给会员 成就感和提升黏度的措施",
		"inventoryLog": "库存日志",
		"logStatement": "分析整个平台商家的库存数据 管控商家销售风险",
		"smsTemplate": "短信模板",
		"smsStatement": "您可在后台设置通过短信、邮 件自动发送消息动态给会员",
		"memberCard": "会员卡",
		"cardStatement": "设置不同的会员卡和相应的优 惠，能有效促进客户消费",
		"dataStatistics": "数据统计",
		"dataStatement": "流量统计、销售统计、生意分 析等，商城数据一览无余",
		"news": "新闻",
		"newsStatement": "新闻和文章的编辑，可以提升 整个平台的活跃度",
		"oldPassword": "原密码",
		"newPassword": "新密码",
		"confirmPassword": "确认密码",
		"cancel": "取 消",
		"confirm": "确 定",
		"pendingMatters": "待处理事项",
		"checkPendingMatters": "查看待处理事项",
	}
}
