import {mapState} from 'vuex';
import i18n from '@/i18n/index.js'
export default{
    data() {
        return {

        }
    },
    computed: {
        ...mapState(['CurrencyInfo']), 
        i18nCommon(){
            return this.$t("common")
        }
    },
    watch:{
        '$route.path':function (new1,old) {
            /*监听路由变化改货币*/ 
            let language = i18n.locale.toLowerCase()
            this.i18nCommon.symbol =this.CurrencyInfo.sign
            //this.i18nCommon.currency = this.CurrencyInfo.[`currency_name_${language}`]
        }
    },
    created() {
        
    }
}