export const en = {
  "guarantee":{
    "guaranteeApply":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"列表为店铺申请加入各项消费者保障服务记录",
      "state2":"当店铺提出的申请记录状态为“等待审核”、“保证金待审核”的时候，可以编辑申请；否则只能查看申请详情。",
      "hideTips":"收起提示",
      "list":"申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "storeName":"店铺名称",
      "service":"保障服务",
      "addTime":"添加时间",
      "status":"状态",
      "operation":"操作",
      "audit":"审核",
      "check":"查看",
      "entryName":"项目名称",
      "time":"申请时间",
      "voucher":"付款凭证",
      "auditStatus":"审核状态：",
      "toAudit":"等待审核",
      "audited":"审核通过",
      "unpayDeposit":"待支付保证金",
      "notAudit":"审核未通过",
      "depositAudit":"保证金审核",
      "depositAudited":"保证金审核通过",
      "notDepositAudit":"保证金审核失败",
      "remark":"原因备注：",
      "state3":"原因备注应小于200个字符",
      "submit":"提 交",
    },
    "guaranteeCostlogList":{
      "backToList":"返回列表",
      "consumerService":"消费者保障服务",
      "log":"保证金日志",
      "storeName":"店铺名称",
      "entryName":"项目名称",
      "balance":"保证金余额",
      "status":"状态",
      "logList":"保证金日志列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "service":"保障服务",
      "dollar":"金额",
      "addTime":"添加时间",
      "operator":"操作人",
      "describe":"描述",
    },
    "guaranteeItem":{
      "backToList":"返回列表",
      "management":"消费者保障服务管理",
      "checkAndManagement":"消费者保障服务查看与管理",
      "name":"项目名称：",
      "state1":"项目名称不能为空且不能大于50个字符",
      "bond":"保证金：",
      "state2":"保证金不能为空且必须为数字",
      "icon":"项目图标：",
      "describe":"项目描述：",
      "state3":"项目描述不能为空且小于200个字符",
      "address":"说明文章链接地址：",
      "sort":"排序：",
      "state4":"排序应为大于1的正整数",
      "status":"状态：",
      "open":"开启",
      "close":"关闭",
      "submit":"确认提交",
    },
    "guaranteeItemList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"列表为平台消费者保障服务项目",
      "state2":"当保障项目状态为'开启'时，店铺可以申请加入该服务；状态为'关闭'时，平台将会禁用该保障服务。",
      "hideTips":"收起提示",
      "list":"保障服务列表",
      "total":"共",
      "records":"条记录",
      "add":"新增消费者保障服务",
      "refresh":"刷新数据",
      "name":"项目名称",
      "guaranteedAmount":"保证金",
      "status":"状态",
      "sort":"排序",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
    "guaranteeList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"列表为店铺各项消费者保障服务信息以及其当下的状态",
      "state2":"点击'编辑'可以进入店铺加入服务的详情页面，并可以关闭店铺使用的保障服务",
      "hideTips":"收起提示",
      "list":"店铺保障服务列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "search":"搜索",
      "storeName":"店铺名称",
      "securityService":"保障服务",
      "guaranteedAmount":"保证金",
      "status":"状态",
      "operation":"操作",
      "management":"管理",
      "check":"查看",
      "log":"保证金日志",
      "serviceManagement":"消费者保障服务管理",
      "entryName":"项目名称",
      "close":"关闭状态",
      "allow":"允许使用",
      "noAllow":"禁止使用",
      "remark":"原因备注：",
      "state3":"原因备注应小于200个字符",
      "submit":"提 交",
    },
    "guaranteeLogList":{
      "backToList":"返回列表",
      "consumerService":"消费者保障服务",
      "checkAndManagement":"消费者保障服务查看与管理",
      "storeName":"店铺名称",
      "entryName":"项目名称",
      "balance":"保证金余额",
      "status":"状态",
      "list":"保障服务日志列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "service":"保障服务",
      "addTime":"添加时间",
      "operator":"操作人",
      "describe":"描述",
    },
  },
  "ownStore":{
    "ownStores":{
      "management":"店铺管理",
      "indexAndManagement":"网站系统店铺索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"1. 平台在此处统一管理自营店铺，可以新增、编辑、删除平台自营店铺。",
      "state2":"2. 可以设置未绑定全部商品类目的平台自营店铺的经营类目。",
      "state3":"3. 已经发布商品的自营店铺不能被删除。",
      "state4":"4. 删除平台自营店铺将会同时删除店铺的相关图片以及相关商家中心账户，请谨慎操作！",
      "hideTips":"收起提示",
      "list":"店铺列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺",
      "refresh":"刷新数据",
      "level":"所属等级",
      "storeType":"店铺类别",
      "storeStatus":"店铺状态",
      "sellerAccount":"卖家账号",
      "name":"店铺名称",
      "search":"搜索",
      "shopkeeperAccount":"店主账号",
      "shopkeeperSellerAccount":"店主卖家账号",
      "createDate":"创建日期",
      "status":"状态",
      "recommend":"推荐",
      "yes":"是",
      "no":"否",
      "synchronization":"默认同步平台",
      "bindAll":"绑定所有类目",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "storeClass":{
    "storeClass":{
      "backToList":"返回列表",
      "management":"店铺分类管理",
      "edit":"编辑",
      "add":"新增",
      "storeSort":"店铺分类",
      "webManagement":"网站系统店铺分类管理",
      "sortName":"分类名称",
      "setName":"设置店铺分类名称",
      "guaranteedAmount":"保证金额度",
      "noLimit":"0表示没有限制",
      "sort":"排序",
      "state":"数值越大表明前台列表排得越前",
      "submit":"确认提交",
    },
    "storeClassList":{
      "management":"店铺分类管理",
      "indexAndManagement":"网站系统店铺分类索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"店铺分类管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"店铺分类列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺分类",
      "refresh":"刷新数据",
      "sortName":"分类名称",
      "guaranteedAmount":"保证金额度",
      "sort":"排序",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "storeGrade":{
    "storeGrade":{
      "backToList":"返回列表",
      "management":"店铺等级管理",
      "edit":"编辑",
      "add":"新增",
      "store":"店铺",
      "webManagement":"网站系统店铺等级管理",
      "levelName":"等级名称",
      "setLevelName":"设置店铺等级名称",
      "goodsNum":"可发布商品数",
      "image":"可上传图片数",
      "template":"可选模板套数",
      "nolimit":"0表示没有限制",
      "standard":"收费标准",
      "unit":"/年,",
      "state1":"收费标准，必须为数字，在会员开通或升级店铺时将显示在前台",
      "level":"级别",
      "state2":"数值越大表明级别越高",
      "statement":"申请说明",
      "state3":"申请说明，在会员开通或升级店铺时将显示在前台",
      "submit":"确认提交",
    },
    "storeGrades":{
      "management":"店铺等级管理",
      "indexAndManagement":"网站系统店铺等级索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"店铺等级管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"店铺等级列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺等级",
      "refresh":"刷新数据",
      "level":"级别",
      "levelName":"等级名称",
      "publish":"可发布商品数",
      "image":"可上传图片数",
      "template":"可选模板套数",
      "standard":"收费标准/年",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "storeManage":{
    "applyClassList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"第三方店铺提交申请的经营类目",
      "hideTips":"收起提示",
      "list":"经营类目申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "status":"审核状态",
      "name":"店铺名称",
      "search":"搜索",
      "category":"经营类目",
      "account":"店主账号",
      "proportion":"分佣比例",
      "operation":"操作",
      "adopt":"通过",
      "reject":"拒绝",
      "delete":"删除",
    },
    "reopen":{
      "backToList":"返回列表",
      "management":"店铺等级管理",
      "level":"审核店铺等级",
      "levelManagement":"网站系统店铺等级管理",
      "storeName":"店铺名称：",
      "levelName":"等级名称：",
      "term":"申请期限：",
      "time":"申请时间：",
      "duration":"续费时长：",
      "standard":"收费标准：",
      "sum":"应付总额：",
      "voucher":"支付凭证：",
      "year":"年",
      "statement":"付款凭证说明：",
      "remark":"审核备注：",
      "audit":"审核：",
      "adopt":"通过",
      "reject":"拒绝",
      "status":"审核状态：",
      "submit":"确认提交",
    },
    "reopenList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "hideTips":"收起提示",
      "list":"签约申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "level":"所属等级",
      "type":"店铺类别",
      "shopkeeperName":"店主名称",
      "storeName":"店铺名称",
      "search":"搜索",
      "levelName":"等级名称",
      "time":"申请时间",
      "standard":"收费标准",
      "perYear":"/年",
      "duration":"续签时长（年）",
      "money":"付款金额（年）",
      "effective":"续签起止有效期",
      "status":"状态",
      "voucher":"付款凭证",
      "operation":"操作",
      "check":"查看",
      "delete":"删除",
    },
    "storeAdd":{
      "backToList":"返回列表",
      "selfRun":"自营店铺",
      "store":"店铺",
      "add":"新增",
      "setupAndManagement":"商城店铺相关设置与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"1. 平台可以在此处添加店铺，新增的店铺默认为开启状态",
      "state2":"2. 新增店铺默认绑定所有经营类目并且佣金为0，可以手动设置绑定其经营类目",
      "state3":"3. 新增店铺将自动创建店主会员账号（用于登录网站会员中心）以及商家账号（用于登录商家中心）",
      "hideTips":"收起提示",
      "name":"店铺名称",
      "memberAccount":"会员账号",
      "state4":"请输入手机号或者邮箱，用于登录会员中心",
      "storeAccount":"商家账号",
      "state5":"用于登录商家中心，可与店主账号不同",
      "password":"登陆密码",
      "submit":"确认提交",
    },
    "storeApplyDetail":{
      "backToList":"返回列表",
      "management":"店铺管理",
      "check":"查看会员",
      "message":"的店铺申请信息",
      "operation":"店铺的申请资料操作",
      "messages":"公司及联系人信息",
      "corporateName":"公司名称：",
      "companyNature":"公司性质：",
      "companyWeb":"公司网址：",
      "companyAddress":"公司所在地：",
      "companyDetail":"公司详细地址：",
      "fixedPhone":"固定电话：",
      "postalCode":"邮政编码：",
      "email":"电子邮箱：",
      "fax":"传真：",
      "contactName":"联系人姓名：",
      "contactPhone":"联系人电话：",
      "contactEmail":"联系人邮箱：",
      "licenseInfo":"营业执照信息（副本）",
      "licenseId":"营业执照号：",
      "licenseValidity":"营业执照有效期：",
      "longTerm":"长期",
      "range":"法定经营范围：",
      "registeredCapital":"注册资本：",
      "organizationCode":"组织机构代码：",
      "taxpayer":"一般纳税人证明：",
      "representative":"法人代表姓名：",
      "taxCode":"纳税类型税码：",
      "registration":"税务登记号码：",
      "bankMessage":"开户银行信息：",
      "bankName":"银行开户名：",
      "bankAccount":"公司银行账号：",
      "bankBranchName":"开户银行支行名称：",
      "bankAddress":"开户银行所在地：",
      "businessInfo":"店铺经营信息",
      "sellerAccount":"卖家账号：",
      "storeName":"店铺名称：",
      "storeType":"店铺分类：",
      "storeManager":"店铺负责人：",
      "managerPhone":"负责人手机号码：",
      "managerQQ":"负责人QQ号码：",
      "storeLevel":"店铺等级：",
      "openingYears":"开店年限：",
      "year":"年",
      "storeNature":"店铺性质：",
      "amount":"付款金额：",
      "voucher":"付款凭证：",
      "category":"经营类目：",
      "sort1":"分类1",
      "sort2":"分类2",
      "sort3":"分类3",
      "proportion":"比例",
      "certificateInfo":"证件信息：",
      "licenseCopy":"企业营业执照副本：",
      "taxRegistrationCopy":"税务登记证复印件：",
      "organizationCodeCopy":"组织机构代码证复印件：",
      "corporateCard":"其它附件：",
      "managerCard":"店铺负责人身份证：",
      "operationMess":"操作信息",
      "remark":"备注说明：",
      "toRemark":"请输入操作备注",
      "audit":"商家信息审核：",
      "notAudit":"未审核",
      "adopt":"通过",
      "notAdopt":"未通过",
      "submit":"确认提交",
    },
    "storeApplys":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"第三方申请入驻的店铺在该列表出现, 审核通过之后出现在店铺列表",
      "hideTips":"收起提示",
      "list":"开店申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "level":"所属等级",
      "type":"店铺类别",
      "shopkeeperName":"店主名称",
      "storeName":"店铺名称",
      "search":"搜索",
      "shopkeeperSellerAccount":"店主卖家账号",
      "recommender":"推荐人",
      "contacts":"联系人",
      "address":"所在地",
      "time":"申请时间",
      "status":"申请状态",
      "operation":"操作",
      "audit":"审核",
      "delete":"删除",
    },
    "storeClassInfo":{
      "backToList":"返回列表",
      "management":"店铺管理",
      "edit":"编辑店铺",
      "category":"的经营类目",
      "list":"经营类目列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "sort1":"分类一",
      "sort2":"分类二",
      "sort3":"分类三",
      "rate":"分佣比例",
      "status":"状态",
      "operation":"操作",
      "delete":"删除",
      "addCategory":"添加经营类目",
      "add":"添 加",
    },
    "storeDetail":{
      "operation":"店铺的审核续费及经营类目结算周期操作",
      "openingFee":"开店费用：",
      "year":"年",
      "managerEmail":"负责人邮箱：",
    },
    "storeEdit":{
      "editStore":"编辑店铺",
      "webEditStore":"网站系统编辑店铺",
      "storeMessage":"店铺信息",
      "regMessage":"注册信息",
      "storeAccount":"店铺账号",
      "openingTime":"店铺开通时间",
      "select":"选择日期",
      "service":"店铺保证服务",
      "bondDisplay":"保证金显示",
      "bond":"保证金",
      "unit":"单位：",
      "sevenDayReturn":"七天退换",
      "needAudit":"商品、活动是否需要审核",
      "subdomain":"店铺二级域名",
      "status":"状态",
      "managerEmail":"负责人邮箱：",
      "state":"6-16位字母数字符号组合",
      "storeValidity":"店铺有效期",
      "taxRate":"纳税类型税率：",
    },
    "stores":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"如果当前店铺处于关闭状态，前台将不能继续浏览该店铺，但是店主仍然可以编辑该店铺",
      "hideTips":"收起提示",
      "list":"店铺列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺",
      "refresh":"刷新数据",
      "level":"所属等级",
      "storeType":"店铺类别",
      "storeStatus":"店铺状态",
      "sellerAccount":"卖家账号",
      "storeName":"店铺名称",
      "search":"搜索",
      "storeId":"店铺ID",
      "shopkeeperAccount":"店主账号",
      "nickname":"店主昵称",
      "recommender":"推荐人",
      "member":"店铺会员",
      "enteringDate":"进驻日期",
      "validity":"有效期",
      "turnover":"营业额",
      "status":"状态",
      "recommend":"推荐",
      "yes":"是",
      "no":"否",
      "operation":"操作",
      "check":"查看",
      "edit":"编辑",
      "category":"经营类目",
      "delete":"删除",
    },
  },
  "storeSatisfaction":{
    "storeSatisfactionList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"展示商家服务相关信息的评分",
      "state2":"可根据店铺名称、用户名称进行搜索",
      "hideTips":"收起提示",
      "list":"满意度评价列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "userName":"用户名称",
      "storeName":"店铺名称",
      "search":"搜索",
      "orderId":"订单号",
      "consistency":"商品描述相符",
      "attitude":"卖家服务态度",
      "speed":"物流发货速度",
      "time":"评论时间",
    },
    "storeScoreList":{
      "state":"订单商品评分数量为0时，默认给5分",
      "list":"店铺评分列表",
      "storeId":"商家ID",
      "scoreNum":"订单商品评分数量（条）",
    },
  },
  "_guarantee":{
    "service":"消费者保障服务",
    "checkAndManagement":"消费者保障服务查看与管理",
    "join":"加入申请",
    "quit":"退出申请",
    "storeService":"店铺保障服务",
    "serviceManagement":"保障服务管理",
  },
  "index":{
    "management":"店铺管理",
    "selfRun":"自营店铺",
    "sort":"店铺分类",
    "level":"店铺等级",
    "service":"消费者保障服务",
    "setting":"店铺设置",
    "help":"店铺帮助",
    "storeSatisfaction":"店铺满意度",
  },
  "_storeManage":{
    "management":"店铺管理",
    "indexAndManagement":"网站系统店铺索引与管理",
    "list":"店铺列表",
    "apply":"开店申请",
    "sign":"签约审核",
    "audit":"经营类目审核",
  },
  "_storeSatisfaction":{
    "storeSatisfaction":"店铺满意度",
    "businessSatisfaction":"商家满意度",
    "score":"店铺评分",
  },
}

export const zh = {
  "guarantee":{
    "guaranteeApply":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"列表为店铺申请加入各项消费者保障服务记录",
      "state2":"当店铺提出的申请记录状态为“等待审核”、“保证金待审核”的时候，可以编辑申请；否则只能查看申请详情。",
      "hideTips":"收起提示",
      "list":"申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "storeName":"店铺名称",
      "service":"保障服务",
      "addTime":"添加时间",
      "status":"状态",
      "operation":"操作",
      "audit":"审核",
      "check":"查看",
      "entryName":"项目名称",
      "time":"申请时间",
      "voucher":"付款凭证",
      "auditStatus":"审核状态：",
      "toAudit":"等待审核",
      "audited":"审核通过",
      "unpayDeposit":"待支付保证金",
      "notAudit":"审核未通过",
      "depositAudit":"保证金审核",
      "depositAudited":"保证金审核通过",
      "notDepositAudit":"保证金审核失败",
      "remark":"原因备注：",
      "state3":"原因备注应小于200个字符",
      "submit":"提 交",
    },
    "guaranteeCostlogList":{
      "backToList":"返回列表",
      "consumerService":"消费者保障服务",
      "log":"保证金日志",
      "storeName":"店铺名称",
      "entryName":"项目名称",
      "balance":"保证金余额",
      "status":"状态",
      "logList":"保证金日志列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "service":"保障服务",
      "dollar":"金额",
      "addTime":"添加时间",
      "operator":"操作人",
      "describe":"描述",
    },
    "guaranteeItem":{
      "backToList":"返回列表",
      "management":"消费者保障服务管理",
      "checkAndManagement":"消费者保障服务查看与管理",
      "name":"项目名称：",
      "state1":"项目名称不能为空且不能大于50个字符",
      "bond":"保证金：",
      "state2":"保证金不能为空且必须为数字",
      "icon":"项目图标：",
      "describe":"项目描述：",
      "state3":"项目描述不能为空且小于200个字符",
      "address":"说明文章链接地址：",
      "sort":"排序：",
      "state4":"排序应为大于1的正整数",
      "status":"状态：",
      "open":"开启",
      "close":"关闭",
      "submit":"确认提交",
    },
    "guaranteeItemList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"列表为平台消费者保障服务项目",
      "state2":"当保障项目状态为'开启'时，店铺可以申请加入该服务；状态为'关闭'时，平台将会禁用该保障服务。",
      "hideTips":"收起提示",
      "list":"保障服务列表",
      "total":"共",
      "records":"条记录",
      "add":"新增消费者保障服务",
      "refresh":"刷新数据",
      "name":"项目名称",
      "guaranteedAmount":"保证金",
      "status":"状态",
      "sort":"排序",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
    "guaranteeList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"列表为店铺各项消费者保障服务信息以及其当下的状态",
      "state2":"点击'编辑'可以进入店铺加入服务的详情页面，并可以关闭店铺使用的保障服务",
      "hideTips":"收起提示",
      "list":"店铺保障服务列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "search":"搜索",
      "storeName":"店铺名称",
      "securityService":"保障服务",
      "guaranteedAmount":"保证金",
      "status":"状态",
      "operation":"操作",
      "management":"管理",
      "check":"查看",
      "log":"保证金日志",
      "serviceManagement":"消费者保障服务管理",
      "entryName":"项目名称",
      "close":"关闭状态",
      "allow":"允许使用",
      "noAllow":"禁止使用",
      "remark":"原因备注：",
      "state3":"原因备注应小于200个字符",
      "submit":"提 交",
    },
    "guaranteeLogList":{
      "backToList":"返回列表",
      "consumerService":"消费者保障服务",
      "checkAndManagement":"消费者保障服务查看与管理",
      "storeName":"店铺名称",
      "entryName":"项目名称",
      "balance":"保证金余额",
      "status":"状态",
      "list":"保障服务日志列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "service":"保障服务",
      "addTime":"添加时间",
      "operator":"操作人",
      "describe":"描述",
    },
  },
  "ownStore":{
    "ownStores":{
      "management":"店铺管理",
      "indexAndManagement":"网站系统店铺索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"1. 平台在此处统一管理自营店铺，可以新增、编辑、删除平台自营店铺。",
      "state2":"2. 可以设置未绑定全部商品类目的平台自营店铺的经营类目。",
      "state3":"3. 已经发布商品的自营店铺不能被删除。",
      "state4":"4. 删除平台自营店铺将会同时删除店铺的相关图片以及相关商家中心账户，请谨慎操作！",
      "hideTips":"收起提示",
      "list":"店铺列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺",
      "refresh":"刷新数据",
      "level":"所属等级",
      "storeType":"店铺类别",
      "storeStatus":"店铺状态",
      "sellerAccount":"卖家账号",
      "name":"店铺名称",
      "search":"搜索",
      "shopkeeperAccount":"店主账号",
      "shopkeeperSellerAccount":"店主卖家账号",
      "createDate":"创建日期",
      "status":"状态",
      "recommend":"推荐",
      "yes":"是",
      "no":"否",
      "synchronization":"默认同步平台",
      "bindAll":"绑定所有类目",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "storeClass":{
    "storeClass":{
      "backToList":"返回列表",
      "management":"店铺分类管理",
      "edit":"编辑",
      "add":"新增",
      "storeSort":"店铺分类",
      "webManagement":"网站系统店铺分类管理",
      "sortName":"分类名称",
      "setName":"设置店铺分类名称",
      "guaranteedAmount":"保证金额度",
      "noLimit":"0表示没有限制",
      "sort":"排序",
      "state":"数值越大表明前台列表排得越前",
      "submit":"确认提交",
    },
    "storeClassList":{
      "management":"店铺分类管理",
      "indexAndManagement":"网站系统店铺分类索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"店铺分类管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"店铺分类列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺分类",
      "refresh":"刷新数据",
      "sortName":"分类名称",
      "guaranteedAmount":"保证金额度",
      "sort":"排序",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "storeGrade":{
    "storeGrade":{
      "backToList":"返回列表",
      "management":"店铺等级管理",
      "edit":"编辑",
      "add":"新增",
      "store":"店铺",
      "webManagement":"网站系统店铺等级管理",
      "levelName":"等级名称",
      "setLevelName":"设置店铺等级名称",
      "goodsNum":"可发布商品数",
      "image":"可上传图片数",
      "template":"可选模板套数",
      "nolimit":"0表示没有限制",
      "standard":"收费标准",
      "unit":"/年,",
      "state1":"收费标准，必须为数字，在会员开通或升级店铺时将显示在前台",
      "level":"级别",
      "state2":"数值越大表明级别越高",
      "statement":"申请说明",
      "state3":"申请说明，在会员开通或升级店铺时将显示在前台",
      "submit":"确认提交",
    },
    "storeGrades":{
      "management":"店铺等级管理",
      "indexAndManagement":"网站系统店铺等级索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"店铺等级管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"店铺等级列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺等级",
      "refresh":"刷新数据",
      "level":"级别",
      "levelName":"等级名称",
      "publish":"可发布商品数",
      "image":"可上传图片数",
      "template":"可选模板套数",
      "standard":"收费标准/年",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "storeManage":{
    "applyClassList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"第三方店铺提交申请的经营类目",
      "hideTips":"收起提示",
      "list":"经营类目申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "status":"审核状态",
      "name":"店铺名称",
      "search":"搜索",
      "category":"经营类目",
      "account":"店主账号",
      "proportion":"分佣比例",
      "operation":"操作",
      "adopt":"通过",
      "reject":"拒绝",
      "delete":"删除",
    },
    "reopen":{
      "backToList":"返回列表",
      "management":"店铺等级管理",
      "level":"审核店铺等级",
      "levelManagement":"网站系统店铺等级管理",
      "storeName":"店铺名称：",
      "levelName":"等级名称：",
      "term":"申请期限：",
      "time":"申请时间：",
      "duration":"续费时长：",
      "standard":"收费标准：",
      "sum":"应付总额：",
      "voucher":"支付凭证：",
      "year":"年",
      "statement":"付款凭证说明：",
      "remark":"审核备注：",
      "audit":"审核：",
      "adopt":"通过",
      "reject":"拒绝",
      "status":"审核状态：",
      "submit":"确认提交",
    },
    "reopenList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "hideTips":"收起提示",
      "list":"签约申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "level":"所属等级",
      "type":"店铺类别",
      "shopkeeperName":"店主名称",
      "storeName":"店铺名称",
      "search":"搜索",
      "levelName":"等级名称",
      "time":"申请时间",
      "standard":"收费标准",
      "perYear":"/年",
      "duration":"续签时长（年）",
      "money":"付款金额（年）",
      "effective":"续签起止有效期",
      "status":"状态",
      "voucher":"付款凭证",
      "operation":"操作",
      "check":"查看",
      "delete":"删除",
    },
    "storeAdd":{
      "backToList":"返回列表",
      "selfRun":"自营店铺",
      "store":"店铺",
      "add":"新增",
      "setupAndManagement":"商城店铺相关设置与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"1. 平台可以在此处添加店铺，新增的店铺默认为开启状态",
      "state2":"2. 新增店铺默认绑定所有经营类目并且佣金为0，可以手动设置绑定其经营类目",
      "state3":"3. 新增店铺将自动创建店主会员账号（用于登录网站会员中心）以及商家账号（用于登录商家中心）",
      "hideTips":"收起提示",
      "name":"店铺名称",
      "memberAccount":"会员账号",
      "state4":"请输入手机号或者邮箱，用于登录会员中心",
      "storeAccount":"商家账号",
      "state5":"用于登录商家中心，可与店主账号不同",
      "password":"登陆密码",
      "submit":"确认提交",
    },
    "storeApplyDetail":{
      "backToList":"返回列表",
      "management":"店铺管理",
      "check":"查看会员",
      "message":"的店铺申请信息",
      "operation":"店铺的申请资料操作",
      "messages":"公司及联系人信息",
      "corporateName":"公司名称：",
      "companyNature":"公司性质：",
      "companyWeb":"公司网址：",
      "companyAddress":"公司所在地：",
      "companyDetail":"公司详细地址：",
      "fixedPhone":"固定电话：",
      "postalCode":"邮政编码：",
      "email":"电子邮箱：",
      "fax":"传真：",
      "contactName":"联系人姓名：",
      "contactPhone":"联系人电话：",
      "contactEmail":"联系人邮箱：",
      "licenseInfo":"营业执照信息（副本）",
      "licenseId":"营业执照号：",
      "licenseValidity":"营业执照有效期：",
      "longTerm":"长期",
      "range":"法定经营范围：",
      "registeredCapital":"注册资本：",
      "organizationCode":"组织机构代码：",
      "taxpayer":"一般纳税人证明：",
      "representative":"法人代表姓名：",
      "taxCode":"纳税类型税码：",
      "registration":"税务登记号码：",
      "bankMessage":"开户银行信息：",
      "bankName":"银行开户名：",
      "bankAccount":"公司银行账号：",
      "bankBranchName":"开户银行支行名称：",
      "bankAddress":"开户银行所在地：",
      "businessInfo":"店铺经营信息",
      "sellerAccount":"卖家账号：",
      "storeName":"店铺名称：",
      "storeType":"店铺分类：",
      "storeManager":"店铺负责人：",
      "managerPhone":"负责人手机号码：",
      "managerQQ":"负责人QQ号码：",
      "storeLevel":"店铺等级：",
      "openingYears":"开店年限：",
      "year":"年",
      "storeNature":"店铺性质：",
      "amount":"付款金额：",
      "voucher":"付款凭证：",
      "category":"经营类目：",
      "sort1":"分类1",
      "sort2":"分类2",
      "sort3":"分类3",
      "proportion":"比例",
      "certificateInfo":"证件信息：",
      "licenseCopy":"企业营业执照副本：",
      "taxRegistrationCopy":"税务登记证复印件：",
      "organizationCodeCopy":"组织机构代码证复印件：",
      "corporateCard":"其它附件：",
      "managerCard":"店铺负责人身份证：",
      "operationMess":"操作信息",
      "remark":"备注说明：",
      "toRemark":"请输入操作备注",
      "audit":"商家信息审核：",
      "notAudit":"未审核",
      "adopt":"通过",
      "notAdopt":"未通过",
      "submit":"确认提交",
    },
    "storeApplys":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"第三方申请入驻的店铺在该列表出现, 审核通过之后出现在店铺列表",
      "hideTips":"收起提示",
      "list":"开店申请列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "level":"所属等级",
      "type":"店铺类别",
      "shopkeeperName":"店主名称",
      "storeName":"店铺名称",
      "search":"搜索",
      "shopkeeperSellerAccount":"店主卖家账号",
      "recommender":"推荐人",
      "contacts":"联系人",
      "address":"所在地",
      "time":"申请时间",
      "status":"申请状态",
      "operation":"操作",
      "audit":"审核",
      "delete":"删除",
    },
    "storeClassInfo":{
      "backToList":"返回列表",
      "management":"店铺管理",
      "edit":"编辑店铺",
      "category":"的经营类目",
      "list":"经营类目列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "sort1":"分类一",
      "sort2":"分类二",
      "sort3":"分类三",
      "rate":"分佣比例",
      "status":"状态",
      "operation":"操作",
      "delete":"删除",
      "addCategory":"添加经营类目",
      "add":"添 加",
    },
    "storeDetail":{
      "operation":"店铺的审核续费及经营类目结算周期操作",
      "openingFee":"开店费用：",
      "year":"年",
      "managerEmail":"负责人邮箱：",
    },
    "storeEdit":{
      "editStore":"编辑店铺",
      "webEditStore":"网站系统编辑店铺",
      "storeMessage":"店铺信息",
      "regMessage":"注册信息",
      "storeAccount":"店铺账号",
      "openingTime":"店铺开通时间",
      "select":"选择日期",
      "service":"店铺保证服务",
      "bondDisplay":"保证金显示",
      "bond":"保证金",
      "unit":"单位：",
      "sevenDayReturn":"七天退换",
      "needAudit":"商品、活动是否需要审核",
      "subdomain":"店铺二级域名",
      "status":"状态",
      "managerEmail":"负责人邮箱：",
      "state":"6-16位字母数字符号组合",
      "storeValidity":"店铺有效期",
      "taxRate":"纳税类型税率：",
    },
    "stores":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"如果当前店铺处于关闭状态，前台将不能继续浏览该店铺，但是店主仍然可以编辑该店铺",
      "hideTips":"收起提示",
      "list":"店铺列表",
      "total":"共",
      "records":"条记录",
      "add":"新增店铺",
      "refresh":"刷新数据",
      "level":"所属等级",
      "storeType":"店铺类别",
      "storeStatus":"店铺状态",
      "sellerAccount":"卖家账号",
      "storeName":"店铺名称",
      "search":"搜索",
      "storeId":"店铺ID",
      "shopkeeperAccount":"店主账号",
      "nickname":"店主昵称",
      "recommender":"推荐人",
      "member":"店铺会员",
      "enteringDate":"进驻日期",
      "validity":"有效期",
      "turnover":"营业额",
      "status":"状态",
      "recommend":"推荐",
      "yes":"是",
      "no":"否",
      "operation":"操作",
      "check":"查看",
      "edit":"编辑",
      "category":"经营类目",
      "delete":"删除",
    },
  },
  "storeSatisfaction":{
    "storeSatisfactionList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"展示商家服务相关信息的评分",
      "state2":"可根据店铺名称、用户名称进行搜索",
      "hideTips":"收起提示",
      "list":"满意度评价列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "userName":"用户名称",
      "storeName":"店铺名称",
      "search":"搜索",
      "orderId":"订单号",
      "consistency":"商品描述相符",
      "attitude":"卖家服务态度",
      "speed":"物流发货速度",
      "time":"评论时间",
    },
    "storeScoreList":{
      "state":"订单商品评分数量为0时，默认给5分",
      "list":"店铺评分列表",
      "storeId":"商家ID",
      "scoreNum":"订单商品评分数量（条）",
    },
  },
  "_guarantee":{
    "service":"消费者保障服务",
    "checkAndManagement":"消费者保障服务查看与管理",
    "join":"加入申请",
    "quit":"退出申请",
    "storeService":"店铺保障服务",
    "serviceManagement":"保障服务管理",
  },
  "index":{
    "management":"店铺管理",
    "selfRun":"自营店铺",
    "sort":"店铺分类",
    "level":"店铺等级",
    "service":"消费者保障服务",
    "setting":"店铺设置",
    "help":"店铺帮助",
    "storeSatisfaction":"店铺满意度",
  },
  "_storeManage":{
    "management":"店铺管理",
    "indexAndManagement":"网站系统店铺索引与管理",
    "list":"店铺列表",
    "apply":"开店申请",
    "sign":"签约审核",
    "audit":"经营类目审核",
  },
  "_storeSatisfaction":{
    "storeSatisfaction":"店铺满意度",
    "businessSatisfaction":"商家满意度",
    "score":"店铺评分",
  },
}
