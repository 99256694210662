/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import Vue from 'vue'
import App from './App.vue'
import router from '@/router/router.js'
import store from '@/store/store.js'
// import Config from '@/config/index.js'
import request from "@/utils/request.js"
import axios from 'axios'
import VueClipboard from 'vue-clipboard2'

Vue.use(VueClipboard);
//导入全局样式
import '@/static/css/perfect-scrollbar.min.css' //原先项目样式
import '@/static/css/main.css' //原先项目样式
import '@/static/css/newstyle.css' //vue项目新样式
import '@/static/css/page.css' //原先项目样式

import '@/static/font/css/font-awesome.min.css' //原先项目样式
import ElementUI from 'element-ui'
import 'element-ui/lib/theme-chalk/index.css'
// import moment from 'moment'
import Print from "@/plugs/print.js";
Vue.use(Print);
Vue.use(ElementUI);

import '@/static/ue/ueditor.config.js'
import '@/static/ue/ueditor.all.js'
import '@/static/ue/lang/zh-cn/zh-cn.js'
import '@/static/ue/ueditor.parse.js'

import i18n from './i18n'
Vue.prototype._i18n = i18n

// 定义一个全局过滤器实现日期格式化
Vue.filter('datefmt', function (val) {
	let date = new Date(parseInt(val) * 1000);
	let Y = date.getFullYear() + '-';
	let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
	let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	return Y + M + D;
})

Vue.filter('datefmtYMDHM', function (val) {
	let date = new Date(parseInt(val) * 1000);
	let Y = date.getFullYear() + '-';
	let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
	let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
	let m = date.getMinutes() < 10 ? '0' + date.getMinutes() : date.getMinutes();
	return Y + M + D + h + m;
})

Vue.filter('datefmtAll', function (val) {
	let date = new Date(parseInt(val) * 1000);
	let Y = date.getFullYear() + '-';
	let M = date.getMonth() + 1 < 10 ? '0' + (date.getMonth() + 1) + '-' : date.getMonth() + 1 + '-';
	let D = date.getDate() < 10 ? '0' + date.getDate() + ' ' : date.getDate() + ' ';
	let h = date.getHours() < 10 ? '0' + date.getHours() + ':' : date.getHours() + ':';
	let m = date.getMinutes() < 10 ? '0' + date.getMinutes() + ':' : date.getMinutes() + ':';
	let s = date.getSeconds() < 10 ? '0' + date.getSeconds() : date.getSeconds();
	return Y + M + D + h + m + s;
})
//Vue.config.devtools = true;
Vue.prototype.request = request //把request绑定在vue实例上
new Vue({
	store,
	router,
	i18n,
	render: h => h(App),
}).$mount('#app')
