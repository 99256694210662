/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import request from '@/utils/request'


/**
 * 获取配置信息gg
 */
export async function getConfigs(param) {
	return request({
		url: '/mall/configs',
		method: 'get',
		params: param
	})
}

/**
 * 修改配置信息
 */
export async function updateConfigs(inc_type, param) {
	return request({
		url: '/mall/configs/' + inc_type,
		method: 'put',
		data: param
	})
}

/**
 * 会员签到配置
 */
export async function updateSignConfig(form) {
	return request({
		url: '/mall/configs/sign',
		method: 'put',
		data: form
	})
}

/**
 * 获取验证码
 */
export async function getVerifyImage(param) {
	return request({
		url: '/auth/verification',
		method: 'get',
		params: param
	})
}
/**
 * 管理员退出登录
 */
export async function logout() {
	return request({
		url: '/admin/logout',
		method: 'post'
	})
}

/**
 * 管理员修改密码
 */
export async function updatePassword(form) {
	return request({
		url: '/admin/password',
		method: 'put',
		data: form
	})
}

/**
 * 修改管理员基础信息
 */
export async function updateOpenTeach(param) {
	return request({
		url: '/admin/open_teach?open_teach=' + param,
		method: 'put'
	})
}

/**
 * 获取管理员基础信息
 */
export async function getBaseInfo() {
	return request({
		url: '/admin/current',
		method: 'get',
	})
}

/**
 * 获取总后台商品分类
 */
export async function getGoodsCategoryListAdmin(param) {
	return request({
		url: '/mall/goods/category/list/admin?parent_id=' + param,
		method: 'get',
	})
}

//邮件测试
export async function mailTest(form) {
	return request({
		url: '/user/mail',
		method: 'post',
		data: form
	});
}

//获取订单列表
export async function getOrderPage(params) {
	return request({
		url: '/order/page',
		method: 'get',
		params: params
	});
}

//获取退款单列表
export async function getRefundOrderPage(params) {
	return request({
		url: '/order/refund_order/page',
		method: 'get',
		params: params
	});
}

/**
 * 获取管理员分页
 */
export async function getAdminPage(param) {
	return request({
		url: '/admin/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取管理员
 */
export async function getAdmin(param) {
	return request({
		url: '/admin/' + param,
		method: 'get',
	})
}

/**
 * 添加管理员
 */
export async function addAdmin(param) {
	return request({
		url: '/admin',
		method: 'post',
		data: param
	})
}

/**
 * 添加管理员
 */
export async function updateAdmin(param) {
	return request({
		url: '/admin',
		method: 'put',
		data: param
	})
}

/**
 * 添加管理员
 */
export async function deleteAdmin(param) {
	return request({
		url: '/admin/' + param,
		method: 'delete',
	})
}

/**
 * 获取订单日期统计
 */
export async function getOrderReportDay(param) {
	return request({
		url: '/order/report/days',
		method: 'get',
		params: param
	})
}

/**
 * 获取会员统计
 */
export async function getUserReport() {
	return request({
		url: '/user/report',
		method: 'get'
	})
}

/**
 * 获取评论统计
 */
export async function getCommentCount(param) {
	return request({
		url: '/order/comment/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺申请统计
 */
export async function getStoreApplyCount(param) {
	return request({
		url: '/seller/store_apply/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单统计
 */
export async function getOrderReport() {
	return request({
		url: '/order/report',
		method: 'get'
	})
}

/**
 * 获取退货退款统计
 */
export async function getReturnGoodsReport() {
	return request({
		url: '/order/return_goods/report',
		method: 'get'
	})
}

/**
 * 获取店铺签约统计
 */
export async function getStoreReopenCount(param) {
	return request({
		url: '/seller/store_reopen/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取经营类目统计
 */
export async function getStoreBindClassCount(param) {
	return request({
		url: '/seller/store_bind_class/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取商家提现申请统计
 */
export async function getStoreWithdrawalsCount(param) {
	return request({
		url: '/seller/store_withdrawals/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取用户提现申请统计
 */
export async function getUserWithdrawalsCount(param) {
	return request({
		url: '/user/withdrawals/count/admin',
		method: 'get',
		params: param
	})
}

/**
 * 获取投诉统计
 */
export async function getComplainCount(param) {
	return request({
		url: '/order/complain/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取举报统计
 */
export async function getExposeCount(param) {
	return request({
		url: '/order/expose/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取抢购活动统计
 */
export async function getFlashSaleCount(param) {
	return request({
		url: '/mall/flash_sale/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取团购活动统计
 */
export async function getTeamActivityCount(param) {
	return request({
		url: '/mall/team/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取预售活动统计
 */
export async function getPreSellCount(param) {
	return request({
		url: '/mall/pre_sell/count',
		method: 'get',
		params: param
	})
}


/**
 * 获取品牌统计
 */
export async function getBrandCount(param) {
	return request({
		url: '/mall/brand/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品统计报告
 */
export async function getGoodsReport(param) {
	return request({
		url: '/mall/goods/report',
		method: 'get',
		params: param
	})
}

/**
 * 获取文章统计
 */
export async function getArticleCount(param) {
	return request({
		url: '/mall/article/count',
		method: 'get',
		params: param
	})
}

/**
 * 获取销售排行列表
 */
export async function getSaleTopPage(param) {
	return request({
		url: '/order/sales_ranking/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺排行
 */
export async function getStoreTop(param) {
	return request({
		url: '/order/store_ranking/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺类别
 */
export async function getStoreClass() {
	return request({
		url: '/seller/store_class/list',
		method: 'get'
	})
}

/**
 * 获取获取销售明细列表
 */
export async function getSaleList(param) {
	return request({
		url: '/order/sale_details/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取资金统计
 */
export async function getDaysFinance(param) {
	return request({
		url: '/order/finance/days',
		method: 'get',
		params: param
	})
}

/**
 * 获取商家提现申请/转款列表
 */
export async function getStoreWithdrawalsPage(param) {
	return request({
		url: '/seller/store_withdrawals/page',
		method: 'get',
		params: param
	})
}

/**
 * 批量修改商家提现申请
 */
export async function updateStoreWithdrawalsList(param1, param2) {
	return request({
		url: '/seller/store_withdrawals/status?ids=' + param1 + "&status=" + param2,
		method: 'put'
	})
}

/**
 * 删除商家提现申请
 */
export async function deleteStoreWithdrawals(param) {
	return request({
		url: '/seller/store_withdrawals/' + param,
		method: 'delete'
	})
}

/**
 * 获取商家提现申请
 */
export async function getStoreWithdrawals(param) {
	return request({
		url: '/seller/store_withdrawals',
		method: 'get',
		params: param
	})
}

/**
 * 修改商家提现申请
 */
export async function updateStoreWithdrawals(param) {
	return request({
		url: '/seller/store_withdrawals',
		method: 'put',
		data: param
	})
}


/**
 * 获取商家结算记录列表
 */
export async function getOrderStatis(param) {
	return request({
		url: '/order/order_statis/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取平台支出记录列表
 */
export async function getExpenseLog(param) {
	return request({
		url: '/admin/expense_log/page',
		method: 'get',
		params: param
	})
}

/**
 * 统计会员订单数和消费额
 */
export async function getUserTop(param) {
	return request({
		url: '/user/user_top/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取会员提现申请/转款记录
 */
export async function getUserWithdrawalsPage(param) {
	return request({
		url: '/user/withdrawals/withdrawals_page',
		method: 'get',
		params: param
	})
}

/**
 * 批量修改会员提现申请
 */
export async function updateUserWithdrawalsList(param1, param2) {
	return request({
		url: '/user/withdrawals/withdrawals_status?ids=' + param1 + "&status=" + param2,
		method: 'put'
	})
}

/**
 * 删除会员提现申请
 */
export async function deleteUserWithdrawals(param) {
	return request({
		url: '/user/withdrawals/' + param,
		method: 'delete'
	})
}

/**
 * 获取会员提现申请
 */
export async function getUserWithdrawals(param) {
	return request({
		url: '/user/withdrawals',
		method: 'get',
		params: param
	})
}

/**
 * 修改会员提现申请
 */
export async function updateUserWithdrawals(param) {
	return request({
		url: '/user/withdrawals',
		method: 'put',
		data: param
	})
}


/* ==================页面====================== */

/**
 * 获取新闻列表
 */
export async function getNewList(param) {
	return request({
		url: '/mall/news/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取新闻类别
 */
export async function getNewsCats() {
	return request({
		url: '/mall/news_cat/list',
		method: 'get'
	})
}

/**
 * 批量删除新闻
 */
export async function deleteNews(param) {
	return request({
		url: '/mall/news?ids=' + param,
		method: 'delete'
	})
}

/**
 * 编辑新闻
 */
export async function updateNews(param) {
	return request({
		url: '/mall/news',
		method: 'put',
		data: param
	})
}

/**
 * 新增新闻
 */
export async function addNews(param) {
	return request({
		url: '/mall/news',
		method: 'post',
		data: param
	})
}

/**
 * 获取新闻
 */
export async function getNews(param) {
	return request({
		url: '/mall/news?article_id=' + param,
		method: 'get'
	})
}

/**
 * 获取新闻分类列表
 */
export async function getNewCatList() {
	return request({
		url: '/mall/news_cat/list',
		method: 'get'
	})
}

/**
 * 删除新闻分类
 */
export async function removeNewsCat(param) {
	return request({
		url: '/mall/news_cat?cat_id=' + param,
		method: 'delete'
	})
}

/**
 * 修改新闻分类
 */
export async function updateNewsCat(param) {
	return request({
		url: '/mall/news_cat/sort_order',
		method: 'put',
		params: param
	})
}

/**
 * 获取文章列表
 */
export async function getArticlePage(param) {
	return request({
		url: '/mall/article/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取文章分类
 */
export async function getArticleCats() {
	return request({
		url: '/mall/article_cat/tree',
		method: 'get'
	})
}

/**
 * 修改/编辑文章
 */
export async function updateArticle(param) {
	return request({
		url: '/mall/article',
		method: 'put',
		data: param
	})
}

/**
 * 删除文章
 */
export async function deleteArticle(param) {
	return request({
		url: '/mall/article?article_id=' + param,
		method: 'delete'
	})
}

/**
 * 获取文章
 */
export async function getArticle(param) {
	return request({
		url: '/mall/article?article_id=' + param,
		method: 'get'
	})
}

/**
 * 新增文章
 */
export async function addArticle(param) {
	return request({
		url: '/mall/article',
		method: 'post',
		data: param
	})
}

/**
 * 获取树状结构文章分类
 */
export async function getArticleCatList() {
	return request({
		url: '/mall/article_cat/tree',
		method: 'get'
	})
}

/**
 * 修改文章分类排序
 */
export async function updateArticleCatSort(param) {
	return request({
		url: '/mall/article_cat/sort_order',
		method: 'put',
		params: param
	})
}

/**
 * 删除文章分类
 */
export async function deleteArticleCat(param) {
	return request({
		url: '/mall/article_cat?cat_id=' + param,
		method: 'delete'
	})
}

/**
 * 获取文章分类
 */
export async function getArticleCat(param) {
	return request({
		url: '/mall/article_cat?cat_id=' + param,
		method: 'get'
	})
}

/**
 * 修改文章分类
 */
export async function updateArticleCat(param) {
	return request({
		url: '/mall/article_cat',
		method: 'put',
		data: param
	})
}

/**
 * 新增文章分类
 */
export async function addArticleCat(param) {
	return request({
		url: '/mall/article_cat',
		method: 'post',
		data: param
	})
}

/**
 * 获取友情链接列表
 */
export async function getFriendLinkList(param) {
	return request({
		url: '/mall/friend_link/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改友情链接
 */
export async function updateFriendLink(param) {
	return request({
		url: '/mall/friend_link',
		method: 'put',
		data: param
	})
}

/**
 * 删除友情链接
 */
export async function deleteFriendLink(param) {
	return request({
		url: '/mall/friend_link?link_id=' + param,
		method: 'delete'
	})
}

/**
 * 新增友情链接
 */
export async function addFriendLink(param) {
	return request({
		url: '/mall/friend_link',
		method: 'post',
		data: param
	})
}

/**
 * 获取友情链接
 */
export async function getFriendLink(param) {
	return request({
		url: '/mall/friend_link?link_id=' + param,
		method: 'get'
	})
}

/**
 * 获取会员协议列表
 */
export async function getSystemArticleList() {
	return request({
		url: '/mall/system_article/list',
		method: 'get'
	})
}

/**
 * 获取会员协议
 */
export async function getSystemArticle(param) {
	return request({
		url: '/mall/system_article?doc_code=' + param,
		method: 'get'
	})
}

/**
 * 编辑会员协议
 */
export async function updateSystemArticle(param) {
	return request({
		url: '/mall/system_article',
		method: 'put',
		data: param
	})
}

/**
 * 新增会员协议
 */
export async function addSystemArticle(param) {
	return request({
		url: '/mall/system_article',
		method: 'post',
		data: param
	})
}

/**
 * 获取帮助内容列表
 */
export async function getHelpContentList(param) {
	return request({
		url: '/mall/help/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改帮助内容
 */
export async function updateHelpContent(param) {
	return request({
		url: '/mall/help',
		method: 'put',
		data: param
	})
}

/**
 * 删除帮助内容
 */
export async function deleteHelpContent(param) {
	return request({
		url: '/mall/help?help_id=' + param,
		method: 'delete'
	})
}

/**
 * 获取树状结构帮助类型
 */
export async function getHelpTypeList() {
	return request({
		url: '/mall/help_type/tree',
		method: 'get'
	})
}

/**
 * 修改帮助类型
 */
export async function updateHelpType(param) {
	return request({
		url: '/mall/help_type',
		method: 'put',
		data: param
	})
}

/**
 * 删除帮助类型
 */
export async function deleteHelpType(param) {
	return request({
		url: '/mall/help_type?type_id=' + param,
		method: 'delete'
	})
}

/**
 * 获取帮助类型
 */
export async function getHelpType(param) {
	return request({
		url: '/mall/help_type?type_id=' + param,
		method: 'get'
	})
}

/**
 * 获取帮助类型列表
 */
export async function getHelpTypes() {
	return request({
		url: '/mall/help_type/list/top_level',
		method: 'get'
	})
}

/**
 * 添加帮助类型
 */
export async function addHelpType(param) {
	return request({
		url: '/mall/help_type',
		method: 'post',
		data: param
	})
}

/**
 * 获取广告列表
 */
export async function getAdPage(param) {
	return request({
		url: '/mall/ad/page/admin',
		method: 'get',
		params: param
	})
}

/**
 * 修改广告
 */
export async function updateAd(param) {
	return request({
		url: '/mall/ad',
		method: 'put',
		data: param
	})
}

/**
 * 获取广告位置列表
 */
export async function getADPosition() {
	return request({
		url: '/mall/ad_position/list',
		method: 'get'
	})
}

/**
 * 获取广告
 */
export async function getAd(param) {
	return request({
		url: '/mall/ad',
		method: 'get',
		params: param
	})
}

/* ==================页面====================== */

/**
 * 获取商品分页(需要商品分类的数据时)
 */
export async function getGoodsPage(param) {
	return request({
		url: '/mall/goods/page/admin',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品分页
 */
export async function getGoodsIPage(param) {
	return request({
		url: '/mall/goods/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改商品
 */
export async function updateGoods(param) {
	return request({
		url: '/mall/goods',
		method: 'put',
		data: param
	})
}
/**
 * 清楚商品图片缓存
 */
export async function clearImage() {
	return request({
		url: '/mall/goods/thumb_image/admin',
		method: 'delete'
	})
}

/**
 * 动态发布状态修改
 */
export async function updateState(param){
	return request({
		url: '/mall/trend',
		method: 'put',
		data: param
	})
}
/**
 * 动态发布分页
 */
export async function getTrendPage(param){
	return request({
		url: '/mall/trend',
		method: 'get',
		params: param
	})
}

/**
 * 动态审核分页
 */
export async function getExaminePage(param){
	return request({
		url: '/mall/trend/examine',
		method: 'get',
		params: param
	})
}



/**
 * 获取动态发布信息
 */
export async function getTrend(param){
	return request({
		url: '/mall/trend/trendInfo',
		method: 'get',
		params: param
	})
}
/**
 * 动态修改
 */
export async function updateTrend(param){
	return request({
		url: '/mall/trend/updateTrend',
		method: 'put',
		data: param
	})
}
/**
 * 动态删除
 */
export async function deleteTrend(param){
	return request({
		url: '/mall/trend/trendModify',
		method: 'put',
		params: param
	})
}
/**
 * 动态类型删除
 */
export async function deleteTrendType(param){
	return request({
		url: '/mall/trend/trendType',
		method: 'put',
		params: param
	})
}


/**
 * 获取评论人消息内容
 */
export async function getTrendComment(param){
	return request({
		url: '/mall/trend_comment',
		method: 'get',
		params: param
	})
}
/**
 * 获取动态类型分页
 */
export async function getTrendType(param){
	return request({
		url: '/mall/trend/type_list',
		method: 'get',
		params: param
	})
}
/**
 * 获取图片标签类型分页
 */
export async function getImageLabel(param){
	return request({
		url: '/mall/trend/label_list',
		method: 'get',
		params: param
	})
}
/**
 * 新增类型
 */
export async function addType(data){
	return request({
		url: '/mall/trend/add_type',
		method: 'post',
		data: data
	})
}
/**
 * 新增标签
 */
export async function addLabel(data){
	return request({
		url: '/mall/trend/add_label',
		method: 'post',
		data: data
	})
}
/**
 * 获取动态标签分页
 */
export async function getTrendLabel(param){
	return request({
		url: '/mall/trend/label_list',
		method: 'get',
		params: param
	})
}
/**
 * 动态标签删除
 */
export async function deleteTrendLabel(param){
	return request({
		url: '/mall/trend/trendLabel',
		method: 'put',
		params: param
	})
}

/**
 * 发布动态
 */
export async function addTrend(data){
	return request({
		url: '/mall/trend',
		method: 'post',
		data: data
	})
}

 /**
  * 回复动态消息
  */
 export async function addReplyComment(data){
 	return request({
 		url: '/mall/trend_comment/comment_message',
 		method: 'post',
 		data: data
 	})
 }


/**
 * 批量修改商品
 */
export async function updateGoodsList(param) {
	return request({
		url: '/mall/goods/list',
		method: 'put',
		data: param
	})
}

/**
 * 修改商品分类
 */
export async function updateGoodsCategory(param) {
	return request({
		url: '/mall/goods/category',
		method: 'put',
		data: param
	})
}

/**
 * 新增商品分类
 */
export async function addGoodsCategory(param) {
	return request({
		url: '/mall/goods/category',
		method: 'post',
		data: param
	})
}

/**
 * 删除商品分类
 */
export async function deleteGoodsCategory(param) {
	return request({
		url: '/mall/goods/category/' + param,
		method: 'delete'
	})
}

/**
 * 获取商品分类
 */
export async function getGoodsCategoryInfo(param) {
	return request({
		url: '/mall/goods/category',
		method: 'get',
		params: param
	})
}

/**
 * 获取库存日志分页
 */
export async function getStockLogPage(param) {
	return request({
		url: '/mall/stock_log/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取商品模型分页
 */
export async function getGoodsTypePage(param) {
	return request({
		url: '/mall/goods_type/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除商品模型
 */
export async function deleteGoodsType(param) {
	return request({
		url: '/mall/goods_type/' + param,
		method: 'delete'
	})
}

/**
 * 修改商品模型
 */
export async function updateGoodsType(param) {
	return request({
		url: '/mall/goods_type',
		method: 'put',
		data: param
	})
}

/**
 * 新增商品模型
 */
export async function addGoodsType(param) {
	return request({
		url: '/mall/goods_type',
		method: 'post',
		data: param
	})
}

/**
 * 删除绑定的分类
 */
export async function deleteBindGoodsCat(param) {
	return request({
		url: '/mall/goods_type/goods_category/' + param,
		method: 'delete'
	})
}

/**
 * 删除自定义属性
 */
export async function deleteAttribute(param) {
	return request({
		url: '/mall/goods_attribute/' + param,
		method: 'delete'
	})
}

/**
 * 删除规格
 */
export async function deleteSpec(param) {
	return request({
		url: '/mall/spec/' + param,
		method: 'delete'
	})
}

/**
 * 获取某商品分类下的品牌列表
 */
export async function getGoodsCatBrands() {
	return request({
		url: '/mall/goods/category/brands',
		method: 'get'
	})
}

/**
 * 获取商品模型
 */
export async function getGoodsType(param) {
	return request({
		url: '/mall/goods_type',
		method: 'get',
		params: param
	})
}

/**
 * 获取品牌列表分页
 */
export async function getBrands(param) {
	return request({
		url: '/mall/brand/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改品牌
 */
export async function updateBrand(param) {
	return request({
		url: '/mall/brand',
		method: 'put',
		data: param
	})
}

/**
 * 批量删除品牌
 */
export async function deleteBrands(param) {
	return request({
		url: '/mall/brand?ids=' + param,
		method: 'delete'
	})
}

/**
 * 获取品牌
 */
export async function getBrand(param) {
	return request({
		url: '/mall/brand',
		method: 'get',
		params: param
	})
}

/**
 * 添加品牌
 */
export async function addBrand(param) {
	return request({
		url: '/mall/brand',
		method: 'post',
		data: param
	})
}

/**
 * 获取会员列表
 */
export async function getUserList(param) {
	return request({
		url: '/user/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取会员信息
 */
export async function getUser(param) {
	return request({
		url: '/user/admin/user',
		method: 'get',
		params: param
	})
}

/**
 * 修改会员信息
 */
export async function updateUser(param) {
	return request({
		url: '/user/admin/user',
		method: 'put',
		data: param
	})
}

/**
 * 添加会员
 */
export async function addUser(param) {
	return request({
		url: '/user/',
		method: 'post',
		data: param
	})
}

/**
 * 获取会员资金记录列表
 */
export async function getUserAccountLog(param) {
	return request({
		url: '/user/account_log/list',
		method: 'get',
		params: param
	})
}

/**
 * 会员资金调节
 */
export async function addUserAccountLog(param) {
	return request({
		url: '/user/account_log',
		method: 'post',
		data: param
	})
}

/**
 * 获取会员收货地址列表
 */
export async function getUserAddress(param) {
	return request({
		url: '/user/address/list/admin',
		method: 'get',
		params: param
	})
}

/**
 * 获取会员等级列表
 */
export async function getUserLevelPage(params) {
	return request({
		url: '/user/user_level/page',
		method: 'get',
		params: params
	})
}

/**
 * 删除会员等级
 */
export async function deleteUserLevel(param) {
	return request({
		url: '/user/user_level/' + param,
		method: 'delete'
	})
}

/**
 * 获取会员等级
 */
export async function getUserLevel(param) {
	return request({
		url: '/user/user_level',
		method: 'get',
		params: param
	})
}

/**
 * 修改会员等级
 */
export async function updateUserLevel(param) {
	return request({
		url: '/user/user_level',
		method: 'put',
		data: param
	})
}

/**
 * 新增会员等级
 */
export async function addUserLevel(param) {
	return request({
		url: '/user/user_level',
		method: 'post',
		data: param
	})
}

/**
 * 获取会员充值记录列表
 */
export async function getRechargeRecord(param) {
	return request({
		url: '/order/recharge/page',
		method: 'get',
		params: param
	})
}
/**
 * 获取充值详情
 */
export async function getRechargeDetail(param) {
	return request({
		url: '/order/recharge/'+param,
		method: 'get',
		param:{}
	})
}
/**
 * 获取充值详情
 */
export async function auditRecharge(param) {
	return request({
		url: '/order/recharge/audit',
		method: 'post',
		data:param
	})
}

/**
 * 获取会员签到列表
 */
export async function getUserSignList(param) {
	return request({
		url: '/user/sign/page',
		method: 'get',
		params: param
	})
}

/* ==================订单===================== */

/**
 * 获取订单
 */
export async function getOrder(param) {
	return request({
		url: '/order/admin',
		method: 'get',
		params: param
	})
}
export async function getOrderGoods(param) {
	return request({
		url: '/order/order_goods',
		method: 'get',
		params: param
	})
}
/**
 * 获取发货单
 */
export async function getDeliveryDocs(param) {
	return request({
		url: '/order/delivery_doc/list/admin',
		method: 'get',
		params: param
	})
}

/**
 * 获取物流信息
 */
export async function getExpress(params) {
	return request({
		url: '/order/delivery_doc/express',
		method: 'get',
		params: params
	})
}

/**
 * 获取订单记录
 */
export async function getOrderAction(param) {
	return request({
		url: '/order/order_action/' + param,
		method: 'get'
	})
}

/**
 * 获取拼团列表
 */
export async function getTeamFoundOrderPage(param) {
	return request({
		url: '/mall/team/found/order/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取拼团订单列表
 */
export async function getTeamOrderPage(param) {
	return request({
		url: '/mall/team/order/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取订单日志列表
 */
export async function getOrderActionPage(param) {
	return request({
		url: '/order/order_action/page',
		method: 'get',
		params: param
	})
}

/**
 * 批量删除订单日志
 */
export async function deleteOrderAction(param) {
	return request({
		url: '/order/order_action?ids=' + param,
		method: 'delete'
	})
}

/**
 * 获取待处理退换货列表
 */
export async function getReturnGoodsPage(param) {
	return request({
		url: '/order/return_goods/page/admin',
		method: 'get',
		params: param
	})
}

/**
 * 获取发票列表
 */
export async function getInvoicePage(param) {
	return request({
		url: '/user/invoice/page',
		method: 'get',
		params: param
	})
}

/**
 * 发票统计
 */
export async function getStatistic() {
	return request({
		url: '/user/invoice/statistic',
		method: 'get'
	})
}

/**
 * 获取商品评论列表
 */
export async function getCommentPage(param) {
	return request({
		url: '/order/comment/page/admin',
		method: 'get',
		params: param
	})
}

/**
 * 批量修改商品评论
 */
export async function updateCommentsIsShow(param1, param2) {
	return request({
		url: '/order/comment/is_show?comment_ids=' + param1 + "&is_show=" + param2,
		method: 'put'
	})
}

/**
 * 批量删除商品评论
 */
export async function deleteComments(param) {
	return request({
		url: '/order/comment?ids=' + param,
		method: 'delete'
	})
}

/**
 * 获取商品咨询列表
 */
export async function getGoodsConsultPage(param) {
	return request({
		url: '/mall/consult/page',
		method: 'get',
		params: param
	})
}

/**
 * 批量修改商品咨询
 */
export async function updateGoodsConsults(param1, param2) {
	return request({
		url: '/mall/consult?ids=' + param1 + "&is_show=" + param2,
		method: 'put'
	})
}

/**
 * 批量删除商品咨询
 */
export async function deleteGoodsConsults(param) {
	return request({
		url: '/mall/consult?ids=' + param,
		method: 'delete'
	})
}

/**
 * 获取商家投诉列表
 */
export async function getComplainPage(param) {
	return request({
		url: '/order/complain/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取商家主题列表
 */
export async function getComplainSubjectPage(params) {
	return request({
		url: '/order/complain_subject/page',
		method: 'get',
		params: params
	})
}

/**
 * 删除主题
 */
export async function deleteSubject(param) {
	return request({
		url: '/order/complain_subject/' + param,
		method: 'delete'
	})
}

/**
 * 获取举报列表
 */
export async function getExpostPage(param) {
	return request({
		url: '/order/expose/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取举报类型列表
 */
export async function getExpostTypeList() {
	return request({
		url: '/order/expose_type/list',
		method: 'get'
	})
}

/**
 * 删除举报类型
 */
export async function deleteExpostType(param) {
	return request({
		url: '/order/expose_type/' + param,
		method: 'delete'
	})
}

/**
 * 修改举报类型
 */
export async function updateExpostType(param) {
	return request({
		url: '/order/expose',
		method: 'put',
		data: param
	})
}

/**
 * 获取举报类型
 */
export async function getExposeType(param) {
	return request({
		url: '/order/expose',
		method: 'get',
		params: param
	})
}

/**
 * 新增举报类型
 */
export async function addExpostType(param) {
	return request({
		url: '/order/expose_type',
		method: 'post',
		data: param
	})
}

/**
 * 获取举报主题列表
 */
export async function getExposeSubjectPage(params) {
	return request({
		url: '/order/expose_subject/page',
		method: 'get',
		params: params
	})
}

/**
 * 新增举报主题
 */
export async function addExpostSubject(param) {
	return request({
		url: '/order/expose_subject',
		method: 'post',
		data: param
	})
}

/* ==================营销====================== */

/**
 * 获取抢购活动列表
 */
export async function getFlashSalePage(param) {
	return request({
		url: '/mall/flash_sale/page',
		method: 'get',
		params: param
	})
}

/**
 * 抢购活动是否推荐
 */
export async function changeFlashSaleRecommend(param) {
	return request({
		url: '/mall/flash_sale/recommend',
		method: 'put',
		data: param
	})
}

/**
 * 修改抢购活动状态
 */
export async function changeFlashSaleStatus(param) {
	return request({
		url: '/mall/flash_sale/status',
		method: 'put',
		data: param
	})
}

/**
 * 取消抢购活动
 */
export async function closeFlashSale(param) {
	return request({
		url: '/mall/flash_sale/close_prom/' + param,
		method: 'put'
	})
}

/**
 * 删除抢购活动
 */
export async function deleteFlashSale(param) {
	return request({
		url: '/mall/flash_sale/' + param,
		method: 'delete'
	})
}

/**
 * 获取团购活动列表
 */
export async function getGroupBuyPage(param) {
	return request({
		url: '/mall/group_buy/page',
		method: 'get',
		params: param
	})
}

/**
 * 团购活动是否推荐
 */
export async function changeGroupBuyRecommend(param) {
	return request({
		url: '/mall/group_buy/recommend',
		method: 'put',
		data: param
	})
}

/**
 * 修改团购活动状态
 */
export async function changeGroupBuyStatus(param) {
	return request({
		url: '/mall/group_buy/status',
		method: 'put',
		data: param
	})
}

/**
 * 取消团购活动
 */
export async function closeGroupBuy(param) {
	return request({
		url: '/mall/group_buy/close_prom/' + param,
		method: 'put'
	})
}

/**
 * 删除团购活动
 */
export async function deleteGroupBuy(param) {
	return request({
		url: '/mall/group_buy/' + param,
		method: 'delete'
	})
}

/**
 * 获取优惠促销列表
 */
export async function getPromGoodsPage(param) {
	return request({
		url: '/mall/prom_goods/page',
		method: 'get',
		params: param
	})
}
/**
 * 获取优惠促销列表商品
 */
export async function getPromGoods(id) {
	return request({
		url: '/mall/prom_goods/'+id,
		method: 'get',
	})
}

/**
 * 优惠促销是否推荐
 */
export async function changePromGoodsRecommend(param) {
	return request({
		url: '/mall/prom_goods/recommend',
		method: 'put',
		data: param
	})
}

/**
 * 取消优惠促销活动
 */
export async function closePromGoods(param) {
	return request({
		url: '/mall/prom_goods/close_prom/' + param,
		method: 'put'
	})
}

/**
 * 删除优惠促销活动
 */
export async function deletePromGoods(param) {
	return request({
		url: '/mall/prom_goods/' + param,
		method: 'delete'
	})
}

/**
 * 获取订单促销列表
 */
export async function getPromOrderPage(param) {
	return request({
		url: '/mall/prom_order/page',
		method: 'get',
		params: param
	})
}

/**
 * 订单促销是否推荐
 */
export async function changePromOrderRecommend(param) {
	return request({
		url: '/mall/prom_order/recommend',
		method: 'put',
		data: param
	})
}

/**
 * 取消订单促销活动
 */
export async function closePromOrder(param) {
	return request({
		url: '/mall/prom_order/close_prom/' + param,
		method: 'put'
	})
}

/**
 * 删除订单促销活动
 */
export async function deletePromOrder(param) {
	return request({
		url: '/mall/prom_order/' + param,
		method: 'delete'
	})
}

/**
 * 获取预售活动列表
 */
export async function getPreSellPage(param) {
	return request({
		url: '/mall/pre_sell/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改预售活动状态
 */
export async function changePreSellStatus(param) {
	return request({
		url: '/mall/pre_sell/status',
		method: 'put',
		data: param
	})
}

/**
 * 取消预售活动
 */
export async function closePreSell(param) {
	return request({
		url: '/mall/pre_sell/close_prom/' + param,
		method: 'put'
	})
}

/**
 * 店铺拼团活动列表
 */
export async function getShareTeamPage(param) {
	return request({
		url: '/mall/team/activity/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺拼团活动
 */
export async function updateShareTeam(param) {
	return request({
		url: '/mall/team/activity/status',
		method: 'put',
		data: param
	})
}

/**
 * 获取拼团信息
 */
export async function getTeam(param) {
	return request({
		url: '/mall/team/activity/' + param,
		method: 'get'
	})
}

/**
 * 获取店铺优惠劵列表
 */
export async function getStoreCouponPage(param) {
	return request({
		url: '/mall/coupon/store/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺优惠劵信息
 */
export async function getStoreCoupon(param) {
	return request({
		url: '/mall/coupon',
		method: 'get',
		params: param
	})
}

/**
 * 获取用户优惠劵列表
 */
export async function getUserCouponPage(param) {
	return request({
		url: '/mall/coupon/user/page',
		method: 'get',
		params: param
	})
}

/**
 * 新增优惠券
 */
export async function addCoupon(form) {
	return request({
		url: '/mall/coupon/newcomer',
		method: 'post',
		data: form
	})
}

/**
 * 删除用户优惠劵
 */
export async function deleteUserCoupon(param) {
	return request({
		url: '/mall/coupon/user_coupon/' + param,
		method: 'delete'
	})
}

/**
 * 删除优惠劵
 */
export async function deleteCoupon(param) {
	return request({
		url: '/mall/coupon/' + param,
		method: 'delete'
	})
}

/**
 * 修改新人优惠劵信息
 */
export async function updateStoreCoupon(param) {
	return request({
		url: '/mall/coupon/newcomer',
		method: 'put',
		data: param
	})
}


/* ==================分销====================== */

/**
 * 获取商品分类下拉列表
 */
export async function getGoodsCategoryTree(param) {
	return request({
		url: '/mall/goods/category/tree',
		method: 'get',
		params: param
	})
}

/**
 * 获取品牌下拉列表
 */
export async function getBrandList(param) {
	return request({
		url: '/mall/brand/list',
		method: 'get',
		params: param
	})
}

/**
 * 删除分销商品
 */
export async function deleteDistributionGoods(param) {
	return request({
		url: '/mall/distribution?goods_id=' + param,
		method: 'delete',
	})
}

/**
 * 获取分销商列表
 */
export async function getDistributor(param) {
	return request({
		url: '/user/distribute/page',
		method: 'get',
		params: param
	})
}

/**
 * 微店管理-分销商品列表
 */
export async function getMyStoreManage(param) {
	return request({
		url: '/mall/admin/my_shop/goods/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取分销等级列表
 */
export async function getDistributLevel() {
	return request({
		url: '/order/distribut/level/list',
		method: 'get'
	})
}

/**
 * 编辑分销等级
 */
export async function updateDistributLevel(param) {
	return request({
		url: '/order/distribut/level',
		method: 'put',
		data: param
	})
}

/**
 * 添加分销等级
 */
export async function addDistributLevel(param) {
	return request({
		url: '/order/distribut/level',
		method: 'post',
		data: param
	})
}

/**
 * 获取分成记录列表
 */
export async function getRebateLogPage(param) {
	return request({
		url: '/order/rebate_log/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取分成记录列表
 */
export async function getRebateLogList(param) {
	return request({
		url: '/order/rebate_log/list',
		method: 'get',
		params: param
	})
}

/* ==================商家====================== */
/**
 * 获取入驻申请分类信息
 *
 * @export
 * @param {*} param 入驻时申请分类保存的三级分类id
 * @returns
 */
export async function getStoreApplyStoreClassInfo(param) {
	return request({
		url: '/mall/goods/category/store_apply/store_class',
		method: 'get',
		params: param
	})
}

/**
 * 获取店铺列表
 */
export async function getStorePage(param) {
	return request({
		url: '/seller/store/page',
		method: 'get',
		params: param
	})
}
/**
 * 获取店铺
 */
export async function getStore() {
	return request({
		url: '/seller/store/list',
		method: 'get',
	})
}
/**
 * 修改店铺
 */
export async function updateStore(param) {
	return request({
		url: '/seller/store',
		method: 'put',
		data: param
	})
}

/**
 * 删除店铺
 */
export async function deleteStore(param) {
	return request({
		url: '/seller/store?store_id=' + param,
		method: 'delete',
	})
}

/**
 * 获取店铺等级
 */
export async function getStoreGradeList() {
	return request({
		url: '/seller/store_grade/list',
		method: 'get'
	})
}

/**
 * 新增店铺
 */
export async function addStore(param) {
	return request({
		url: '/seller/store',
		method: 'post',
		data: param
	})
}

/**
 * 获取开店申请列表
 */
export async function getStoreApplyPage(param) {
	return request({
		url: '/seller/store_apply/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除开店申请
 */
export async function deleteStoreApply(param) {
	return request({
		url: '/seller/store_apply?id=' + param,
		method: 'delete',
	})
}

/**
 * 获取开店申请
 */
export async function getStoreApply(param) {
	return request({
		url: '/seller/store_apply',
		method: 'get',
		params: param
	})
}

/**
 * 编辑开店申请
 */
export async function updateStoreApply(param) {
	return request({
		url: '/seller/store_apply',
		method: 'put',
		data: param
	})
}

/**
 * 获取签约申请列表
 */
export async function getStoreReopenPage(param) {
	return request({
		url: '/seller/store_reopen/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取签约申请
 */
export async function getStoreReopen(param) {
	return request({
		url: '/seller/store_reopen',
		method: 'get',
		params: param,
	})
}

/**
 * 修改签约申请
 */
export async function updateStoreReopen(param) {
	return request({
		url: '/seller/store_reopen/re_state',
		method: 'put',
		data: param
	})
}

/**
 * 删除签约申请
 */
export async function deleteStoreReopen(param) {
	return request({
		url: '/seller/store_reopen?re_id=' + param,
		method: 'delete',
	})
}

/**
 * 获取经营类目申请列表
 */
export async function getApplyClassList(param) {
	return request({
		url: '/seller/store_bind_class/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改经营类目申请状态
 */
export async function updateApplayClassStatus(param1, param2) {
	return request({
		url: '/seller/store_bind_class/check?bid=' + param1 + "&state=" + param2,
		method: 'put'
	})
}

/**
 * 删除经营类目申请
 */
export async function deleteApplayClassList(param) {
	return request({
		url: '/seller/store_bind_class?bid=' + param,
		method: 'delete',
	})
}

/**
 * 设置默认平台
 */
export async function setDefaultStore(param) {
	return request({
		url: '/seller/store/default_store?store_id=' + param,
		method: 'put',
	})
}

/**
 * 获取店铺分类列表
 */
export async function getStoreClassPage(param) {
	return request({
		url: '/seller/store_class/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺分类
 */
export async function updateStoreClass(param) {
	return request({
		url: '/seller/store_class',
		method: 'put',
		data: param
	})
}

/**
 * 删除店铺分类
 */
export async function deleteStoreClass(param) {
	return request({
		url: '/seller/store_class?sc_id=' + param,
		method: 'delete',
	})
}

/**
 * 获取店铺分类
 */
export async function getStoreClassInfo(param) {
	return request({
		url: '/seller/store_class',
		method: 'get',
		params: param
	})
}

/**
 * 新增店铺分类
 */
export async function addStoreClass(param) {
	return request({
		url: '/seller/store_class',
		method: 'post',
		data: param
	})
}

/**
 * 获取店铺等级列表
 */
export async function getStoreGradePage(param) {
	return request({
		url: '/seller/store_grade/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除店铺等级
 */
export async function deleteStoreGrade(param) {
	return request({
		url: '/seller/store_grade?sg_id=' + param,
		method: 'delete',
	})
}

/**
 * 获取店铺等级
 */
export async function getStoreGrade(param) {
	return request({
		url: '/seller/store_grade',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺等级
 */
export async function updateStoreGrade(param) {
	return request({
		url: '/seller/store_grade',
		method: 'put',
		data: param
	})
}

/**
 * 新增店铺等级
 */
export async function addStoreGrade(param) {
	return request({
		url: '/seller/store_grade',
		method: 'post',
		data: param
	})
}

/**
 * 获取消费者保障服务申请列表
 */
export async function getGuaranteeApplyPage(param) {
	return request({
		url: '/seller/guarantee_apply/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改消费者保障服务申请
 */
export async function updateAuditstate(param1, param2) {
	return request({
		url: '/seller/guarantee_apply/auditstate?log_msg=' + param1,
		method: 'put',
		data: param2
	})
}

/**
 * 获取店铺保障服务列表
 */
export async function getGuaranteeList(param) {
	return request({
		url: '/seller/guarantee/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改店铺保障服务
 */
export async function updateGuaranteeIsOpen(param1, param2) {
	return request({
		url: '/seller/guarantee/isopen?log_msg=' + param1,
		method: 'put',
		data: param2
	})
}

/**
 * 获取保障服务
 */
export async function getGuarantee(param) {
	return request({
		url: '/seller/guarantee',
		method: 'get',
		params: param
	})
}

/**
 * 获取保障服务日志列表
 */
export async function getGuaranteeLogList(param) {
	return request({
		url: '/seller/guarantee_log/page',
		method: 'get',
		params: param
	})
}

/**
 * 获取保障服务列表
 */
export async function getGuaranteeItemList() {
	return request({
		url: '/seller/guarantee_item/page',
		method: 'get'
	})
}

/**
 * 获取保证金日志列表
 */
export async function getGuaranteeCostLogList(param) {
	return request({
		url: '/seller/guarantee_costlog/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除保障服务
 */
export async function deleteGuaranteeItem(param) {
	return request({
		url: '/seller/guarantee_item?grt_id=' + param,
		method: 'delete',
	})
}

/**
 * 获取保障服务
 */
export async function getGuaranteeItem(param) {
	return request({
		url: '/seller/guarantee_item',
		method: 'get',
		params: param
	})
}

/**
 * 修改保障服务
 */
export async function updateGuaranteeItem(param) {
	return request({
		url: '/seller/guarantee_item',
		method: 'put',
		data: param
	})
}

/**
 * 添加保障服务
 */
export async function addGuaranteeItem(param) {
	return request({
		url: '/seller/guarantee_item',
		method: 'post',
		data: param
	})
}

/**
 * 店铺满意度评价列表
 */
export async function getStoreSatisfactionList(param) {
	return request({
		url: '/order/order_comment/page/store_satisfaction',
		method: 'get',
		params: param
	})
}

/**
 * 店铺评分列表
 */
export async function getStoreScoreList(param) {
	return request({
		url: '/order/order_comment/page/store_score',
		method: 'get',
		params: param
	})
}

/**
 * 获取消息模板
 */
export async function getMemberMsgTpl(params) {
	return request({
		url: '/user/member_msg_tpl',
		method: 'get',
		params: params
	});
}

/**
 * 发送站内信
 */
export async function sendNoticeMsg(form) {
	return request({
		url: '/user/message/send_notice_msg',
		method: 'post',
		data: form
	});
}
/**
 * 获取地区列表
 */
export async function getRegionList(params) {
	return request({
		url: '/mall/region/list',
		method: 'get',
		params: params
	});
}

/**
 * 获取地区父级全称
 */
export async function getRegionParentFullName(params) {
	return request({
		url: '/mall/region/parent_name_full',
		method: 'get',
		params: params
	});
}

/**
 * 添加地区
 */
export async function addRegion(params) {
	return request({
		url: '/mall/region',
		method: 'post',
		data: params
	});
}

/**
 * 添加地区
 */
export async function deleteRegionById(id) {
	return request({
		url: '/mall/region?id=' + id,
		method: 'delete'
	});
}

/**
 * 获取店铺经营类目列表
 */
export async function getStoreBindClasses(param) {
	return request({
		url: '/seller/store_bind_class/list',
		method: 'get',
		params: param
	})
}

/**
 * 店铺新增经营类目
 */
export async function addStoreBindClass(param) {
	return request({
		url: '/seller/store_bind_class',
		method: 'post',
		data: param
	})
}

/**
 * 删除店铺经营类目
 */
export async function deleteStoreBindClass(id) {
	return request({
		url: '/seller/store_bind_class?bid=' + id,
		method: 'delete'
	});
}

/**
 * 删除店铺经营类目
 */
export async function getMenus() {
	return request({
		url: '/mall/menu',
	});
}

/**
 * 修改个人中心自定义
 */
export async function updateMenu(param) {
	return request({
		url: '/mall/menu',
		method: 'put',
		data: param
	})
}
/*
新增个人中心自定义
*/
export async function addMenu(params) {
	return request({
		url: '/mall/menu',
		method: 'post',
		data: params
	})
}

/*删除个人中心自定义按钮*/
export async function deleteMenu(id) {
	return request({
		url: `/mall/menu/${id}`,
		method: 'delete'
	})
}
/**
 * 获取搭配购管理列表
 */
export async function getCollocationPage(param) {
	return request({
		url: '/mall/combination/page',
		method: 'get',
		params: param
	})
}

/**
 * 修改搭配购
 */
export async function updateCollocationStatus(param) {
	return request({
		url: '/mall/combination/status',
		method: 'put',
		params: param
	})
}

/**
 * 搭配购是否上架
 */
export async function isOnSaleCollocation(param) {
	return request({
		url: '/mall/combination/is_on_sale',
		method: 'put',
		params: param
	})
}

/**
 * 删除搭配购
 */
export async function deleteCombinationById(id) {
	return request({
		url: '/mall/combination/' + id,
		method: 'delete'
	})
}

/**
 * 获取搭配购信息
 */
export async function getCombination(id) {
	return request({
		url: '/mall/combination/' + id,
		method: 'get'
	})
}

/**
 * 获取退货记录
 */
export async function getReturnGoods(param) {
	return request({
		url: '/order/return_goods/admin',
		method: 'get',
		params: param
	})
}
/**
 * 退款售后
 */
export async function refundReturnGoods(param) {
	return request({
		url: '/order/return_goods/status/5',
		method: 'put',
		data: param
	})
}

/**
 * 同意退款
 */
export async function cancelPayOrder(refundType, param) {
	return request({
		url: '/order/pay_status/3/admin?refund_type=' + refundType,
		method: 'put',
		data: param
	})
}

/**
 * 同意退款
 */
export async function noAgreeCancelPayOrder(param) {
	return request({
		url: '/order/pay_status/4',
		method: 'put',
		data: param
	})
}

/**
 * 获取调度任务列表
 */
export async function getJobInfoPage(param) {
	return request({
		url: '/admin/jobs_info/page',
		method: 'get',
		params: param
	})
}

/**
 * 新增调度任务
 */
export async function addJobInfo(param) {
	return request({
		url: '/admin/jobs_info',
		method: 'post',
		data: param
	})
}

/**
 * 编辑调度任务
 */
export async function updateJobInfo(param) {
	return request({
		url: '/admin/jobs_info',
		method: 'put',
		data: param
	})
}

/**
 * 获取调度任务信息
 */
export async function getJobInfo(id) {
	return request({
		url: '/admin/jobs_info/' + id,
		method: 'get'
	})
}

/**
 * 执行调度任务
 */
export async function jobInfoExecute(id, param) {
	return request({
		url: '/admin/jobs_info/execute/' + id,
		method: 'post',
		params: param
	})
}

/**
 * 启动调度任务
 */
export async function jobInfoStart(id) {
	return request({
		url: '/admin/jobs_info/start/' + id,
		method: 'post'
	})
}

/**
 * 停止调度任务
 */
export async function jobInfoStop(id) {
	return request({
		url: '/admin/jobs_info/stop/' + id,
		method: 'post'
	})
}

/**
 * 删除调度任务
 */
export async function deleteJobInfo(id) {
	return request({
		url: '/admin/jobs_info/' + id,
		method: 'delete'
	})
}

/**
 * 获取调度任务日志列表
 */
export async function getJobLogPage(param) {
	return request({
		url: '/admin/jobs_log/page',
		method: 'get',
		params: param
	})
}

/**
 * 删除调度任务日志
 */
export async function deleteJobLog(id) {
	return request({
		url: '/admin/jobs_log/' + id,
		method: 'delete'
	})
}

/**
 * 获取调度任务日期分布图
 */
export async function getDateDistribution() {
	return request({
		url: '/admin/jobs_statistics/date_distribution',
		method: 'get'
	})
}

/**
 * 获取调度任务成功比例
 */
export async function getJobsSuccessRatio() {
	return request({
		url: '/admin/jobs_statistics/success_ratio',
		method: 'get'
	})
}

/**
 * 获取调度任务重要参数数量
 */
export async function getImportantNum() {
	return request({
		url: '/admin/jobs_statistics/important_num',
		method: 'get'
	})
}

/**
 * 更改分销上级检测
 */
export async function checkFirstLeader(params) {
	return request({
		url: '/user/check/first_leader',
		method: 'get',
		params: params
	})
}

/**
 * 更改分销上级
 */
export async function changeFirstLeader(params) {
	return request({
		url: '/user/first_leader',
		method: 'put',
		params: params
	})
}

/**
 * 获取关注时自动回复
 */
export async function getAutoReply(params) {
	return request({
		url: '/mall/wx_reply',
		method: 'get',
		params: params
	})
}

/**
 * 添加关注时自动回复
 */
export async function addAutoReply(data) {
	return request({
		url: '/mall/wx_reply',
		method: 'post',
		data: data
	})
}

/**
 * 更新关注时自动回复
 */
export async function updateAutoReply(data) {
	return request({
		url: '/mall/wx_reply',
		method: 'put',
		data: data
	})
}

/**
 * 获取关键字自动回复列表
 */
export async function getAutoReplyKeywordPage(params) {
	return request({
		url: '/mall/wx_reply/page',
		method: 'get',
		params: params
	})
}

/**
 * 删除关键字自动回复
 */
export async function removeReply(params) {
	return request({
		url: '/mall/wx_reply',
		method: 'delete',
		params: params
	})
}

/**
 * 获取素材分页数据
 */
export async function getMaterialPage(params) {
	return request({
		url: '/mall/wx_material/page',
		method: 'get',
		params: params
	})
}

/**
 * 获取素材
 */
export async function getMaterial(params) {
	return request({
		url: '/mall/wx_material',
		method: 'get',
		params: params
	})
}

/**
 * 添加素材
 */
export async function addMaterial(data) {
	return request({
		url: '/mall/wx_material',
		method: 'post',
		data: data
	})
}

/**
 * 获取支付宝配置
 */
export async function getAliPayConfig() {
	return request({
		url: '/order/ali_pay/config',
		method: 'get'
	})
}

/**
 * 支付宝配置
 */
export async function addAliPayConfig(data) {
	return request({
		url: '/order/ali_pay/config',
		method: 'post',
		data: data
	})
}

/**
 * 获取paypal配置
 */
export async function getPayPal() {
	return request({
		url: '/order/pay_pal',
		method: 'get'
	})
}

/**
 * 保存paypal配置
 */
export async function addPayPal(data) {
	return request({
		url: '/order/pay_pal',
		method: 'post',
		data: data
	})
}

/**
 * 顶置商品
 */
export async function goodsSetTop(data) {
	return request({
		url: '/mall/goods/sort',
		method: 'post',
		data: data
	})
}

/**
 * 获取商品分类的地区税率
 */
export async function getGoodsCatRegionTax(params) {
	return request({
		url: '/mall/tax/cat',
		method: 'get',
		params: params
	})
}

/**
 * 获取商品分类父类的地区税率
 */
export async function getParentGoodsCatRegionTax(params) {
	return request({
		url: '/mall/tax/parent/cat',
		method: 'get',
		params: params
	})
}


/**
 * 群发邮件
 */

export async function getEmailSends(param) {
  return request({
    url: '/user/mail/send',
    method: 'post',
    data: param,
  })
}


/**
 * 商家邮件日志
 */
export async function getEmaillog(param) {
  return request({
    url: '/user/mail/log',
    method: 'get',
    params: param
  })
}

/*
接收人详情
*/
export async function getEmailReceive(param) {
  return request({
    url: '/user/mail/receiver',
    method: 'get',
    params: param
  })
}


/**
 * 获取商品分类的地区税率
 */
export async function addGoodsCatRegionTax(data) {
	return request({
		url: '/mall/tax/cat',
		method: 'post',
		data: data
	})
}
/*创建单楼层*/
export async function addFloor(data){
	return request({
		url:'/mall/floor',
		method:'post',
		data:data
	})
}
/*单楼层列表*/
export async function getFloorList(data){
	return request({
		url:'/mall/floor/list',
		method:"get",
		data:data
	})
}

/*单楼层*/
export async function getFloor(data){
	return request({
		url:`/mall/floor?id=${data}`,
		method:'get',
	})
}
/*更新单楼层*/
export async function putFloor(data){
	return request({
		url:'/mall/floor',
		method:'put',
		data:data
	})
}



/* 创建楼层商品模块 */
export async function postblock(data){
	return request({
		url:'/mall/floor/block',
		method:'post',
		data:data
	})
}

/* 更新楼层商品模块 */
export async function putblock(data){
	return request({
		url:'/mall/floor/block',
		method:'put',
		data:data
	})
}
/* 删除楼层商品模块 */
export async function deleteblock(id){
	return request({
		url:'/mall/floor/block/'+id,
		method:'delete',
	})
}
/* 更新商品模板排序 */
export async function putblocksort(){
	return request({
		url:'/mall/floor/block/sort',
		method:'put'
	})
}
/* 创建楼层资源品牌列表 */
export async function postbrand(data){
	return request({
		url:'/mall/floor/brand',
		method:'post',
		data:data
	})
}
/* 更新楼层资源品牌列表 */
export async function putbrand(data){
	return request({
		url:'/mall/floor/brand',
		method:'put',
		data:data
	})
}
/* 创建楼层商品分类列表 */
export async function postcategory(data){
	return request({
		url:'/mall/floor/goods_category',
		method:'post',
		data:data
	})
}
/* 创建楼层资源url */
export async function postfloorurl(data){
	return request({
		url:'/mall/floor/url',
		method:'post',
		data:data
	})
}
/* 更新楼层资源url */
export async function putfloorurl(data){
	return request({
		url:'/mall/floor/url',
		method:'put',
		data:data
	})
}

/* 删除楼层资源url */
export async function delfloorurl(id){
	return request({
		url:`/mall/floor/url/${id}`,
		method:'delete',
	})
}


/**
 * 获取$变量
 */
export async function getstandard(param) {
	return request({
		url: '/user/member_msg_tpl/standard',
		method: 'get',
	    params: param
	})
}

 //添加海报信息
 export async function addfoundPoster(data) {
   return request({
     url: '/mall/poster/found_poster_admin',
     method: 'post',
     data: data
   })
 }
 //获得全站海报
 export async function getPosterAdmin() {
   return request({
     url: '/mall/poster/admin_poster',
     method: 'get',
   })
 }

 //获取Stripe配置
 export async function getStripe() {
   return request({
     url: '/order/stripe',
     method: 'get'
   })
 }

 //保存Stripe配置
 export async function addStripe(data) {
   return request({
     url: '/order/stripe',
     method: 'post',
	 data: data
   })
 }

 /*
 获取绑定商品模型商品分类列表
 */
 export async function getBindGoodsCatList(param) {
   return request({
     url: '/mall/goods_type/check_goods_cat',
     method: 'get',
     params: param
   })
 }

 /*
 获取商品分类列表
 */
 export async function getGoodsCatList(param) {
   return request({
     url: '/mall/goods/category/list',
     method: 'get',
     params: param
   })
 }

 // 获取商品清单分页
 export async function getInventoryPage(params) {
 	return request({
 		url: '/mall/inventory/page',
 		method: 'get',
 		params: params
 	})
 }

 //保存商品清单
 export async function addInventory(data) {
   return request({
     url: '/mall/inventory',
     method: 'post',
 	 data: data
   })
 }

 //保存商品清单
 export async function updateInventory(data) {
   return request({
     url: '/mall/inventory',
     method: 'put',
 	 data: data
   })
 }

 // 获取商品清单
 export async function getInventory(id) {
 	return request({
 		url: '/mall/inventory/' + id,
 		method: 'get'
 	})
 }

 // 删除商品清单
 export async function removeInventory(id) {
 	return request({
 		url: '/mall/inventory/' + id,
 		method: 'delete'
 	})
 }


 //获得商家试用商品
 export async function getIpageTrialtGoods(params) {
   return request({
     url: '/mall/trial_goods',
     method: 'get',
     params: params
   })
 }

 //获得已支付订单
 export async function getTrialOrderListById(params) {
   return request({
     url: '/order/trial_order/order_by_trial_id',
     method: 'get',
     params: params
   })
 }

 //一键退款
 export async function keyUpdate(params) {
 	return request({
 		url: '/order/trial_order/key_update',
 		method: 'put',
 		params: params
 	})
 }

 //单个退款
 export async function aUpdate(params) {
 	return request({
 		url: '/order/trial_order/a_update',
 		method: 'put',
 		params: params
 	})
 }

 //试用商品所有试用轮播图
 export async function getTrialPoster(params) {
 	return request({
 		url: '/mall/trial_poster',
 		method: 'get',
 		params: params
 	})
 }

 //添加试用试用轮播图
 export async function addTrialPoster(data) {
 	return request({
 		url: '/mall/trial_poster',
 		method: 'post',
 		data: data
 	})
 }


 //修改试用试用轮播图
 export async function updateTrialPoster(data) {
 	return request({
 		url: '/mall/trial_poster',
 		method: 'put',
 		data: data
 	})
 }

 //获得可抽取人数
 export async function getIsGet(params) {
 	return request({
 		url: '/mall/trial_apply/is_get',
 		method: 'get',
 		params: params
 	})
 }


 /**
  * 获取优惠券面额列表
  */
 export async function getCouponPrices(param) {
 	return request({
 		url: '/mall/coupon_price/list',
 		method: 'get',
 		params: param
 	})
 }

 //添加试用试用轮播图
 export async function addCouponPrices(data) {
 	return request({
 		url: '/mall/coupon_price',
 		method: 'post',
 		data: data
 	})
 }


 /**
  * 优惠券面额删除
  */
 export async function delCouponPrice(param) {
 	return request({
 		url: '/mall/coupon_price',
 		method: 'delete',
		params: param
 	})
 }



 //管理员下架商品
 export async function offStoreGoodsSale(params) {
 	return request({
 		url: '/mall/goods/off_sale/admin',
 		method: 'put',
 		params: params
 	})
 }

 /**
  * 获取加入购物车商品排行分页
  */
 export async function cartRankingPage(param) {
 	return request({
 		url: '/mall/cart/ranking/page',
 		method: 'get',
 	    params: param
 	})
 }

 /**
  * 删除楼层模块
  */
 export async function delFloor(id) {
 	return request({
 		url: '/mall/floor/' + id,
 		method: 'delete'
 	})
 }



 /**
  * 获取充值面额
  */
 export async function getRechargeFaceIPage(param) {
 	return request({
 		url: '/order/recharge_face',
 		method: 'get',
 	    params: param
 	})
 }
//获取菜单
export async function getMenuList() {
	return request({
		url: '/admin/menu',
		method:'get'
	})
}

 //获取管理员角色
 export async function getNavigationPage(params) {
 	return request({
 		url: '/mall/navigation',
 		method: 'get',
 		params: params
 	})
 }

 //删除角色
 export async function delNavigation(params) {
 	return request({
 		url: '/mall/navigation',
 		method: 'delete',
		params: params
 	})
 }


 //修改或添加导航
 export async function addOrUpdateNavigation(data) {
 	return request({
 		url: '/mall/navigation',
 		method: 'post',
 		data: data
 	})
 }

 //添加充值面额
 export async function addRechargeFace(data) {
 	return request({
 		url: '/order/recharge_face',
 		method: 'post',
 		data: data
 	})
 }

//删除
export async function delRechargeFace(data) {
	return request({
		url: '/order/recharge_face',
		method: 'delete',
		params: data
	})
}

 //审核试用商品
 export async function updateTrialGoodsByTrialId(params) {
 	return request({
 		url: '/mall/trial_goods/by_id',
 		method: 'put',
 		params: params
 	})
 }


 //管理员关闭活动
 export async function updateTrialGoodsByTrialIdSetState(params) {
 	return request({
 		url: '/mall/trial_goods/admin_close',
 		method: 'put',
 		params: params
 	})
 }

 //菜单分页
 export async function getAdminMenuList(params) {
 	return request({
 		url: '/admin/menu/list',
 		method: 'get',
 		params: params
 	})
 }

 //添加菜单
 export async function addAdminMenu(data) {
 	return request({
 		url: '/admin/menu',
 		method: 'post',
 		data: data
 	})
 }

 //添加菜单
 export async function editAdminMenu(data) {
 	return request({
 		url: '/admin/menu',
 		method: 'put',
 		data: data
 	})
 }
 //添加菜单
 export async function delAdminMenu(id) {
 	return request({
 		url: '/admin/menu?menu_id=' + id,
 		method: 'delete'
 	})
 }
 //获取角色分页
 export async function getRolePage(params) {
 	return request({
 		url: '/admin/role/page',
 		method: 'get',
		params: params
 	})
 }
 //获取角色列表
 export async function getRoleList(params) {
 	return request({
 		url: '/admin/role/list',
 		method: 'get',
 		params: params
 	})
 }

 //获取管理员角色
 export async function getAdminRole(params) {
 	return request({
 		url: '/admin/role/admin',
 		method: 'get',
 		params: params
 	})
 }

 //保存管理员角色
 export async function saveAdminRole(data) {
 	return request({
 		url: '/admin/role/admin',
 		method: 'post',
 		data: data
 	})
 }

 //添加角色
 export async function addRole(data) {
 	return request({
 		url: '/admin/role',
 		method: 'post',
 		data: data
 	})
 }

 //修改角色
 export async function editRole(data) {
 	return request({
 		url: '/admin/role',
 		method: 'put',
 		data: data
 	})
 }

 //删除角色
 export async function delRole(id) {
 	return request({
 		url: '/admin/role?role_id=' + id,
 		method: 'delete'
 	})
 }
 //获取角色的访问菜单
 export async function getRoleMenuIds(id) {
 	return request({
 		url: '/admin/role/menu_ids?role_id=' + id,
 		method: 'get'
 	})
 }

 //保存角色的访问菜单
 export async function saveRoleMenu(id, data) {
 	return request({
 		url: '/admin/role/menu/' + id,
 		method: 'post',
		data: data
 	})
 }

 //获取运营报表统计
 export async function getWebsiteLogs(params) {
 	return request({
 		url: '/mall/website/logs',
 		method: 'get',
 		params: params
 	})
 }

 //获取今天运营报表统计
 export async function getTodayWebsiteLog(params) {
 	return request({
 		url: '/mall/website/today/log',
 		method: 'get',
 		params: params
 	})

 }


 //批量审核品牌
 export async function batchCheckBrand(params) {
 	return request({
 		url: '/mall/brand/check_batch',
 		method: 'put',
 		params: params
 	})

 }
 //获取兑换码
 export async function getVirtualCode(params) {
	return request({
		url: '/order/virtual_code',
		method: 'get',
		params:params
	})
}

//获取手机自定义页面(分页)
export async function getcustompage(p,size) {
	return request({
		// url: '/mall/h5_diy_page',
		url: `/mall/h5_diy_page?p=${p}&size=${size}`,
		method: 'get'
	})
}

//获取手机自定义页面
export async function getcustompage_list() {
	return request({
		url: '/mall/h5_diy_page/list',
		method: 'get'
	})
}

//修改自定义是否设置首页
export async function putcustompage(data) {
	return request({
		url: '/mall/h5_diy_page',
		method: 'put',
		data:data
	})
}

//修改自定义是否设置首页
export async function putcustompageall(data) {
	return request({
		url: 'mall/h5_diy_page/all',
		method: 'post',
		data:data
	})
}

 //添加手机自定义页面
export async function addcustompage(data) {
	return request({
		url: '/mall/h5_diy_page',
		method: 'post',
		data: data
	})
}
  //删除手机自定义页面
export async function delcustompage(params) {
	return request({
		url: '/mall/h5_diy_page',
		method: 'delete',
		params: params
	})
}
//获取单页面的数据
export async function getpagedata(params) {
	return request({
		url: '/mall/h5_diy_element',
		method: 'get',
		params: params
	})
}


 /**
  * 获取门店
  */
 export async function  getShopPage(param){
     return request({
         url: '/shop/shop',
         method: 'get',
         params: param
     })
 }


 /**
  * 删除门店
  */
 export async function deleteShop(param) {
     return request({
         url: '/shop/shop',
         method: 'delete',
         params: param
     })
 }

 /**
  * 审核门店
  */
 export async function admincheckShop(data){
     return request({
         url: '/shop/shop/check',
         method: 'put',
         data: data
     })
 }


  /**
   * 修改门店
   */
  export async function updateShop(data){
      return request({
          url: '/shop/shop',
          method: 'put',
          data: data
      })
  }

  /**
   * 获取货币列表
   */
  export async function getCurrencyList(){
      return request({
          url: '/mall/currency/list',
          method: 'get'
      })
  }

  /**
   * 获取货币列表
   */
  export async function useCurrency(id){
      return request({
          url: '/mall/currency/is_use/' + id,
          method: 'put'
      })
  }

/*获取选中的货币*/
export async function getCurrency () {
    return request({
        url: "/mall/currency",
        method: 'get'
    })
}
