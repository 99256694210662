/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import Vue from 'vue'
import Router from 'vue-router';
import store from '@/store/store.js';
import { getToken } from "@/utils/auth.js";
Vue.use(Router)
const originalPush = Router.prototype.push

Router.prototype.push = function push (location) {

return originalPush.call(this, location).catch(err => err)

}

const homeArr = [ //顶部首页菜单内部路由
	{
		path: '',
		redirect: {
			name: 'system'
		}
	},
	{
		path: 'system',
		name: 'system',
		component: () => import('@/view/home/system.vue'),
		//children: indexArr
	},
	// {
	// 	path: 'explain',
	// 	name: 'explain',
	// 	component: () => import('@/view/home/explain.vue'),
	// 	//children: indexArr
	// }
]

// const setupArr = [ //顶部设置菜单内部路由
// 	{
// 		path: '',
// 		redirect: {
// 			name: 'shop_set'
// 		}
// 	},
// 	{
// 		path: 'shop_set',
// 		name: 'shop_set',
// 		component: () => import('@/view/setup/shop_set.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'shop_info'
// 				}
// 			},
// 			{
// 				path: 'shop_info',
// 				name: 'shop_info',
// 				component: () => import('@/view/setup/shop_set/shop_info.vue'),
// 			},
// 			{
// 				path: 'basic_set',
// 				name: 'basic_set',
// 				component: () => import('@/view/setup/shop_set/basic_set.vue'),
// 			},
// 			{
// 				path: 'withdrawal_set',
// 				name: 'withdrawal_set',
// 				component: () => import('@/view/setup/shop_set/withdrawal_set.vue'),
// 			},
// 			{
// 				path: 'shopping',
// 				name: 'shopping',
// 				component: () => import('@/view/setup/shop_set/shopping.vue'),
// 			},
// 			{
// 				path: 'notice',
// 				name: 'notice',
// 				component: () => import('@/view/setup/shop_set/notice.vue'),
// 			},
// 			{
// 				path: 'water',
// 				name: 'water',
// 				component: () => import('@/view/setup/shop_set/water.vue'),
// 			},
// 			{
// 				path: 'distribut',
// 				name: 'distribut',
// 				component: () => import('@/view/setup/shop_set/distribut.vue'),
// 			},
// 			{
// 				path: 'push',
// 				name: 'push',
// 				component: () => import('@/view/setup/shop_set/push.vue'),
// 			},
// 			{
// 				path: 'oss',
// 				name: 'oss',
// 				component: () => import('@/view/setup/shop_set/oss.vue'),
// 			},
// 			{
// 				path: 'statisticl',
// 				name: 'statisticl',
// 				component: () => import('@/store/ueditor/dialogs/wordimage/statistice.vue'),
// 			},
// 			{
// 				path: 'poster',
// 				name: 'poster',
// 				component: () => import('@/view/setup/shop_set/poster.vue'),
// 			},
// 			{
// 				path: 'tip',
// 				name: 'tip',
// 				component: () => import('@/view/setup/shop_set/tip.vue'),
// 			},
// 			//  { //二级域名
// 			//    path: 'subdomain',
// 			//    name: 'subdomain',
// 			//    component: () => import('@/view/setup/shop_set/subdomain.vue'),
// 			// children:[
// 			//   {
// 			//     path: '',
// 			//     redirect: {
// 			//       name: 'subdomain_set'
// 			//     }
// 			//   },
// 			//   {
// 			// 	path: 'subdomain_set',
// 			// 	name: 'subdomain_set',
// 			// 	component: () => import('@/view/setup/shop_set/subdomain/subdomain_set.vue'),
// 			//   },
// 			//   {
// 			// 	path: 'subdomain_list',
// 			// 	name: 'subdomain_list',
// 			// 	component: () => import('@/view/setup/shop_set/subdomain/subdomain_list.vue'),
// 			//   },
// 			// ]
// 			//  },
// 		]
// 	},
// 	{
// 		path: 'address_with_send',
// 		name: 'address_with_send',
// 		component: () => import('@/view/setup/address_with_send/address_with_send.vue'),
// 	},
// 	{
// 		path: 'seo_set',
// 		name: 'seo_set',
// 		component: () => import('@/view/setup/seo_set.vue'),
// 	},
// 	{
// 		path: 'shippings',
// 		name: 'shippings',
// 		component: () => import('@/view/setup/shippings.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'shipping_list'
// 				}
// 			},
// 			{
// 				path: 'shipping_list',
// 				name: 'shipping_list',
// 				component: () => import('@/view/setup/shipping_set/shipping_list.vue'),
// 			},
// 			{
// 				path: 'shipping_details',
// 				name: 'shipping_add',
// 				component: () => import('@/view/setup/shipping_set/shipping_details.vue'),
// 			},
// 			{
// 				path: 'shipping_details/:id',
// 				name: 'shipping_edit',
// 				component: () => import('@/view/setup/shipping_set/shipping_details.vue'),
// 			},
// 			{
// 				path: 'express',
// 				name: 'express',
// 				component: () => import('@/view/setup/shipping_set/express.vue'),
// 			},
// 		]
// 	},
// 	{
// 		path: 'admin_list',
// 		name: 'admin_list',
// 		component: () => import('@/view/setup/admin_list.vue'),
// 	},
// 	{
// 		path: 'menus',
// 		name: 'menus',
// 		component: () => import('@/view/setup/menus.vue'),
// 	},
// 	{
// 		path: 'roles',
// 		name: 'roles',
// 		component: () => import('@/view/setup/roles.vue'),
// 	},
// 	{
// 		path: 'email',
// 		name: 'email',
// 		component: () => import('@/view/setup/email.vue'),
// 	},
// 	{
// 		path: 'emailreceiver',
// 		name: 'emailreceiver',
// 		component: () => import('@/view/setup/emailreceiver.vue'),
// 	},

// 	{
// 		path: 'emails',
// 		name: 'emails',
// 		component: () => import('@/view/setup/emails.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'emaillog'
// 				}
// 			},
// 			{
// 				path: 'emaillog',
// 				name: 'emaillog',
// 				component: () => import('@/view/setup/emaillog.vue'),
// 			},
// 			{
// 				path: 'selleremaillog',
// 				name: 'selleremaillog',
// 				component: () => import('@/view/setup/selleremaillog.vue'),
// 			},
// 		]
// 	},

// 	{
// 		path: 'wx_user',
// 		name: 'wx_user',
// 		component: () => import('@/view/setup/wechat/wx_user.vue'),
// 	}, {
// 		path: 'pay_set',
// 		name: 'pay_set',
// 		component: () => import('@/view/setup/wechat/pay_set.vue'),
// 	},
// 	{
// 		path: 'wx_menu',
// 		name: 'wx_menu',
// 		component: () => import('@/view/setup/wechat/wx_menu.vue'),
// 	},
// 	{
// 		path: 'reply',
// 		name: 'reply',
// 		component: () => import('@/view/setup/wechat/reply.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'keyword'
// 				}
// 			},
// 			{
// 				path: 'keyword',
// 				name: 'keyword',
// 				component: () => import('@/view/setup/wechat/reply/keyword.vue'),
// 			},
// 			{
// 				path: 'default',
// 				name: 'default',
// 				component: () => import('@/view/setup/wechat/reply/default.vue'),
// 			},
// 			{
// 				path: 'follow',
// 				name: 'follow',
// 				component: () => import('@/view/setup/wechat/reply/follow.vue'),
// 			},
// 		]
// 	},
// 	{
// 		path: 'follower_list',
// 		name: 'follower_list',
// 		component: () => import('@/view/setup/wechat/follower/follower_list.vue')
// 	},
// 	{
// 		path: 'material_manage',
// 		name: 'material_manage',
// 		component: () => import('@/view/setup/wechat/material_manage.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'wx_news_list'
// 				}
// 			},
// 			{
// 				path: 'news_list',
// 				name: 'wx_news_list',
// 				component: () => import('@/view/setup/wechat/material_manage/news_list.vue'),
// 			}, {
// 				path: 'news',
// 				name: 'wx_news_add',
// 				component: () => import('@/view/setup/wechat/material_manage/news.vue'),
// 			}, {
// 				path: 'news/:material_id',
// 				name: 'wx_news_add_by_material',
// 				component: () => import('@/view/setup/wechat/material_manage/news.vue'),
// 			}, {
// 				path: 'news/:id',
// 				name: 'wx_news_edit',
// 				component: () => import('@/view/setup/wechat/material_manage/news.vue'),
// 			},
// 			{
// 				path: 'image_list',
// 				name: 'wx_image_list',
// 				component: () => import('@/view/setup/wechat/material_manage/image_list.vue'),
// 			},
// 			{
// 				path: 'video_list',
// 				name: 'wx_video_list',
// 				component: () => import('@/view/setup/wechat/material_manage/video_list.vue'),
// 			},
// 			{
// 				path: 'voice_list',
// 				name: 'wx_voice_list',
// 				component: () => import('@/view/setup/wechat/material_manage/voice_list.vue'),
// 			},
// 		]
// 	},
// 	{
// 		path: 'plugins',
// 		name: 'plugins',
// 		component: () => import('@/view/setup/plugins.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'pay_plugin'
// 				}
// 			},
// 			{
// 				path: 'pay_plugin',
// 				name: 'pay_plugin',
// 				component: () => import('@/view/setup/plugin_set/pay_plugin.vue'),
// 			},
// 			{
// 				path: 'login_plugin',
// 				name: 'login_plugin',
// 				component: () => import('@/view/setup/plugin_set/login_plugin.vue'),
// 			},
// 		]
// 	},
// 	{
// 		path: 'plugin/:type/:code',
// 		name: 'plugin',
// 		component: () => import('@/view/setup/plugin.vue'),
// 	},
// 	{
// 		path: 'sms_tpl',
// 		name: 'sms_tpl',
// 		component: () => import('@/view/setup/sms_tpl.vue'),
// 	},
// 	{
// 		path: 'sms_tpl_detail',
// 		name: 'sms_tpl_detail',
// 		component: () => import('@/view/setup/sms_tpl_detail.vue'),
// 	},
// 	{
// 		path: 'sms_tpl_detail/:id',
// 		name: 'sms_tpl_detail',
// 		component: () => import('@/view/setup/sms_tpl_detail.vue'),
// 	},
// 	{
// 		path: 'msg_tpl',
// 		name: 'msg_tpl',
// 		component: () => import('@/view/setup/msg_tpl.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'store_msg_tpl'
// 				}
// 			},
// 			{
// 				path: 'store_msg_tpl',
// 				name: 'store_msg_tpl',
// 				component: () => import('@/view/setup/msg_tpl/store_msg_tpl.vue'),
// 			},
// 			{
// 				path: 'member_msg_tpl',
// 				name: 'member_msg_tpl',
// 				component: () => import('@/view/setup/msg_tpl/member_msg_tpl.vue'),
// 			},
// 		]
// 	},
// 	{
// 		path: 'store_msg_detail/:smt_code',
// 		name: 'store_msg_detail',
// 		component: () => import('@/view/setup/msg_tpl/store_msg_detail.vue'),
// 	},
// 	{
// 		path: 'member_msg_detail/:mmt_code',
// 		name: 'member_msg_detail',
// 		component: () => import('@/view/setup/msg_tpl/member_msg_detail.vue'),
// 	},
// 	{
// 		path: 'suppliers',
// 		name: 'suppliers',
// 		component: () => import('@/view/setup/suppliers.vue'),
// 	},
// 	{
// 		path: 'suppliers_detail',
// 		name: 'suppliers_detail',
// 		component: () => import('@/view/setup/suppliers_detail.vue'),
// 	},
// 	{
// 		path: 'ali_pay_set',
// 		name: 'ali_pay_set',
// 		component: () => import('@/view/setup/ali_pay/ali_pay_set.vue'),
// 	},
// 	{
// 		path: 'pay_pal',
// 		name: 'pay_pal',
// 		component: () => import('@/view/setup/pay_pal/set.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'set'
// 				}
// 			},
// 			{
// 				path: 'set',
// 				name: 'pay_pal_set',
// 				component: () => import('@/view/setup/pay_pal/set.vue'),
// 			},
// 		]
// 	},
// 	{
// 		path: 'third_login_set',
// 		name: 'third_login_set',
// 		component: () => import('@/view/setup/third_login_set.vue'),
// 	},
// ]

// const pageArr = [ //顶部页面内部路由
// 	{
// 		path: '',
// 		redirect: {
// 			name: 'news_list'
// 		}
// 	},
// 	{
// 		path: 'news_list',
// 		name: 'news_list',
// 		component: () => import('@/view/page/news/news_list.vue'),
// 	},
// 	{
// 		path: 'news',
// 		name: 'news_add',
// 		component: () => import('@/view/page/news/news.vue'),
// 	},
// 	{
// 		path: 'news/:id',
// 		name: 'news_edit',
// 		component: () => import('@/view/page/news/news.vue'),
// 	},
// 	{
// 		path: 'news_comment_list/:id',
// 		name: 'news_comment_list',
// 		component: () => import('@/view/page/news/news_comment_list.vue'),
// 	},
// 	{
// 		path: 'news_comment/:id',
// 		name: 'news_comment',
// 		component: () => import('@/view/page/news/news.vue'),
// 	},
// 	{
// 		path: 'news_cat_list',
// 		name: 'news_cat_list',
// 		component: () => import('@/view/page/news/news_cat_list.vue'),
// 	},
// 	{
// 		path: 'article_list',
// 		name: 'article_list',
// 		component: () => import('@/view/page/article/article_list.vue'),
// 	},
// 	{
// 		path: 'article',
// 		name: 'article_add',
// 		component: () => import('@/view/page/article/article.vue'),
// 	},
// 	{
// 		path: 'article/:id',
// 		name: 'article_edit',
// 		component: () => import('@/view/page/article/article.vue'),
// 	},
// 	{
// 		path: 'article_cat_list',
// 		name: 'article_cat_list',
// 		component: () => import('@/view/page/article/article_cat_list.vue'),
// 	},
// 	{
// 		path: 'friend_link_list',
// 		name: 'friend_link_list',
// 		component: () => import('@/view/page/article/friend_link_list.vue'),
// 	},
// 	{
// 		path: 'friend_link',
// 		name: 'friend_link_add',
// 		component: () => import('@/view/page/article/friend_link.vue'),
// 	},
// 	{
// 		path: 'friend_link/:id',
// 		name: 'friend_link_edit',
// 		component: () => import('@/view/page/article/friend_link.vue'),
// 	},
// 	{
// 		path: 'system_article_list',
// 		name: 'system_article_list',
// 		component: () => import('@/view/page/article/system_article_list.vue'),
// 	}, {
// 		path: 'system_article',
// 		name: 'system_article_add',
// 		component: () => import('@/view/page/article/system_article.vue'),
// 	}, {
// 		path: 'system_article/:id',
// 		name: 'system_article_edit',
// 		component: () => import('@/view/page/article/system_article.vue'),
// 	},
// 	{
// 		path: 'help_list',
// 		name: 'help_list',
// 		component: () => import('@/view/page/article/help_list.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'help_content_list'
// 				}
// 			},
// 			{
// 				path: 'help_content_list',
// 				name: 'help_content_list',
// 				component: () => import('@/view/page/article/help/help_content_list.vue'),
// 			},
// 			{
// 				path: 'help_type_list',
// 				name: 'help_type_list',
// 				component: () => import('@/view/page/article/help/help_type_list.vue'),
// 			}
// 		]
// 	},
// 	{
// 		path: 'help_content',
// 		name: 'help_content_add',
// 		component: () => import('@/view/page/article/help/help_content.vue'),
// 	}, {
// 		path: 'help_content/:id',
// 		name: 'help_content_edit',
// 		component: () => import('@/view/page/article/help/help_content.vue'),
// 	},
// 	   {
// 	        path: 'poster_admin',
// 	        name: 'poster_admin',
// 	        component: () => import('@/view/page/ad/poster_admin.vue'),
// 	    },
// 		{
// 		     path: 'trial_poster',
// 		     name: 'trial_poster',
// 		     component: () => import('@/view/page/ad/trial_poster.vue'),
// 		 },
// 		 {
// 		      path: 'update_trial_poster',
// 		      name: 'update_trial_poster',
// 		      component: () => import('@/view/page/ad/update_trial_poster.vue'),
// 		  },

// 	{
// 		path: 'ad_list',
// 		name: 'ad_list',
// 		component: () => import('@/view/page/ad/ad_list.vue'),
// 	},
// 	{
// 		path: 'ad_edit/:id',
// 		name: 'ad_edit',
// 		component: () => import('@/view/page/ad/ad_edit.vue'),
// 	},
// 	{
// 		path: 'ad_page',
// 		name: 'ad_page',
// 		component: () => import('@/view/page/ad/ad_page.vue'),
// 	},
// 	{
// 		path: 'exerer',
// 		name: 'exerer',
// 		component: () => import('@/utils/dialogs/attachment/exerer.vue'),
// 	},
// 	{
// 		path: 'user_center',
// 		name: 'user_center',
// 		component: () => import('@/view/page/app/user_center.vue'),
// 	},
// 	{
// 		path:'pc_index',
// 		name:'pc_index',
// 		component: () => import('@/view/page/pc/pc_index.vue'),
// 	},
// 	{
// 		path:'pc_block_design/:id',
// 		name:'pc_block_design',
// 		component: () => import('@/view/page/pc/pc_block_design.vue'),
// 	},
// 	{
// 		path:'pc_advertising',
// 		name:'pc_advertising',
// 		component: () => import('@/view/page/pc/pc_advertising.vue'),
// 	},
// 	{
// 		path:'pc_section/:id',
// 		name:'pc_section',
// 		component: () => import('@/view/page/pc/pc_section.vue'),
// 	},

// ]

// const marketArr = [ //顶部商城内部路由
// 	{
// 		path: '',
// 		redirect: {
// 			name: 'goods'
// 		}
// 	},
// 	{
// 		path: 'goods',
// 		name: 'goods',
// 		component: () => import('@/view/market/goods.vue'),
// 	},
// 	{
// 		path: 'goods_types',
// 		name: 'goods_types',
// 		component: () => import('@/view/market/goods_types.vue'),
// 	},
// 	{
// 		path: 'goods_type/:id',
// 		name: 'goods_type',
// 		component: () => import('@/view/market/goods_type.vue'),
// 	},
// 	{
// 		path: 'goods_type',
// 		name: 'add_goods_type',
// 		component: () => import('@/view/market/goods_type.vue'),
// 	}, {
// 		path: 'brands',
// 		name: 'brands',
// 		component: () => import('@/view/market/brands.vue'),
// 	}, {
// 		path: 'inventory',
// 		name: 'inventory',
// 		component: () => import('@/view/market/inventory.vue'),
// 	}, {
// 		path: 'inventory_goods/:id',
// 		name: 'inventory_goods',
// 		component: () => import('@/view/market/inventory_goods.vue'),
// 	}, {
// 		path: 'brand/:id',
// 		name: 'brand_edit',
// 		component: () => import('@/view/market/brand.vue'),
// 	}, {
// 		path: 'brand',
// 		name: 'brand_add',
// 		component: () => import('@/view/market/brand.vue'),
// 	},
// 	{
// 		path: 'goods_categories',
// 		name: 'goods_categories',
// 		component: () => import('@/view/market/goods_categories.vue'),
// 		meta: {keepAlive: true}
// 	},
// 	{
// 		path: 'goods_category',
// 		name: 'goods_category',
// 		component: () => import('@/view/market/goods_category.vue'),
// 	},
// 	{
// 		path: 'goods_category/:id',
// 		name: 'goods_category_edit',
// 		component: () => import('@/view/market/goods_category.vue'),
// 	}, {
// 		path: 'goods_category/:id/:flag',
// 		name: 'goods_category_add_child',
// 		component: () => import('@/view/market/goods_category.vue'),
// 	},
// 	{
// 		path: 'tax/:id',
// 		name: 'tax',
// 		component: () => import('@/view/market/tax.vue'),
// 	},
// 	{
// 		path: 'members',
// 		name: 'members',
// 		component: () => import('@/view/market/members.vue'),
// 	},
// 	{
// 		path: 'members/:first_leader',
// 		name: 'members_first',
// 		component: () => import('@/view/market/members.vue'),
// 	},
// 	{
// 		path: 'members/:second_leader',
// 		name: 'members_second',
// 		component: () => import('@/view/market/members.vue'),
// 	},
// 	{
// 		path: 'members/:third_leader',
// 		name: 'members_third',
// 		component: () => import('@/view/market/members.vue'),
// 	},
// 	{
// 		path: 'member_levels',
// 		name: 'member_levels',
// 		component: () => import('@/view/market/member_levels.vue'),
// 	},
// 	{
// 		path: 'member_level',
// 		name: 'member_level',
// 		component: () => import('@/view/market/member_level.vue'),
// 	},
// 	{
// 		path: 'member_level/:id',
// 		name: 'member_level_edit',
// 		component: () => import('@/view/market/member_level.vue'),
// 	},
// 	{
// 		path: 'member_address',
// 		name: 'member_address',
// 		component: () => import('@/view/market/member_address.vue'),
// 	},
// 	{
// 		path: 'member_account',
// 		name: 'member_account',
// 		component: () => import('@/view/market/member_account.vue'),
// 	},
// 	{
// 		path: 'add_account',
// 		name: 'add_account',
// 		component: () => import('@/view/market/add_account.vue'),
// 	},
// 	{
// 		path: 'add_member',
// 		name: 'add_member',
// 		component: () => import('@/view/market/add_member.vue'),
// 	},
// 	{
// 		path: 'member_details/:user_id',
// 		name: 'member_details',
// 		component: () => import('@/view/market/member_details.vue'),
// 	},
// 	{
// 		path: 'recharge_record',
// 		name: 'recharge_record',
// 		component: () => import('@/view/market/recharge_record.vue'),
// 	},

// 	{
// 		path: 'recharge_face',
// 		name: 'recharge_face',
// 		component: () => import('@/view/market/recharge_face.vue'),
// 	},
// 	{
// 		path: 'user_sign',
// 		name: 'user_sign',
// 		component: () => import('@/view/market/user_sign.vue'),
// 		children: [{
// 				path: '',
// 				redirect: {
// 					name: 'user_sign_list'
// 				}
// 			},
// 			{
// 				path: 'user_sign_list',
// 				name: 'user_sign_list',
// 				component: () => import('@/view/market/user_sign/user_sign_list.vue'),
// 			},
// 			{
// 				path: 'sign_config',
// 				name: 'sign_config',
// 				component: () => import('@/view/market/user_sign/sign_config.vue'),
// 			}
// 		]
// 	},
// 	{
// 		path: 'stock_log',
// 		name: 'stock_log',
// 		component: () => import('@/view/market/stock_log.vue'),
// 	},
// ]

const orderArr = [ //顶部订单内部路由
	{
		path: '',
		redirect: {
			name: 'orders'
		}
	},
	{
		path: 'orders',
		name: 'orders',
		component: () => import('@/view/order/orders.vue'),
	},
	{
		path: 'order_detail/:sn',
		name: 'order_detail',
		component: () => import('@/view/order/order_detail.vue'),
	},
	{
		path: 'refund_order',
		name: 'refund_order',
		component: () => import('@/view/order/refund_order.vue'),
	},
	{
		path: 'order_log',
		name: 'order_log',
		component: () => import('@/view/order/order_log.vue'),
	},
	{
		path: 'invoice',
		name: 'invoice',
		component: () => import('@/view/order/invoice.vue'),
	},
	{
		path: 'return',
		name: 'return',
		component: () => import('@/view/order/return.vue'),
	},
	{
		path: 'returned',
		name: 'returned',
		component: () => import('@/view/order/returned.vue'),
	},
	{
		path: 'returned_detail/:id',
		name: 'returned_detail',
		component: () => import('@/view/order/returned_detail.vue'),
	},
	{
		path: 'repairs',
		name: 'repairs',
		component: () => import('@/view/order/repairs.vue'),
	},
	{
		path: 'founds',
		name: 'founds',
		component: () => import('@/view/order/founds.vue'),
	},
	{
		path: 'team',
		name: 'team',
		component: () => import('@/view/order/team.vue'),
	},
	{
		path: 'team_found/:found_id',
		name: 'team_found',
		component: () => import('@/view/order/team.vue'),
	},
	{
		path: 'team/:team_id',
		name: 'team_order',
		component: () => import('@/view/order/team.vue'),
	},
	{
		path: 'complains',
		name: 'complains',
		component: () => import('@/view/order/complains'),
	},
	{
		path: 'complain_subject',
		name: 'complain_subject',
		component: () => import('@/view/order/complain_subject.vue'),
	},
	{
		path: 'add_subject',
		name: 'add_subject',
		component: () => import('@/view/order/add_subject.vue'),
	},
	{
		path: 'complain_time',
		name: 'complain_time',
		component: () => import('@/view/order/complain_time.vue'),
	},
	{
		path: 'complain_detail/:complainId/:order_id',
		name: 'complain_detail',
		component: () => import('@/view/order/complain_detail.vue'),
	},
	{
		path: 'expose',
		name: 'expose',
		component: () => import('@/view/order/expose.vue'),
	},
	{
		path: 'expose_type',
		name: 'expose_type',
		component: () => import('@/view/order/expose_type.vue'),
	},
	{
		path: 'add_expose_type',
		name: 'add_expose_type',
		component: () => import('@/view/order/add_expose_type.vue'),
	},
	{
		path: 'trial_goods_refund',
		name: 'trial_goods_refund',
		component: () => import('@/view/order/trial_goods_refund.vue'),
	},
	{
		path: 'trial_order_list',
		name: 'trial_order_list',
		component: () => import('@/view/order/trial_order_list.vue'),
	},
	{
		path: 'expose_subject',
		name: 'expose_subject',
		component: () => import('@/view/order/expose_subject.vue'),
	},
	{
		path: 'add_expose_subject',
		name: 'add_expose_subject',
		component: () => import('@/view/order/add_expose_subject.vue'),
	},
	{
		path: 'expose_detail',
		name: 'expose_detail',
		component: () => import('@/view/order/expose_detail.vue'),
	},
	{
		path: 'comment',
		name: 'comment',
		component: () => import('@/view/order/comment.vue'),
	},
	{
		path: 'goods_consult',
		name: 'goods_consult',
		component: () => import('@/view/order/goods_consult.vue'),
	},
]

const marketingArr = [ //顶部营销页面路由
	{
		path: '',
		redirect: {
			name: 'menu'
		}
	},
	{
		path: 'menu',
		name: 'menu',
		component: () => import('@/view/marketing/menu.vue'),
	},
	{
		path: 'flash_sale',
		name: 'flash_sale',
		component: () => import('@/view/marketing/flash_sale.vue'),
	},
	{
		path: 'group_buy',
		name: 'group_buy',
		component: () => import('@/view/marketing/group_buy.vue'),
	},
	{
		path: 'collocation',
		name: 'collocation',
		component: () => import('@/view/marketing/collocation.vue'),
	},
	{
		path: 'trial_goodslist',
		name: 'trial_goodslist',
		component: () => import('@/view/marketing/trial_goodslist.vue'),
	},
	{
		path: 'collocation_info/:id',
		name: 'collocation_info',
		component: () => import('@/view/marketing/collocation_info.vue'),
	},
	{
		path: 'prom_goods',
		name: 'prom_goods',
		component: () => import('@/view/marketing/prom_goods.vue'),
	},
	{
		path: 'prom_order',
		name: 'prom_order',
		component: () => import('@/view/marketing/prom_order.vue'),
	},
	{
		path: 'pre_sell',
		name: 'pre_sell',
		component: () => import('@/view/marketing/pre_sell.vue'),
	},
	{
		path: 'team_list/:status',
		name: 'team_list',
		component: () => import('@/view/marketing/share_team.vue'),
	},
	{
		path: 'share_team/:team_type',
		name: 'share_team',
		component: () => import('@/view/marketing/share_team.vue'),
	},
	{
		path: 'team_activity/:team_id',
		name: 'team_activity',
		component: () => import('@/view/marketing/team_activity.vue'),
	},
	{
		path: 'coupons',
		name: 'coupons',
		component: () => import('@/view/marketing/coupons.vue'),
	},
	{
		path: 'coupon_add',
		name: 'coupon_add',
		component: () => import('@/view/marketing/coupon_add.vue'),
	},
	{
		path: 'coupon_list/:id',
		name: 'coupon_list',
		component: () => import('@/view/marketing/coupon_list.vue'),
	},
	{
		path: 'coupon/:id',
		name: 'coupon',
		component: () => import('@/view/marketing/coupon.vue'),
	},
	{
		path: 'newbie_coupons',
		name: 'newbie_coupons',
		component: () => import('@/view/marketing/newbie_coupons.vue'),
	},
	{
		path: 'newbie_coupons_details',
		name: 'newbie_coupons_details',
		component: () => import('@/view/marketing/newbie_coupons_details.vue'),
	},
	{
		path: 'newbie_coupons_edit/:id',
		name: 'newbie_coupons_edit',
		component: () => import('@/view/marketing/newbie_coupons_edit.vue'),
	},

]

const distributionArr = [ //顶部分销页面路由
	{
		path: '',
		redirect: {
			name: 'distribution_goods_list'
		}
	},
	{
		path: 'distribution_goods_list',
		name: 'distribution_goods_list',
		component: () => import('@/view/distribution/distribution_goods_list.vue'),
	},
	{
		path: 'distributor_list',
		name: 'distributor_list',
		component: () => import('@/view/distribution/distributor_list.vue'),
	},
	{
		path: 'distributor_list/my_store_manage/:user_id',
		name: 'my_store_manage',
		component: () => import('@/view/distribution/my_store_manage.vue'),
	},
	{
		path: 'rebate_log',
		name: 'rebate_log',
		component: () => import('@/view/distribution/rebate_log.vue'),
	},
	{
		path: 'distribut_level',
		name: 'distribut_level',
		component: () => import('@/view/distribution/distribut_level.vue'),
	},
	{
		path: 'distribut_relation',
		name: 'distribut_relation',
		component: () => import('@/view/distribution/distribut_relation.vue'),
	},
	{
		path: 'apply_co_founder',
		name: 'apply_co_founder',
		component: () => import('@/view/distribution/apply_co_founder.vue'),
	},
	{
		path: 'apply_co_founder_detail',
		name: 'apply_co_founder_detail',
		component: () => import('@/view/distribution/apply_co_founder_detail.vue'),
	}
]

const statisticsArr = [ //顶部统计页面路由
	{
		path: '',
		redirect: {
			name: 'report'
		}
	},
	{
		path: 'store_remittance_list',
		name: 'store_remittance_list',
		component: () => import('@/view/statistics/store_remittance_list.vue'),
	},
	{
		path: 'order_statis',
		name: 'order_statis',
		component: () => import('@/view/statistics/order_statis.vue'),
	},
	{
		path: 'report',
		name: 'report',
		component: () => import('@/view/statistics/report.vue'),
	},
	{
		path: 'report_order/:startTime/:endTime',
		name: 'report_order',
		component: () => import('@/view/statistics/report_order.vue'),
	},
	{
		path: 'finance',
		name: 'finance',
		component: () => import('@/view/statistics/finance.vue'),
	},
	{
		path: 'finance_order/:startTime/:endTime',
		name: 'finance_order',
		component: () => import('@/view/statistics/finance_order.vue'),
	},
	{
		path: 'user_report',
		name: 'user_report',
		component: () => import('@/view/statistics/user_report.vue'),
	},
	{
		path: 'sales_ranking',
		name: 'sales_ranking',
		component: () => import('@/view/statistics/sales_ranking.vue'),
		children: [{
				path: '',
				redirect: {
					name: 'sale_top'
				}
			},
			{
				path: 'sale_top',
				name: 'sale_top',
				component: () => import('@/view/statistics/sales_ranking/sale_top.vue'),
			},
			{
				path: 'cart_goods_top',
				name: 'cart_goods_top',
				component: () => import('@/view/statistics/sales_ranking/cart_goods_top.vue'),
			},
			{
				path: 'action_top',
				name: 'action_top',
				component: () => import('@/view/statistics/sales_ranking/action_top.vue'),
			},
			{
				path: 'store_top/:type',
				name: 'store_top',
				component: () => import('@/view/statistics/sales_ranking/store_top.vue'),
			},
		]
	},
	{
		path: 'sale_list',
		name: 'sale_list',
		component: () => import('@/view/statistics/sale_list.vue'),
	},
	{
		path: 'store_withdrawals',
		name: 'store_withdrawals',
		component: () => import('@/view/statistics/store_withdrawals.vue'),
	},
	{
		path: 'store_withdrawals_details',
		name: 'store_withdrawals_details',
		component: () => import('@/view/statistics/store_withdrawals_details.vue'),
	},
	{
		path: 'expense_log',
		name: 'expense_log',
		component: () => import('@/view/statistics/expense_log.vue'),
	},
	{
		path: 'statistics_set',
		name: 'statistics_set',
		component: () => import('@/view/statistics/statistics_set.vue'),
	},
	{
		path: 'user_top',
		name: 'user_top',
		component: () => import('@/view/statistics/user_top.vue'),
	},
	{
		path: 'user_withdrawals',
		name: 'user_withdrawals',
		component: () => import('@/view/statistics/user_withdrawals.vue'),
	},
	{
		path: 'user_withdrawals_details',
		name: 'user_withdrawals_details',
		component: () => import('@/view/statistics/user_withdrawals_details.vue'),
	},
	{
		path: 'user_remittance',
		name: 'user_remittance',
		component: () => import('@/view/statistics/user_remittance.vue'),
	},
	{
		path: 'operation_report',
		name: 'operation_report',
		component: () => import('@/view/statistics/operation_report.vue'),
		children: [{
				path: '',
				redirect: {
					name: 'shop_survey'
				}
			},
			{
				path: 'shop_survey',
				name: 'shop_survey',
				component: () => import('@/view/statistics/operation_report/shop_survey.vue'),
			},
			{
				path: 'goods_survey',
				name: 'goods_survey',
				component: () => import('@/view/statistics/operation_report/goods_survey.vue'),
			},
			{
				path: 'activity_survey',
				name: 'activity_survey',
				component: () => import('@/view/statistics/operation_report/activity_survey.vue'),
			},
			{
				path: 'funnel',
				name: 'funnel',
				component: () => import('@/view/statistics/operation_report/funnel.vue'),
			},
			{
				path: 'user_analysis',
				name: 'user_analysis',
				component: () => import('@/view/statistics/operation_report/user_analysis.vue'),
			},
		]
	},
]

const sellerArr = [ //顶部商家页面路由
	{
		path: '',
		redirect: {
			name: 'store_manage'
		}
	},
	{
		path: 'store_manage',
		name: 'store_manage',
		component: () => import('@/view/seller/store_manage.vue'),
		children: [{
				path: '',
				redirect: {
					name: 'stores'
				}
			},
			{
				path: 'stores',
				name: 'stores',
				component: () => import('@/view/seller/store_manage/stores.vue'),
			},
			{
				path: 'store_applys',
				name: 'store_applys',
				component: () => import('@/view/seller/store_manage/store_applys.vue'),
			},
			{
				path: 'reopen_list',
				name: 'reopen_list',
				component: () => import('@/view/seller/store_manage/reopen_list.vue'),
			},
			{
				path: 'apply_class_list',
				name: 'apply_class_list',
				component: () => import('@/view/seller/store_manage/apply_class_list.vue'),
			},
		]
	},
	{
		path: 'reopen/:id',
		name: 'reopen',
		component: () => import('@/view/seller/store_manage/reopen.vue'),
	},
	{
		path: 'store_detail/:userId/:sgId/:storeId',
		name: 'store_detail',
		component: () => import('@/view/seller/store_manage/store_detail.vue'),
	},
	{
		path: 'store_add/:isOwnShop',
		name: 'store_add',
		component: () => import('@/view/seller/store_manage/store_add.vue'),
	},
	{
		path: 'store_apply_detail/:userId',
		name: 'store_apply_detail',
		component: () => import('@/view/seller/store_manage/store_apply_detail.vue'),
	}, {
		path: 'store_edit/:storeId',
		name: 'store_edit',
		component: () => import('@/view/seller/store_manage/store_edit.vue'),
	}, {
		path: 'store_class_info/:storeId',
		name: 'store_class_info',
		component: () => import('@/view/seller/store_manage/store_class_info.vue'),
	},
	{
		path: 'own_stores',
		name: 'own_stores',
		component: () => import('@/view/seller/own_store/own_stores.vue'),
	},
	{
		path: 'store_class_list',
		name: 'store_class_list',
		component: () => import('@/view/seller/store_class/store_class_list.vue'),
	},
	{
		path: 'store_class',
		name: 'store_class_add',
		component: () => import('@/view/seller/store_class/store_class.vue'),
	},
	{
		path: 'store_class/:scId',
		name: 'store_class_edit',
		component: () => import('@/view/seller/store_class/store_class.vue'),
	},
	{
		path: 'store_grades',
		name: 'store_grades',
		component: () => import('@/view/seller/store_grade/store_grades.vue'),
	},
	{
		path: 'store_grade',
		name: 'store_grade_add',
		component: () => import('@/view/seller/store_grade/store_grade.vue'),
	},
	{
		path: 'store_grade/:sgId',
		name: 'store_grade_edit',
		component: () => import('@/view/seller/store_grade/store_grade.vue'),
	},
	{
		path: 'guarantee',
		name: 'guarantee',
		component: () => import('@/view/seller/guarantee.vue'),
		children: [{
				path: '',
				redirect: {
					name: 'guarantee_apply/default'
				}
			},
			{
				path: 'guarantee_apply/1',
				name: 'guarantee_apply/default',
				component: () => import('@/view/seller/guarantee/guarantee_apply.vue'),
			},
			{
				path: 'guarantee_apply/:type',
				name: 'guarantee_apply',
				component: () => import('@/view/seller/guarantee/guarantee_apply.vue'),
			},
			{
				path: 'guarantee_list',
				name: 'guarantee_list',
				component: () => import('@/view/seller/guarantee/guarantee_list.vue'),
			},
			{
				path: 'guarantee_item_list',
				name: 'guarantee_item_list',
				component: () => import('@/view/seller/guarantee/guarantee_item_list.vue'),
			}
		]
	},
	{
		path: 'guarantee_log_list/:id',
		name: 'guarantee_log_list',
		component: () => import('@/view/seller/guarantee/guarantee_log_list.vue'),
	},
	{
		path: 'guarantee_costlog_list/:id',
		name: 'guarantee_costlog_list',
		component: () => import('@/view/seller/guarantee/guarantee_costlog_list.vue'),
	},
	{
		path: 'guarantee_item',
		name: 'guarantee_item_add',
		component: () => import('@/view/seller/guarantee/guarantee_item.vue'),
	}, {
		path: 'guarantee_item/:id',
		name: 'guarantee_item_edit',
		component: () => import('@/view/seller/guarantee/guarantee_item.vue'),
	},
	{
		path: 'store_satisfaction',
		name: 'store_satisfaction',
		component: () => import('@/view/seller/store_satisfaction.vue'),
		children: [{
			path: '',
			redirect: {
				name: 'store_satisfaction_list'
			}
		}, {
			path: 'store_satisfaction_list',
			name: 'store_satisfaction_list',
			component: () => import('@/view/seller/store_satisfaction/store_satisfaction_list.vue'),
		}, {
			path: 'store_score_list',
			name: 'store_score_list',
			component: () => import('@/view/seller/store_satisfaction/store_score_list.vue'),
		}]
	},
]

// const jobsArr = [ //顶部任务调度页面路由
// 	{
// 		path: '',
// 		redirect: {
// 			name: 'dashboard'
// 		}
// 	},
// 	{
// 		path: 'dashboard',
// 		name: 'dashboard',
// 		component: () => import('@/view/jobs/dashboard.vue'),
// 	},
// 	{
// 		path: 'job_info',
// 		name: 'job_info',
// 		component: () => import('@/view/jobs/job_info.vue'),
// 	},
// 	{
// 		path: 'job_log',
// 		name: 'job_log',
// 		component: () => import('@/view/jobs/job_log.vue'),
// 	}
// ]


// const MainArr = [ //顶部第一级路由
// 	{
// 		path: '',
// 		redirect: {
// 			name: 'system'
// 		}
// 	},
// 	{
// 		path: 'home',
// 		name: 'home',
// 		component: () => import('@/view/home/index.vue'),
// 		children: homeArr
// 	},
// 	{
// 		path: 'setup',
// 		name: 'setup',
// 		component: () => import('@/view/setup/index.vue'),
// 		// children: setupArr
// 	},
// 	{
// 		path: 'page',
// 		name: 'page',
// 		component: () => import('@/view/page/index.vue'),
// 		// children: pageArr
// 	},
// 	{
// 		path: 'market',
// 		name: 'market',
// 		component: () => import('@/view/market/index.vue'),
// 		// children: marketArr
// 	},
// 	{
// 		path: 'order',
// 		name: 'order',
// 		component: () => import('@/view/order/index.vue'),
// 		// children: orderArr
// 	},
// 	{
// 		path: 'marketing',
// 		name: 'marketing',
// 		component: () => import('@/view/marketing/index.vue'),
// 		// children: marketingArr
// 	},
// 	{
// 		path: 'distribution',
// 		name: 'distribution',
// 		component: () => import('@/view/distribution/index.vue'),
// 		// children: distributionArr
// 	},
// 	{
// 		path: 'statistics',
// 		name: 'statistics',
// 		component: () => import('@/view/statistics/index.vue'),
// 		// children: statisticsArr
// 	},
// 	{
// 		path: 'seller',
// 		name: 'seller',
// 		component: () => import('@/view/seller/index.vue'),
// 		// children: sellerArr
// 	},
// 	{
// 		path: 'jobs',
// 		name: 'jobs',
// 		component: () => import('@/view/jobs/index.vue'),
// 		children: jobsArr
// 	}
// ]

const routerConfig = [
	// {
	// 	path: '/',
	// 	redirect: {
	// 		name: 'system'
	// 	}
	// },
	{
		path: "/",
		redirect: '/home/system',
		//component: () => import('@/view/home/system.vue'),
		// children: MainArr //顶部第一级路由
	},
	// {
	// 	path: "/index",
	// 	name: "index",
	// 	component: () => import('@/view/index/index.vue'),
	// 	children: MainArr //顶部第一级路由
	// },
	// {
	// 	path: "*",
	// 	redirect: "/404",
	// },
	{
		path: "/404",
		name: "404",
		meta: {
            title: '404'
        },
		component:()=>import("@/view/404/index.vue")
	},
	{
		path: "/jurisdiction",
		name: "jurisdiction",
		meta: {
            title: '权限'
		},
		redirect: {
			path: '/jurisdiction/menus'
		},
		component:()=>import("@/view/jurisdiction/index.vue"),
		children:[
			{
				path: "/jurisdiction/menus",
				name: "jurisdiction",
				meta: {
					title: '菜单'
				},
				component:()=>import("@/view/jurisdiction/menus.vue")
			},
			{
				path: "/jurisdiction/roles",
				name: "jurisdiction",
				meta: {
					title: '角色'
				},
				component:()=>import("@/view/jurisdiction/roles.vue")
			},
			{
				path: "/jurisdiction/admin_list",
				name: "jurisdiction",
				meta: {
					title: '角色'
				},
				component:()=>import("@/view/jurisdiction/admin_list.vue")
			},
		]
	},


	{
		path:"/setup",
		name:"menus",
		component:()=>import("@/view/setup/index.vue"),
		children:[{

				path:"/setup/menus",
				name:"menus",
				component:()=>import("@/view/setup/menus.vue")
			},
			{

				path:"/setup/roles",
				name:"menus",
				component:()=>import("@/view/setup/roles.vue")
			},
			{

				path:"/setup/admin_list",
				name:"menus",
				component:()=>import("@/view/setup/admin_list.vue")
			},
		]
	},

	{
		path: "/login",
		name: "login",
		component: () => import('@/view/index/login.vue'),
		// children: MainArr //顶部第一级路由
	},
	{
		path: '/deploy_pages',
		name: 'deploy_pages',
		component: () => import('@/view/page/mobile_list/deploy_pages.vue'),
	},
]



const router = new Router({
	mode: process.env.VUE_APP_MODE,
	routes: routerConfig,
	base: '/' + process.env.VUE_APP_NAME, // nginx location路径， 也就是请求路径
});
router.beforeEach((to,from,next)=> {
	let path =to.path;
	let menuList = store.state.menuList||[];
	let data = readNodes(menuList)
	/*通过是否有token */
	if(getToken()){
		if(menuList.length == 0) {
			next()
			return false
		}

		next()

	}else if(!getToken()&&path != '/login') {
		next({path:'/login'})
	}else {
		if(menuList.length == 0) {
			next()
			return false
		}
		data.map(item=>{
			if(item.path == path) {
				if(item.children.length>0) {
					item.children.map(i=>{
						if(i.is_navigation== 1) {
							next({path:i.path})
						}
					})
				}else {
					next()
				}
			}
		})
		next()
	}
	if (to.meta.title) {
		document.title = to.meta.title;
	}

})

function readNodes (nodes = [], arr = []) {
    for (let item of nodes) {
        arr.push(item)
        if (item.children && item.children.length) readNodes(item.children, arr)
    }
    return arr
}

export default router;

