export const en = {
  "distributLevel":{
    "distributorLevel":"Distributor Level",
    "defaultRules":"Default Distribution Rules",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "state":"Distributor level, managed by platform settings.",
    "hideTips":"HideTips",
    "newLevel":"Add new level",
    "levelName":"level name",
    "firstRate":"First-level commission ratio",
    "secondRate":"Secondary rate of commission",
    "thirdRate":"third-level commission ratio",
    "upgrade":"Upgrade Conditions",
    "operation":"OPERATION",
    "edit":"Edit",
    "total":"The accumulated commission amount is full",
    "conditions":"Distributor upgrade conditions, if not filled in, the default is not to automatically upgrade",
    "confirm":"OK",
  }, 

  "distributRelation":{
    "relationship":"Distribution Relationship",
    "checkRelationship1":"Check the agent level relationship",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "checkRelationship2":"Check the agent level relationship.",
    "hideTips":"HideTips",
    "list":"Reseller List",
    "total":"Total",
    "records":"Records",
    "refresh":"Refresh data",
    "superiorArea":"superior area",
    "superiorUser":"SuperiorUser",
    "returnSuperior":"Return to the distribution superior",
    "userId":"Member ID",
    "username":"Member Name",
    "operation":"OPERATION",
    "checkSubordinate":"Check Subordinate",
  },

  "distributionGoodsList":{
    "management":"Distribution commodity management",
    "mallManagement":"Mall Distribution Product Management",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "display":"The products participating in the distribution are displayed in this list",
    "hideTips":"HideTips",
    "list":"List of Distributed Products",
    "total":"Total",
    "records":"Records",
    "refresh":"Refresh data",
    "allSort":"All Categories",
    "allBrand":"All Brands",
    "goodName":"product name",
    "search":"Search",
    "volume":"Sales",
    "sort":"Classification",
    "price":"Price",
    "store":"Inventory",
    "share":"Shared amount",
    "proportion":"Proportion of commodity price",
    "operation":"OPERATION",
    "check":"View",
    "delete":"Delete",
  },

  "distributorList":{
    "list":"Distributor List",
    "relationshipManagement":"Distribution Relationship Management",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "state1": "Click to view the operation will display the current user's upper three-level distributor ID and the user's lower three-level distributor number",
    "state2": "The list can be searched by user ID and nickname",
    "state3":"The number of third-level distributors in the list can be clicked, and the list of members under this level will be displayed after clicking.",
    "hideTips":"HideTips",
    "total":"Total",
    "records":"Records",
    "refresh":"Refresh data",
    "userId":"User ID",
    "username":"User nickname",
    "search":"Search",
    "no":"Number",
    "wxNickname":"WeChat nickname",
    "userable":"Available Funds",
    "freezing":"Freezing Funds",
    "share":"Total share amount",
    "offline":"Total number of offline members",
    "firstMember":"Number of first-level members",
    "secondMember":"Number of secondary members",
    "threeMember": "Number of three-level members",
    "operation":"OPERATION",
    "check":"View",
    "microShop":"microShop",
  },

  "index":{
    "distributManagement":"Distribution Management",
    "distributGoods":"DistributGoods",
    "distributorList":"Distributor List",
    "distributRelationship":"Distribution Relationship",
    "distributSetting":"DistributSetting",
    "distributorLevel":"DistributorLevel",
    "commissionManagement":"Commission Management",
    "dividedLog":"Divided Into Log",
  },

  "myStoreManage":{
    "backToList":"Back To List",
    "microStoreManagement":"微店管理",
    "nameOrNum": "Product name or article number",
    "distributId":"Distribut Id",
    "distributNum":"Distribution Sales",
    "shareNum":"Share Number",
    "time":"Commodity Join Time",
    "mallManagement":"Mall Distribution Product Management",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "display":"The products participating in the distribution are displayed in this list",
    "hideTips":"HideTips",
    "list":"List of Distributed Products",
    "total":"Total",
    "records":"Records",
    "refresh":"Refresh data",
    "allSort":"All Categories",
    "allBrand":"All Brands",
    "goodName":"product name",
    "search":"Search",
    "volume":"Total Sales",
    "sort":"Classification",
    "price":"Price",
    "store":"Inventory",
    "shareMoney":"Share Amount",
    "proportion":"Proportion of commodity price",
    "operation":"OPERATION",
    "check":"View",
    "delete":"Delete",
  },

  "rebateLog":{
    "splitList":"Split into record list",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "state1":"Click to view order to view order details",
    "state2":"The list can be queried according to the state, order number and generation date",
    "hideTips":"HideTips",
    "total":"Total",
    "records":"Records",
    "refresh":"Refresh data",
    "commissionId":"Commissioned User ID",
    "menuId":"Order Number",
    "startDate":"Start Date",
    "endDate":"End Date",
    "status":"Status",
    "search":"Search",
    "commission":"Amount of commission",
    "level":"Helped User Level",
    "createTime":"Record generation time",
  },
}

export const zh = {
   "distributLevel":{
    "distributorLevel":"分销商等级",
    "defaultRules":"默认分销规则",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state":"分销商等级, 由平台设置管理.",
    "hideTips":"收起提示",
    "newLevel":"添加新等级",
    "levelName":"等级名称",
    "firstRate":"一级佣金比例",
    "secondRate":"二级佣金比例",
    "thirdRate":"三级佣金比例",
    "upgrade":"升级条件",
    "operation":"操作",
    "edit":"编辑",
    "total":"累计获佣金额满",
    "conditions":"分销商升级条件，不填写默认为不自动升级",
    "confirm":"确 定",
  }, 

  "distributRelation":{
    "relationship":"分销关系",
    "checkRelationship1":"查看代理商层级关系",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "checkRelationship2":"查看代理商层级关系。",
    "hideTips":"收起提示",
    "list":"分销人员列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "superiorArea":"上级区域",
    "superiorUser":"上级用户",
    "returnSuperior":"返回分销上级",
    "userId":"会员ID",
    "username":"会员名称",
    "operation":"操作",
    "checkSubordinate":"查看下级",
  },

  "distributionGoodsList":{
    "management":"分销商品管理",
    "mallManagement":"商城分销商品管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "display":"参与分销的商品在此列表显示",
    "hideTips":"收起提示",
    "list":"分销商品列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "allSort":"所有分类",
    "allBrand":"所有品牌",
    "goodName":"商品名称",
    "search":"搜索",
    "volume":"销量",
    "sort":"分类",
    "price":"价格",
    "store":"库存",
    "share":"分成金额",
    "proportion":"占商品价格比",
    "operation":"操作",
    "check":"查看",
    "delete":"删除",
  },

  "distributorList":{
    "list":"分销商列表",
    "relationshipManagement":"分销关系管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"点击查看操作将显示当前用户上三级分销ID和该用户的下三级分销商数量",
    "state2":"列表可根据用户ID和昵称搜索",
    "state3":"列表中的三级分销商数量可点击, 点击后显示该级别下的会员列表",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "userId":"用户ID",
    "username":"用户昵称",
    "search":"搜索",
    "no":"编号",
    "wxNickname":"微信昵称",
    "userable":"可用资金",
    "freezing":"冻结资金",
    "share":"总分成金额",
    "offline":"下线会员总数",
    "firstMember":"一级会员数",
    "secondMember":"二级会员数",
    "threeMember":"三级会员数",
    "operation":"操作",
    "check":"查看",
    "microShop":"微店",
  },

  "index":{
    "distributManagement":"分销管理",
    "distributGoods":"分销商品",
    "distributorList":"分销商列表",
    "distributRelationship":"分销关系",
    "distributSetting":"分销设置",
    "distributorLevel":"分销商等级",
    "commissionManagement":"佣金管理",
    "dividedLog":"分成日志",
  },

  "myStoreManage":{
    "backToList":"返回列表",
    "microStoreManagement":"微店管理",
    "nameOrNum":"商品名称或者货号",
    "distributId":"分销ID",
    "distributNum":"分销销量",
    "shareNum":"分享数",
    "time":"商品加入时间",
    "mallManagement":"商城分销商品管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "display":"参与分销的商品在此列表显示",
    "hideTips":"收起提示",
    "list":"分销商品列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "allSort":"所有分类",
    "allBrand":"所有品牌",
    "goodName":"商品名称",
    "search":"搜索",
    "volume":"总销量",
    "sort":"分类",
    "price":"价格",
    "store":"库存",
    "shareMoney":"分成金额",
    "proportion":"占商品价格比",
    "operation":"操作",
    "check":"查看",
    "delete":"删除", 
  },

  "rebateLog":{
    "splitList":"分成记录列表",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"点击查看订单可查看订单详情",
    "state2":"列表可根据状态,订单号和生成日期查询",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "commissionId":"获佣用户ID",
    "menuId":"订单编号",
    "startDate":"起始日期",
    "endDate":"截至日期",
    "status":"状态",
    "search":"搜索",
    "commission":"获佣金额",
    "level":"获佣用户级别",
    "createTime":"记录生成时间",
  },
}
