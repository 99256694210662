/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */
import Vue from 'vue'
import Vuex from 'vuex'
import createPersistedState from 'vuex-persistedstate'
const key = 'user' //localStorage中存储用户信息的key
const isLogin = 'isLogin' //登录状态0未登录 1已登录
import {getCurrency} from "@/utils/api";
Vue.use(Vuex)

const store = new Vuex.Store({
  state: {
    // user: JSON.parse(localStorage.getItem(key)),
    // isLogin: localStorage.getItem(isLogin),
    indexTop: 0,
    goodsTop: 0,
    excludeName: [],
    openFlag:false,//开源提示开关
	pcUrl: process.env.VUE_APP_PC_NAME,
	//h5Url: process.env.VUE_APP_H5_NAME,
	h5Url: "https://h5.shop.zhjk108.com",
	menuList:[],//权限菜单列表
    CurrencyInfo:"",//货币信息
  },
  getters: {

    // getUesr: function (state) { //获取登录信息
    //   if (!state.user) {
    //     state.user = JSON.parse(localStorage.getItem(key))
    //     //state.isLogin = localStorage.getItem(isLogin)
    //   }
    //   return state.user
    // },
    // getiSLogin: function (state) {
    //   if(!state.isLogin){
    //     state.isLogin = localStorage.getItem(isLogin)
    //   }
    //   return state.isLogin
    // },
    excludeName: function (state) {
      return state.excludeName
    }
  },
  mutations: {
    // $_setLogin(state, value) { //设置登录状态
    //   state.isLogin = value
    //   localStorage.setItem(isLogin, value)
    // },
    // $_setUser(state, value) { //设置登录信息
    //   state.user = value
    //   localStorage.setItem(key, JSON.stringify(value))
    // },
    // $_removeStorage(state) { //移除登录信息
    //   state.user = null
    //   state.isLogin = false
    //   localStorage.removeItem(key)
    // },
    $_setIndexTop(state, value){
      state.indexTop = value
    },
    $_setgoodsTop(state, value){
      state.goodsTop = value
    },
    setExcludeName (state,value) {
      state.excludeName = value
    },
    /*更新开源开关*/
    updateOpenFlag(state,item){
      state.openFlag=item;
	},
	setMenuList (state,item) {
		state.menuList = item;
	},
    /*更新货币信息*/
    updateCurrency(state,item) {
        state.CurrencyInfo = item;
    }
  },
  actions: {
    setExcludeName({commit},value){
      commit('setExcludeName',value)
	},
    getCurrency(ctx, item) {
        getCurrency().then(res=>{
            ctx.commit("updateCurrency",res)
        })
    }
  },
  plugins: [createPersistedState({
    storage: window.sessionStorage
  })],

})

export default store
