/**
 * VUEshop
 * ============================================================================
 * * 版权所有 2015-2027 深圳搜豹网络科技有限公司，并保留所有权利。
 * 网站地址: http://www.vueshop.com.cn
 * ----------------------------------------------------------------------------
 * 这不是一个自由软件！您只能在不用于商业目的的前提下对程序代码进行修改和使用 .
 * 不允许对程序代码以任何形式任何目的的再发布。
 * ============================================================================
 * $Author: soubao-java 2020-07-22 $
 */<template>
  <div class="topnav" v-if="ishide">
    <div class="admincp-header">
      <div class="bgSelector"></div>
      <div
        class="admincp-name"
        style="background-color: #293038;"
        onclick="javascript:openItem('welcome|Index');"
      >
        <img :src="storeLogo" />
      </div>
      <div class="nc-module-menu">
        <ul >
			<router-link class="link" v-for="(item,index) in menuList" :key="index" active-class="active" :to="item.path">
				<a>
				<span :class="['iconfont',filterImg(item.icon,'children')?filterImg(item.icon,'children'):'']"></span>
				<i>{{item.name}}</i>
				</a>
			</router-link>
			<router-link class="link"  active-class="active" to="/jurisdiction">
				<a>
				<span :class="['iconfont','iconquanxian']"></span>
				<i>权限</i>
				</a>
			</router-link>
        </ul>
      </div>
      <div class="admincp-header-r">
        <el-dropdown style="display: inline-block; margin-top: 20px; margin-right: 5px;">
          <span class="el-dropdown-link">
            {{i18n.topNav.msg}}
            <i class="el-icon-arrow-down el-icon--right"></i>
          </span>
          <el-badge class="mark" :value="message_count > 999 ? '···' : message_count" />
          <el-dropdown-menu style="width: 200px; background-color: #1c2024;" slot="dropdown">
            <el-collapse>
              <el-collapse-item>
                <template slot="title">
                  {{i18n.topNav.productTips}}
                  <el-badge class="mark" :value="goods_msg_count > 999 ? '···' : goods_msg_count" />
                </template>
                <router-link :to="{path:'/market/goods',query:{goodsState:0}}">
                  <a href="javascript:void(0)">{{i18n.topNav.approved}}({{goods_report.wait_auth_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/order/refund_order'}">
                  <a href="javascript:void(0)">{{i18n.topNav.refundOrder}}({{order_report.wait_refund_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'return',query:{status:0}}">
                  <a href="javascript:void(0)">{{i18n.topNav.return}}({{return_goods_report.wait_refund_count}})</a>
                </router-link>
                <br />
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  {{i18n.topNav.tips}}
                  <el-badge
                    class="mark"
                    :value="seller_msg_count > 999 ? '···' : seller_msg_count"
                  />
                </template>
                <router-link :to="{path:'/seller/store_manage/store_applys'}">
                  <a href="javascript:void(0)">{{i18n.topNav.storeApply}}({{store_apply_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/seller/store_manage/reopen_list'}">
                  <a href="javascript:void(0)">{{i18n.topNav.storeReopen}}({{store_reopen_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/seller/store_manage/apply_class_list'}">
                  <a href="javascript:void(0)">{{i18n.topNav.businessCat}}({{store_bind_class_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/statistics/store_withdrawals'}">
                  <a href="javascript:void(0)">{{i18n.topNav.businessWithdrawal}}({{store_withdrawals_count}})</a>
                </router-link>
                <br />
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  {{i18n.topNav.userReminder}}
                  <el-badge class="mark" :value="user_msg_count > 999 ? '···' : user_msg_count" />
                </template>
                <router-link :to="{path:'/statistics/user_withdrawals'}">
                  <a href="javascript:void(0)">{{i18n.topNav.userWithdrawal}}({{user_withdrawals_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/order/complains',query:{state:1}}">
                  <a href="javascript:void(0)">{{i18n.topNav.complaint}}({{complain_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/order/expose',query:{state:1}}">
                  <a href="javascript:void(0)">{{i18n.topNav.expose}}({{expose_count}})</a>
                </router-link>
                <br />
              </el-collapse-item>
              <el-collapse-item>
                <template slot="title">
                  {{i18n.topNav.activity}}
                  <el-badge class="mark" :value="activity_count > 999 ? '···' : activity_count" />
                </template>
                <router-link :to="{path:'/marketing/flash_sale',query:{status:0}}">
                  <a href="javascript:void(0)">{{i18n.topNav.flashSale}}({{flash_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/marketing/share_team',query:{status: 0}}">
                  <a href="javascript:void(0)">{{i18n.topNav.team}}({{team_activity_count}})</a>
                </router-link>
                <br />
                <router-link :to="{path:'/marketing/pre_sell',query:{status:0}}">
                  <a href="javascript:void(0)">{{i18n.topNav.preSell}}({{pre_sell_count}})</a>
                </router-link>
                <br />
              </el-collapse-item>
            </el-collapse>
          </el-dropdown-menu>
        </el-dropdown>
        <div class="manager">
          <!--服务器升级-->
          <textarea id="textarea_upgrade" style="display:none;"></textarea>
          <!--服务器升级 end-->
          <a
            href="http://help.tp-shop.cn/Index/Help/channel/cat_id/5.html"
            class="manual"
            target="_blank"
          >{{i18n.topNav.help}}</a>
        </div>
        <div class="operate bgd-opa">
          <span class="bgdopa-t">
            admin
            <i class="opa-arow"></i>
          </span>
          <ul class="bgdopa-list">
            <li style="display: none !important;" tptype="pending_matters">
              <a
                class="toast show-option"
                href="javascript:void(0);"
                onclick="$.cookie('commonPendingMatters', 0, {expires : -1});ajax_form('pending_matters', i18n.topNav.pendingMatters, 'http://www.baidu.cn', '480');"
                :title="i18n.topNav.checkPendingMatters"
              >
                &nbsp;
                <em>0</em>
              </a>
            </li>
            <li>
              <a
                class="changepasd"
                @click="passwordDialogVisible = true"
                href="javascript:void(0);"
              >{{i18n.topNav.password}}</a>
            </li>
            <li>
              <a class="novice" @click="newDialogVisible = true" href="javascript:void(0);">{{i18n.topNav.novice}}</a>
            </li>
            <li>
              <a class="login-out show-option" @click="quit()">{{i18n.topNav.quit}}</a>
            </li>
          </ul>
        </div>
      </div>
      <div class="clear"></div>
    </div>

    <el-dialog :title="i18n.topNav.guide" :visible.sync="newDialogVisible">
      <el-tabs v-model="activeName">
        <el-tab-pane :label="i18n.topNav.systemSettings" name="first">
          <div class="novice-guide-body">
            <h3>{{i18n.topNav.basicSettings}}</h3>
            <p>
              {{i18n.topNav.basicStatement}}
              <a
                href="#"
                class="fillin"
              > {{i18n.topNav.nowFillIn}}</a>
            </p>
            <h3>{{i18n.topNav.mallDecoration}}</h3>
            <p>
              {{i18n.topNav.decorationStatement1}}
              <a href="#" class="fillin">{{i18n.topNav.nowDecoration}}</a>
            </p>
          </div>
          <el-row>
            <el-switch
              v-model="open_teach"
              active-color="#13ce66"
              inactive-color="#ff4949"
              :active-value="1"
              :inactive-value="0"
              @change="switchTeach()"
            ></el-switch>{{i18n.topNav.noShow}}
            <el-button type="primary" style="float: right;" @click="activeName = 'second'">{{i18n.topNav.next}}</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="i18n.topNav.commodityData" name="second">
          <div class="novice-guide-body">
            <h3>{{i18n.topNav.productList}}</h3>
            <p>
              {{i18n.topNav.listStatement}}
              <a
                href="#"
                class="fillin"
              >{{i18n.topNav.nowRelease}}</a>
            </p>
            <h3>{{i18n.topNav.commoditySpec}}</h3>
            <p>
              {{i18n.topNav.specStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowAdd}}</a>
            </p>
            <h3>{{i18n.topNav.addBrand}}</h3>
            <p>
              {{i18n.topNav.brandStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowAdd}}</a>
            </p>
            <h3>{{i18n.topNav.setUpShop}}</h3>
            <p>
              {{i18n.topNav.setUpShop}}
              <a href="#" class="fillin">{{i18n.topNav.setUpStatement}}</a>
            </p>
          </div>
          <el-row style="float: right;">
            <el-button @click="activeName = 'first'">{{i18n.topNav.prev}}</el-button>
            <el-button type="primary" @click="activeName = 'third'">{{i18n.topNav.next}}</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="i18n.topNav.marketPromotion" name="third">
          <div class="novice-guide-body">
            <h3>{{i18n.topNav.promotionManagement}}</h3>
            <p>
              {{i18n.topNav.marketStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowSetup}}</a>
            </p>
            <h3>{{i18n.topNav.advPromotion}}</h3>
            <p>
              {{i18n.topNav.advStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowAdd}}</a>
            </p>
            <h3>{{i18n.topNav.distributionSystem}}</h3>
            <p>
             {{i18n.topNav.distributeStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowSetup}}</a>
            </p>
          </div>
          <el-row style="float: right;">
            <el-button @click="activeName = 'second'">{{i18n.topNav.prev}}</el-button>
            <el-button type="primary" @click="activeName = 'fourth'">{{i18n.topNav.next}}</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="i18n.topNav.businessManagement" name="fourth">
          <div class="novice-guide-body">
            <h3>{{i18n.topNav.paySettings}}</h3>
            <p>
              {{i18n.topNav.payStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowSetup}}</a>
            </p>
            <h3>{{i18n.topNav.seoSettings}}</h3>
            <p>
              {{i18n.topNav.seoStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowSetup}}</a>
            </p>
            <h3>{{i18n.topNav.logisticsSettings}}</h3>
            <p>
              {{i18n.topNav.logisticsStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowSetup}}</a>
            </p>
            <h3>{{i18n.topNav.orderManagement}}</h3>
            <p>
              {{i18n.topNav.orderStatement}}
              <a href="#" class="fillin">{{i18n.topNav.nowCheck}}</a>
            </p>
          </div>
          <el-row style="float: right;">
            <el-button @click="activeName = 'third'">{{i18n.topNav.prev}}</el-button>
            <el-button type="primary" @click="activeName = 'five'">{{i18n.topNav.next}}</el-button>
          </el-row>
        </el-tab-pane>
        <el-tab-pane :label="i18n.topNav.complete" name="five">
          <div class="novice-guide-body">
            <p>{{i18n.topNav.completeStatement}}</p>
            <el-row>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.roleManagement}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.roleStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.memberLevel}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.levelStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.inventoryLog}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.logStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.smsTemplate}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.smsStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.memberCard}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.cardStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.dataStatistics}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.dataStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
            <el-row>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;" :style="isZh==false?'height: 76px;':''">
                    <span>{{i18n.topNav.news}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.newsStatement}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
              <el-col :span="8">
                <el-card :body-style="{ padding: '0px' }">
                  <div style="padding: 14px;">
                    <span>{{i18n.topNav.mallDecoration}}</span>
                    <div class="bottom clearfix">
                      <span>{{i18n.topNav.decorationStatement2}}</span>
                    </div>
                  </div>
                </el-card>
              </el-col>
            </el-row>
          </div>
          <el-row style="float: right;">
            <el-button @click="activeName = 'fourth'">{{i18n.topNav.prev}}</el-button>
            <el-button type="primary" @click="newDialogVisible = false">{{i18n.topNav.complete}}</el-button>
          </el-row>
        </el-tab-pane>
      </el-tabs>
    </el-dialog>
    <el-dialog :title="i18n.topNav.password" :visible.sync="passwordDialogVisible" width="30%" center>
      <el-form :model="passWordForm" status-icon :rules="rules" ref="passWordForm">
        <el-form-item :label="i18n.topNav.oldPassword" :label-width="formLabelWidth" style="text-align: center;">
          <el-input v-model="passWordForm.old_password" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item :label="i18n.topNav.newPassword" :label-width="formLabelWidth" style="text-align: center;">
          <el-input v-model="passWordForm.test_password" autocomplete="off" type="password"></el-input>
        </el-form-item>
        <el-form-item :label="i18n.topNav.confirmPassword" :label-width="formLabelWidth2" style="text-align: center;">
          <el-input v-model="passWordForm.new_password" autocomplete="off" type="password"></el-input>
        </el-form-item>
      </el-form>
      <div slot="footer" class="dialog-footer">
        <el-button @click="passwordDialogVisible = false">{{i18n.topNav.cancel}}</el-button>
        <el-button type="primary" :loading="passwordLoading" @click="confirmPassword()">{{i18n.topNav.confirm}}</el-button>
      </div>
    </el-dialog>
  </div>
</template>

<script>
import {
  logout,
  getBaseInfo,
  updatePassword,
  updateOpenTeach,
  getGoodsReport,
  getOrderReport,
  getReturnGoodsReport,
  getStoreApplyCount,
  getStoreReopenCount,
  getStoreBindClassCount,
  getStoreWithdrawalsCount,
  getUserWithdrawalsCount,
  getComplainCount,
  getExposeCount,
  getFlashSaleCount,
  getTeamActivityCount,
  getPreSellCount,
  getConfigs
} from "@/utils/api";
import { removeToken } from "@/utils/auth";
import md5 from "@/utils/md5.js";
import navBarIcon from "@/mixins/navBarIcon.js";
import { mapState } from "vuex";
export default {
  props:{
	  ishide:{
		  default:true,
		  type:[Boolean]
	  }
  },
  data() {
    var validateOldPass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('js').inputOldPassword));
      } else {
        callback();
      }
    };
    var validatePass = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('js').inputNewPassword));
      } else {
        callback();
      }
    };
    var validatePass2 = (rule, value, callback) => {
      if (value === "") {
        callback(new Error(this.$t('js').inputAgain));
      } else if (value !== this.passWordForm.test_password) {
        callback(new Error(this.$t('js').atypism));
      } else {
        callback();
      }
    };
    return {
      activeName: "first",
      newDialogVisible: false,
      passwordDialogVisible: false,
      passWordForm: {
        old_password: "",
        test_password: "",
        new_password: ""
      },
      rules: {
        old_password: [
          {
            validator: validateOldPass,
            trigger: "blur"
          }
        ],
        test_password: [
          {
            validator: validatePass,
            trigger: "blur"
          }
        ],
        new_password: [
          {
            validator: validatePass2,
            trigger: "blur"
          }
        ]
      },
      formLabelWidth: "120px",
      formLabelWidth2: "142px",
      passwordLoading: false,
      open_teach: 0,
      goods_count: 0,
      goods_report: {
        wait_auth_count: 0
      },
      order_report: {
        wait_refund_count: 0
      },
      return_goods_report: {
        wait_refund_count: 0
      },
      store_apply_count: 0,
      store_reopen_count: 0,
      store_bind_class_count: 0,
      store_withdrawals_count: 0,
      user_withdrawals_count: 0,
      complain_count: 0,
      expose_count: 0,
      flash_count: 0,
      team_activity_count: 0,
      pre_sell_count: 0,
      message_count: 0,
      goods_msg_count: 0,
      seller_msg_count: 0,
      user_msg_count: 0,
      activity_count: 0,
      storeLogo: null,
	  isZh: "",
    };
  },
  mixins:[navBarIcon],
  created() {
	  // console.log(this.menuListUrl())

  },
  mounted() {
	  this.openTeach();
    this.getReport();
	this.getLogo();
  },
  computed: {
	...mapState(['menuList']),
  	i18n () {
      if (localStorage.getItem('language')=='zh_CN') {
        this.isZh = true
      } else {
        this.isZh = false
      }
  	  return this.$t('components')
    }
  },
  methods: {
    getLogo() {
      var that = this;
      getConfigs({ inc_type: "shop_info" }).then(function(res) {
        that.storeLogo = res.shop_info.store_logo;
      });
    },
    switchTeach() {
      updateOpenTeach(this.open_teach);
    },
    quit() {
      var that = this;
      logout().then(function(res) {
        if (res.status == 1) {
		  removeToken();
			/*退出清空本地缓存的菜单栏*/
			let arr = new Array()
			that.$store.commit('setMenuList',arr);
			that.$router.push({
				path: "/login"
			});
        } else {
          that.$message.error(that.$t('js').exitFailReason + res.msg);
        }
      });
    },
    confirmPassword() {
      var that = this;
      this.$refs["passWordForm"].validate(valid => {
        if (valid) {
          that.passwordLoading = true;
          var requestform = {
            old_password: md5("TPSHOP" + that.passWordForm.old_password),
            new_password: md5("TPSHOP" + that.passWordForm.new_password)
          };
          updatePassword(requestform).then(function(res) {
            if (res.status == 1) {
              //修改成功退出重新登录
              that.quit();
            } else {
              that.$message({
                showClose: true,
                message: that.$t('js').modifyFailReason + res.msg,
                type: "error"
              });
            }
            that.passwordLoading = false;
            that.resetForm("passWordForm");
          });
        } else {
          return false;
        }
      });
    },
    resetForm(formName) {
      this.$refs[formName].resetFields();
    },
    openTeach() {
      var that = this;
      getBaseInfo().then(function(res) {
        that.open_teach = res.open_teach;
        if (res.open_teach) {
          that.newDialogVisible = false;
        } else {
          that.newDialogVisible = true;
        }
      });
    },
    getGoodsReport() {
      var that = this;
      getGoodsReport({}).then(function(res) {
        that.goods_report = res;
        that.getMessageCount();
      });
    },
    getOrderReport() {
      var that = this;
      getOrderReport().then(function(res) {
        that.order_report = res;
        that.getMessageCount();
      });
    },
    getReturnGoodsReport() {
      var that = this;
      getReturnGoodsReport().then(function(res) {
        that.return_goods_report = res;
        that.getMessageCount();
      });
    },
    getStoreApplyCount() {
      var that = this;
      getStoreApplyCount({
        apply_state: 0
      }).then(function(res) {
        that.store_apply_count = res;
        that.getMessageCount();
      });
    },
    getStoreReopenCount() {
      var that = this;
      getStoreReopenCount({
        re_state: 1
      }).then(function(res) {
        that.store_reopen_count = res;
        that.getMessageCount();
      });
    },
    getStoreBindClassCount() {
      var that = this;
      getStoreBindClassCount({
        state: 0
      }).then(function(res) {
        that.store_bind_class_count = res;
        that.getMessageCount();
      });
    },
    getStoreWithdrawalsCount() {
      var that = this;
      getStoreWithdrawalsCount({
        status: 0
      }).then(function(res) {
        that.store_withdrawals_count = res;
        that.getMessageCount();
      });
    },
    getUserWithdrawalsCount() {
      var that = this;
      getUserWithdrawalsCount({
        status: 0
      }).then(function(res) {
        that.user_withdrawals_count = res;
        that.getMessageCount();
      });
    },
    getComplainCount() {
      var that = this;
      getComplainCount({
        complain_state: 1
      }).then(function(res) {
        that.complain_count = res;
        that.getMessageCount();
      });
    },
    getExposeCount() {
      var that = this;
      getExposeCount({
        expose_state: 1
      }).then(function(res) {
        that.expose_count = res;
        that.getMessageCount();
      });
    },
    getFlashSaleCount() {
      var that = this;
      getFlashSaleCount({
        status: 0,
        is_end: 0
      }).then(function(res) {
        that.flash_count = res;
        that.getMessageCount();
      });
    },
    getTeamActivityCount() {
      var that = this;
      getTeamActivityCount({
        status: 0
      }).then(function(res) {
        that.team_activity_count = res;
        that.getMessageCount();
      });
    },
    getPreSellCount() {
      var that = this;
      getPreSellCount({
        status: 0
      }).then(function(res) {
        that.pre_sell_count = res;
        that.getMessageCount();
      });
    },
    getReport() {
      this.getGoodsReport();
      this.getOrderReport();
      this.getReturnGoodsReport();
      this.getStoreApplyCount();
      this.getStoreReopenCount();
      this.getStoreBindClassCount();
      this.getStoreWithdrawalsCount();
      this.getUserWithdrawalsCount();
      this.getComplainCount();
      this.getExposeCount();
      this.getFlashSaleCount();
      this.getTeamActivityCount();
      this.getPreSellCount();
    },
    getMessageCount() {
      this.goods_msg_count =
        this.goods_report.wait_auth_count +
        this.order_report.wait_refund_count +
        this.return_goods_report.wait_refund_count;
      this.seller_msg_count =
        this.store_apply_count +
        this.store_reopen_count +
        this.store_bind_class_count +
        this.store_withdrawals_count;
      this.user_msg_count =
        this.user_withdrawals_count + this.complain_count + this.expose_count;
      this.activity_count =
        this.flash_count + this.team_activity_count + this.pre_sell_count;

      this.message_count =
        parseInt(this.goods_msg_count) +
        parseInt(this.seller_msg_count) +
        parseInt(this.user_msg_count) +
        parseInt(this.activity_count);
    },
	changeLang(val) {
		this.$i18n.locale = val
		localStorage.setItem("language", val)
	},
	menuListUrl(path){
		// let path = "/page/ad"
		let url
		let menuList = this.$store.state.menuList||[];
		let data = this.readNodes(menuList)
		data.map(item=>{
			if(item.path == path) {
				if(item.children.length > 0) {
					item.children.map(i=>{
						if(i.is_navigation ==1) {
							url = i.path
						}
					})
				}else{
					url = item.path
				}
			}
		})
		return url
	},
	readNodes (nodes = [], arr = []) {
		for (let item of nodes) {
			arr.push(item)
			if (item.children && item.children.length) this.readNodes(item.children, arr)
		}
		return arr
	}
  }
};
</script>

<style scoped>
.topnav {
  width: 100%;
  /* min-width: 1560px; */
  font-size: 14px;
  font-weight: bold;
}

.topnav .leftimg {
  text-align: center;
  cursor: pointer;
  float: left;
  background-color: #2e3c45;
}
.el-dropdown-link {
  cursor: pointer;
  color: #f5f5f5;
}
.el-icon-arrow-down {
  font-size: 12px;
}
>>> .el-collapse {
/*  border-top: 1px solid #ebeef5;
  border-bottom: 1px solid #ebeef5; */
  border: none;
  width: 200px;
}
>>> .el-collapse-item__header {
  display: -webkit-box;
  display: -ms-flexbox;
  display: flex;
  -webkit-box-align: center;
  -ms-flex-align: center;
  align-items: center;
  height: 48px;
  line-height: 48px;
  background-color: #1c2024;
  color: #fff;
  cursor: pointer;
  border-bottom: 1px solid #293038;
  font-size: 13px;
  font-weight: 500;
  -webkit-transition: border-bottom-color 0.3s;
  transition: border-bottom-color 0.3s;
  outline: 0;
  margin-left: 15px;
  margin-right: 15px;
}
>>> .el-collapse-item__content {
  padding-bottom: 25px;
  font-size: 13px;
  color: #303133;
  line-height: 1.769230769230769;
  margin-left: 15px;
  background-color: #293038;
}
>>> .el-collapse-item__wrap {
  will-change: height;
  background-color: #293038;
  overflow: hidden;
  -webkit-box-sizing: border-box;
  box-sizing: border-box;
  border-bottom: 1px solid #293038;
}

>>> .el-dropdown-menu {
  position: absolute;
  top: 0;
  left: 0;
  padding: 10px 0;
  margin: 5px 0;
  background-color: #fff;
  border: 1px solid #293038;
  border-radius: 4px;
  -webkit-box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
  box-shadow: 0 2px 12px 0 rgba(0, 0, 0, 0.1);
}

a {
  color: #fff;
  text-decoration: none;
  blr: expression(this.onFocus=this.blur());
}
.nc-module-menu .iconfont{
	vertical-align: baseline;
	font-weight: normal;
	font-size: 18px;
}
i{
	font-style: normal;
}
</style>
