export const en = {
  "addressWithSend":{
    "addressWithSend":{
      "areaSetting":"地区设置",
      "areaEdit":"可对系统内置的地区进行编辑",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"全站所有涉及的地区均来源于此处，强烈建议对此处谨慎操作。",
      "state2":"编辑地区信息后，需手动更新地区缓存(平台 > 设置 > 清理缓存 > 地区)，前台才会生效。",
      "state3":"所属大区为默认的全国性的几大区域，只有省级地区才需要填写大区域，目前全国几大区域有：华北、东北、华东、华南、华中、西南、西北、港澳台、海外",
      "state4":"所在层级为该地区的所在的层级深度，如北京>北京市>朝阳区,其中北京层级为1，北京市层级为2，朝阳区层级为3",
      "hideTips":"收起提示",
      "areaList":"地区列表",
      "total":"共",
      "records":"条记录",
      "addArea":"新增地区",
      "refresh":"刷新数据",
      "superiorArea":"上级区域",
      "backToSuperior":"返回上级地区",
      "searchArea":"搜索地区",
      "search":"搜索",
      "area":"地区",
      "hierarchy":"所在层级",
      "superiorAreaId":"上级地区id",
      "operation":"操作",
      "checkSubordinates":"查看下级",
      "addSubordinates":"新增下级",
      "delete":"删除",
      "areaAdd":"地区设置 - 新增",
      "areaName":"地区名",
      "state5":"请认真填写地区名称，地区设定后将直接影响订单、收货地址等重要信息，请谨慎操作。",
      "add":"添加",
    },
  },
  "aliPay":{
    "aliPaySet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "relatedConfiguration":"支付宝支付相关配置",
      "hideTips":"收起提示",
      "configuration":"支付宝支付配置",
      "appId":"支付宝APPID",
      "privateKey":"商户私钥",
      "publicKey":"支付宝公钥",
      "isOpenAlipay":"是否启用支付宝支付",
      "yes":"是",
      "no":"否",
      "submit":"确认提交",
    },
  },
  "msgTpl":{
    "memberMsgDetail":{
      "backToList":"返回列表",
      "notification":"消息通知",
      "editTemplate":"编辑消息模板“商品违规被下架”",
      "state1":"商城对商家及用户消息类发送设定",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state2":"点击复制变量，请粘贴到邮件内容。",
      "hideTips":"收起提示",
      "switchSelection":"消息模板切换选择",
      "mailTemplate":"站内信模板",
      "smsTemplate":"手机短信模板",
      "emailTemplate":"邮件模板",
      "templateName":"模板名称",
      "templateNum":"模板编号",
      "mailSwitch":"站内信开关",
      "content":"消息内容",
      "smsSwitch":"手机短信开关",
      "smsSignature":"短信签名",
      "smsTemplateId":"短信模板ID",
      "copy":"复制",
      "emailSwitch":"邮件开关",
      "emailTitle":"邮件标题",
      "emailContent":"邮件内容",
      "submit":"确认提交",
    },
    "memberMsgTpl":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"平台可给商家提供站内信、手机短信、邮件三种通知方式。平台可以选择开启一种或多种通知方式供商家选择。",
      "state2":"开启强制接收后，商家不能取消该方式通知的接收。",
      "state3":"短消息、邮件需要商家设置正确号码后才能正常接收。",
      "hideTips":"收起提示",
      "list":"模板列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "describe":"模板描述",
      "mail":"站内信",
      "sms":"手机短信",
      "email":"邮件",
      "operation":"操作",
      "edit":"编辑",
    },
    "storeMsgDetail":{
      "state1":"平台可给商家提供站内信、手机短信、邮件三种通知方式。平台可以选择开启一种或多种通知方式供商家选择。",
      "state2":"开启强制接收后，商家不能取消该方式通知的接收。",
      "state3":"短消息、邮件需要商家设置正确号码后才能正常接收。",
      "compulsoryReception":"强制接收",
    },
  },
  "payPal":{
    "set":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"PayPal/Stripe支付相关配置",
      "hideTips":"收起提示",
      "configuration":"配置",
      "submit":"确认提交",
      "payConfiguration":"支付配置",
      "secretKey":"密钥",
      "enable":"启用",
      "isEnable":"是否启用",
      "publish":"可发布的密钥",
    },
  },
  "pluginSet":{
    "loginPlugin":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"插件内部包括: 支付插件、登录插件、物流插件、",
      "state2":"1.APP跟PC网站支付宝支是同一个配置, 只需在'PC端支付宝支付'配置项配置',密钥'即可实现APP支付'密钥'配置请参考'APP支付宝支付'手册文档",
      "state3":"2.小程序微信登录统一在'微信小程序支付'配置(小程序微信登录不单独配置), 只需配置好'微信小程序支付'即可",
      "hideTips":"收起提示",
      "name":"插件名称",
      "describe":"插件描述",
      "image":"插件图片",
      "operation":"操作",
      "configuration":"配置",
      "delete":"删除",
    },
    "payPlugin":{
      "list":"插件列表",
    },
  },
  "shippingSet":{
    "express":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"设置快递100key用于跟踪查询物流信息",
      "state2":"快递鸟提供电子面单接口, 请从快递鸟注册账户, 并在其用户管理后台获取相关信息。",
      "hideTips":"收起提示",
      "expressDelivery":"快递方式",
      "express100":"快递100",
      "expressBird":"快递鸟",
      "warrant":"客户授权",
      "inquiry":"实时查询",
      "test":"测试",
      "selectCompany":"请选择快递公司",
      "sf":"顺丰快递",
      "yt":"圆通快递",
      "yd":"韵达快递",
      "zt":"中通快递",
      "bsht":"百世汇通快递",
      "logisticsOrderNo":"请填写物流单号",
      "storeId":"商户ID",
      "customerId":"快递客户号",
      "customerPassword":"快递客户密码",
      "explain":"说明",
      "applicationView":"点击申请查看",
      "result":"物流信息测试结果",
      "submit":"确认提交",
    },
    "shippingDetails":{
      "backToList":"返回列表",
      "management":"物流管理",
      "add":"添加物流",
      "IndexAndManagement":"网站系统物流索引和管理",
      "name":"物流公司名字",
      "state1":"物流公司大写首字母：如圆通快递：YTKD",
      "code":"快递公司编码",
      "state2":"物流公司编码由拼音组成,不得含有英文以外的字符。",
      "describe":"简短描述",
      "imageIcon":"物流图片图标",
      "toUpload":"请上传图片格式文件",
      "formworkWidth":"模板宽度",
      "widthUnit":"运单宽度，单位为毫米(mm)",
      "formworkHeight":"模板高度",
      "heightUnit":"运单高度，单位为毫米(mm)",
      "offset":"偏移量",
      "leftOffset":"运单模板左偏移量，单位为毫米(mm)",
      "topOffset":"运单模板上偏移量，单位为毫米(mm)",
      "formworkImage":"模板图片",
      "upload":"点击上传",
      "state3":"请上传扫描好的运单图片，图片尺寸必须与快递单实际尺寸相符",
      "selectPrint":"选择打印项",
      "selectSign":"请选择标签",
      "printCorrection":"打印项偏移校正",
      "submit":"确认提交",
    },
    "shippingList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"快递公司管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"快递公司列表",
      "total":"共",
      "records":"条记录",
      "add":"新增快递公司",
      "batchDelete":"批量删除",
      "refresh":"刷新数据",
      "name":"快递公司名称",
      "logisticsNum":"物流编号",
      "search":"搜索",
      "companyId":"物流公司ID",
      "companyName":"物流公司名称",
      "companyCode":"物流公司编码",
      "logisticsDec":"物流描述",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "shopSet":{
    "subdomain":{
      "subdomainEdit":{ 
        "backToList":"返回列表",
        "management":"促销管理",
        "edit":"编辑抢购",
        "webDetailPage":"网站系统抢购活动详情页",
        "title":"抢购标题",
        "fillInTitle":"请填写抢购标题",
        "shoppingOptions":"选择抢购商品",
        "selectGoods":"选择商品",
        "rushPrice":"限时抢购价格",
        "state1":"商品抢购价格,单位：",
        "rushNum":"参加抢购数量",
        "state2":"此抢购活动最多允许抢购的商品数量",
        "limitNum":"限购数量",
        "state3":"限时抢购每个人能购买的数量",
        "startTime":"开始时间",
        "startRushTime":"抢购开始时间",
        "state4":"重要:抢购时间是从凌晨0:00:00秒开始, 每隔2小时一个节点, 所以活动时间必须是两小时之内才有效, (例如今天是2017年1月1日下午2点场,",
        "state5":"那活动开始和结束时间必须是:2017-01-01 14:00:01 ~ 2017-01-01 15:59:59 时间范围内), 否则前端无法显示活动信息",
        "rushIntroduce":"抢购介绍",
        "fillInIntroduce":"请输入活动介绍",
        "describe":"抢购描述介绍",
        "publishNotice":"发布活动时，进行通知",
        "mailNotice":"站内信通知",
        "yes":"是",
        "no":"否",
        "submit":"确认提交",
      },
      "subdomainList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"1. 商家二级域名相关信息管理。",
        "state2":"2. 申请二级域名可提高网店的辨识度。",
        "hideTips":"收起提示",
        "list":"店铺域名列表",
        "total":"共",
        "records":"条记录",
        "refresh":"刷新数据",
        "secondaryDomainName":"二级域名",
        "editTimes":"编辑次数",
        "storeName":"店铺名称",
        "shopkeeperName":"店主名称",
        "status":"状态",
        "operation":"操作",
        "edit":"编辑",
        "delete":"删除",
      },
      "subdomainSet":{
        "isEnable":"是否启用二级域名",
        "isModify":"是否可修改",
        "state1":"不可修改时店主填写提交后将不可改动",
        "modifyTimes":"修改次数",
        "state2":"可修改时达到设定的次数后将不能再改动",
        "keepDomainName":"保留域名",
        "state3":"保留的二级域名，多个保留域名之间请用','隔开",
        "lengthLimit":"长度限制",
        "state4":"如'3-12'，代表注册的域名长度限制在3到12个字符之间",
        "submit":"确认提交",
      },
    },
    "basicSet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "hideTips":"收起提示",
      "signForPoints":"会员注册赠送积分",
      "uploadLimit":"附件上传大小限制",
      "selectUploadLimit":"请选择附件上传大小限制",
      "defaultStore":"默认库存",
      "addDefaultStore":"添加商品的默认库存",
      "taxRate":"发票税率",
      "state2":"当买家需要发票的时候就要增加[商品金额]*[税率]的费用",
      "hotwords":"首页热门搜索词",
      "state3":"首页或手机搜索页，搜索栏下侧显示",
      "browserBaiduAk":"浏览器端百度ak",
      "forBrowser":"用于浏览器",
      "applyBaiduAk":"去申请百度ak",
      "state4":"认证成功调用次数多些。",
      "serverBaiduAk":"服务端百度ak",
      "forServer":"用于服务器",
      "submit":"确认提交",
      "im":"Customer service system",
    },
    "distribut":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"如果分销开关关闭，那么不会平台启用分销模块，每个商品独立设置分销金",
      "state2":"如果规则由平台设置，那么分销规则统一按平台设置的规则返利，否则按各商家自行设置的比例规则",
      "state3":"所有分销商获佣比例之和不超过100%，比例为0则也视为不参与分佣，平台分销规则可针对分销商设置等级",
      "hideTips":"收起提示",
      "switch":"分销开关",
      "ruleSetting":"分销规则设置",
      "setByPlatform":"由平台设置",
      "setByStore":"由商家设置",
      "validity":"推荐开店有效期",
      "selectValidity":"请选择推荐开店有效期",
      "day":"天",
      "longTerm":"长期",
      "example":"例：多少天内开店成功，才能算是这个人推荐的的店",
      "rebateSeries":"返佣级数",
      "selectRebateSeries":"请选择返佣级数",
      "return1":"返一级",
      "return2":"返二级",
      "return3":"返三级",
      "commissionPercent1":"一级分销商获佣百分比",
      "state4":"购买者直接推荐人返佣占商品分销金比例",
      "commissionPercent2":"二级分销商获佣百分比",
      "state5":"购买者推荐人的上级返佣占商品分销金比例",
      "commissionPercent3":"三级分销商获佣百分比",
      "state6":"购买者推荐人的上上级占商品分销金比例",
      "QRCodeSetting":"二维码相关设定",
      "QRCodeBackground":"页面二维码背景",
      "myQRCodeBackground":"'我的二维码'大背景",
      "menuKeyWord":"微信菜单关键字",
      "state7":"用户点击微信菜单中的某项，响应'我的二维码'图片",
      "inputKeyWord":"微信输入关键字",
      "state8":"用户在微信中输入的文本，响应'我的二维码'图片",
      "submit":"确认提交",
    },
    "notice":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "hideTips":"收起提示",
      "messageSetting":"消息设置",
      "sms":"短信",
      "select":"请选择",
      "min":"分钟",
      "email":"邮箱",
      "smsSetting":"短信配置",
      "submit":"确认提交",
      "selectSmsPlatform":"选择短信平台",
      "alicloud":"阿里云",
      "tianruicloud":"天瑞云",
      "smsPlatform":"短信平台",
      "smsPlatformSetting":"短信平台配置",
      "name":"公司名/品牌名/产品名/配置电话",
      "emailSetting":"邮箱配置",
      "sendSmtp":"邮件发送服务器(SMTP)",
      "state2":"发送邮箱的smtp地址。如: smtp.gmail.com或smtp.qq.com",
      "smtpPort":"服务器(SMTP)端口",
      "state3":"smtp的端口。默认为25。具体请参看各STMP服务商的设置说明 （如果使用Gmail，请将端口设为465）",
      "emailAccount":"邮箱账号",
      "useSendAccount":"使用发送邮件的邮箱账号",
      "password":"邮箱密码/授权码",
      "state4":"使用发送邮件的邮箱密码,或者授权码。具体请参看各STMP服务商的设置说明",
      "receiveAddress":"测试接收的邮件地址",
      "test":"测试",
      "firstSave":"首次请先保存配置再测试",
    },
    "oss":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"推送服务相关设置, 请从阿里云注册账户, 并在其控制台获取相关信息。",
      "hideTips":"收起提示",
      "ossSwitvh":"上传oss开关",
      "isStored":"是否对商品图片启用对象存储功能",
      "stored":"对象存储的",
      "state2":"对象存储的Endpoint，比如 oss-cn-shenzhen.aliyuncs.com",
      "submit":"确认提交",
    },
    "poster":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"自定义海报设置请注意上传的图片尺寸，避免影响海报的展示效果。",
      "hideTips":"收起提示",
      "name":"海报名称",
      "image":"背景图片",
      "state2":"自定义海报建议尺寸640*1008像素，可根据该数据对二维码位置进行调整",
      "pixel":"当前上传的图片宽高像素为",
      "position":"二维码位置",
      "axis":"轴",
      "coordinate":"坐标",
      "state3":"输入像素数值可移动模拟二维码在海报背景图上的位置",
      "size":"二维码大小",
      "state4":"数值越大，二维码图片越大",
      "submit":"确认提交",
      "preview":"预览",
    },
    "push":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"推送服务相关设置, 请从极光官网注册账户并获取相关信息。",
      "hideTips":"收起提示",
      "uniqueIdentification":"App应用推送服务的唯一标识",
      "state2":"用于服务器端 API 调用时与 AppKey 配合使用达到鉴权的目的，请保管好 Master Secret 防止外泄。",
      "submit":"确认提交",
    },
    "shopInfo":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "state2":"该页面的所有默认图标logo在'网站根目录/public/static/images/logo'目录下, 如果需要重新设计图标可参考默认图",
      "hideTips":"收起提示",
      "domainName":"接口域名",
      "state3":"示例:https://j.tpsns.com（注:必须是https://开头的域名，用于接受微信小程序等支付通知）",
      "webRecordNum":"网站备案号",
      "state4":"网站备案号，将显示在前台底部欢迎信息等位置",
      "webName":"网站名称",
      "state5":"网站名称，将显示在前台顶部欢迎信息等位置",
      "companyName":"公司名称",
      "state6":"公司名称:将显示在管理员后台欢迎页面",
      "webLogo":"网站Logo",
      "state7":"默认网站首页LOGO,通用头部显示，最佳显示尺寸为230*58像素",
      "userCenterLogo":"网站用户中心Logo",
      "state8":"默认用户中心网站LOGO,用户中心通用头部显示，最佳显示尺寸为230*58像素",
      "titleIcon":"网站标题图标",
      "state9":"网站标题图标LOGO,最佳显示尺寸为48*48像素",
      "webTitle":"网站标题",
      "state10":"网站标题，将显示在前台顶部欢迎信息等位置",
      "webDescribe":"网站描述",
      "state19":"网站描述，将显示在前台顶部欢迎信息等位置",
      "webKeyword":"网站关键字",
      "state11":"网站关键字，便于SEO",
      "storeLoginLogo":"商家登录页Logo",
      "state12":"商家登录页LOGO,最佳显示尺寸为163*26像素",
      "adminLoginLogo":"平台管理员登录页Logo",
      "state13":"平台管理员登录页LOGO,最佳显示尺寸为220*82像素",
      "adminTopLogo":"平台后台顶部Logo",
      "state14":"平台后台顶部LOGO,最佳显示尺寸为148*28像素",
      "mobileLogo":"手机端首页Logo",
      "state15":"手机端首页搜索框LOGO,最佳显示尺寸为48*48像素",
      "mobileLoginLogo":"手机端登录页Logo",
      "state16":"手机端登录页LOGO,最佳显示尺寸为312*92像素",
      "officialAccount":"公众号二维码",
      "state17":"pc端公共底部官方微信二维码,最佳显示尺寸为90*90像素",
      "contacts":"联系人",
      "telephone":"联系电话",
      "detailAddress":"具体地址",
      "customerService":"平台客服",
      "state18":"商家中心右下侧显示，方便商家遇到问题时咨询",
      "PCFormat":"PC首页版式",
      "format":"版式",
	  "showInvoice": "是否显示发票信息",
	  "yes": "是",
	  "no": "否",
      "submit":"确认提交",
    },
    "shopping":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "hideTips":"收起提示",
      "conversionProportion":"积分换算比例",
      "integral":"积分",
      "bonusProportion":"赠送积分比例",
      "state2":"发布商品, 赠送积分限制: 100表示不限制, 50时购买该商品赠送的积分所抵扣金额不能超过商品价格的50%",
      "minLimit":"最低使用限制",
      "state3":"购买商品, 使用积分时: 0表示不限制, 大于0时, 用户积分小于该值将不能使用积分",
      "useProportion":"使用比例",
      "state4":"购买商品, 使用积分时: 100时不限制, 为0时不能使用积分, 50时积分抵扣金额不能超过该笔订单应付金额的50%",
      "automaticReceipt":"发货后多少天自动收货",
      "day":"天",
      "select":"请选择天数",
      "orderSettlement":"收货多少天后订单结算",
      "advanceDeposit":"收货多少天后,订单结算金额转入卖家平台预存款",
      "afterSales":"申请售后时间段",
      "inventoryReduction":"减库存的时机",
      "orderSuccess":"下单成功时",
      "paySuccess":"支付成功时",
      "state5":"拼团下单后未支付多久时间后才能让后续的人下单(秒)",
      "state6":"订单下单后未支付多久后取消订单(秒)",
      "submit":"确认提交",
    },
    "_subdomain":{
      "secondaryDomainName":"二级域名",
      "storeSetting":"商城店铺使用二级域名管理设置",
      "setting":"设置",
      "list":"域名列表",
    },
    "tip":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"最终小费的额度是以订单金额*小费比例形成档位。可供用户支付选择",
      "hideTips":"收起提示",
      "tipConfiguration":"小费配置",
      "submit":"确认提交",
      "tipSwitch":"小费开关",
      "proportion1":"一档小费比例(%)",
      "proportion2":"二档小费比例(%)",
      "proportion3":"三档小费比例(%)",
      "state2":"小费归属比例(%):0表示归平台所有,100表示归商家所有",
      "taxConfiguration":"税费配置",
      "taxSwitch":"税费开关",
    },
    "water":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "globalSetting":"系统平台全局设置,包括基础设置、购物、短信、邮件、水印和分销等相关模块。",
      "hideTips":"收起提示",
      "addWatermark":"商品图片添加水印",
      "watermarkType":"水印类型",
      "text":"文字",
      "image":"图片",
      "watermarkText":"水印文字",
      "fontSize1":"文字字号",
      "fontSize2":"字体大小",
      "color":"文字颜色",
      "watermarkImage":"水印图片",
      "state1":"用于商品缩略图上水印",
      "addCondition":"水印添加条件",
      "width":"图片宽度 单位像素(px)",
      "height":"图片高度 单位像素(px)",
      "widthAndHeight":"水印的宽度和高度",
      "quality":"水印质量",
      "state2":"水印质量请设置为0-100之间的数字,决定 jpg 格式图片的质量",
      "transparency":"水印透明度",
      "state3":"0代表完全透明，100代表不透明",
      "position":"水印位置",
      "topLeft":"顶部居左",
      "topMiddle":"顶部居中",
      "topRight":"顶部居右",
      "middleLeft":"中部居左",
      "middle":"中部居中",
      "middleRight":"中部居右",
      "bottomLeft":"底部居左",
      "bottomMiddle":"底部居中",
      "bottomRight":"底部居右",
      "submit":"确认提交",
    },
    "withdrawalSet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"最高手续费不应高于最高提现金额",
      "hideTips":"收起提示",
      "withdrawalConfiguration":"提现配置",
      "handlingChargesProportion":"手续费比例",
      "state2":"注：默认是百分比，如填1就是 代表每笔提现，收取提现金额1%的手续费",
      "minHandlingCharge":"最低手续费",
      "state3":"注：单笔手续费计算出来小于该值时，则取该值",
      "maxHandlingCharge":"最高手续费",
      "state4":"注：单笔手续费计算出来大于该值时，则取该值",
      "minWithdrawal":"最低提现额",
      "state5":"注：单笔最低提现额",
      "maxWithdrawal":"最高提现额",
      "state6":"注：单笔最高提现额",
      "withdrawalAmount":"每日累计提现额",
      "state7":"注：单人每日累计提现额达到该值时，本日将不支持继续提现",
      "withdrawalTimes":"每日累计提现次数",
      "state8":"注：单人每日累计提现次数达到该值时，本日将不支持继续提现",
      "submit":"确认提交",
    },
  },
  "wechat":{
    "follower":{
      "followerList":{
        "fansList":"粉丝列表",
        "fansListManagement":"粉丝列表管理",
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"关注本公众号的粉丝在此列表显示",
        "state2":"若要同步粉丝信息，请清除缓存，自动同步",
        "state3":"服务号才可以按选定的多个用户进行群发，订阅号没这个权限",
        "state4":"服务号和订阅号都可以使用发送所有粉丝功能，群发的次数一个月最多4次，一天最多1次",
        "hideTips":"收起提示",
        "total":"共",
        "records":"条记录",  
        "refresh":"刷新数据",
        "portrait":"头像",          
        "nickname":"昵称",
        "gender":"性别",          
        "male":"男",
        "female":"女",
        "unknown":"未知",          
        "address":"所在地",
        "language":"用户语言",          
        "focusTime":"关注时间",
        "operation":"操作",          
        "member":"会员",
        "wechat":"微信",          
        "detailInfo":"微信粉丝详细信息",
        "portraitAddress":"头像地址",
        "remark":"备注",
        "signList":"标签列表",
        "fansResource":"粉丝来源",
        "close":"关闭",
      },
    },
    "materialManage":{
      "imageList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state":"发送图片消息请移步粉丝列表",
        "hideTips":"收起提示",
        "imageList":"图片列表",
        "total":"共",
        "records":"条记录",
        "refresh":"刷新数据",
        "upload":"上传",
        "noMaterial":"还没有相关素材",
        "deleteMaterial":"删除图片素材",
      },
      "news":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"文章中的图片请使用插件上传，不要使用复制粘贴的方式。",
        "hideTips":"收起提示",
        "title":"标题",
        "state2":"最多64个字",
        "author":"作者",
        "state3":"非必填，最多8个字",
        "text":"正文",
        "state4":"不多于2万字",
        "abstract":"摘要",
        "state5":"选填，如果不填则抓取正文前64字。不多于120字。",
        "link":"原文链接",
        "state6":"链接前请带上 http:// 或者 https:// ，不填则不显示",
        "cover":"封面",
        "state7":"请上传图片格式文件",
        "displayCovers":"显示封面",
        "state8":"是否在正文开始处显示封面",
        "openComments":"是否打开评论",
        "state9":"当公众号具备留言功能的权限时，此设置才生效",
        "fanReviews":"是否粉丝才可评论",
        "state10":"评论范围（所有人，或仅公众号粉丝可以留言）",
        "submit":"确认提交",
      },
      "newsList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"发送图文消息请移步粉丝列表",
        "state2":"每个图文素材最多包含8篇文章！",
        "hideTips":"收起提示",
        "list":"图文列表",
        "total":"共",
        "records":"条记录",
        "addImageText":"新增图文",
        "refresh":"刷新数据",
        "noMaterial":"还没有相关素材",
        "addArticle":"在素材上添加文章",
        "deleteMaterial":"删除图文素材",
      },
      "videoList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"发送视频消息请移步粉丝列表",
        "hideTips":"收起提示",
        "list":"视频列表",
        "total":"共",
        "records":"条记录",
        "add":"添 加",
        "refresh":"刷新数据",
        "noMaterial":"还没有相关素材",
        "deleteMaterial":"删除视频素材",
        "uploadMaterial":"视频素材上传",
        "addVideo":"添加视频",
        "selectFile":"选取文件",
        "state2":"最大10MB，支持MP4格式，点击文件名预览播放",
        "title":"视频标题",
        "state3":"标题将展示在相关播放页面，建议填写清晰、准确、生动的标题",
        "introduce":"视频介绍",
        "synopsis":"视频简介",
        "state4":"介绍语将展示在相关播放页面，建议填写简洁明确、有信息量的内容",
        "preview":"预览",
        "upload":"上 传",
      },
      "voiceList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"发送音频消息请移步粉丝列表",
        "hideTips":"收起提示",
        "list":"音频列表",
        "total":"共",
        "records":"条记录",
        "add":"添 加",
        "refresh":"刷新数据",
        "noMaterial":"还没有相关素材",
        "deleteMaterial":"删除音频素材",
        "uploadMaterial":"音频素材上传",
        "addVoice":"添加音频",
        "selectFile":"选取文件",
        "state2":"最大2MB，支持mp3/wma/wav/amr格式，点击文件名预览播放",
        "title":"音频标题",
        "state3":"标题将展示在相关播放页面，建议填写清晰、准确、生动的标题",
        "preview":"预览",
        "upload":"上 传",
      },
    },
    "reply":{
      "default":{
        "replyContent":"回复内容",
        "toInput":"请输入内容",
        "submit":"提 交",
      },
      "keyword":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"在此添加关键字, 用户发送关键词与之匹配时自动回复",
        "hideTips":"收起提示",
        "list":"关键词回复列表",
        "total":"共",
        "records":"条记录",
        "add":"添加关键词回复",
        "refresh":"刷新数据",
        "keyword":"关键词",
        "replyType":"回复类型",
        "text":"文本",
        "imageText":"图文",
        "image":"图片",
        "video":"视频",
        "voice":"音频",
        "content":"内容",
        "operation":"操作",
        "edit":"编辑",
        "delete":"删除",
        "state2":"多个关键词，请以英文逗号隔开( , )",
        "state3":"注意：关键字图文消息回复只能回复一条，也就是选择图文素材的第一条图文",
        "replyContent":"回复内容",
        "autoReplyContent":"自动回复内容",
        "save":"保 存",
        "selectImageText":"选择图文素材",
        "selectImage":"选择图片素材",
        "selectVideo":"选择视频素材",
        "selectVoice":"选择音频素材",
      },
    },
    "_materialManage":{
      "management":"素材管理",
      "displayAndManagement":"微信素材展示与管理",
      "textMessage":"图文消息",
      "image":"图片",
      "video":"视频",
      "voice":"音频",
    },
    "miniapp":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "wxAppletsConfiguration":"微信小程序配置",
      "hideTips":"收起提示",
      "appletsConfiguration":"小程序配置",
      "applets":"小程序",
      "submit":"确认提交",
    },
    "paySet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "configuration":"微信支付与微信小程序相关配置",
      "hideTips":"收起提示",
      "merchantConfiguration":"商户配置",
      "submit":"确认提交",
      "num":"商户号",
      "paymentKey":"商户支付密钥",
      "certificate":"商户证书",
      "upload":"点击上传",
      "openWechatPayment":"是否启用微信支付",
      "yes":"是",
      "no":"否",
      "appletsConfiguration":"小程序配置",
      "applets":"小程序",
    },
    "_reply":{
      "replyManagement":"自动回复管理",
      "officialAccountManagement":"微信公众号管理",
      "keyword":"关键词自动回复",
      "message":"消息自动回复",
      "follow":"关注时自动回复",
    },
    "wxMenu":{
      "menuManagement":"微信菜单管理",
      "menu":"微信菜单",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"自定义菜单最多包括3个一级菜单，每个一级菜单最多包含5个二级菜单",
      "state2":"一级菜单最多4个汉字，二级菜单最多7个汉字，多出来的部分将会以'...'代替",
      "state3":"创建自定义菜单后，菜单的刷新策略是，在用户进入公众号会话页或公众号profile页时，如果发现上一次拉取菜单的请求在5分钟以前，就会拉取一下菜单，如果菜单有更新，就会刷新客户端的菜单。测试时可以尝试取消关注公众账号后再次关注，则可以看到创建后的效果",
      "state4":"如果需要添加小程序菜单，请把路径指定为首页： 复制这个pages/index/index/index",
      "state5":"更改菜单后需要点击保存生效，公众号菜单才能更新",
      "hideTips":"收起提示",
      "list":"菜单列表",
      "addPrimaryMenu":"添加一级菜单",
      "save":"保存生效",
      "refresh":"刷新数据",
      "name":"菜单名称",
      "type":"菜单类型",
      "url":"菜单URL",
      "operation":"操作",
      "addSubmenu":"添加子菜单",
      "add":"添加",
      "delete":"删除",
    },
    "wxUser":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"配置微信公众号, token、Appid、AppSecret要与微信公众开放平台信息一致",
      "hideTips":"收起提示",
      "address":"服务器地址",
      "configuration":"配置",
      "domainName":"接口域名",
      "display":"后显示",
      "state2":"1. Token相当于腾讯微信公众平台服务器与你服务器交互的密钥",
      "state3":"2. 在正式部署的时候，请务必设置得较为复杂，否则可能被黑客利用，伪装你的服务器向你的用户发送消息",
      "officialAccountName":"公众号名称",
      "officialAccountId":"公众号原始ID",
      "wechatNum":"微信号",
      "portrait":"头像",
      "qrcode":"二维码",
      "state4":"1. 此处上传公众号二维码,建议上传90*高90像素GIF\JPG\PNG格式图片",
      "state5":"2. 此处二维码将会在PC首页侧边栏（右下角）展示",
      "wechatType":"微信号类型",
      "accessStatus":"微信接入状态",
      "toAccess":"等待接入",
      "accessed":"已接入",
      "state6":"重要：任何时候都要勾选'已接入'，否则微信功能无法正常使用。 除非，在接入微信（开发 ->基本配置 -> 修改配置）提交url和token",
      "state7":"提示'url超时'或'token'错误时，此处临时勾选为'等待接入'，提交成功后改为'已接入'",
      "submit":"确认提交",
    },
  },
  "adminList":{
    "management":"管理员管理",
    "webAdministratorList":"网站系统管理员列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"网站系统管理员, 由总平台设置管理.",
    "hideTips":"收起提示",
    "list":"管理员列表",
    "addAdministrator":"新增管理员",
    "refresh":"刷新数据",
    "username":"用户名",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addEditAdministrator":"添加/编辑管理员",
    "address":"Email地址",
    "password":"登陆密码",
    "confirmPassword":"确认登录密码",
    "add":"添加",
    "role":"角色",
  },
  "menus":{
    "management":"菜单管理",
    "managementList":"网站系统菜单列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"网站系统菜单列表, 由超级管理员设置管理.",
    "hideTips":"收起提示",
    "list":"菜单列表",
    "addMenu":"新增菜单",
    "menuName":"菜单名称",
    "path":"网址",
	"parentMenu":"父菜单",
    "refresh":"刷新数据",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addEditMenu":"添加/编辑菜单",
    "add":"保存",
    "sort":"排序",
  },
  "roles":{
    "management":"角色管理",
    "managementList":"网站系统角色列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"网站系统角色列表, 由超级管理员设置管理.",
    "hideTips":"收起提示",
    "list":"角色列表",
    "addRole":"新增角色",
    "roleName":"角色名称",
    "content":"角色描述",
    "refresh":"刷新数据",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addEditRole":"添加/编辑角色",
    "add":"保存",
  	"sort":"排序",
	"menu":"访问菜单",
  	"createTime":"创建时间",
  },
  "email":{
    "notification":"邮件通知",
    "state1":"可对用户发送商品信息和自定义编辑",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state2":"可通过选择会员发送给选中的用户",
    "state3":"选择商品可以发送商品的图片和商品名",
    "hideTips":"收起提示",
    "title":"邮件标题:",
    "member":"会员:",
    "goods":"商品:",
    "select":"选择商品",
    "goodsName":"商品名称",
    "spec":"规格",
    "stock":"库存",
    "mallPrice":"商城价格",
    "operation":"操作",
    "delete":"删除",
    "describe":"邮件描述:",
    "submitToSend":"提交发送",
    "searchUser":"选择用户",
    "level":"会员等级",
    "nickname":"会员昵称",
    "email":"邮箱",
    "search":"搜索",
    "confirm":"确认选择",
    "memberId":"会员id",
    "goodsId":"商品id",
    "goodsCode":"商品货号",
    "price":"价格",
    "marketPrice":"市场价",
    "store":"库存量",
  },
  "emaillog":{
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "checkLog":"可查看平台日志。",
    "hideTips":"收起提示",
    "startDate":"开始日期",
    "endDate":"结束日期",
    "search":"搜索",
    "scene":"应用场景",
    "email":"普通邮件",
    "notice":"通知",
    "verificationCode":"验证码",
    "sendTime":"发送时间",
    "title":"标题",
    "sender":"发送人",
    "operation":"操作",
    "checkDetail":"查看详情",
  },
  "emailreceiver":{
    "notification":"邮件日志",
    "state":"日志详情",
    "title":"邮件标题：",
    "sendTime":"发送时间：",
    "sender":"发送人：",
    "content":"邮件内容：",
    "receiver":"接收人",
    "mailBox":"接收邮箱",
    "status":"接收状态",
    "success":"成功",
  },
  "emails":{
    "emailLog":"邮件日志",
    "sendSettings":"商城对商家及用户消息类发送设定",
    "systemLog":"平台日志",
    "storeLog":"商家日志",
  },
  "index":{
    "setting":"设置",
    "mallSetting":"商城设置",
    "regionalDistribution":"地区&配送",
    "smsTemplate":"短信模板",
    "notification":"消息通知",
    "seoSetting":"SEO设置",
    "expressCompany":"快递公司",
    "jurisdiction":"权限",
    "list":"管理员列表",
    "menu_manage":"菜单管理",
    "role_manage":"角色管理",
    "email":"邮件",
    "sendEmail":"发送邮件",
    "emailLog":"邮件日志",
    "wechat":"微信",  
    "officialAccountSetting":"公众号设置",
    "appletsPay":"小程序/支付",
    "alipay":"支付宝",
    "alipaySetting":"支付宝配置",
    "paySetting":"支付配置",
  },
  "_msgTpl":{
    "notification":"消息通知",
    "sendSettings":"商城对商家及用户消息类发送设定",
    "merchantMessTemplate":"商家消息模板",
    "userMessTemplate":"用户消息模板",
  },
  "plugin":{
    "management":"插件管理",
    "setting":"配置",
    "webManagement":"网站系统插件管理",
    "sortName":"分类名称",
  },
  "plugins":{
    "management":"插件管理",
    "indexAndManagement":"网站系统插件索引与管理",
    "payPlugin":"支付插件",
    "loginPlugin":"登录插件",
  },
  "selleremaillog":{
    "checkLog":"查看商家日志。",
    "type":"类型",
    "storeName":"商家名称：",
  },
  "seoSet":{
    "seoSettings":"SEO设置",
    "setOptions":"商城各级页面搜索引擎优化设置选项",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"插入的变量必需包括花括号“{}”，当应用范围不支持该变量时，该变量将不会在前台显示(变量后边的分隔符也不会显示)，留空为系统默认设置，SEO自定义支持手写。以下是可用SEO变量：",
    "state2":"站点名称 {sitename}，（应用范围：全站）",
    "state3":"名称 {name}，（应用范围：抢购名称、商品名称、品牌名称、文章标题、分类名称）",
    "state4":"文章分类名称 {article_class}，（应用范围：文章分类页）",
    "state5":"店铺名称 {shopname}，（应用范围：店铺页）",
    "state6":"关键词 {key}，（应用范围：商品关键词、文章关键词、店铺关键词）",
    "state7":"简单描述 {description}，（应用范围：商品描述、文章摘要、店铺关键词）",
    "hideTips":"收起提示",
    "homePage":"首页",
    "save":"保 存",
    "rushPurchase":"抢购",
    "rushPurchaseContent":"抢购内容",
    "brand":"品牌",
    "aBrandList":"某一品牌商品列表",
    "makeUpGroup":"拼团",
    "groupContent":"拼团内容",
    "integralCenter":"积分中心",
    "goodsContent":"积分中心商品内容",
    "article":"文章",
    "sortList":"文章分类列表",
    "articleContent":"文章内容",
    "store":"店铺",
    "goods":"商品",
    "goodsDetail":"商品详情",
    "goodsList":"商品列表",
  },
  "shippings":{
    "management":"快递公司管理",
    "listAndManagement":"快递公司列表与管理",
    "logisticsInfo":"物流信息配置",
  },
  "_shopSet":{
    "mall":"商城设置",
    "webBasic":"网站全局内容基本选项设置",
    "mallInfo":"商城信息",
    "basic":"基本设置",
    "withdrawal":"提现设置",
    "process":"购物流程",
    "smsOrEmail":"短信/邮件设置",
    "watermark":"水印设置",
    "distribution":"分销设置",
    "object":"对象存储",
    "poster":"海报设置",
    "tipOrTax":"小费/税费",
  },
  "smsTpl":{
    "template":"短信配置模板",
    "platformTemplate":"短信平台短信配置模板",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"短信配置模板, 由平台设置管理.",
    "state2":"添加/编辑模板时注意'短信签名'、'短信内容'的填写.",
    "state3":"当添加了所有短信场景时，无法继续新增模板.",
    "hideTips":"收起提示",
    "list":"模板列表",
    "total":"共",
    "records":"条记录",
    "add":"新增模板",
    "refresh":"刷新数据",
    "scene":"应用场景",
    "smsSignature":"短信签名",
    "smsTemplate":"短信模板",
    "sendContent":"发送短信的内容",
    "modifyTime":"修改时间",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "smsTplDetail":{
    "backToList":"返回列表",
    "template":"短信配置模板",
    "edit":"编辑模板",
    "webManagement":"网站系统短信配置模板管理", 
    "smsSignature":"短信签名",
    "state1":"必须与短信平台短信签名一致",
    "smsTemplateId":"短信模板ID",
    "state2":"必须与短信平台短信模板ID一致",
    "sendScene":"发送场景",
    "selectScene":"请选择使用场景",
    "usageScene":"使用场景",
    "smsContent":"短信内容",
    "submit":"确认提交",
  },
  "suppliers":{
    "management":"供应商管理",
    "indexAndManagement":"网站系统供应商索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"供应商管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "list":"供应商列表",
    "total":"共",
    "records":"条记录",
    "add":"添加供应商",
    "refresh":"刷新数据",
    "name":"供应商名称",
    "describe":"供应商描述",
    "contacts":"供应商联系人",
    "telephone":"供应商电话",
    "storeId":"所属店铺ID",
    "status":"状态",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addOrEdit":"供应商新增/编辑",
    "determine":"确 定",
  },
  "suppliersDetail":{
    "backToList":"返回列表",
    "management":"权限管理",
    "supplierManagement":"供应商管理",
    "webSupplierManagement":"网站系统供应商管理",
    "supplierName":"供应商名称",
    "supplierDesc":"供应商描述",
    "supplierContact":"供应商联系人",
    "supplierPhone":"供应商电话",
    "submit":"确认提交",
  },
}

export const zh = {
  "addressWithSend":{
    "addressWithSend":{
      "areaSetting":"地区设置",
      "areaEdit":"可对系统内置的地区进行编辑",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"全站所有涉及的地区均来源于此处，强烈建议对此处谨慎操作。",
      "state2":"编辑地区信息后，需手动更新地区缓存(平台 > 设置 > 清理缓存 > 地区)，前台才会生效。",
      "state3":"所属大区为默认的全国性的几大区域，只有省级地区才需要填写大区域，目前全国几大区域有：华北、东北、华东、华南、华中、西南、西北、港澳台、海外",
      "state4":"所在层级为该地区的所在的层级深度，如北京>北京市>朝阳区,其中北京层级为1，北京市层级为2，朝阳区层级为3",
      "hideTips":"收起提示",
      "areaList":"地区列表",
      "total":"共",
      "records":"条记录",
      "addArea":"新增地区",
      "refresh":"刷新数据",
      "superiorArea":"上级区域",
      "backToSuperior":"返回上级地区",
      "searchArea":"搜索地区",
      "search":"搜索",
      "area":"地区",
      "hierarchy":"所在层级",
      "operation":"操作",
      "checkSubordinates":"查看下级",
      "addSubordinates":"新增下级",
      "delete":"删除",
      "areaAdd":"地区设置 - 新增",
      "areaName":"地区名",
      "state5":"请认真填写地区名称，地区设定后将直接影响订单、收货地址等重要信息，请谨慎操作。",
      "add":"添加",
    },
  },
  "aliPay":{
    "aliPaySet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "relatedConfiguration":"支付宝支付相关配置",
      "hideTips":"收起提示",
      "configuration":"支付宝支付配置",
      "appId":"支付宝APPID",
      "privateKey":"商户私钥",
      "publicKey":"支付宝公钥",
      "isOpenAlipay":"是否启用支付宝支付",
      "yes":"是",
      "no":"否",
      "submit":"确认提交",
    },
  },
  "msgTpl":{
    "memberMsgDetail":{
      "backToList":"返回列表",
      "notification":"消息通知",
      "editTemplate":"编辑消息模板“商品违规被下架”",
      "state1":"商城对商家及用户消息类发送设定",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state2":"点击复制变量，请粘贴到邮件内容。",
      "hideTips":"收起提示",
      "switchSelection":"消息模板切换选择",
      "mailTemplate":"站内信模板",
      "smsTemplate":"手机短信模板",
      "emailTemplate":"邮件模板",
      "templateName":"模板名称",
      "templateNum":"模板编号",
      "mailSwitch":"站内信开关",
      "content":"消息内容",
      "smsSwitch":"手机短信开关",
      "smsSignature":"短信签名",
      "smsTemplateId":"短信模板ID",
      "copy":"复制",
      "emailSwitch":"邮件开关",
      "emailTitle":"邮件标题",
      "emailContent":"邮件内容",
      "submit":"确认提交",
    },
    "memberMsgTpl":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"平台可给商家提供站内信、手机短信、邮件三种通知方式。平台可以选择开启一种或多种通知方式供商家选择。",
      "state2":"开启强制接收后，商家不能取消该方式通知的接收。",
      "state3":"短消息、邮件需要商家设置正确号码后才能正常接收。",
      "hideTips":"收起提示",
      "list":"模板列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "describe":"模板描述",
      "mail":"站内信",
      "sms":"手机短信",
      "email":"邮件",
      "operation":"操作",
      "edit":"编辑",
    },
    "storeMsgDetail":{
      "state1":"平台可给商家提供站内信、手机短信、邮件三种通知方式。平台可以选择开启一种或多种通知方式供商家选择。",
      "state2":"开启强制接收后，商家不能取消该方式通知的接收。",
      "state3":"短消息、邮件需要商家设置正确号码后才能正常接收。",
      "compulsoryReception":"强制接收",
    },
  },
  "payPal":{
    "set":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"PayPal/Stripe支付相关配置",
      "hideTips":"收起提示",
      "configuration":"配置",
      "submit":"确认提交",
      "payConfiguration":"支付配置",
      "secretKey":"密钥",
      "enable":"启用",
      "isEnable":"是否启用",
      "publish":"可发布的密钥",
    },
  },
  "pluginSet":{
    "loginPlugin":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"插件内部包括: 支付插件、登录插件、物流插件、",
      "state2":"1.APP跟PC网站支付宝支是同一个配置, 只需在'PC端支付宝支付'配置项配置',密钥'即可实现APP支付'密钥'配置请参考'APP支付宝支付'手册文档",
      "state3":"2.小程序微信登录统一在'微信小程序支付'配置(小程序微信登录不单独配置), 只需配置好'微信小程序支付'即可",
      "hideTips":"收起提示",
      "name":"插件名称",
      "describe":"插件描述",
      "image":"插件图片",
      "operation":"操作",
      "configuration":"配置",
      "delete":"删除",
    },
    "payPlugin":{
      "list":"插件列表",
    },
  },
  "shippingSet":{
    "express":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"设置快递100key用于跟踪查询物流信息",
      "state2":"快递鸟提供电子面单接口, 请从快递鸟注册账户, 并在其用户管理后台获取相关信息。",
      "hideTips":"收起提示",
      "expressDelivery":"快递方式",
      "express100":"快递100",
      "expressBird":"快递鸟",
      "warrant":"客户授权",
      "inquiry":"实时查询",
      "test":"测试",
      "selectCompany":"请选择快递公司",
      "sf":"顺丰快递",
      "yt":"圆通快递",
      "yd":"韵达快递",
      "zt":"中通快递",
      "bsht":"百世汇通快递",
      "logisticsOrderNo":"请填写物流单号",
      "storeId":"商户ID",
      "customerId":"快递客户号",
      "customerPassword":"快递客户密码",
      "explain":"说明",
      "applicationView":"点击申请查看",
      "result":"物流信息测试结果",
      "submit":"确认提交",
    },
    "shippingDetails":{
      "backToList":"返回列表",
      "management":"物流管理",
      "add":"添加物流",
      "IndexAndManagement":"网站系统物流索引和管理",
      "name":"物流公司名字",
      "state1":"物流公司大写首字母：如圆通快递：YTKD",
      "code":"快递公司编码",
      "state2":"物流公司编码由拼音组成,不得含有英文以外的字符。",
      "describe":"简短描述",
      "imageIcon":"物流图片图标",
      "toUpload":"请上传图片格式文件",
      "formworkWidth":"模板宽度",
      "widthUnit":"运单宽度，单位为毫米(mm)",
      "formworkHeight":"模板高度",
      "heightUnit":"运单高度，单位为毫米(mm)",
      "offset":"偏移量",
      "leftOffset":"运单模板左偏移量，单位为毫米(mm)",
      "topOffset":"运单模板上偏移量，单位为毫米(mm)",
      "formworkImage":"模板图片",
      "upload":"点击上传",
      "state3":"请上传扫描好的运单图片，图片尺寸必须与快递单实际尺寸相符",
      "selectPrint":"选择打印项",
      "selectSign":"请选择标签",
      "printCorrection":"打印项偏移校正",
      "submit":"确认提交",
    },
    "shippingList":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"快递公司管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"快递公司列表",
      "total":"共",
      "records":"条记录",
      "add":"新增快递公司",
      "batchDelete":"批量删除",
      "refresh":"刷新数据",
      "name":"快递公司名称",
      "logisticsNum":"物流编号",
      "search":"搜索",
      "companyId":"物流公司ID",
      "companyName":"物流公司名称",
      "companyCode":"物流公司编码",
      "logisticsDec":"物流描述",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
  },
  "shopSet":{
    "subdomain":{
      "subdomainEdit":{
        "backToList":"返回列表",
        "management":"促销管理",
        "edit":"编辑抢购",
        "webDetailPage":"网站系统抢购活动详情页",
        "title":"抢购标题",
        "fillInTitle":"请填写抢购标题",
        "shoppingOptions":"选择抢购商品",
        "selectGoods":"选择商品",
        "rushPrice":"限时抢购价格",
        "state1":"商品抢购价格,单位：",
        "rushNum":"参加抢购数量",
        "state2":"此抢购活动最多允许抢购的商品数量",
        "limitNum":"限购数量",
        "state3":"限时抢购每个人能购买的数量",
        "startTime":"开始时间",
        "startRushTime":"抢购开始时间",
        "state4":"重要:抢购时间是从凌晨0:00:00秒开始, 每隔2小时一个节点, 所以活动时间必须是两小时之内才有效, (例如今天是2017年1月1日下午2点场,",
        "state5":"那活动开始和结束时间必须是:2017-01-01 14:00:01 ~ 2017-01-01 15:59:59 时间范围内), 否则前端无法显示活动信息",
        "rushIntroduce":"抢购介绍",
        "fillInIntroduce":"请输入活动介绍",
        "describe":"抢购描述介绍",
        "publishNotice":"发布活动时，进行通知",
        "mailNotice":"站内信通知",
        "yes":"是",
        "no":"否",
        "submit":"确认提交",
      },
      "subdomainList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"1. 商家二级域名相关信息管理。",
        "state2":"2. 申请二级域名可提高网店的辨识度。",
        "hideTips":"收起提示",
        "list":"店铺域名列表",
        "total":"共",
        "records":"条记录",
        "refresh":"刷新数据",
        "secondaryDomainName":"二级域名",
        "editTimes":"编辑次数",
        "storeName":"店铺名称",
        "shopkeeperName":"店主名称",
        "status":"状态",
        "operation":"操作",
        "edit":"编辑",
        "delete":"删除",
      },
      "subdomainSet":{
        "isEnable":"是否启用二级域名",
        "isModify":"是否可修改",
        "state1":"不可修改时店主填写提交后将不可改动",
        "modifyTimes":"修改次数",
        "state2":"可修改时达到设定的次数后将不能再改动",
        "keepDomainName":"保留域名",
        "state3":"保留的二级域名，多个保留域名之间请用','隔开",
        "lengthLimit":"长度限制",
        "state4":"如'3-12'，代表注册的域名长度限制在3到12个字符之间",
        "submit":"确认提交",
      },
    },
    "basicSet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "hideTips":"收起提示",
      "signForPoints":"会员注册赠送积分",
      "uploadLimit":"附件上传大小限制",
      "selectUploadLimit":"请选择附件上传大小限制",
      "defaultStore":"默认库存",
      "addDefaultStore":"添加商品的默认库存",
      "taxRate":"发票税率",
      "state2":"当买家需要发票的时候就要增加[商品金额]*[税率]的费用",
      "hotwords":"首页热门搜索词",
      "state3":"首页或手机搜索页，搜索栏下侧显示",
      "browserBaiduAk":"浏览器端百度ak",
      "forBrowser":"用于浏览器",
      "applyBaiduAk":"去申请百度ak",
      "state4":"认证成功调用次数多些。",
      "serverBaiduAk":"服务端百度ak",
      "forServer":"用于服务器",
      "submit":"确认提交",
	  "im":"客服系统",
    },
    "distribut":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"如果分销开关关闭，那么不会平台启用分销模块，每个商品独立设置分销金",
      "state2":"如果规则由平台设置，那么分销规则统一按平台设置的规则返利，否则按各商家自行设置的比例规则",
      "state3":"所有分销商获佣比例之和不超过100%，比例为0则也视为不参与分佣，平台分销规则可针对分销商设置等级",
      "hideTips":"收起提示",
      "switch":"分销开关",
      "ruleSetting":"分销规则设置",
      "setByPlatform":"由平台设置",
      "setByStore":"由商家设置",
      "validity":"推荐开店有效期",
      "selectValidity":"请选择推荐开店有效期",
      "day":"天",
      "longTerm":"长期",
      "example":"例：多少天内开店成功，才能算是这个人推荐的的店",
      "rebateSeries":"返佣级数",
      "selectRebateSeries":"请选择返佣级数",
      "return1":"返一级",
      "return2":"返二级",
      "return3":"返三级",
      "commissionPercent1":"一级分销商获佣百分比",
      "state4":"购买者直接推荐人返佣占商品分销金比例",
      "commissionPercent2":"二级分销商获佣百分比",
      "state5":"购买者推荐人的上级返佣占商品分销金比例",
      "commissionPercent3":"三级分销商获佣百分比",
      "state6":"购买者推荐人的上上级占商品分销金比例",
      "QRCodeSetting":"二维码相关设定",
      "QRCodeBackground":"页面二维码背景",
      "myQRCodeBackground":"'我的二维码'大背景",
      "menuKeyWord":"微信菜单关键字",
      "state7":"用户点击微信菜单中的某项，响应'我的二维码'图片",
      "inputKeyWord":"微信输入关键字",
      "state8":"用户在微信中输入的文本，响应'我的二维码'图片",
      "submit":"确认提交",
    },
    "notice":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "hideTips":"收起提示",
      "messageSetting":"消息设置",
      "sms":"短信",
      "select":"请选择",
      "min":"分钟",
      "email":"邮箱",
      "smsSetting":"短信配置",
      "submit":"确认提交",
      "selectSmsPlatform":"选择短信平台",
      "alicloud":"阿里云",
      "tianruicloud":"天瑞云",
      "smsPlatform":"短信平台",
      "smsPlatformSetting":"短信平台配置",
      "name":"公司名/品牌名/产品名",
      "emailSetting":"邮箱配置",
      "sendSmtp":"邮件发送服务器(SMTP)",
      "state2":"发送邮箱的smtp地址。如: smtp.gmail.com或smtp.qq.com",
      "smtpPort":"服务器(SMTP)端口",
      "state3":"smtp的端口。默认为25。具体请参看各STMP服务商的设置说明 （如果使用Gmail，请将端口设为465）",
      "emailAccount":"邮箱账号",
      "useSendAccount":"使用发送邮件的邮箱账号",
      "password":"邮箱密码/授权码",
      "state4":"使用发送邮件的邮箱密码,或者授权码。具体请参看各STMP服务商的设置说明",
      "receiveAddress":"测试接收的邮件地址",
      "test":"测试",
      "firstSave":"首次请先保存配置再测试",
    },
    "oss":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"推送服务相关设置, 请从阿里云注册账户, 并在其控制台获取相关信息。",
      "hideTips":"收起提示",
      "ossSwitvh":"上传oss开关",
      "isStored":"是否对商品图片启用对象存储功能",
      "stored":"对象存储的",
      "state2":"对象存储的Endpoint，比如 oss-cn-shenzhen.aliyuncs.com",
      "submit":"确认提交",
    },
    "poster":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"自定义海报设置请注意上传的图片尺寸，避免影响海报的展示效果。",
      "hideTips":"收起提示",
      "name":"海报名称",
      "image":"背景图片",
      "state2":"自定义海报建议尺寸640*1008像素，可根据该数据对二维码位置进行调整",
      "pixel":"当前上传的图片宽高像素为",
      "position":"二维码位置",
      "axis":"轴",
      "coordinate":"坐标",
      "state3":"输入像素数值可移动模拟二维码在海报背景图上的位置",
      "size":"二维码大小",
      "state4":"数值越大，二维码图片越大",
      "submit":"确认提交",
      "preview":"预览",
    },
    "push":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"推送服务相关设置, 请从极光官网注册账户并获取相关信息。",
      "hideTips":"收起提示",
      "uniqueIdentification":"App应用推送服务的唯一标识",
      "state2":"用于服务器端 API 调用时与 AppKey 配合使用达到鉴权的目的，请保管好 Master Secret 防止外泄。",
      "submit":"确认提交",
    },
    "shopInfo":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "state2":"该页面的所有默认图标logo在'网站根目录/public/static/images/logo'目录下, 如果需要重新设计图标可参考默认图",
      "hideTips":"收起提示",
      "domainName":"接口域名",
      "state3":"示例:https://j.tpsns.com（注:必须是https://开头的域名，用于接受微信小程序等支付通知）",
      "webRecordNum":"网站备案号",
      "state4":"网站备案号，将显示在前台底部欢迎信息等位置",
      "webName":"网站名称",
      "state5":"网站名称，将显示在前台顶部欢迎信息等位置",
      "companyName":"公司名称",
      "state6":"公司名称:将显示在管理员后台欢迎页面",
      "webLogo":"网站Logo",
      "state7":"默认网站首页LOGO,通用头部显示，最佳显示尺寸为230*58像素",
      "userCenterLogo":"网站用户中心Logo",
      "state8":"默认用户中心网站LOGO,用户中心通用头部显示，最佳显示尺寸为230*58像素",
      "titleIcon":"网站标题图标",
      "state9":"网站标题图标LOGO,最佳显示尺寸为48*48像素",
      "webTitle":"网站标题",
      "state10":"网站标题，将显示在前台顶部欢迎信息等位置",
      "webDescribe":"网站描述",
      "state19":"网站描述，将显示在前台顶部欢迎信息等位置",
      "webKeyword":"网站关键字",
      "state11":"网站关键字，便于SEO",
      "storeLoginLogo":"商家登录页Logo",
      "state12":"商家登录页LOGO,最佳显示尺寸为163*26像素",
      "adminLoginLogo":"平台管理员登录页Logo",
      "state13":"平台管理员登录页LOGO,最佳显示尺寸为220*82像素",
      "adminTopLogo":"平台后台顶部Logo",
      "state14":"平台后台顶部LOGO,最佳显示尺寸为148*28像素",
      "mobileLogo":"手机端首页Logo",
      "state15":"手机端首页搜索框LOGO,最佳显示尺寸为48*48像素",
      "mobileLoginLogo":"手机端登录页Logo",
      "state16":"手机端登录页LOGO,最佳显示尺寸为312*92像素",
      "officialAccount":"公众号二维码",
      "state17":"pc端公共底部官方微信二维码,最佳显示尺寸为90*90像素",
      "contacts":"联系人",
      "telephone":"联系电话",
      "detailAddress":"具体地址",
      "customerService":"平台客服",
      "state18":"商家中心右下侧显示，方便商家遇到问题时咨询",
      "PCFormat":"PC首页版式",
      "format":"版式",
	  "showInvoice": "是否显示发票信息",
	  "yes": "是",
	  "no": "否",
      "submit":"确认提交",
    },
    "shopping":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"网站全局基本设置，商城及其他模块相关内容在其各自栏目设置项内进行操作。",
      "hideTips":"收起提示",
      "conversionProportion":"积分换算比例",
      "integral":"积分",
      "bonusProportion":"赠送积分比例",
      "state2":"发布商品, 赠送积分限制: 100表示不限制, 50时购买该商品赠送的积分所抵扣金额不能超过商品价格的50%",
      "minLimit":"最低使用限制",
      "state3":"购买商品, 使用积分时: 0表示不限制, 大于0时, 用户积分小于该值将不能使用积分",
      "useProportion":"使用比例",
      "state4":"购买商品, 使用积分时: 100时不限制, 为0时不能使用积分, 50时积分抵扣金额不能超过该笔订单应付金额的50%",
      "automaticReceipt":"发货后多少天自动收货",
      "day":"天",
      "select":"请选择天数",
      "orderSettlement":"收货多少天后订单结算",
      "advanceDeposit":"收货多少天后,订单结算金额转入卖家平台预存款",
      "afterSales":"申请售后时间段",
      "inventoryReduction":"减库存的时机",
      "orderSuccess":"下单成功时",
      "paySuccess":"支付成功时",
      "state5":"拼团下单后未支付多久时间后才能让后续的人下单(秒)",
      "state6":"订单下单后未支付多久后取消订单(秒)",
      "submit":"确认提交",
    },
    "_subdomain":{
      "secondaryDomainName":"二级域名",
      "storeSetting":"商城店铺使用二级域名管理设置",
      "setting":"设置",
      "list":"域名列表",
    },
    "tip":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"最终小费的额度是以订单金额*小费比例形成档位。可供用户支付选择",
      "hideTips":"收起提示",
      "tipConfiguration":"小费配置",
      "submit":"确认提交",
      "tipSwitch":"小费开关",
      "proportion1":"一档小费比例(%)",
      "proportion2":"二档小费比例(%)",
      "proportion3":"三档小费比例(%)",
      "state2":"小费归属比例(%):0表示归平台所有,100表示归商家所有",
      "taxConfiguration":"税费配置",
      "taxSwitch":"税费开关",
    },
    "water":{ 
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "globalSetting":"系统平台全局设置,包括基础设置、购物、短信、邮件、水印和分销等相关模块。",
      "hideTips":"收起提示",
      "addWatermark":"商品图片添加水印",
      "watermarkType":"水印类型",
      "text":"文字",
      "image":"图片",
      "watermarkText":"水印文字",
      "fontSize1":"文字字号",
      "fontSize2":"字体大小",
      "color":"文字颜色",
      "watermarkImage":"水印图片",
      "state1":"用于商品缩略图上水印",
      "addCondition":"水印添加条件",
      "width":"图片宽度 单位像素(px)",
      "height":"图片高度 单位像素(px)",
      "widthAndHeight":"水印的宽度和高度",
      "quality":"水印质量",
      "state2":"水印质量请设置为0-100之间的数字,决定 jpg 格式图片的质量",
      "transparency":"水印透明度",
      "state3":"0代表完全透明，100代表不透明",
      "position":"水印位置",
      "topLeft":"顶部居左",
      "topMiddle":"顶部居中",
      "topRight":"顶部居右",
      "middleLeft":"中部居左",
      "middle":"中部居中",
      "middleRight":"中部居右",
      "bottomLeft":"底部居左",
      "bottomMiddle":"底部居中",
      "bottomRight":"底部居右",
      "submit":"确认提交",
    },
    "withdrawalSet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"最高手续费不应高于最高提现金额",
      "hideTips":"收起提示",
      "withdrawalConfiguration":"提现配置",
      "handlingChargesProportion":"手续费比例",
      "state2":"注：默认是百分比，如填1就是 代表每笔提现，收取提现金额1%的手续费",
      "minHandlingCharge":"最低手续费",
      "state3":"注：单笔手续费计算出来小于该值时，则取该值",
      "maxHandlingCharge":"最高手续费",
      "state4":"注：单笔手续费计算出来大于该值时，则取该值",
      "minWithdrawal":"最低提现额",
      "state5":"注：单笔最低提现额",
      "maxWithdrawal":"最高提现额",
      "state6":"注：单笔最高提现额",
      "withdrawalAmount":"每日累计提现额",
      "state7":"注：单人每日累计提现额达到该值时，本日将不支持继续提现",
      "withdrawalTimes":"每日累计提现次数",
      "state8":"注：单人每日累计提现次数达到该值时，本日将不支持继续提现",
      "submit":"确认提交",
    },
  },
  "wechat":{
    "follower":{
      "followerList":{
        "fansList":"粉丝列表",
        "fansListManagement":"粉丝列表管理",
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"关注本公众号的粉丝在此列表显示",
        "state2":"若要同步粉丝信息，请清除缓存，自动同步",
        "state3":"服务号才可以按选定的多个用户进行群发，订阅号没这个权限",
        "state4":"服务号和订阅号都可以使用发送所有粉丝功能，群发的次数一个月最多4次，一天最多1次",
        "hideTips":"收起提示",
        "total":"共",
        "records":"条记录",  
        "refresh":"刷新数据",
        "portrait":"头像",          
        "nickname":"昵称",
        "gender":"性别",          
        "male":"男",
        "female":"女",
        "unknown":"未知",          
        "address":"所在地",
        "language":"用户语言",          
        "focusTime":"关注时间",
        "operation":"操作",          
        "member":"会员",
        "wechat":"微信",      
        "detailInfo":"微信粉丝详细信息",
        "portraitAddress":"头像地址",
        "remark":"备注",
        "signList":"标签列表",
        "fansResource":"粉丝来源",
        "close":"关闭",
      },
    },
    "materialManage":{
      "imageList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state":"发送图片消息请移步粉丝列表",
        "hideTips":"收起提示",
        "imageList":"图片列表",
        "total":"共",
        "records":"条记录",
        "refresh":"刷新数据",
        "upload":"上传",
        "noMaterial":"还没有相关素材",
        "deleteMaterial":"删除图片素材",
      },
      "news":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"文章中的图片请使用插件上传，不要使用复制粘贴的方式。",
        "hideTips":"收起提示",
        "title":"标题",
        "state2":"最多64个字",
        "author":"作者",
        "state3":"非必填，最多8个字",
        "text":"正文",
        "state4":"不多于2万字",
        "abstract":"摘要",
        "state5":"选填，如果不填则抓取正文前64字。不多于120字。",
        "link":"原文链接",
        "state6":"链接前请带上 http:// 或者 https:// ，不填则不显示",
        "cover":"封面",
        "state7":"请上传图片格式文件",
        "displayCovers":"显示封面",
        "state8":"是否在正文开始处显示封面",
        "openComments":"是否打开评论",
        "state9":"当公众号具备留言功能的权限时，此设置才生效",
        "fanReviews":"是否粉丝才可评论",
        "state10":"评论范围（所有人，或仅公众号粉丝可以留言）",
        "submit":"确认提交",
      },
      "newsList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"发送图文消息请移步粉丝列表",
        "state2":"每个图文素材最多包含8篇文章！",
        "hideTips":"收起提示",
        "list":"图文列表",
        "total":"共",
        "records":"条记录",
        "addImageText":"新增图文",
        "refresh":"刷新数据",
        "noMaterial":"还没有相关素材",
        "addArticle":"在素材上添加文章",
        "deleteMaterial":"删除图文素材",
      },
      "videoList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"发送视频消息请移步粉丝列表",
        "hideTips":"收起提示",
        "list":"视频列表",
        "total":"共",
        "records":"条记录",
        "add":"添 加",
        "refresh":"刷新数据",
        "noMaterial":"还没有相关素材",
        "deleteMaterial":"删除视频素材",
        "uploadMaterial":"视频素材上传",
        "addVideo":"添加视频",
        "selectFile":"选取文件",
        "state2":"最大10MB，支持MP4格式，点击文件名预览播放",
        "title":"视频标题",
        "state3":"标题将展示在相关播放页面，建议填写清晰、准确、生动的标题",
        "introduce":"视频介绍",
        "synopsis":"视频简介",
        "state4":"介绍语将展示在相关播放页面，建议填写简洁明确、有信息量的内容",
        "preview":"预览",
        "upload":"上 传",
      },
      "voiceList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"发送音频消息请移步粉丝列表",
        "hideTips":"收起提示",
        "list":"音频列表",
        "total":"共",
        "records":"条记录",
        "add":"添 加",
        "refresh":"刷新数据",
        "noMaterial":"还没有相关素材",
        "deleteMaterial":"删除音频素材",
        "uploadMaterial":"音频素材上传",
        "addVoice":"添加音频",
        "selectFile":"选取文件",
        "state2":"最大2MB，支持mp3/wma/wav/amr格式，点击文件名预览播放",
        "title":"音频标题",
        "state3":"标题将展示在相关播放页面，建议填写清晰、准确、生动的标题",
        "preview":"预览",
        "upload":"上 传",
      },
    },
    "reply":{
      "default":{
        "replyContent":"回复内容",
        "toInput":"请输入内容",
        "submit":"提 交",
      },
      "keyword":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"在此添加关键字, 用户发送关键词与之匹配时自动回复",
        "hideTips":"收起提示",
        "list":"关键词回复列表",
        "total":"共",
        "records":"条记录",
        "add":"添加关键词回复",
        "refresh":"刷新数据",
        "keyword":"关键词",
        "replyType":"回复类型",
        "text":"文本",
        "imageText":"图文",
        "image":"图片",
        "video":"视频",
        "voice":"音频",
        "content":"内容",
        "operation":"操作",
        "edit":"编辑",
        "delete":"删除",
        "state2":"多个关键词，请以英文逗号隔开( , )",
        "state3":"注意：关键字图文消息回复只能回复一条，也就是选择图文素材的第一条图文",
        "replyContent":"回复内容",
        "autoReplyContent":"自动回复内容",
        "save":"保 存",
        "selectImageText":"选择图文素材",
        "selectImage":"选择图片素材",
        "selectVideo":"选择视频素材",
        "selectVoice":"选择音频素材",
      },
    },
    "_materialManage":{
      "management":"素材管理",
      "displayAndManagement":"微信素材展示与管理",
      "textMessage":"图文消息",
      "image":"图片",
      "video":"视频",
      "voice":"音频",
    },
    "miniapp":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "wxAppletsConfiguration":"微信小程序配置",
      "hideTips":"收起提示",
      "appletsConfiguration":"小程序配置",
      "applets":"小程序",
      "submit":"确认提交",
    },
    "paySet":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "configuration":"微信支付与微信小程序相关配置",
      "hideTips":"收起提示",
      "merchantConfiguration":"商户配置",
      "submit":"确认提交",
      "num":"商户号",
      "paymentKey":"商户支付密钥",
      "certificate":"商户证书",
      "upload":"点击上传",
      "openWechatPayment":"是否启用微信支付",
      "yes":"是",
      "no":"否",
      "appletsConfiguration":"小程序配置",
      "applets":"小程序",
    },
    "_reply":{
      "replyManagement":"自动回复管理",
      "officialAccountManagement":"微信公众号管理",
      "keyword":"关键词自动回复",
      "message":"消息自动回复",
      "follow":"关注时自动回复",
    },
    "wxMenu":{
      "menuManagement":"微信菜单管理",
      "menu":"微信菜单",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"自定义菜单最多包括3个一级菜单，每个一级菜单最多包含5个二级菜单",
      "state2":"一级菜单最多4个汉字，二级菜单最多7个汉字，多出来的部分将会以'...'代替",
      "state3":"创建自定义菜单后，菜单的刷新策略是，在用户进入公众号会话页或公众号profile页时，如果发现上一次拉取菜单的请求在5分钟以前，就会拉取一下菜单，如果菜单有更新，就会刷新客户端的菜单。测试时可以尝试取消关注公众账号后再次关注，则可以看到创建后的效果",
      "state4":"如果需要添加小程序菜单，请把路径指定为首页： 复制这个pages/index/index/index",
      "state5":"更改菜单后需要点击保存生效，公众号菜单才能更新",
      "hideTips":"收起提示",
      "list":"菜单列表",
      "addPrimaryMenu":"添加一级菜单",
      "save":"保存生效",
      "refresh":"刷新数据",
      "name":"菜单名称",
      "type":"菜单类型",
      "url":"菜单URL",
      "operation":"操作",
      "addSubmenu":"添加子菜单",
      "add":"添加",
      "delete":"删除",
    },
    "wxUser":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"配置微信公众号, token、Appid、AppSecret要与微信公众开放平台信息一致",
      "hideTips":"收起提示",
      "address":"服务器地址",
      "configuration":"配置",
      "domainName":"接口域名",
      "display":"后显示",
      "state2":"1. Token相当于腾讯微信公众平台服务器与你服务器交互的密钥",
      "state3":"2. 在正式部署的时候，请务必设置得较为复杂，否则可能被黑客利用，伪装你的服务器向你的用户发送消息",
      "officialAccountName":"公众号名称",
      "officialAccountId":"公众号原始ID",
      "wechatNum":"微信号",
      "portrait":"头像",
      "qrcode":"二维码",
      "state4":"1. 此处上传公众号二维码,建议上传90*高90像素GIF\JPG\PNG格式图片",
      "state5":"2. 此处二维码将会在PC首页侧边栏（右下角）展示",
      "wechatType":"微信号类型",
      "accessStatus":"微信接入状态",
      "toAccess":"等待接入",
      "accessed":"已接入",
      "state6":"重要：任何时候都要勾选'已接入'，否则微信功能无法正常使用。 除非，在接入微信（开发 ->基本配置 -> 修改配置）提交url和token",
      "state7":"提示'url超时'或'token'错误时，此处临时勾选为'等待接入'，提交成功后改为'已接入'",
      "submit":"确认提交",
    },
  },
  "adminList":{
    "management":"管理员管理",
    "webAdministratorList":"网站系统管理员列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"网站系统管理员, 由总平台设置管理.",
    "hideTips":"收起提示",
    "list":"管理员列表",
    "addAdministrator":"新增管理员",
    "refresh":"刷新数据",
    "username":"用户名",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "editAdministrator":"编辑管理员",
    "address":"Email地址",
    "password":"登陆密码",
    "confirmPassword":"确认登录密码",
    "add":"添加",
	"role":"角色",
  },
  "menus":{
    "management":"菜单管理",
    "managementList":"网站系统菜单列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"网站系统菜单列表, 由超级管理员设置管理.",
    "hideTips":"收起提示",
    "list":"菜单列表",
    "addMenu":"新增菜单",
    "menuName":"菜单名称",
    "path":"网址",
    "parentMenu":"父菜单",
    "refresh":"刷新数据",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addEditMenu":"添加/编辑菜单",
    "add":"保存",
	"sort":"排序",
  },
  "roles":{
    "management":"角色管理",
    "managementList":"网站系统角色列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"网站系统角色列表, 由超级管理员设置管理.",
    "hideTips":"收起提示",
    "list":"角色列表",
    "addRole":"新增角色",
    "roleName":"角色名称",
    "content":"角色描述",
    "refresh":"刷新数据",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addEditRole":"添加/编辑角色",
    "add":"保存",
  	"sort":"排序",
  	"menu":"访问菜单",
	"createTime":"创建时间",
  },
  "email":{
      "notification":"邮件通知",
      "state1":"可对用户发送商品信息和自定义编辑",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state2":"可通过选择会员发送给选中的用户",
      "state3":"选择商品可以发送商品的图片和商品名",
      "hideTips":"收起提示",
      "title":"邮件标题:",
      "member":"会员:",
      "goods":"商品:",
      "select":"选择商品",
      "goodsName":"商品名称",
      "spec":"规格",
      "stock":"库存",
      "mallPrice":"商城价格",
      "operation":"操作",
      "delete":"删除",
      "describe":"邮件描述:",
      "submitToSend":"提交发送",
      "searchUser":"选择用户",
      "level":"会员等级",
      "nickname":"会员昵称",
      "email":"邮箱",
      "search":"搜索",
      "confirm":"确认选择",
      "memberId":"会员id",
      "goodsId":"商品id",
      "goodsCode":"商品货号",
      "price":"价格",
      "marketPrice":"市场价",
      "store":"库存量",
  },
  "emaillog":{
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "checkLog":"可查看平台日志。",
    "hideTips":"收起提示",
    "startDate":"开始日期",
    "endDate":"结束日期",
    "search":"搜索",
    "scene":"应用场景",
    "email":"普通邮件",
    "notice":"通知",
    "verificationCode":"验证码",
    "sendTime":"发送时间",
    "title":"标题",
    "sender":"发送人",
    "operation":"操作",
    "checkDetail":"查看详情",
  },
  "emailreceiver":{
    "notification":"邮件日志",
    "state":"日志详情",
    "title":"邮件标题：",
    "sendTime":"发送时间：",
    "sender":"发送人：",
    "content":"邮件内容：",
    "receiver":"接收人",
    "mailBox":"接收邮箱",
    "status":"接收状态",
    "success":"成功",
  },
  "emails":{
    "emailLog":"邮件日志",
    "sendSettings":"商城对商家及用户消息类发送设定",
    "systemLog":"平台日志",
    "storeLog":"商家日志",
  },
  "index":{
    "setting":"设置",
    "mallSetting":"商城设置",
    "regionalDistribution":"地区&配送",
    "smsTemplate":"短信模板",
    "notification":"消息通知",
    "seoSetting":"SEO设置",
    "expressCompany":"快递公司",
    "jurisdiction":"权限",
    "list":"管理员列表",
    "menu_manage":"菜单管理",
	"role_manage":"角色管理",
    "email":"邮件",
    "sendEmail":"发送邮件",
    "emailLog":"邮件日志",
    "wechat":"微信",  
    "officialAccountSetting":"公众号设置",
    "appletsPay":"小程序/支付",
    "alipay":"支付宝",
    "alipaySetting":"支付宝配置",
    "paySetting":"支付配置",
  },
  "_msgTpl":{
    "notification":"消息通知",
    "sendSettings":"商城对商家及用户消息类发送设定",
    "merchantMessTemplate":"商家消息模板",
    "userMessTemplate":"用户消息模板",
  },
  "plugin":{
    "management":"插件管理",
    "setting":"配置",
    "webManagement":"网站系统插件管理",
    "sortName":"分类名称",
  },
  "plugins":{
    "management":"插件管理",
    "indexAndManagement":"网站系统插件索引与管理",
    "payPlugin":"支付插件",
    "loginPlugin":"登录插件",
  },
  "selleremaillog":{
    "checkLog":"查看商家日志。",
    "type":"类型",
    "storeName":"商家名称：",
  },
  "seoSet":{
    "seoSettings":"SEO设置",
    "setOptions":"商城各级页面搜索引擎优化设置选项",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"插入的变量必需包括花括号“{}”，当应用范围不支持该变量时，该变量将不会在前台显示(变量后边的分隔符也不会显示)，留空为系统默认设置，SEO自定义支持手写。以下是可用SEO变量：",
    "state2":"站点名称 {sitename}，（应用范围：全站）",
    "state3":"名称 {name}，（应用范围：抢购名称、商品名称、品牌名称、文章标题、分类名称）",
    "state4":"文章分类名称 {article_class}，（应用范围：文章分类页）",
    "state5":"店铺名称 {shopname}，（应用范围：店铺页）",
    "state6":"关键词 {key}，（应用范围：商品关键词、文章关键词、店铺关键词）",
    "state7":"简单描述 {description}，（应用范围：商品描述、文章摘要、店铺关键词）",
    "hideTips":"收起提示",
    "homePage":"首页",
    "save":"保 存",
    "rushPurchase":"抢购",
    "rushPurchaseContent":"抢购内容",
    "brand":"品牌",
    "aBrandList":"某一品牌商品列表",
    "makeUpGroup":"拼团",
    "groupContent":"拼团内容",
    "integralCenter":"积分中心",
    "goodsContent":"积分中心商品内容",
    "article":"文章",
    "sortList":"文章分类列表",
    "articleContent":"文章内容",
    "store":"店铺",
    "goods":"商品",
    "goodsDetail":"商品详情",
    "goodsList":"商品列表",
  },
  "shippings":{
    "management":"快递公司管理",
    "listAndManagement":"快递公司列表与管理",
    "logisticsInfo":"物流信息配置",
  },
  "_shopSet":{
    "mall":"商城设置",
    "webBasic":"网站全局内容基本选项设置",
    "mallInfo":"商城信息",
    "basic":"基本设置",
    "withdrawal":"提现设置",
    "process":"购物流程",
    "smsOrEmail":"短信/邮件设置",
    "watermark":"水印设置",
    "distribution":"分销设置",
    "object":"对象存储",
    "poster":"海报设置",
    "tipOrTax":"小费/税费",
  },
  "smsTpl":{
    "template":"短信配置模板",
    "platformTemplate":"短信平台短信配置模板",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"短信配置模板, 由平台设置管理.",
    "state2":"添加/编辑模板时注意'短信签名'、'短信内容'的填写.",
    "state3":"当添加了所有短信场景时，无法继续新增模板.",
    "hideTips":"收起提示",
    "list":"模板列表",
    "total":"共",
    "records":"条记录",
    "add":"新增模板",
    "refresh":"刷新数据",
    "scene":"应用场景",
    "smsSignature":"短信签名",
    "smsTemplate":"短信模板",
    "sendContent":"发送短信的内容",
    "modifyTime":"修改时间",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "smsTplDetail":{
    "backToList":"返回列表",
    "template":"短信配置模板",
    "edit":"编辑模板",
    "webManagement":"网站系统短信配置模板管理",
    "smsSignature":"短信签名",
    "state1":"必须与短信平台短信签名一致",
    "smsTemplateId":"短信模板ID",
    "state2":"必须与短信平台短信模板ID一致",
    "sendScene":"发送场景",
    "selectScene":"请选择使用场景",
    "usageScene":"使用场景",
    "smsContent":"短信内容",
    "submit":"确认提交",
  },
  "suppliers":{      
    "management":"供应商管理",
    "indexAndManagement":"网站系统供应商索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"供应商管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "list":"供应商列表",
    "total":"共",
    "records":"条记录",
    "add":"添加供应商",
    "refresh":"刷新数据",
    "name":"供应商名称",
    "describe":"供应商描述",
    "contacts":"供应商联系人",
    "telephone":"供应商电话",
    "storeId":"所属店铺ID",
    "status":"状态",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
    "addOrEdit":"供应商新增/编辑",
    "determine":"确 定",
  },
  "suppliersDetail":{
    "backToList":"返回列表",
    "management":"权限管理",
    "supplierManagement":"供应商管理",
    "webSupplierManagement":"网站系统供应商管理",
    "supplierName":"供应商名称",
    "supplierDesc":"供应商描述",
    "supplierContact":"供应商联系人",
    "supplierPhone":"供应商电话",
    "submit":"确认提交",
  },
}
