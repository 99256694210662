export const en = {
  "dashboard":{
    "center":"管理中心",
    "taskNum":"调度中心运行的任务数量",
    "dispatchFrequency":"调度中心触发的调度次数",
    "machineNum":"调度中心在线的执行器机器数量",
  },
  "index":{
    "dispatch":"任务调度中心",
    "dashboard":"仪表盘",
    "taskManagement":"任务管理",
    "journal":"调度日志",
  },
  "jobInfo":{
    "taskManagement":"任务管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"通过任务管理，你可以进行查看、编辑等操作",
    "state2":"你可以根据条件搜索任务，然后选择相应的操作",
    "state3":"点击执行，手动触发一次任务执行",
    "hideTips":"收起提示",
    "dispatchList":"调度列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "addTask":"添加任务",
    "server":"服务名",
    "processor":"处理器",
    "search":"搜索",
    "taskID":"任务ID",
    "author":"作者",
    "param":"参数",
    "remark":"备注",
    "failFrequency":"失败次数",
    "lastTime":"最后一次执行时间",
    "nextTime":"下次执行时间",
    "updateTime":"更新时间",
    "executionStatus":"执行状态",
    "run":"运行",
    "start":"启动",
    "stop":"停止",
    "operation":"操作",
    "execute":"执 行",
    "journal":"日志",
    "edit":"编辑",
    "delete":"删除",
    "addOrEdit":"添加/编辑",
    "actuator":"执行器",
    "timeOut":"任务超时时间",
    "retry":"失败重试次数",
    "taskParam":"任务参数",
    "cancel":"取 消",
    "save":"保 存",
  },
  "jobLog":{
    "journal":"调度日志",
    "journalList":"日志列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "batchDelete":"批量删除",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "taskID":"任务ID",
    "processor":"处理器",
    "search":"搜索",
    "triggerType":"触发类型",
    "result":"调度结果",
    "remark":"调度备注",
    "frequency":"失败次数",
    "time":"执行时间",
    "operation":"操作",
    "delete":"删除",
  },
}

export const zh = {
  "dashboard":{
    "center":"管理中心",
    "taskNum":"调度中心运行的任务数量",
    "dispatchFrequency":"调度中心触发的调度次数",
    "machineNum":"调度中心在线的执行器机器数量",
  },
  "index":{
    "dispatch":"任务调度中心",
    "dashboard":"仪表盘",
    "taskManagement":"任务管理",
    "journal":"调度日志",
  },
  "jobInfo":{
    "taskManagement":"任务管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"通过任务管理，你可以进行查看、编辑等操作",
    "state2":"你可以根据条件搜索任务，然后选择相应的操作",
    "state3":"点击执行，手动触发一次任务执行",
    "hideTips":"收起提示",
    "dispatchList":"调度列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "addTask":"添加任务",
    "server":"服务名",
    "processor":"处理器",
    "search":"搜索",
    "taskID":"任务ID",
    "author":"作者",
    "param":"参数",
    "remark":"备注",
    "failFrequency":"失败次数",
    "lastTime":"最后一次执行时间",
    "nextTime":"下次执行时间",
    "updateTime":"更新时间",
    "executionStatus":"执行状态",
    "run":"运行",
    "start":"启动",
    "stop":"停止",
    "operation":"操作",
    "execute":"执 行",
    "journal":"日志",
    "edit":"编辑",
    "delete":"删除",
    "addOrEdit":"添加/编辑",
    "actuator":"执行器",
    "timeOut":"任务超时时间",
    "retry":"失败重试次数",
    "taskParam":"任务参数",
    "cancel":"取 消",
    "save":"保 存",
  },
  "jobLog":{
    "journal":"调度日志",
    "journalList":"日志列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "batchDelete":"批量删除",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "taskID":"任务ID",
    "processor":"处理器",
    "search":"搜索",
    "triggerType":"触发类型",
    "result":"调度结果",
    "remark":"调度备注",
    "frequency":"失败次数",
    "time":"执行时间",
    "operation":"操作",
    "delete":"删除",
  },
}
