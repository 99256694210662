import {getMenuList} from "@/utils/api";
import {
	mapState,
	mapMutations
} from 'vuex';
export default{
	data() {
		return {

		}
	},
	computed: {
		...mapState(['menuList']),
	},
	methods: {
		...mapMutations(['setMenuList']),
		/*权限菜单*/
		getMenuList() {
			getMenuList().then(res=>{
				if(res.length>0){
					let that = this;
					that.forChildren(res)

				}else {
					this.$router.push({
						path: "/setup/menus"
					});
				}

			})
		},
		/*处理路由 规格*/
		mergeRoutes (subs) {
			if(subs) {
				let route = new Array();
				let menu = subs
				let children = new Array();
				menu.map((item,index)=>{
					let temp = {
						path: item.path,
						name: item.name,
						//component: (resolve) => require([`@/view${item.path}.vue`], resolve),
						component: resolve => require([`@/view${item.path}.vue`], resolve),
						//iscom:`@/view${item.path}.vue`,
						meta: {
							title: item.name,
						},

						is_navigation:item.is_navigation,

						children:[],
						icon:item.icon,
					}
					if(item.children_menus){
						temp.children = this.setChildren(item)
						temp.redirect=this.getPath(item.children_menus)
					}
					route.push(temp)
				})
				return route;
			}
		},
		setChildren(data) {
			let route = new Array();
			let children = new Array();
			let menu = data.children_menus;
			menu.map((item,index)=>{
				let temp = {
					path: item.path,
					name: item.name,
					component: resolve => require([`@/view${item.path}.vue`], resolve),
					iscom:`@/view${item.path}.vue`,
					meta: {
						title: item.name,
					},
					children:[],
					is_navigation:item.is_navigation,
					icon:item.icon,
				}
				if(item.children_menus){
					temp.children=this.mergeRoutes(item.children_menus)
				}
				route.push(temp)
			})
			return route;
		},
		/*筛选是否还有子集*/
		forChildren(arr){
			let route =  new Array();
			arr.map((item,index)=>{
				let path = item.path.split("/")
				let temp ={
					path: item.path,
					name: item.name,
					component: (resolve) => require([`@/view/${path[1]}/index.vue`], resolve),
					//iscom:`@/view/${path[1]}/index.vue`,
					redirect: "",
					meta: {
						title:item.name,
					},
					children:[],
					is_navigation:item.is_navigation,
					icon:item.icon,
				}
				if(item.children_menus){
					temp.children=this.mergeRoutes(item.children_menus)
				}
				temp.redirect=this.getPath(item.children_menus)
				route.push(temp)
			})
			this.$router.addRoutes(route)
			if(route) {
				if(this.$route.path == '/login') {
					this.$router.push({
						path: route[0].path
					});
				}
			}else {
				this.$router.push({
					path: "/setup/menus"
				});
			}
			this.setMenuList(route);
		},
		getPath(arr) {
			let path;
			arr.find((item,index)=>{
				if(item.is_navigation == 1) {
					return path = item.path
				}
			})
			return path
		}

	},
}
