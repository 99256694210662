export const en = {
  "ad":{
    "adEdit":{
      "suggested":"建议尺寸",
      "backToList":"返回列表",
      "detail":"广告详情",
      "edit":"广告编辑",
      "name":"广告名称",
      "link":"广告链接",
      "position":"广告位置.",
      "startDate":"开始日期",
      "selectDate":"选择日期",
      "endDate":"结束日期",
      "image":"广告图片",
      "backgroundColor":"背景颜色",
      "sort":"默认排序",
      "submit":"确认提交",
      "openNew":"新窗口打开",
    },
    "adList":{
      "adList":"广告列表",
      "indexAndManagement":"广告索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"只需要点击半透明广告图片即可更换广告.",
      "state2":"预览广告所在页面中选择更换你的广告",
      "hideTips":"收起提示",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "allPosition":"广告位置 - 所有",
      "name":"广告名称",
      "search":"搜索",
      "id":"广告ID",
      "position":"广告位置",
      "image":"广告图片",
      "newWinodw":"新窗口",
      "yes":"是",
      "no":"否",
      "show":"显示",
      "sort":"排序",
      "operation":"操作",
      "edit":"编辑",
      "fullSitePoster":"全站海报",
    },
    "postertAdmin":{
      "backToList":"返回列表",
      "posterDetail":"海报详情",
      "posterEdit":"海报编辑",
      "posterName":"海报名称",
      "posterLink":"海报链接", 
      "startDate":"开始日期",
      "selectDate":"选择日期时间",
      "endDate":"结束日期",
      "posterImage":"海报图片",
      "submit":"确认提交",
    },
  },
  "app":{
    "userCenter":{
      "setting":"个人中心设置",
      "onlyApp":"只能用于移动端",
      "name":"默认名称",
      "menu":"菜单",
      "toInput":"请输入内容",
      "showOrHide":"隐藏/显示",
    },
  },
  "article":{
    "help":{
      "helpContent":{
        "backToList":"返回列表",
        "management":"帮助管理",
        "addAndEdit":"网站系统帮助添加与编辑",
        "title":"标题",
        "sort":"所属分类",
        "state1":"当选择发布'商城公告'时，还需要设置下面的'出现位置'项",
        "keywords":"seo关键字",
        "state2":"用于seo 搜索引擎友好",
        "link":"链接",
        "state3":"当填写'链接'后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头",
        "show":"显示",
        "helpContent":"帮助内容",
        "submit":"确认提交",
      },
      "helpContentList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state":"帮助内容排序显示规则为排序小的在前，新增内容的在前.",
        "hideTips":"收起提示",
        "helpList":"帮助列表",
        "total":"共",
        "records":"条记录",
        "newHelp":"新增帮助",
        "refresh":"刷新数据",
        "helpType":"帮助类型",
        "title":"标题",
        "search":"搜索",
        "show":"显示",
        "yes":"是",
        "no":"否",
        "publish":"发布时间",
        "operation":"操作",
        "check":"查看",
        "edit":"编辑",
        "delete":"删除",
      },
      "helpTypeList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"新增文章时，可选择文章分类。文章分类将在前台文章列表页显示",
        "state2":"ID前10的为系统分类，不可以删除",
        "hideTips":"收起提示",
        "sortList":"帮助分类列表",
        "addSort":"新增分类",
        "refresh":"刷新数据",
        "sortName":"分类名称",
        "sort":"排序",
        "isShow":"是否显示",
        "yes":"是",
        "no":"否",
        "operation":"操作",
        "newChild":"新增下级",
        "edit":"编辑",
        "delete":"删除",
        "parent":"上级分类",
        "topest":"顶级分类",
        "determine":"确 定",
      },
    },
    "article":{
      "backToList":"返回列表",
      "management":"文章管理",
      "addAndEdit":"网站系统文章添加与编辑",
      "title":"标题",
      "sort":"所属分类",
      "selSort":"选择分类",
      "state1":"当选择发布'商城公告'时，还需要设置下面的'出现位置'项",
      "keywords":"seo关键字",
      "state2":"用于seo 搜索引擎友好",
      "link":"链接",
      "state3":"当填写'链接'后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头",
      "publish":"发布时间",
      "select":"选择日期",
      "show":"显示",
      "describe":"网页描述",
      "content":"文章内容",
      "upload":"图片上传",
      "toUpload":"请上传图片格式文件",
      "submit":"确认提交",
    },
    "articleCatList":{
      "articleSort":"文章分类",
      "addAndManagement":"网站文章分类添加与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"新增文章时，可选择文章分类。文章分类将在前台文章列表页显示",
      "state2":"系统文章分类不可以删除",
      "hideTips":"收起提示",
      "sortList":"文章分类列表",
      "addSort":"新增分类",
      "refresh":"刷新数据",
      "sortName":"分类名称",
      "sortDescribe":"分类描述",
      "sort":"排序",
      "operation":"操作",
      "newChild":"新增下级",
      "edit":"编辑",
      "delete":"删除",
      "parent":"上级分类",
      "topest":"顶级分类",
      "nav":"导航显示",
      "keywords":"搜索关键字",
      "searchDescribe":"搜索描述",
      "determine":"确 定",
    },
    "articleList":{
      "management":"文章管理",
      "indexAndManagement":"网站系统文章索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"文章管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"文章列表",
      "total":"共",
      "records":"条记录",
      "addArticle":"新增文章",
      "refresh":"刷新数据",
      "topest":"顶级分类",
      "articleTitle":"文章标题",
      "search":"搜索",
      "title":"标题",
      "sort":"文章分类",
      "show":"显示",
      "yes":"是",
      "no":"否",
      "publish":"发布时间",
      "operation":"操作",
      "check":"查看",
      "edit":"编辑",
      "delete":"删除",
    },
    "friendLink":{
      "backToList":"返回列表",
      "management":"友情链接管理",
      "addOrEdit":"新增/编辑友情链接",
      "webManagement":"网站系统友情链接管理",
      "linkName":"链接名称",
      "friendLinkName":"请填写友情链接的名称",
      "link":"链接地址",
      "friendLink":"友情链接跳转地址",
      "sort":"排序",
      "state1":"请填写0-255的自然数，友情链接会根据排序进行由小到大排列显示",
      "linkLogo":"链接logo",
      "state2":"默认网站LOGO,通用头部显示，最佳显示尺寸为240*60像素",
      "openInNew":"是否新窗口打开",
      "isShow":"是否显示",
      "submit":"确认提交",
    },
    "friendLinkList":{
      "management":"友情链接管理",
      "indexAndManagement":"网站系统友情链接索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"友情链接管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"友情链接列表",
      "total":"共",
      "records":"条记录",
      "addFriendLink":"新增友情链接",
      "refresh":"刷新数据",
      "linkName":"链接名称",
      "link":"链接地址",
      "sort":"排序",
      "openInNew":"新窗口打开",
      "yes":"是",
      "no":"否",
      "isShow":"是否显示",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
    "helpList":{
      "systemHelp":"平台帮助",
      "typeAndManagement":"平台帮助类型与帮助文章内容管理",
      "content":"帮助内容",
      "type":"帮助类型",
    },
    "systemArticle":{
      "backToList":"返回列表",
      "management":"会员协议管理",
      "settingManagement":"网站会员协议设置管理",
      "title":"标题",
      "identifier":"标识码",
      "content":"帮助内容",
      "submit":"确认提交",
    },
    "systemArticleList":{
      "agreement":"会员协议",
      "settingManagement":"网站会员协议设置管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"在相关操作处可查看具体内容，例：在注册会员时须查看用户服务协议",
      "hideTips":"收起提示",
      "list":"会员协议列表",
      "total":"共",
      "records":"条记录",
      "addAgreement":"新增会员协议",
      "refresh":"刷新数据",
      "title":"标题",
      "time":"时间",
      "operation":"操作",
      "edit":"编辑",
    },
  },
  "news":{
    "news":{
      "backToList":"返回列表",
      "management":"新闻管理",
      "addAndEdit":"网站系统新闻新增与编辑",
      "title":"标题",
      "sort":"所属分类",
      "state1":"当选择发布'商城公告'时，还需要设置下面的'出现位置'项",
      "sign":"新闻标签",
      "keywords":"seo关键字",
      "state2":"用于seo 搜索引擎友好",
      "link":"链接",
      "state3":"当填写'链接'后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头",
      "publish":"发布时间",
      "select":"选择日期时间",
      "describe":"网页描述",
      "firstMap":"新闻导图一",
      "secondMap":"新闻导图二",
      "thirdMap":"新闻导图三",
      "upload":"请上传图片格式文件",
      "style":"导图展现样式",
      "noshow":"不显示图片",
      "left":"左边图片",
      "right":"右边图片",
      "top":"上边多图片",
      "bottom":"下边多图片",
      "state4":"注：左右显示图片只显示导图一，上下多图片显示为3张比例",
      "show":"显示",
      "audit":"审核：",
      "adopt":"审核通过",
      "reject":"拒绝通过",
      "content":"文章内容",
      "submit":"确认提交",
    },
    "newsCatList":{
      "newsSort":"新闻分类",
      "addAndManagement":"网站新闻分类添加与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"新增新闻时，可选择新闻分类。新闻分类将在前台新闻列表页显示",
      "hideTips":"收起提示",
      "sortList":"分类列表",
      "total":"共",
      "records":"条记录",
      "addSort":"新增分类",
      "refresh":"刷新数据",
      "sortName":"分类名称",
      "sortDescribe":"分类描述",
      "sort":"排序",
      "operation":"操作",
      "delete":"删除",
      "addNewsSort":"新增新闻分类",
      "sortName":"分类名称",
      "nav":"导航显示",
      "keywords":"搜索关键字",
      "describe":"搜索描述",
      "determine":"确 定",
    },
    "newsCommentList":{
      "backToList":"返回列表",
      "management":"新闻管理",
      "indexAndManagement":"网站系统新闻评论索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"新闻评论管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"评论列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "content":"评论内容",
      "reviewers":"评论者",
      "time":"评论时间",
      "status":"审核状态",
      "notAudit":"未审核",
      "adopt":"通过",
      "reject":"拒绝",
      "operation":"操作",
    },
    "newsList":{
      "management":"新闻管理",
      "indexAndManagement":"网站系统新闻索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"新闻管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"新闻列表",
      "total":"共",
      "records":"条记录",
      "addNews":"新增新闻",
      "batchDelete":"批量删除",
      "refresh":"刷新数据",
      "newsCategory":"新闻类别",
      "newsTitle":"新闻标题",
      "search":"搜索",
      "title":"标题",
      "sort":"文章分类",
      "publisher":"发布者",
      "show":"显示",
      "yes":"是",
      "no":"否",
      "time":"发布时间",
      "sign":"新闻标记",
      "status":"审核状态",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
      "article":"审核文章",
      "comment":"审核评论",
    },
  },
  "pc":{
    "pcBlockDesign":{
      "backToList":"返回列表",
      "set":"首页管理 - 设置",
      "block":"板块",
      "state1":"商城首页模板及广告设计",
      "blockName":"版块名称",
      "state2":"板块名称只在后台首页模板设置中作为板块标识出现，在前台首页不显示。",
      "color":"色彩风格",
      "sort":"排序",
      "state3":"数字范围为0~255，数字越小越靠前",
      "show":"显示",
      "submit":"确认提交",
    },
    "pcIndex":{
      "index":"首页管理",
      "formworkAndDesign":"商城首页模板及广告设计",
      "floor":"楼层管理",
      "adSpace":"首页广告位",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"排序越小越靠前，可以控制板块显示先后。",
      "state2":"色彩风格和前台的样式一致，在设置中选择更换。",
      "state3":"色彩风格是css样式中已经有的，如果需要修改名称则相关程序也要同时改变才会有效果。",
      "hideTips":"收起提示",
      "list":"商城首页板块列表",
      "total":"共",
      "records":"条记录",
      "addBlock":"新增版块",
      "refresh":"刷新数据",
      "operation":"操作",
      "setting":"设置",
      "design":"设计",
      "sort":"排序",
      "blockName":"板块名称",
      "color":"色彩风格",
      "updateTime":"更新时间",
      "show":"显示",
      "yes":"是",
      "no":"否",
    },
    "pcSection":{
      "backToList":"返回列表",
      "design":"首页管理 - 设计",
      "block":"板块",
      "state1":"商城首页模板及广告设计",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state2":"所有相关设置完成，使用底部的“更新板块内容”前台展示页面才会变化。",
      "state3":"左侧的“推荐分类”没有个数限制，但是如果太多会不显示(已选择的子分类可以拖动进行排序，单击选中，双击删除)。",
      "state4":"中部的“商品推荐模块”由于页面宽度只能加5个，商品数为10个；右侧的品牌最多为10个(已选择的可以拖动进行排序，单击选中，双击删除)。",
      "hideTips":"收起提示",
      "state5":"板块内容设置：色彩风格：",
      "setColor":"设置该板块色彩",
      "titleImg":"标题图片",
      "activityImg":"活动图片",
      "recommendSort":"推荐分类",
      "edit":"编辑",
      "selectedRecommendation":"精选推荐",
      "add":"新增",
      "hide":"隐藏",
      "goodsRecommend":"商品推荐",
      "editGoods":"编辑商品",
      "delete":"删除",
      "addBlock":"+新增商品推荐模块",
      "recommendBrand":"推荐品牌",
      "select":"选择类型",
      "text":"文字类型",
      "blockTitle":"版块标题",
      "state6":"如鞋包配饰、男女服装、运动户外。",
      "state7":"建议上传宽210*高40像素jpg/png格式图片，超出规定范围的图片部分将被自动隐藏，且不超过500kb。",
      "submit1":"确认提交",
      "submit2":"确认添加",
      "activityName":"活动名字",
      "linkAddress":"链接地址",
      "modify":"修改",
      "increase":"添加",
      "selectedSort":"已选商品分类",
      "addSort":"添加推荐分类",
      "save":"保存",
      "selectedBrand":"已选择品牌",
      "brandList":"候选推荐品牌列表",
      "titleName":"商品推荐模板标题名称",
      "state8":"修改该区域中部推荐商品模块选项卡名称，控制名称字符在4-8字左右",
      "recommendGoods":"推荐商品",
      "sort":"商品分类",
      "query":"查询",
      "title":"文字标题",
      "imgLink":"图片跳转链接",
    },
  },
  "index":{
    "mobile":"移动端",
    "personalCenter":"个人中心",
    "index":"pc首页",
    "news":"新闻",
    "newsList":"新闻列表",
    "newsSort":"新闻分类",
    "article":"文章",
    "articleList":"文章列表",
    "articleSort":"文章分类",
    "link":"友情链接",
    "agreement":"会员协议",
    "settlement":"商家入驻",
    "ad":"广告",
    "adList":"广告列表",
  },
}

export const zh = {
  "ad":{
    "adEdit":{
      "suggested":"建议尺寸",
      "backToList":"返回列表",
      "detail":"广告详情",
      "edit":"广告编辑",
      "name":"广告名称",
      "link":"广告链接",
      "position":"广告位置.",
      "startDate":"开始日期",
      "selectDate":"选择日期",
      "endDate":"结束日期",
      "image":"广告图片",
      "backgroundColor":"背景颜色",
      "sort":"默认排序",
      "submit":"确认提交",
      "openNew":"新窗口打开",
    },
    "adList":{
      "adList":"广告列表",
      "indexAndManagement":"广告索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"只需要点击半透明广告图片即可更换广告.",
      "state2":"预览广告所在页面中选择更换你的广告",
      "hideTips":"收起提示",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "allPosition":"广告位置 - 所有",
      "name":"广告名称",
      "search":"搜索",
      "id":"广告ID",
      "position":"广告位置",
      "image":"广告图片",
      "newWinodw":"新窗口",
      "yes":"是",
      "no":"否",
      "show":"显示",
      "sort":"排序",
      "operation":"操作",
      "edit":"编辑",
      "fullSitePoster":"全站海报",
    },
    "postertAdmin":{
      "backToList":"返回列表",
      "posterDetail":"海报详情",
      "posterEdit":"海报编辑",
      "posterName":"海报名称",
      "posterLink":"海报链接", 
      "startDate":"开始日期",
      "selectDate":"选择日期时间",
      "endDate":"结束日期",
      "posterImage":"海报图片",
      "submit":"确认提交",
    },
  },
  "app":{
    "userCenter":{
      "setting":"个人中心设置",
      "onlyApp":"只能用于移动端",
      "name":"默认名称",
      "menu":"菜单",
      "toInput":"请输入内容",
      "showOrHide":"隐藏/显示",
    },
  },
  "article":{
    "help":{
      "helpContent":{
        "backToList":"返回列表",
        "management":"帮助管理",
        "addAndEdit":"网站系统帮助添加与编辑",
        "title":"标题",
        "sort":"所属分类",
        "state1":"当选择发布'商城公告'时，还需要设置下面的'出现位置'项",
        "keywords":"seo关键字",
        "state2":"用于seo 搜索引擎友好",
        "link":"链接",
        "state3":"当填写'链接'后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头",
        "show":"显示",
        "helpContent":"帮助内容",
        "submit":"确认提交",
      },
      "helpContentList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state":"帮助内容排序显示规则为排序小的在前，新增内容的在前.",
        "hideTips":"收起提示",
        "helpList":"帮助列表",
        "total":"共",
        "records":"条记录",
        "newHelp":"新增帮助",
        "refresh":"刷新数据",
        "helpType":"帮助类型",
        "title":"标题",
        "search":"搜索",
        "show":"显示",
        "yes":"是",
        "no":"否",
        "publish":"发布时间",
        "operation":"操作",
        "check":"查看",
        "edit":"编辑",
        "delete":"删除",
      },
      "helpTypeList":{
        "tipsNode":"提示相关设置操作时应注意的要点",
        "tips":"操作提示",
        "state1":"新增文章时，可选择文章分类。文章分类将在前台文章列表页显示",
        "state2":"ID前10的为系统分类，不可以删除",
        "hideTips":"收起提示",
        "sortList":"帮助分类列表",
        "addSort":"新增分类",
        "refresh":"刷新数据",
        "sortName":"分类名称",
        "sort":"排序",
        "isShow":"是否显示",
        "yes":"是",
        "no":"否",
        "operation":"操作",
        "newChild":"新增下级",
        "edit":"编辑",
        "delete":"删除",
        "parent":"上级分类",
        "topest":"顶级分类",
        "determine":"确 定",
      },
    },
    "article":{
      "backToList":"返回列表",
      "management":"文章管理",
      "addAndEdit":"网站系统文章添加与编辑",
      "title":"标题",
      "sort":"所属分类",
      "selSort":"选择分类",
      "state1":"当选择发布'商城公告'时，还需要设置下面的'出现位置'项",
      "keywords":"seo关键字",
      "state2":"用于seo 搜索引擎友好",
      "link":"链接",
      "state3":"当填写'链接'后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头",
      "publish":"发布时间",
      "select":"选择日期",
      "show":"显示",
      "describe":"网页描述",
      "content":"文章内容",
      "upload":"图片上传",
      "toUpload":"请上传图片格式文件",
      "submit":"确认提交",
    },
    "articleCatList":{
      "articleSort":"文章分类",
      "addAndManagement":"网站文章分类添加与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"新增文章时，可选择文章分类。文章分类将在前台文章列表页显示",
      "state2":"系统文章分类不可以删除",
      "hideTips":"收起提示",
      "sortList":"文章分类列表",
      "addSort":"新增分类",
      "refresh":"刷新数据",
      "sortName":"分类名称",
      "sortDescribe":"分类描述",
      "sort":"排序",
      "operation":"操作",
      "newChild":"新增下级",
      "edit":"编辑",
      "delete":"删除",
      "parent":"上级分类",
      "topest":"顶级分类",
      "nav":"导航显示",
      "keywords":"搜索关键字",
      "searchDescribe":"搜索描述",
      "determine":"确 定",
    },
    "articleList":{
      "management":"文章管理",
      "indexAndManagement":"网站系统文章索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"文章管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"文章列表",
      "total":"共",
      "records":"条记录",
      "addArticle":"新增文章",
      "refresh":"刷新数据",
      "topest":"顶级分类",
      "articleTitle":"文章标题",
      "search":"搜索",
      "title":"标题",
      "sort":"文章分类",
      "show":"显示",
      "yes":"是",
      "no":"否",
      "publish":"发布时间",
      "operation":"操作",
      "check":"查看",
      "edit":"编辑",
      "delete":"删除",
    },
    "friendLink":{
      "backToList":"返回列表",
      "management":"友情链接管理",
      "addOrEdit":"新增/编辑友情链接",
      "webManagement":"网站系统友情链接管理",
      "linkName":"链接名称",
      "friendLinkName":"请填写友情链接的名称",
      "link":"链接地址",
      "friendLink":"友情链接跳转地址",
      "sort":"排序",
      "state1":"请填写0-255的自然数，友情链接会根据排序进行由小到大排列显示",
      "linkLogo":"链接logo",
      "state2":"默认网站LOGO,通用头部显示，最佳显示尺寸为240*60像素",
      "openInNew":"是否新窗口打开",
      "isShow":"是否显示",
      "submit":"确认提交",
    },
    "friendLinkList":{
      "management":"友情链接管理",
      "indexAndManagement":"网站系统友情链接索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"友情链接管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"友情链接列表",
      "total":"共",
      "records":"条记录",
      "addFriendLink":"新增友情链接",
      "refresh":"刷新数据",
      "linkName":"链接名称",
      "link":"链接地址",
      "sort":"排序",
      "openInNew":"新窗口打开",
      "yes":"是",
      "no":"否",
      "isShow":"是否显示",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
    },
    "helpList":{
      "systemHelp":"平台帮助",
      "typeAndManagement":"平台帮助类型与帮助文章内容管理",
      "content":"帮助内容",
      "type":"帮助类型",
    },
    "systemArticle":{
      "backToList":"返回列表",
      "management":"会员协议管理",
      "settingManagement":"网站会员协议设置管理",
      "title":"标题",
      "identifier":"标识码",
      "content":"帮助内容",
      "submit":"确认提交",
    },
    "systemArticleList":{
      "agreement":"会员协议",
      "settingManagement":"网站会员协议设置管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"在相关操作处可查看具体内容，例：在注册会员时须查看用户服务协议",
      "hideTips":"收起提示",
      "list":"会员协议列表",
      "total":"共",
      "records":"条记录",
      "addAgreement":"新增会员协议",
      "refresh":"刷新数据",
      "title":"标题",
      "time":"时间",
      "operation":"操作",
      "edit":"编辑",
    },
  },
  "news":{
    "news":{
      "backToList":"返回列表",
      "management":"新闻管理",
      "addAndEdit":"网站系统新闻新增与编辑",
      "title":"标题",
      "sort":"所属分类",
      "state1":"当选择发布'商城公告'时，还需要设置下面的'出现位置'项",
      "sign":"新闻标签",
      "keywords":"seo关键字",
      "state2":"用于seo 搜索引擎友好",
      "link":"链接",
      "state3":"当填写'链接'后点击文章标题将直接跳转至链接地址，不显示文章内容。链接格式请以http://开头",
      "publish":"发布时间",
      "select":"选择日期时间",
      "describe":"网页描述",
      "firstMap":"新闻导图一",
      "secondMap":"新闻导图二",
      "thirdMap":"新闻导图三",
      "upload":"请上传图片格式文件",
      "style":"导图展现样式",
      "noshow":"不显示图片",
      "left":"左边图片",
      "right":"右边图片",
      "top":"上边多图片",
      "bottom":"下边多图片",
      "state4":"注：左右显示图片只显示导图一，上下多图片显示为3张比例",
      "show":"显示",
      "audit":"审核：",
      "adopt":"审核通过",
      "reject":"拒绝通过",
      "content":"文章内容",
      "submit":"确认提交",
    },
    "newsCatList":{
      "newsSort":"新闻分类",
      "addAndManagement":"网站新闻分类添加与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"新增新闻时，可选择新闻分类。新闻分类将在前台新闻列表页显示",
      "hideTips":"收起提示",
      "sortList":"分类列表",
      "total":"共",
      "records":"条记录",
      "addSort":"新增分类",
      "refresh":"刷新数据",
      "sortName":"分类名称",
      "sortDescribe":"分类描述",
      "sort":"排序",
      "operation":"操作",
      "delete":"删除",
      "addNewsSort":"新增新闻分类",
      "sortName":"分类名称",
      "nav":"导航显示",
      "keywords":"搜索关键字",
      "describe":"搜索描述",
      "determine":"确 定",
    },
    "newsCommentList":{
      "backToList":"返回列表",
      "management":"新闻管理",
      "indexAndManagement":"网站系统新闻评论索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"新闻评论管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"评论列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "content":"评论内容",
      "reviewers":"评论者",
      "time":"评论时间",
      "status":"审核状态",
      "notAudit":"未审核",
      "adopt":"通过",
      "reject":"拒绝",
      "operation":"操作",
    },
    "newsList":{
      "management":"新闻管理",
      "indexAndManagement":"网站系统新闻索引与管理",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"新闻管理, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"新闻列表",
      "total":"共",
      "records":"条记录",
      "addNews":"新增新闻",
      "batchDelete":"批量删除",
      "refresh":"刷新数据",
      "newsCategory":"新闻类别",
      "newsTitle":"新闻标题",
      "search":"搜索",
      "title":"标题",
      "sort":"文章分类",
      "publisher":"发布者",
      "show":"显示",
      "yes":"是",
      "no":"否",
      "time":"发布时间",
      "sign":"新闻标记",
      "status":"审核状态",
      "operation":"操作",
      "edit":"编辑",
      "delete":"删除",
      "article":"审核文章",
      "comment":"审核评论",
    },
  },
  "pc":{
    "pcBlockDesign":{
      "backToList":"返回列表",
      "set":"首页管理 - 设置",
      "block":"板块",
      "state1":"商城首页模板及广告设计",
      "blockName":"版块名称",
      "state2":"板块名称只在后台首页模板设置中作为板块标识出现，在前台首页不显示。",
      "color":"色彩风格",
      "sort":"排序",
      "state3":"数字范围为0~255，数字越小越靠前",
      "show":"显示",
      "submit":"确认提交",
    },
    "pcIndex":{
      "index":"首页管理",
      "formworkAndDesign":"商城首页模板及广告设计",
      "floor":"楼层管理",
      "adSpace":"首页广告位",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state1":"排序越小越靠前，可以控制板块显示先后。",
      "state2":"色彩风格和前台的样式一致，在设置中选择更换。",
      "state3":"色彩风格是css样式中已经有的，如果需要修改名称则相关程序也要同时改变才会有效果。",
      "hideTips":"收起提示",
      "list":"商城首页板块列表",
      "total":"共",
      "records":"条记录",
      "addBlock":"新增版块",
      "refresh":"刷新数据",
      "operation":"操作",
      "setting":"设置",
      "design":"设计",
      "sort":"排序",
      "blockName":"板块名称",
      "color":"色彩风格",
      "updateTime":"更新时间",
      "show":"显示",
      "yes":"是",
      "no":"否",
    },
    "pcSection":{
      "backToList":"返回列表",
      "design":"首页管理 - 设计",
      "block":"板块",
      "state1":"商城首页模板及广告设计",
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state2":"所有相关设置完成，使用底部的“更新板块内容”前台展示页面才会变化。",
      "state3":"左侧的“推荐分类”没有个数限制，但是如果太多会不显示(已选择的子分类可以拖动进行排序，单击选中，双击删除)。",
      "state4":"中部的“商品推荐模块”由于页面宽度只能加5个，商品数为10个；右侧的品牌最多为10个(已选择的可以拖动进行排序，单击选中，双击删除)。",
      "hideTips":"收起提示",
      "state5":"板块内容设置：色彩风格：",
      "setColor":"设置该板块色彩",
      "titleImg":"标题图片",
      "activityImg":"活动图片",
      "recommendSort":"推荐分类",
      "edit":"编辑",
      "selectedRecommendation":"精选推荐",
      "add":"新增",
      "hide":"隐藏",
      "goodsRecommend":"商品推荐",
      "editGoods":"编辑商品",
      "delete":"删除",
      "addBlock":"+新增商品推荐模块",
      "recommendBrand":"推荐品牌",
      "select":"选择类型",
      "text":"文字类型",
      "blockTitle":"版块标题",
      "state6":"如鞋包配饰、男女服装、运动户外。",
      "state7":"建议上传宽210*高40像素jpg/png格式图片，超出规定范围的图片部分将被自动隐藏，且不超过500kb。",
      "submit1":"确认提交",
      "submit2":"确认添加",
      "activityName":"活动名字",
      "linkAddress":"链接地址",
      "modify":"修改",
      "increase":"添加",
      "selectedSort":"已选商品分类",
      "addSort":"添加推荐分类",
      "save":"保存",
      "selectedBrand":"已选择品牌",
      "brandList":"候选推荐品牌列表",
      "titleName":"商品推荐模板标题名称",
      "state8":"修改该区域中部推荐商品模块选项卡名称，控制名称字符在4-8字左右",
      "recommendGoods":"推荐商品",
      "sort":"商品分类",
      "query":"查询",
      "title":"文字标题",
      "imgLink":"图片跳转链接",
    },
  },
  "index":{
    "mobile":"移动端",
    "personalCenter":"个人中心",
    "index":"pc首页",
    "news":"新闻",
    "newsList":"新闻列表",
    "newsSort":"新闻分类",
    "article":"文章",
    "articleList":"文章列表",
    "articleSort":"文章分类",
    "link":"友情链接",
    "agreement":"会员协议",
    "settlement":"商家入驻",
    "ad":"广告",
    "adList":"广告列表",
  },
}
