export const en = {
  "addExposeSubject":{
    "backToList":"返回列表",
    "management":"举报管理",
    "typeAndHandle":"商城设置举报类型及处理被举报商品",
    "reportType":"举报类型",
    "reportSubject":"举报主题",
    "submit":"确认提交",
  },
  "addExposeType":{
    "backToList":"返回列表",
    "management":"举报管理",
    "add":"新增",
    "typeAndHandle":"商城设置举报类型及处理被举报商品",
    "reportType":"举报类型",
    "reportDec":"举报类型描述",
    "submit":"确认提交",
  },
  "addSubject":{
    "backToList":"返回列表",
    "management":"投诉管理",
    "add":"新增",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "complaintSubject":"投诉主题",
    "complaintDec":"投诉主题描述",
    "submit":"确认提交",
  },
  "comment":{
    "management":"评价管理",
    "goodsManagement":"商品交易评价管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"买家可在订单完成后对订单商品进行评价操作，评价信息将显示在对应的商品页面",
    "hideTips":"收起提示",
    "list":"商品评价列表",
    "total":"共",
    "records":"条记录",
    "batchDelete":"批量删除",
    "show":"显示",
    "hide":"隐藏",
    "refresh":"刷新数据",
    "content":"评论内容",
    "searchUser":"搜索用户",
    "search":"搜索",
    "user":"用户",
    "score":"评分",
    "image":"晒单图片",
    "goods":"商品",
    "time":"评论时间",
    "ip":"ip地址",
    "operation":"操作",
    "delete":"删除",
  },
  "complainDetail":{
    "backToList":"返回列表",
    "management":"投诉管理",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "pending":"待处理",
    "inConversation":"对话中",
    "arbitrating":"待仲裁",
    "completed":"已完成",
    "menuDetail":"订单详情",
    "storeName":"店铺名称：",
    "menuStatus":"订单状态：",
    "menuId":"订单号：",
    "menuTime":"下单时间：",
    "menuSum":"订单总额：",
    "complaintGoods":"投诉的商品",
    "goodsName":"商品名称",
    "num":"数量",
    "price":"价格",
    "complaintContent":"投诉内容",
    "complaintMessage":"投诉信息",
    "complaintStatus":"投诉状态：",
    "complaintTheme":"投诉主题：",
    "complainants":"投诉人：",
    "complaintEvidence":"投诉证据：",
    "noImg":"暂无图片",
    "complaintTime":"投诉时间：",
    "complaintDetail":"申诉详情",
    "complaintStore":"被投诉店铺：",
    "appealEvidence":"申诉证据：",
    "appealTime":"申诉时间：",
    "appealContent":"申诉内容：",
    "dialogDetail":"对话详情",
    "dialogHistory":"对话记录：",
    "shield":"屏蔽",
    "shielding":"该对话被管理员屏蔽",
    "publish":"发布对话",
    "refresh":"刷新对话",
    "complaintHandling":"投诉处理",
    "closeComplaint":"关闭投诉",
    "back":"返回",
    "handleAdvice":"处理意见：",
    "submit":"确认提交",
    "cancel":"取消",
    "handleDetail":"处理详情",
    "handleTime":"处理时间：",
  },
  "complainSubject":{
    "management":"投诉管理",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "new":"新投诉",
    "inConversation":"对话中",
    "arbitrating":"待仲裁",
    "completed":"已完成",
    "themeSetting":"主题设置",
    "timeSetting":"时效设置",
    "themeList":"商家主题列表",
    "total":"共",
    "records":"条记录",
    "add":"新增数据",
    "refresh":"刷新数据",
    "theme":"投诉主题",
    "describe":"投诉主题描述",
    "operation":"操作",
    "delete":"删除",
  },
  "complainTime":{
    "timeLimit":"投诉时效",
    "state":"单位为天，订单完成后开始计算，多少天内可以发起投诉",
    "submit":"确认提交",
  },
  "complains":{
    "management":"投诉管理",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "new":"新投诉",
    "inConversation":"对话中",
    "arbitrating":"待仲裁",
    "completed":"已完成",
    "themeSetting":"主题设置",
    "timeSetting":"时效设置",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"在投诉时效内，买家可对订单进行投诉，投诉主题由管理员在后台统一设置",
    "state2":"投诉时效可在系统设置处进行设置",
    "state3":"点击详细，可查看投诉。被投诉店铺可进行回复进行申诉。申诉成功后，投诉双方进行对话",
    "state4":"会员可确认结束纠纷单，若会员对于卖家的处理不满意可以申请仲裁，最后由后台管理员进行仲裁操作",
    "hideTips":"收起提示",
    "complaintList":"商家投诉列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "complainants":"投诉人",
    "store":"被投商家",
    "search":"搜索",
    "content":"投诉内容",
    "image":"投诉图片",
    "time":"投诉时间",
    "theme":"投诉主题",
    "respondentId":"投诉人ID",
    "storeId":"商家ID",
    "operation":"操作",
    "check":"查看",
  },
  "expose":{
    "management":"举报管理",
    "arbitration":"商城设置举报类型及处理被举报商品",
    "unhandled":"未处理",
    "handled":"已处理",
    "typeSetting":"类型设置",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"举报类型和举报主题由管理员在后台设置，在商品信息页会员可根据举报主题举报违规商品，点击详细，查看举报内容",
    "hideTips":"收起提示",
    "reportList":"待处理的举报列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "reporter":"举报人",
    "type":"举报类型",
    "theme":"举报主题",
    "goods":"举报商品",
    "image":"图片",
    "time":"举报时间",
    "involvedStore":"涉及商家",
    "reporterId":"举报人ID",
    "goodsId":"商品ID",
    "storeId":"商家ID",
    "result":"处理结果",
    "message":"处理信息",
    "operation":"操作",
    "handle":"处理",
  },
  "exposeDetail":{
    "backToList":"返回列表",
    "management":"举报管理",
    "handleReport":"处理举报",
    "arbitration":"商城设置举报类型及处理被举报商品",
    "reportMessage":"举报信息",
    "store":"被举报商家:",
    "evidence":"举报证据:",
    "goodsName":"商品名称",
    "type":"举报类型:",
    "theme":"举报主题:",
    "menuId":"相关订单号:",
    "describe":"违规描述",
    "result":"处理结果",
    "state1":"无效举报--商品会正常销售",
    "state2":"恶意举报--该用户的所有未处理举报将被取消",
    "state3":"有效举报--商品将违规下架",
    "handleMessage":"处理信息",
    "submit":"确认提交",
  },
  "exposeSubject":{
    "management":"举报管理",
    "arbitration":"商城设置举报类型及处理被举报商品",
    "unhandled":"未处理",
    "handled":"已处理",
    "typeSetting":"类型设置",
    "themeSetting":"主题设置",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"可在同一举报类型下添加多个举报主题",
    "hideTips":"收起提示",
    "themeList":"举报主题列表",
    "total":"共",
    "records":"条记录",
    "add":"新增数据",
    "refresh":"刷新数据",
    "type":"举报类型",
    "theme":"举报主题",
    "status":"状态",
    "operation":"操作",
    "delete":"删除",
    "typeList":"举报类型列表",
    "describe":"举报类型描述",
  },
  "founds":{
    "groupList":"拼团列表",
    "inquiryAndManagement":"商城拼团列表查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"点击查看操作将显示订单（包括订单物品）的详细信息",
    "state2":"点击取消操作可以取消订单（在线支付但未付款的订单和货到付款但未发货的订单）",
    "state3":"如果平台已确认收到买家的付款，但系统支付状态并未变更，可以点击收到货款操作(仅限于下单后7日内可更改收款状态)，并填写相关信息后更改订单支付状态",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "groupStatus":"拼团状态",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "search":"搜索",
    "orderId":"订单编号",
    "receiver":"收货人",
    "sum":"总金额",
    "payable":"应付金额",
    "menuStatus":"订单状态",
    "payStatus":"支付状态",
    "deliverStatus":"发货状态",
    "payMethod":"支付方式",
    "sendMethod":"配送方式",
    "orderTime":"下单时间",
    "store":"店铺",
    "operation":"操作",
    "menuDetail":"订单详情",
    "checkMenu":"查看拼单",
  },
  "goodsConsult":{
    "management":"咨询管理",
    "showAndhHide":"管理咨询/内容显示与隐藏",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员可在商品信息页对商品进行咨询，系统设置处可设置游客是否能够咨询",
    "hideTips":"收起提示",
    "consultList":"商品咨询列表",
    "total":"共",
    "records":"条记录",
    "batchDelete":"批量删除",
    "show":"显示",
    "hide":"隐藏",
    "refresh":"刷新数据",
    "content":"咨询内容",
    "searchUser":"搜索用户",
    "search":"搜索",
    "user":"用户",
    "goods":"商品",
    "time":"咨询时间",
    "operation":"操作",
    "delete":"删除",
  },
  "index":{
    "management":"订单管理",
    "orderList":"订单列表",
    "groupList":"拼团列表",
    "groupMenu":"拼团订单",
    "orderLog":"订单日志",
    "orderHandling":"订单处理",
    "refunds":"退款单",
    "returns":"退换货",
    "invoice":"发票管理",
    "barter":"换货维修",
    "returnGoods":"售后退货",
    "comment":"商品评论",
    "consult":"商品咨询",
    "complaint":"投诉管理",
    "report":"举报管理",
  },
  "invoice":{
    "management":"发票管理",
    "inquiryAndManagement":"商城发票查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "checkAll":"查看所有发票记录",
    "hideTips":"收起提示",
    "list":"发票列表",
    "total":"共",
    "records":"条记录",
    "state":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出发票",
    "refresh":"刷新数据",
    "toInvoice":"待开发票:",
    "invoiced":"累计开票:",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "status":"发票状态",
    "search":"搜索",
    "invoiceId":"发票编号",
    "orderId":"订单编号",
    "username":"用户名",
    "store":"店铺",
    "type":"发票类型",
    "amountOfMoney":"开票金额",
    "rise":"发票抬头",
    "content":"发票内容",
    "taxRate":"发票税率",
    "identificationNum":"纳税人识别号",
    "createTime":"创建时间",
    "billingTime":"开票时间",
    "operation":"操作",
    "check":"查看",
  },
  "orderDetail":{
    "backToList":"返回列表",
    "goodsOrder":"商品订单",
    "inquiryAndManagement":"商城实物商品交易订单查询及管理",
    "printInvoice":"打印配货单",
    "basicMessage":"基本信息",
    "order":"订单",
    "orderId":"订单号：",
    "member":"会员：",
    "telephone":"电话：",
    "payable":"应付金额：",
    "orderStatus":"订单状态",
    "cashOnDelivery":"货到付款",
    "orderTime":"下单时间：",
    "payTime":"支付时间：",
    "payMethod":"支付方式：",
    "rise":"发票抬头：",
    "identificationNum":"纳税人识别号：",
    "receiveInfo":"收货信息",
    "receiver":"收货人：",
    "contact":"联系方式：",
    "shippingAddress":"收货地址：",
    "postcode":"邮编：",
    "sendMethod":"配送方式：",
    "selfRaising":"自提",
    "cancelMessage":"取消订单留言：",
    "logisticsInfo":"物流信息",
    "operateTime":"操作时间",
    "status":"状态",
    "costInfo":"费用信息",
    "subtotal":"小计：",
    "freight":"运费：",
    "integral":"积分",
    "balance":"余额抵扣：",
    "coupon":"优惠券抵扣：",
    "priceAdjust":"价格调整：",
    "preferential":"订单优惠：",
    "copeWith":"应付：",
    "taxes_dues":"税费：",
    "tip":"小费：",
    "goodsMessage":"商品信息",
    "goodsId":"商品编号",
    "goods":"商品",
    "spec":"规格属性",
    "num":"数量",
    "price":"单品价格",
    "discount":"会员折扣价",
    "singleSubtotal":"单品小计",
    "refundProcessing":"退款处理",
    "auditMind":"审核意见：",
    "agree":"同意退款",
    "disagree":"拒绝退款",
    "refundMethod":"退款方式：",
    "backToBalance":"退到用户余额",
    "backToOrigin":"支付原路退回",
    "notSupport":"不支持",
    "handleRemark":"处理备注：",
    "submit":"确认提交",
    "refundMessage":"退款信息：",
    "refunded":"已退款",
    "record":"操作记录",
    "payStatus":"付款状态",
    "deliverStatus":"发货状态",
    "describe":"描述",
    "remark":"备注",
    "selfRaised":"已自提",
    "toSelfRaise":"待自提",
    "printOrder":"打印订单",
    "state":"选择喷墨或激光打印机，根据下列纸张描述进行，设置并打印发货单据",
    "print":"打印",
    "consigneeInfo":"收货人信息",
    "addressee":"收件人：",
    "phone":"联系电话：",
    "email":"邮箱：",
    "orderDetail":"订单详情",
    "orderDate":"下单日期：",
    "orderSum":"订单总价：",
    "goodsPrice":"商品价格：",
    "delivery":"配送费用：",
    "useIntegral":"使用积分：",
    "useAmount":"使用余额：",
    "goodsName":"商品名称",
    "goodsCode":"商品货号",
    "unitPrice":"单价",
  },
  "orderLog":{
    "orderLog":"订单日志",
    "history":"订单操作日志包括商家操作订单记录",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "operationLog":"订单操作日志",
    "hideTips":"收起提示",
    "to":"订单列表",
    "total":"共",
    "records":"条记录",
    "batchDelete":"批量删除",
    "refresh":"刷新数据", 
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "operaterId":"操作员id",
    "search":"搜索",
    "orderId":"订单ID",
    "operate":"操作",
    "operater":"操作员",
    "remark":"操作备注",
    "time":"操作时间",
    "check":"查看",
    "delete":"删除",
  },
  "orders":{
    "goodsOrder":"商品订单",
    "inquiryAndManagement":"商城实物商品交易订单查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"点击查看操作将显示订单（包括订单物品）的详细信息",
    "state2":"如果平台已确认收到买家的付款，但系统支付状态并未变更，可以点击收到货款操作(仅限于下单后7日内可更改收款状态)，并填写相关信息后更改订单支付状态",
    "hideTips":"收起提示",
    "orderList":"订单列表",
    "total":"共",
    "records":"条记录",
    "state3":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出订单",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "payStatus":"支付状态",
    "payMethod":"支付方式",    
    "deliverStatus":"发货状态",
    "orderStatus":"订单状态",
    "searchRelated":"相关搜索",
    "searchRelatedData":"搜索相关数据",
    "search":"搜索",
    "orderId":"订单编号",
    "receiver":"收货人",
    "sum":"总金额",
    "payable":"应付金额",
    "sendMethod":"配送方式",
    "orderTime":"下单时间",
    "store":"店铺",
    "operation":"操作",
    "check":"查看",
  },
  "refundOrder":{
    "list":"已付款并取消单列表",
    "all":"全部",
    "refundForm":"拼团退款单",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"未发货时用户操作取消的订单",
    "state2":"已付款并取消订单列表",
    "hideTips":"收起提示",
    "refundList":"取消退款单列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "orderId":"订单编号",
    "search":"搜索",
    "orderTime":"下单时间",
    "receiver":"收货人",
    "phone":"联系电话",
    "logisticsCost":"物流费用",
    "payTime":"支付时间",
    "orderSum":"订单总价",
    "handleStatus":"处理状态",
    "operation":"操作",
    "check":"查看",
  },
  "repairs":{
    "management":"退货退款管理",
    "applyAndAudit":"商品订单退货申请及审核处理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"买家提交申请，商家同意后，如果订单未发货，商家发货时按换货要求发，如果以发货，由买家与卖家协商快递费用，卖家收到买家换货商品确认之后再次发货",
    "hideTips":"收起提示",
    "list":"待处理的线上实物交易订单退货列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "searchRelatedData":"搜索相关数据",
    "search":"搜索",
    "buyerId":"买家会员id",
    "reason":"申请原因",
    "image":"申请图片",
    "describe":"问题描述",
    "remark":"商家处理备注",
    "auditTime":"商家审核时间",
    "orderId":"订单编号",
    "goodsId":"商品id",
    "involve":"涉及商品名称",
    "exchangeNum":"换货数量",
    "applyType":"申请类型",
    "applyTime":"申请时间",
    "storeHandle":"商家处理",
    "storeName":"店铺名称",
  },
  "return":{
    "management":"退货退款管理",
    "applyAndAudit":"商品订单退货申请及审核处理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",    
    "state":"买家提交申请，商家同意并经平台确认后，退款金额以预存款的形式返还给买家（充值卡部分只能退回到充值卡余额）。",
    "hideTips":"收起提示",
    "list":"待处理的线上实物交易订单退货列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"申请日期起始",
    "endTime":"申请日期截止",
    "payStatus":"支付状态",
    "orderId":"订单编号",
    "search":"搜索",
    "goodsName":"商品名称",
    "type":"类型",
    "applyTime":"申请时间",
    "status":"状态",
    "store":"店铺",
  },
  "returned":{
    "management":"退货退款管理",
    "applyAndAudit":"商品订单退货申请及审核处理",
    "pending":"待处理",
    "allRecords":"所有记录",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",    
    "state":"买家提交申请，商家同意并经平台确认后，退款金额以预存款的形式返还给买家（账户余额）（支付宝支付或者微信用户微信支付支持原路退回）。",
    "hideTips":"收起提示",
    "list":"待处理的线上实物交易订单退货列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"申请日期起始",
    "endTime":"申请日期截止",
    "searchRelatedData":"搜索相关数据",
    "orderId":"订单编号",
    "search":"搜索",
    "memberId":"买家会员id:",
    "memberName":"买家会员名:",
    "image":"申请图片:",
    "reason":"申请原因:",
    "refundNum":"退货数量:",
    "auditTime":"商家审核时间:",
    "orderId":"订单编号",
    "goodsId":"商品id",
    "goodsName":"商品名称",
    "store":"商家",
    "applyTime":"申请时间",
    "status":"状态",
    "operation":"操作",
    "check":"查看",
    "handle":"处理",
  },
  "returnedDetail":{
    "backToList":"返回列表",    
    "management":"退货管理",
    "state1":"查看退单“退单编号：",
    "appltAndAudit":"商品订单退货申请及审核处理",
    "refundApply":"买家退货退款申请",    
    "applyTime":"申请时间",    
    "goodsName":"商品名称",    
    "money":"退款金额",    
    "balance":"退还余额：",    
    "integral":"退还积分：",    
    "reason":"退货原因",    
    "num":"退货数量",    
    "state":"退货说明",    
    "upload":"凭证上传",    
    "handle":"商家退款退货处理",   
    "result":"审核结果",   
    "remark":"处理备注",   
    "time":"处理时间",   
    "payMessage":"订单支付信息",   
    "payMethod":"支付方式",   
    "orderSum":"订单总额",   
    "refundAudit":"平台退款审核",   
    "refundMethod":"退款方式",   
    "advanceDeposit1":"退到预存款",   
    "supportOrigin":"支付原路退还",   
    "remarkMes":"备注信息",   
    "toInput":"请输入内容",   
    "state2":"系统默认退款到“站内余额”，如果“在线退款”到原支付账号，建议在备注里说明，方便核对。",   
    "confirm":"平台确认",   
    "complete":"已完成",   
    "detail":"退款详细",
    "returnOnline":"在线原路退回",
    "stationPay":"站内余额支付",   
    "advanceDeposit2":"退还预存款",   
    "submit":"确认提交",
  },
  "team":{
    "groupOrder":"拼团订单",
    "inquiryAndManagement":"商城拼团列表查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",    
    "state1":"点击查看操作将显示订单（包括订单物品）的详细信息",
    "state2":"点击取消操作可以取消订单（在线支付但未付款的订单和货到付款但未发货的订单）",
    "state3":"如果平台已确认收到买家的付款，但系统支付状态并未变更，可以点击收到货款操作(仅限于下单后7日内可更改收款状态)，并填写相关信息后更改订单支付状态",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "state4":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出订单",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "payStatus":"支付状态",
    "payMethod":"支付方式",    
    "deliverStatus":"发货状态",
    "orderStatus":"订单状态",
    "searchRelated":"相关搜索",
    "searchRelatedData":"搜索相关数据",
    "search":"搜索",
    "orderId":"订单编号",
    "receiver":"收货人",
    "sum":"总金额",
    "payable":"应付金额",
    "member":"团员",
    "groupStatus":"拼团状态",
    "sendMethod":"配送方式",
    "orderTime":"下单时间",
    "store":"店铺",
    "operation":"操作",
    "orderDetail":"订单详情",
    "groupActivity":"拼团活动",
    "groupMember":"拼团成员",
  },
}

export const zh = {
  "addExposeSubject":{
    "backToList":"返回列表",
    "management":"举报管理",
    "typeAndHandle":"商城设置举报类型及处理被举报商品",
    "reportType":"举报类型",
    "reportSubject":"举报主题",
    "submit":"确认提交",
  },
  "addExposeType":{
    "backToList":"返回列表",
    "management":"举报管理",
    "add":"新增",
    "typeAndHandle":"商城设置举报类型及处理被举报商品",
    "reportType":"举报类型",
    "reportDec":"举报类型描述",
    "submit":"确认提交",
  },
  "addSubject":{
    "backToList":"返回列表",
    "management":"投诉管理",
    "add":"新增",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "complaintSubject":"投诉主题",
    "complaintDec":"投诉主题描述",
    "submit":"确认提交",
  },
  "comment":{
    "management":"评价管理",
    "goodsManagement":"商品交易评价管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"买家可在订单完成后对订单商品进行评价操作，评价信息将显示在对应的商品页面",
    "hideTips":"收起提示",
    "list":"商品评价列表",
    "total":"共",
    "records":"条记录",
    "batchDelete":"批量删除",
    "show":"显示",
    "hide":"隐藏",
    "refresh":"刷新数据",
    "content":"评论内容",
    "searchUser":"搜索用户",
    "search":"搜索",
    "user":"用户",
    "score":"评分",
    "image":"晒单图片",
    "goods":"商品",
    "time":"评论时间",
    "ip":"ip地址",
    "operation":"操作",
    "delete":"删除",
  },
  "complainDetail":{
    "backToList":"返回列表",
    "management":"投诉管理",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "pending":"待处理",
    "inConversation":"对话中",
    "arbitrating":"待仲裁",
    "completed":"已完成",
    "menuDetail":"订单详情",
    "storeName":"店铺名称：",
    "menuStatus":"订单状态：",
    "menuId":"订单号：",
    "menuTime":"下单时间：",
    "menuSum":"订单总额：",
    "complaintGoods":"投诉的商品",
    "goodsName":"商品名称",
    "num":"数量",
    "price":"价格",
    "complaintContent":"投诉内容",
    "complaintMessage":"投诉信息",
    "complaintStatus":"投诉状态：",
    "complaintTheme":"投诉主题：",
    "complainants":"投诉人：",
    "complaintEvidence":"投诉证据：",
    "noImg":"暂无图片",
    "complaintTime":"投诉时间：",
    "complaintDetail":"申诉详情",
    "complaintStore":"被投诉店铺：",
    "appealEvidence":"申诉证据：",
    "appealTime":"申诉时间：",
    "appealContent":"申诉内容：",
    "dialogDetail":"对话详情",
    "dialogHistory":"对话记录：",
    "shield":"屏蔽",
    "shielding":"该对话被管理员屏蔽",
    "publish":"发布对话",
    "refresh":"刷新对话",
    "complaintHandling":"投诉处理",
    "closeComplaint":"关闭投诉",
    "back":"返回",
    "handleAdvice":"处理意见：",
    "submit":"确认提交",
    "cancel":"取消",
    "handleDetail":"处理详情",
    "handleTime":"处理时间：",
  },
  "complainSubject":{
    "management":"投诉管理",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "new":"新投诉",
    "inConversation":"对话中",
    "arbitrating":"待仲裁",
    "completed":"已完成",
    "themeSetting":"主题设置",
    "timeSetting":"时效设置",
    "themeList":"商家主题列表",
    "total":"共",
    "records":"条记录",
    "add":"新增数据",
    "refresh":"刷新数据",
    "theme":"投诉主题",
    "describe":"投诉主题描述",
    "operation":"操作",
    "delete":"删除",
  },
  "complainTime":{
    "timeLimit":"投诉时效",
    "state":"单位为天，订单完成后开始计算，多少天内可以发起投诉",
    "submit":"确认提交",
  },
  "complains":{
    "management":"投诉管理",
    "arbitration":"商城对商品交易投诉管理及仲裁",
    "new":"新投诉",
    "inConversation":"对话中",
    "arbitrating":"待仲裁",
    "completed":"已完成",
    "themeSetting":"主题设置",
    "timeSetting":"时效设置",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"在投诉时效内，买家可对订单进行投诉，投诉主题由管理员在后台统一设置",
    "state2":"投诉时效可在系统设置处进行设置",
    "state3":"点击详细，可查看投诉。被投诉店铺可进行回复进行申诉。申诉成功后，投诉双方进行对话",
    "state4":"会员可确认结束纠纷单，若会员对于卖家的处理不满意可以申请仲裁，最后由后台管理员进行仲裁操作",
    "hideTips":"收起提示",
    "complaintList":"商家投诉列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "complainants":"投诉人",
    "store":"被投商家",
    "search":"搜索",
    "content":"投诉内容",
    "image":"投诉图片",
    "time":"投诉时间",
    "theme":"投诉主题",
    "respondentId":"投诉人ID",
    "storeId":"商家ID",
    "operation":"操作",
    "check":"查看",
  },
  "expose":{
    "management":"举报管理",
    "arbitration":"商城设置举报类型及处理被举报商品",
    "unhandled":"未处理",
    "handled":"已处理",
    "typeSetting":"类型设置",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"举报类型和举报主题由管理员在后台设置，在商品信息页会员可根据举报主题举报违规商品，点击详细，查看举报内容",
    "hideTips":"收起提示",
    "reportList":"待处理的举报列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "reporter":"举报人",
    "type":"举报类型",
    "theme":"举报主题",
    "goods":"举报商品",
    "image":"图片",
    "time":"举报时间",
    "involvedStore":"涉及商家",
    "reporterId":"举报人ID",
    "goodsId":"商品ID",
    "storeId":"商家ID",
    "result":"处理结果",
    "message":"处理信息",
    "operation":"操作",
    "handle":"处理",
  },
  "exposeDetail":{
    "backToList":"返回列表",
    "management":"举报管理",
    "handleReport":"处理举报",
    "arbitration":"商城设置举报类型及处理被举报商品",
    "reportMessage":"举报信息",
    "store":"被举报商家:",
    "evidence":"举报证据:",
    "goodsName":"商品名称",
    "type":"举报类型:",
    "theme":"举报主题:",
    "menuId":"相关订单号:",
    "describe":"违规描述",
    "result":"处理结果",
    "state1":"无效举报--商品会正常销售",
    "state2":"恶意举报--该用户的所有未处理举报将被取消",
    "state3":"有效举报--商品将违规下架",
    "handleMessage":"处理信息",
    "submit":"确认提交",
  },
  "exposeSubject":{
    "management":"举报管理",
    "arbitration":"商城设置举报类型及处理被举报商品",
    "unhandled":"未处理",
    "handled":"已处理",
    "typeSetting":"类型设置",
    "themeSetting":"主题设置",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"可在同一举报类型下添加多个举报主题",
    "hideTips":"收起提示",
    "themeList":"举报主题列表",
    "total":"共",
    "records":"条记录",
    "add":"新增数据",
    "refresh":"刷新数据",
    "type":"举报类型",
    "theme":"举报主题",
    "status":"状态",
    "operation":"操作",
    "delete":"删除",
    "typeList":"举报类型列表",
    "describe":"举报类型描述",
  },
  "founds":{
    "groupList":"拼团列表",
    "inquiryAndManagement":"商城拼团列表查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"点击查看操作将显示订单（包括订单物品）的详细信息",
    "state2":"点击取消操作可以取消订单（在线支付但未付款的订单和货到付款但未发货的订单）",
    "state3":"如果平台已确认收到买家的付款，但系统支付状态并未变更，可以点击收到货款操作(仅限于下单后7日内可更改收款状态)，并填写相关信息后更改订单支付状态",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "groupStatus":"拼团状态",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "search":"搜索",
    "orderId":"订单编号",
    "receiver":"收货人",
    "sum":"总金额",
    "payable":"应付金额",
    "menuStatus":"订单状态",
    "payStatus":"支付状态",
    "deliverStatus":"发货状态",
    "payMethod":"支付方式",
    "sendMethod":"配送方式",
    "orderTime":"下单时间",
    "store":"店铺",
    "operation":"操作",
    "menuDetail":"订单详情",
    "checkMenu":"查看拼单",
  },
  "goodsConsult":{
    "management":"咨询管理",
    "showAndhHide":"管理咨询/内容显示与隐藏",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员可在商品信息页对商品进行咨询，系统设置处可设置游客是否能够咨询",
    "hideTips":"收起提示",
    "consultList":"商品咨询列表",
    "total":"共",
    "records":"条记录",
    "batchDelete":"批量删除",
    "show":"显示",
    "hide":"隐藏",
    "refresh":"刷新数据",
    "content":"咨询内容",
    "searchUser":"搜索用户",
    "search":"搜索",
    "user":"用户",
    "goods":"商品",
    "time":"咨询时间",
    "operation":"操作",
    "delete":"删除",
  },
  "index":{
    "management":"订单管理",
    "orderList":"订单列表",
    "groupList":"拼团列表",
    "groupMenu":"拼团订单",
    "orderLog":"订单日志",
    "orderHandling":"订单处理",
    "refunds":"退款单",
    "returns":"退换货",
    "invoice":"发票管理",
    "barter":"换货维修",
    "returnGoods":"售后退货",
    "comment":"商品评论",
    "consult":"商品咨询",
    "complaint":"投诉管理",
    "report":"举报管理",
  },
  "invoice":{
    "management":"发票管理",
    "inquiryAndManagement":"商城发票查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "checkAll":"查看所有发票记录",
    "hideTips":"收起提示",
    "list":"发票列表",
    "total":"共",
    "records":"条记录",
    "state":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出发票",
    "refresh":"刷新数据",
    "toInvoice":"待开发票:",
    "invoiced":"累计开票:",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "status":"发票状态",
    "search":"搜索",
    "invoiceId":"发票编号",
    "orderId":"订单编号",
    "username":"用户名",
    "store":"店铺",
    "type":"发票类型",
    "amountOfMoney":"开票金额",
    "rise":"发票抬头",
    "content":"发票内容",
    "taxRate":"发票税率",
    "identificationNum":"纳税人识别号",
    "createTime":"创建时间",
    "billingTime":"开票时间",
    "operation":"操作",
    "check":"查看",
  },
  "orderDetail":{
    "backToList":"返回列表",
    "goodsOrder":"商品订单",
    "inquiryAndManagement":"商城实物商品交易订单查询及管理",
    "printInvoice":"打印配货单",
    "basicMessage":"基本信息",
    "order":"订单",
    "orderId":"订单号：",
    "member":"会员：",
    "telephone":"电话：",
    "payable":"应付金额：",
    "orderStatus":"订单状态",
    "cashOnDelivery":"货到付款",
    "orderTime":"下单时间：",
    "payTime":"支付时间：",
    "payMethod":"支付方式：",
    "rise":"发票抬头：",
    "identificationNum":"纳税人识别号：",
    "receiveInfo":"收货信息",
    "receiver":"收货人：",
    "contact":"联系方式：",
    "shippingAddress":"收货地址：",
    "postcode":"邮编：",
    "sendMethod":"配送方式：",
    "selfRaising":"自提",
    "cancelMessage":"取消订单留言：",
    "logisticsInfo":"物流信息",
    "operateTime":"操作时间",
    "status":"状态",
    "costInfo":"费用信息",
    "subtotal":"小计：",
    "freight":"运费：",
    "integral":"积分",
    "balance":"余额抵扣：",
    "coupon":"优惠券抵扣：",
    "priceAdjust":"价格调整：",
    "preferential":"订单优惠：",
    "copeWith":"应付：",
    "taxes_dues":"税费：",
    "tip":"小费：",
    "goodsMessage":"商品信息",
    "goodsId":"商品编号",
    "goods":"商品",
    "spec":"规格属性",
    "num":"数量",
    "price":"单品价格",
    "discount":"会员折扣价",
    "singleSubtotal":"单品小计",
    "refundProcessing":"退款处理",
    "auditMind":"审核意见：",
    "agree":"同意退款",
    "disagree":"拒绝退款",
    "refundMethod":"退款方式：",
    "backToBalance":"退到用户余额",
    "backToOrigin":"支付原路退回",
    "notSupport":"不支持",
    "handleRemark":"处理备注：",
    "submit":"确认提交",
    "refundMessage":"退款信息：",
    "refunded":"已退款",
    "record":"操作记录",
    "payStatus":"付款状态",
    "deliverStatus":"发货状态",
    "describe":"描述",
    "remark":"备注",
    "selfRaised":"已自提",
    "toSelfRaise":"待自提",
    "printOrder":"打印订单",
    "state":"选择喷墨或激光打印机，根据下列纸张描述进行，设置并打印发货单据",
    "print":"打印",
    "consigneeInfo":"收货人信息",
    "addressee":"收件人：",
    "phone":"联系电话：",
    "email":"邮箱：",
    "orderDetail":"订单详情",
    "orderDate":"下单日期：",
    "orderSum":"订单总价：",
    "goodsPrice":"商品价格：",
    "delivery":"配送费用：",
    "useIntegral":"使用积分：",
    "useAmount":"使用余额：",
    "goodsName":"商品名称",
    "goodsCode":"商品货号",
    "unitPrice":"单价",
  },
  "orderLog":{
    "orderLog":"订单日志",
    "history":"订单操作日志包括商家操作订单记录",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "operationLog":"订单操作日志",
    "hideTips":"收起提示",
    "to":"订单列表",
    "total":"共",
    "records":"条记录",
    "batchDelete":"批量删除",
    "refresh":"刷新数据", 
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "operaterId":"操作员id",
    "search":"搜索",
    "orderId":"订单ID",
    "operate":"操作",
    "operater":"操作员",
    "remark":"操作备注",
    "time":"操作时间",
    "check":"查看",
    "delete":"删除",
  },
  "orders":{
    "goodsOrder":"商品订单",
    "inquiryAndManagement":"商城实物商品交易订单查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"点击查看操作将显示订单（包括订单物品）的详细信息",
    "state2":"如果平台已确认收到买家的付款，但系统支付状态并未变更，可以点击收到货款操作(仅限于下单后7日内可更改收款状态)，并填写相关信息后更改订单支付状态",
    "hideTips":"收起提示",
    "orderList":"订单列表",
    "total":"共",
    "records":"条记录",
    "state3":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出订单",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "payStatus":"支付状态",
    "payMethod":"支付方式",    
    "deliverStatus":"发货状态",
    "orderStatus":"订单状态",
    "searchRelated":"相关搜索",
    "searchRelatedData":"搜索相关数据",
    "search":"搜索",
    "orderId":"订单编号",
    "receiver":"收货人",
    "sum":"总金额",
    "payable":"应付金额",
    "sendMethod":"配送方式",
    "orderTime":"下单时间",
    "store":"店铺",
    "operation":"操作",
    "check":"查看",
  },
  "refundOrder":{
    "list":"已付款并取消单列表",
    "all":"全部",
    "refundForm":"拼团退款单",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"未发货时用户操作取消的订单",
    "state2":"已付款并取消订单列表",
    "hideTips":"收起提示",
    "refundList":"取消退款单列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "orderId":"订单编号",
    "search":"搜索",
    "orderTime":"下单时间",
    "receiver":"收货人",
    "phone":"联系电话",
    "logisticsCost":"物流费用",
    "payTime":"支付时间",
    "orderSum":"订单总价",
    "handleStatus":"处理状态",
    "operation":"操作",
    "check":"查看",
  },
  "repairs":{
    "management":"退货退款管理",
    "applyAndAudit":"商品订单退货申请及审核处理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"买家提交申请，商家同意后，如果订单未发货，商家发货时按换货要求发，如果以发货，由买家与卖家协商快递费用，卖家收到买家换货商品确认之后再次发货",
    "hideTips":"收起提示",
    "list":"待处理的线上实物交易订单退货列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "searchRelatedData":"搜索相关数据",
    "search":"搜索",
    "buyerId":"买家会员id",
    "reason":"申请原因",
    "image":"申请图片",
    "describe":"问题描述",
    "remark":"商家处理备注",
    "auditTime":"商家审核时间",
    "orderId":"订单编号",
    "goodsId":"商品id",
    "involve":"涉及商品名称",
    "exchangeNum":"换货数量",
    "applyType":"申请类型",
    "applyTime":"申请时间",
    "storeHandle":"商家处理",
    "storeName":"店铺名称",
  },
  "return":{
    "management":"退货退款管理",
    "applyAndAudit":"商品订单退货申请及审核处理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",    
    "state":"买家提交申请，商家同意并经平台确认后，退款金额以预存款的形式返还给买家（充值卡部分只能退回到充值卡余额）。",
    "hideTips":"收起提示",
    "list":"待处理的线上实物交易订单退货列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"申请日期起始",
    "endTime":"申请日期截止",
    "payStatus":"支付状态",
    "orderId":"订单编号",
    "search":"搜索",
    "goodsName":"商品名称",
    "type":"类型",
    "applyTime":"申请时间",
    "status":"状态",
    "store":"店铺",
  },
  "returned":{
    "management":"退货退款管理",
    "applyAndAudit":"商品订单退货申请及审核处理",
    "pending":"待处理",
    "allRecords":"所有记录",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",    
    "state":"买家提交申请，商家同意并经平台确认后，退款金额以预存款的形式返还给买家（账户余额）（支付宝支付或者微信用户微信支付支持原路退回）。",
    "hideTips":"收起提示",
    "list":"待处理的线上实物交易订单退货列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"申请日期起始",
    "endTime":"申请日期截止",
    "searchRelatedData":"搜索相关数据",
    "orderId":"订单编号",
    "search":"搜索",
    "memberId":"买家会员id:",
    "memberName":"买家会员名:",
    "image":"申请图片:",
    "reason":"申请原因:",
    "refundNum":"退货数量:",
    "auditTime":"商家审核时间:",
    "orderId":"订单编号",
    "goodsId":"商品id",
    "goodsName":"商品名称",
    "store":"商家",
    "applyTime":"申请时间",
    "status":"状态",
    "operation":"操作",
    "check":"查看",
    "handle":"处理",
  },
  "returnedDetail":{
    "backToList":"返回列表",    
    "management":"退货管理",
    "state1":"查看退单“退单编号：",
    "appltAndAudit":"商品订单退货申请及审核处理",
    "refundApply":"买家退货退款申请",    
    "applyTime":"申请时间",    
    "goodsName":"商品名称",    
    "money":"退款金额",    
    "balance":"退还余额：",    
    "integral":"退还积分：",    
    "reason":"退货原因",    
    "num":"退货数量",    
    "state":"退货说明",    
    "upload":"凭证上传",    
    "handle":"商家退款退货处理",   
    "result":"审核结果",   
    "remark":"处理备注",   
    "time":"处理时间",   
    "payMessage":"订单支付信息",   
    "payMethod":"支付方式",   
    "orderSum":"订单总额",   
    "refundAudit":"平台退款审核",   
    "refundMethod":"退款方式",   
    "advanceDeposit1":"退到预存款",   
    "supportOrigin":"支付原路退还",   
    "remarkMes":"备注信息",   
    "toInput":"请输入内容",   
    "state2":"系统默认退款到“站内余额”，如果“在线退款”到原支付账号，建议在备注里说明，方便核对。",   
    "confirm":"平台确认",   
    "complete":"已完成",   
    "detail":"退款详细",
    "returnOnline":"在线原路退回",
    "stationPay":"站内余额支付",   
    "advanceDeposit2":"退还预存款",   
    "submit":"确认提交",
  },
  "team":{
    "groupOrder":"拼团订单",
    "inquiryAndManagement":"商城拼团列表查询及管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",    
    "state1":"点击查看操作将显示订单（包括订单物品）的详细信息",
    "state2":"点击取消操作可以取消订单（在线支付但未付款的订单和货到付款但未发货的订单）",
    "state3":"如果平台已确认收到买家的付款，但系统支付状态并未变更，可以点击收到货款操作(仅限于下单后7日内可更改收款状态)，并填写相关信息后更改订单支付状态",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "state4":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出订单",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "payStatus":"支付状态",
    "payMethod":"支付方式",    
    "deliverStatus":"发货状态",
    "orderStatus":"订单状态",
    "searchRelated":"相关搜索",
    "searchRelatedData":"搜索相关数据",
    "search":"搜索",
    "orderId":"订单编号",
    "receiver":"收货人",
    "sum":"总金额",
    "payable":"应付金额",
    "member":"团员",
    "groupStatus":"拼团状态",
    "sendMethod":"配送方式",
    "orderTime":"下单时间",
    "store":"店铺",
    "operation":"操作",
    "orderDetail":"订单详情",
    "groupActivity":"拼团活动",
    "groupMember":"拼团成员",
  },
}
