export default{
	data() {
		return {
			navBarIcon:[
				{type:1,img:require("@/static/images/iconhead1.png")},
				{type:2,img:require("@/static/images/iconhead2.png")},
				{type:3,img:require("@/static/images/iconhead3.png")},
				{type:4,img:require("@/static/images/iconhead4.png")},
				{type:5,img:require("@/static/images/iconhead5.png")},
				{type:6,img:require("@/static/images/iconhead6.png")},
				{type:7,img:require("@/static/images/iconhead8.png")},
				{type:8,img:require("@/static/images/iconhead9.png")},
				{type:9,img:require("@/static/images/iconhead10.png")},
			],
			childrenIcon:[
				{type:1,class:"iconshouji",text:"手机"},
				{type:2,class:"iconwenzhang-copy",text:"文章"},
				{type:3,class:"iconnews_icon",text:"新闻"},
				{type:4,class:"iconPC-",text:"PC"},
				{type:5,class:"iconguanggaoguanli",text:"广告"},
				{type:6,class:"iconshouye",text:"首页"},
				{type:7,class:"iconiconset0335",text:"页面"},
				{type:8,class:"icondingdan",text:"订单"},
				{type:9,class:"iconfenxiao",text:"分销"},
				{type:10,class:"iconshangcheng",text:"商城"},
				{type:11,class:"iconyingxiao",text:"营销"},
				{type:12,class:"iconshangjia",text:"商家"},
				{type:13,class:"icontiaoduzhongxin",text:"调度中心"},
				{type:14,class:"icontongji",text:"统计"},
				{type:15,class:"iconshezhi",text:"设置"},
				{type:16,class:"iconweixin",text:"微信"},
				{type:17,class:"iconquanxian",text:"权限"},
				{type:18,class:"iconyongjinguanli",text:"佣金管理"},
				{type:19,class:"icondenglu",text:"登录"},
				{type:20,class:"iconyunyingshuju",text:"运营数据"},
				{type:21,class:"iconshangpin",text:"商品"},
				{type:22,class:"iconjiaoyishuju",text:"交易数据"},
				{type:23,class:"icondata_members",text:"会员数据"},
				{type:24,class:"icondianpuguanli",text:"店铺管理"},
				{type:25,class:"iconcuxiao",text:"促销"},
				{type:26,class:"iconhuiyuan",text:"会员"},
				{type:27,class:"iconpintuangou",text:"拼团购"},
				{type:28,class:"iconyoujian",text:"邮件"},
				{type:29,class:"iconyouhuijuan",text:"优惠劵"},
				{type:30,class:"icondingdanchuli",text:"订单处理"},
				{type:31,class:"iconzhifu",text:"支付配置"},
				{type:32,class:"iconfenxiaoguanli",text:"分销管理"},
				{type:33,class:"iconzhifubao",text:"支付宝"},
				{type:34,class:"iconguanli",text:"订单管理"},
				{type:35,class:"iconshangpinpinglun",text:"商品评论"},
				{type:36,class:"icontuihuo",text:"退货"},
				{type:37,class:"iconshouhou-tuihuo",text:"售后退货"},
				{type:38,class:"iconjubaoguanli",text:"举报管理"},
				{type:39,class:"iconhuanhuo",text:"换货"},
				{type:40,class:"iconshangpinzixun",text:"商品咨询"},
				{type:41,class:"icontousuguanli",text:"投诉管理"},
				{type:42,class:"iconfapiao-",text:"发票"},
				{type:43,class:"icondingdanrizhi",text:"订单日志"},
				{type:44,class:"icontuikuandan",text:"退款单"},
				{type:45,class:"iconsms",text:"短信模板"},
				{type:46,class:"iconseo",text:"SEO"},
				{type:47,class:"iconxiaoxitongzhi",text:"消息通知"},
				{type:48,class:"iconfasongyoujian",text:"发送邮件"},
				{type:49,class:"iconkuaidi",text:"快递公司"},
				{type:50,class:"iconaddress",text:"地址"},
				{type:51,class:"iconwenzhangfenlei",text:"文章分类"},
				{type:52,class:"iconicon_xinyong_xianxing_jijin-",text:"友情链接"},
				{type:53,class:"iconren",text:"个人中心"},
				{type:54,class:"iconxinwenfenlei",text:"新闻分类"},

			]
		}
	},
	created() {
	},
	methods: {
		filterImg(item,type){
			let className;
			if(type == 'children') {
				Array.from(this.childrenIcon,b=>{
					if(item == b.type) {
						className = b.class;
					}
				})
			}
			return className;
		}
	},
}