export const en = {
  "salesRanking":{
    "saleTop":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"销量排行, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"销量排行列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "allSort":"所有分类",
      "to":"至",
      "startTime":"下单日期起始",
      "endTime":"下单日期截止",
      "search":"搜索",
      "ranking":"排行",
      "goodsName":"商品名称",
      "artNo":"货号",
      "saleNum":"销售量",
      "saleAmount":"销售额",
      "averagePrice":"均价",
    },
    "storeTop":{
      "type":"店铺类别",
      "id":"店铺ID",
      "storeName":"店铺名称",
      "orderNum":"订单数",
      "orderAmount":"订单总额",
    },
  },
  "expenseLog":{
    "log":"支出日志",
    "record":"平台支出结算日志记录",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"平台支出记录包括会员提现，商家提现，订单退款的处理日志以及平台跟商家或会员之间其他协商的退款操作日志",
    "hideTips":"收起提示",
    "list":"订单列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "administratorId":"管理员ID",
    "search":"搜索",
    "recordId":"记录id",
    "amount":"支出金额",
    "integral":"积分",
    "type":"支出类型",
    "relatedId":"业务关联id",
    "involved":"涉及人",
    "operator":"操作人",
    "time":"日志时间",
  },
  "finance":{
    "table":"统计报表",
    "financial":"财务概况",
    "webFinancial":"网站系统财务概况",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"分析商城成本,利润走势.",
    "state2":"统计分析每天订单商品总额,订单物流费用.",
    "hideTips":"收起提示",
    "trend":"成本利润走势",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "date":"日期",
    "totalGoods":"订单商品总额",
    "totalOffset":"优惠券抵用总额",
    "totalDiscount":"订单优惠总额",
    "totalCost":"成本总额",
    "totalLogistics":"物流总额",
    "operation":"操作",
    "check":"查看订单列表",
  },
  "financeOrder":{
    "operate":"运营概览",
    "webOperate":"网站系统运营概览",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析每日商品销售概况.",
    "hideTips":"收起提示",
    "list":"销售明细列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "id":"订单编号",
    "consignee":"收货人",
    "store":"店铺",
    "goodsTotal":"商品总价",
    "logisticsPrice":"物流价格",
    "menuTotal":"订单总价",
    "time":"下单时间",
  },
  "index":{
    "transactionData":"交易数据",
    "salesProfile":"销售概况",
    "salesRanking":"销售排行",
    "salesDetails":"销售明细",
    "operationalData":"运营数据",
    "operationalProfile":"运营概况",
    "storeWithdrawal":"商家提现申请",
    "storeTransfer":"商家转款列表",
    "settlementRecord":"商家结算记录",
    "expenditureRecords":"平台支出记录",
    "memberData":"会员数据",
    "memberStatistics":"会员统计",
    "memberRanking":"会员排行",
    "memberWithdrawal":"会员提现申请",
    "memberTransfer":"会员转款列表",
  },
  "orderStatis":{
    "management":"商家结算管理",
    "indexAndManagement":"网站系统商家结算索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"商家订单按时自动结算",
    "hideTips":"收起提示",
    "list":"商家结算列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"开始日期",
    "endTime":"结束日期",
    "storeName":"店铺名称",
    "search":"搜索",
    "recordId":"记录ID:",
    "storeId":"店铺ID:",
    "createDate":"创建记录日期:",
    "goodsAmount":"订单商品金额",
    "storeTip":"商家小费",
    "platformTip":"平台小费",
    "fare":"运费",
    "pointsAmount":"积分金额",
    "distributionAmount":"分销金额",
    "concessionalRate":"优惠价",
    "couponDeduction":"优惠券抵扣",
    "dueForCurrent":"本期应结",
    "range":"结算日期范围",
    "operation":"操作",
    "check":"查看订单",
  },
  "report":{
    "table":"统计报表",
    "sale":"销售概况",
    "webSale":"网站系统销售概况",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析历史订单交易数, 订单金额, 客单价",
    "hideTips":"收起提示",
    "salesToday":"今日销售总额：",
    "perCustomerPrice":"人均客单价：",
    "orderToday":"今日订单数：",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "date":"日期",
    "orderNum":"订单数",
    "saleTotal":"销售总额",
    "customerPrice":"客单价",
    "operation":"操作",
    "check":"查看订单列表",
    "statement":"ＴＰｓｈｏｐ　深 圳 搜 豹 网 络 公 司 版 权 所 有，盗 版 必 究",
  },
  "reportOrder":{
    "detail":"销售明细",
    "webDetail":"网站系统销售明细",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析每日商品销量排行",
    "hideTips":"收起提示",
    "list":"销售明细列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "id":"订单编号",
    "consignee":"收货人",
    "store":"店铺",
    "goodsTotal":"商品总价",
    "logisticsPrice":"物流价格",
    "menuTotal":"订单总价",
    "time":"下单时间",
  },
  "saleList":{
    "detail":"销售明细",
    "webDetail":"网站系统销售明细",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析每日商品销量排行",
    "hideTips":"收起提示",
    "list":"销售明细列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "allSort":"所有分类",
    "allBrand":"所有品牌",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "search":"搜索",
    "name":"商品名称",
    "artNo":"商品货号",
    "num":"数量",
    "price":"售价",
    "saleDate":"出售日期",
  },
  "_salesRanking":{
    "reportStatistics":"报表统计",
    "salesRanking":"销量排行",
    "webReportStatistics":"网站系统报表统计",
    "goodsSalesRanking":"商品销售排行",
    "storeOrderRanking":"店铺订单数排行",
    "storeTurnoverRanking":"店铺成交额排行",
  },
  "storeOrderContent":{
    "backToList":"返回列表",
    "goodsOrder":"商品订单",
    "InquiryAndManagement":"商城实物商品交易订单查询及管理",
    "print":"打印订单",
    "basicInfo":"基本信息",
    "orderId":"订单 ID：",
    "orderNum":"订单号：",
    "member":"会员：",
    "phone":"电话：",
    "payable":"应付金额：",
    "orderStatus":"订单状态：",
    "cashOnDelivery":"货到付款",
    "orderTime":"下单时间：",
    "payTime":"支付时间：",
    "payMethod":"支付方式：",
    "rise":"发票抬头：",
    "identificationNum":"纳税人识别号：",
    "receiveInfo":"收货信息",
    "receiver":"收货人：",
    "contact":"联系方式：",
    "shippingAddress":"收货地址：",
    "postcode":"邮编：",
    "sendMethod":"配送方式：",
    "selfRaising":"自提",
    "logisticsInfo":"物流信息",
    "operateTime":"操作时间",
    "status":"状态",
    "costInfo":"费用信息",
    "subtotal":"小计：",
    "freight":"运费：",
    "integral":"积分",
    "balance":"余额抵扣：",
    "coupon":"优惠券抵扣：",
    "priceAdjust":"价格调整：",
    "preferential":"订单优惠：",
    "copeWith":"应付：",
    "goodsInfo":"商品信息",
    "goodsId":"商品编号",
    "goods":"商品",
    "spec":"规格属性",
    "num":"数量",
    "price":"单品价格",
    "discount":"会员折扣价",
    "singleSubtotal":"单品小计",
    "orderSum":"订单总额：",
    "record":"操作记录",
    "operator":"操作者",
    "payStatus":"付款状态",
    "deliverStatus":"发货状态",
    "describe":"描述",
    "remark":"备注",
    "selfRaised":"已自提",
    "toSelfRaise":"待自提",
  },
  "storeRemittanceList":{
    "remittanceRecord":"商家汇款记录",
    "indexAndManagement":"网站系统商家汇款索引与管理",
    "toTransferList":"待转账列表",
    "transferredList":"已转账列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"支付宝，微信在线转账会接收到付款成功通知，自动完成转账，银行卡转账则需要手动确认完成",
    "state2":"支付宝，微信支付接口支持在线向用户付款完成转账，银行卡提现请通过网银在线转账或者其他方式处理",
    "state3":"手动完成转账是指通过银行打款或其他转款方式处理了该笔提现申请，手动这一步操作只是标示该申请提现流程已处理完",
    "hideTips":"收起提示",
    "list":"商家汇款记录列表",
    "total":"共",
    "records":"条记录",
    "export":"导出Excel",
    "onlineTransferred":"在线完成转账",
    "manualTransferred":"手动完成转账",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "storeId":"店铺ID",
    "bankAccount1":"银行账户",
    "bankAccount2":"银行账号",
    "search":"搜索",
    "recordId":"记录ID",
    "storeName":"店铺名称",
    "bankName":"银行名称",
    "applyAmount":"申请金额",
    "status":"状态",
    "operateTime":"操作时间",
    "remark":"备注",
    "operate":"操作",
    "onlineTransfer":"在线转账",
    "manualTransfer":"手动转账",
  },
  "storeWithdrawals":{
    "management":"提现申请管理",
    "indexAndManagement":"网站系统提现申请索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"支付宝，微信在线转账需要申请相关支付接口以及设置管理员支付密码",
    "state2":"拒绝提现的申请记录才能删除",
    "state3":"审核通过的提现申请会进入待付款列表",
    "hideTips":"收起提示",
    "list":"店铺列表",
    "total":"共",
    "records":"条记录",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "status":"状态",
    "storeId":"店铺ID",
    "bankAccount1":"银行账户",
    "bankAccount2":"银行账号",
    "search":"搜索",
    "applyId":"申请ID",
    "storeName":"店铺名称",
    "applyTime":"申请时间",
    "applyAmount":"申请金额",
    "bankName":"银行名称",
    "operate":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "storeWithdrawalsDetails":{
    "backToList":"返回列表",
    "management":"财务管理",
    "apply":"提现申请",
    "webManagementApply":"网站系统财务管理提现申请",
    "storeId":"店铺ID",
    "storeName":"店铺名",
    "withdrawalBalance":"店铺可提现余额",
    "withdrawalMoney":"申请提现金额",
    "withdrawalBank":"申请提现银行",
    "bankAccount":"银行账号",
    "bankAccountName":"银行账户名",
    "applyTime":"申请时间",
    "status":"状态",
    "handle":"处理",
    "toAudit":"待审核",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "remark":"备注",
    "toInput":"请输入内容",
    "process":"提现流程",
    "state1":"1:店家前台申请提现",
    "state2":"2:管理员审核通过",
    "state3":"3:进入财务待转款列表",
    "state4":"4:财务管理员给用户转账(转帐时自动扣除用户平台余额 )",
    "submit":"确认提交",
  },
  "userRemittance":{
    "remittanceRecord":"会员汇款记录",
    "indexAndManagement":"网站系统会员汇款索引与管理",
    "list":"会员汇款转账列表",
    "userId":"用户ID",
    "accountName":"账户开户名",
    "collectionAccount":"收款账号",
    "nickname":"用户昵称",
    "accountNum":"账户号码",
    "institutionName":"银行机构名称",
    "toTransfer":"待转账",
    "transferred":"已转账",
    "auditTime":"审核时间",
    "withdrawalType":"提现类型",
  },
  "userReport":{    
    "table":"统计报表",
    "statistics":"会员统计",
    "webStatistics":"网站系统会员统计",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析新增会员数量, 会员历史走势.",
    "hideTips":"收起提示",
    "todayMember":"今日新增会员：",
    "monthMember":"本月新增会员：",
    "totalMembers":"会员总数：",
    "totalBalance":"会员余额总额：",
    "consumerMembersNum":"产生消费会员数：",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
  },
  "userTop":{
    "table":"统计报表",
    "memberRanking":"会员排行",
    "webMemberRanking":"网站系统会员排行",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析会员贡献订单数量和消费金额",
    "hideTips":"收起提示",
    "rankingList":"会员排行榜",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "phoneNum":"手机号码",
    "search":"搜索",
    "ranking":"排行",
    "name":"会员名称",
    "phone":"会员手机",
    "email":"会员邮箱",
    "orderNum":"订单数",
    "purchaseAmount":"购物金额",
    "operation":"操作",
    "check":"查看",
  },
  "userWithdrawals":{
    "records":"会员提现申请记录",
    "indexAndManagement":"网站系统会员提现申请记录索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"支付宝，微信在线转账需要申请相关支付接口以及设置管理员支付密码",
    "state2":"拒绝提现的申请记录才能删除",
    "state3":"审核通过的提现申请会进入待付款列表",
    "hideTips":"收起提示",
    "list":"会员提现申请记录列表",
    "total":"共",
    "records":"条记录",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "status":"状态",
    "userId":"用户ID",
    "realName":"收款账号真实姓名",
    "bankAccount":"银行账号",
    "search":"搜索",
    "applyId":"申请ID",
    "nickname":"用户昵称",
    "applyTime":"申请时间",
    "applyAmount":"申请金额",
    "institutionName":"银行机构名称",
    "accountNum":"账户号码",
    "accountName":"账户开户名",
    "type":"提现类型",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "userWithdrawalsDetails":{
    "backToList":"返回列表",
    "management":"财务管理",
    "apply":"提现申请",
    "webManagementApply":"网站系统财务管理提现申请",
    "userId":"用户id",
    "username":"用户名",
    "platformBanlance":"用户平台余额",
    "freeze":"冻结资金",
    "withdrawalAmount":"申请提现金额",
    "serviceCharge":"扣除手续费",
    "actualReceived":"实际到账金额",
    "institutionName":"提现银行机构名称",
    "accountNum":"提现账户号码",
    "accountName":"提现账户开户名",
    "applyTime":"申请时间",
    "status":"状态",
    "toAudit":"待审核",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "remark":"备注",
    "toInput":"请输入内容",
    "process":"提现流程",
    "state1":"1:用户前台申请提现",
    "state2":"2:管理员审核通过",
    "state3":"3:进入财务待转款列表",
    "state4":"4:财务管理员给用户转账(转帐时自动扣除用户平台余额 )",
    "submit":"确认提交",
  },
}

export const zh = {
  "salesRanking":{
    "saleTop":{
      "tipsNode":"提示相关设置操作时应注意的要点",
      "tips":"操作提示",
      "state":"销量排行, 由总平台设置管理.",
      "hideTips":"收起提示",
      "list":"销量排行列表",
      "total":"共",
      "records":"条记录",
      "refresh":"刷新数据",
      "allSort":"所有分类",
      "to":"至",
      "startTime":"下单日期起始",
      "endTime":"下单日期截止",
      "search":"搜索",
      "ranking":"排行",
      "goodsName":"商品名称",
      "artNo":"货号",
      "saleNum":"销售量",
      "saleAmount":"销售额",
      "averagePrice":"均价",
    },
    "storeTop":{
      "type":"店铺类别",
      "id":"店铺ID",
      "orderName":"店铺名称",
      "orderNum":"订单数",
      "orderAmount":"订单总额",
    },
  },
  "expenseLog":{
    "log":"支出日志",
    "record":"平台支出结算日志记录",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"平台支出记录包括会员提现，商家提现，订单退款的处理日志以及平台跟商家或会员之间其他协商的退款操作日志",
    "hideTips":"收起提示",
    "list":"订单列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "administratorId":"管理员ID",
    "search":"搜索",
    "recordId":"记录id",
    "amount":"支出金额",
    "integral":"积分",
    "type":"支出类型",
    "relatedId":"业务关联id",
    "involved":"涉及人",
    "operator":"操作人",
    "time":"日志时间",
  },
  "finance":{
    "table":"统计报表",
    "financial":"财务概况",
    "webFinancial":"网站系统财务概况",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"分析商城成本,利润走势.",
    "state2":"统计分析每天订单商品总额,订单物流费用.",
    "hideTips":"收起提示",
    "trend":"成本利润走势",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "date":"日期",
    "totalGoods":"订单商品总额",
    "totalOffset":"优惠券抵用总额",
    "totalDiscount":"订单优惠总额",
    "totalCost":"成本总额",
    "totalLogistics":"物流总额",
    "operation":"操作",
    "check":"查看订单列表",
  },
  "financeOrder":{
    "operate":"运营概览",
    "webOperate":"网站系统运营概览",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析每日商品销售概况.",
    "hideTips":"收起提示",
    "list":"销售明细列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "id":"订单编号",
    "consignee":"收货人",
    "store":"店铺",
    "goodsTotal":"商品总价",
    "logisticsPrice":"物流价格",
    "menuTotal":"订单总价",
    "time":"下单时间",
  },
  "index":{
    "transactionData":"交易数据",
    "salesProfile":"销售概况",
    "salesRanking":"销售排行",
    "salesDetails":"销售明细",
    "operationalData":"运营数据",
    "operationalProfile":"运营概况",
    "storeWithdrawal":"商家提现申请",
    "storeTransfer":"商家转款列表",
    "settlementRecord":"商家结算记录",
    "expenditureRecords":"平台支出记录",
    "memberData":"会员数据",
    "memberStatistics":"会员统计",
    "memberRanking":"会员排行",
    "memberWithdrawal":"会员提现申请",
    "memberTransfer":"会员转款列表",
  },
  "orderStatis":{
    "management":"商家结算管理",
    "indexAndManagement":"网站系统商家结算索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"商家订单按时自动结算",
    "hideTips":"收起提示",
    "list":"商家结算列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"开始日期",
    "endTime":"结束日期",
    "storeName":"店铺名称",
    "search":"搜索",
    "recordId":"记录ID:",
    "storeId":"店铺ID:",
    "createDate":"创建记录日期:",
    "goodsAmount":"订单商品金额",
    "storeTip":"商家小费",
    "platformTip":"平台小费",
    "fare":"运费",
    "pointsAmount":"积分金额",
    "distributionAmount":"分销金额",
    "concessionalRate":"优惠价",
    "couponDeduction":"优惠券抵扣",
    "dueForCurrent":"本期应结",
    "range":"结算日期范围",
    "operation":"操作",
    "check":"查看订单",
  },
  "report":{
    "table":"统计报表",
    "sale":"销售概况",
    "webSale":"网站系统销售概况",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析历史订单交易数, 订单金额, 客单价",
    "hideTips":"收起提示",
    "salesToday":"今日销售总额：",
    "perCustomerPrice":"人均客单价：",
    "orderToday":"今日订单数：",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "date":"日期",
    "orderNum":"订单数",
    "saleTotal":"销售总额",
    "customerPrice":"客单价",
    "operation":"操作",
    "check":"查看订单列表",
    "statement":"ＴＰｓｈｏｐ　深 圳 搜 豹 网 络 公 司 版 权 所 有，盗 版 必 究",
  },
  "reportOrder":{
    "detail":"销售明细",
    "webDetail":"网站系统销售明细",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析每日商品销量排行",
    "hideTips":"收起提示",
    "list":"销售明细列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "id":"订单编号",
    "consignee":"收货人",
    "store":"店铺",
    "goodsTotal":"商品总价",
    "logisticsPrice":"物流价格",
    "menuTotal":"订单总价",
    "time":"下单时间",
  },
  "saleList":{
    "detail":"销售明细",
    "webDetail":"网站系统销售明细",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析每日商品销量排行",
    "hideTips":"收起提示",
    "list":"销售明细列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "allSort":"所有分类",
    "allBrand":"所有品牌",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "search":"搜索",
    "name":"商品名称",
    "artNo":"商品货号",
    "num":"数量",
    "price":"售价",
    "saleDate":"出售日期",
  },
  "_salesRanking":{
    "reportStatistics":"报表统计",
    "salesRanking":"销量排行",
    "webReportStatistics":"网站系统报表统计",
    "goodsSalesRanking":"商品销售排行",
    "storeOrderRanking":"店铺订单数排行",
    "storeTurnoverRanking":"店铺成交额排行",
  },
  "storeOrderContent":{
    "backToList":"返回列表",
    "goodsOrder":"商品订单",
    "InquiryAndManagement":"商城实物商品交易订单查询及管理",
    "print":"打印订单",
    "basicInfo":"基本信息",
    "orderId":"订单 ID：",
    "orderNum":"订单号：",
    "member":"会员：",
    "phone":"电话：",
    "payable":"应付金额：",
    "orderStatus":"订单状态：",
    "cashOnDelivery":"货到付款",
    "orderTime":"下单时间：",
    "payTime":"支付时间：",
    "payMethod":"支付方式：",
    "rise":"发票抬头：",
    "identificationNum":"纳税人识别号：",
    "receiveInfo":"收货信息",
    "receiver":"收货人：",
    "contact":"联系方式：",
    "shippingAddress":"收货地址：",
    "postcode":"邮编：",
    "sendMethod":"配送方式：",
    "selfRaising":"自提",
    "logisticsInfo":"物流信息",
    "operateTime":"操作时间",
    "status":"状态",
    "costInfo":"费用信息",
    "subtotal":"小计：",
    "freight":"运费：",
    "integral":"积分",
    "balance":"余额抵扣：",
    "coupon":"优惠券抵扣：",
    "priceAdjust":"价格调整：",
    "preferential":"订单优惠：",
    "copeWith":"应付：",
    "goodsInfo":"商品信息",
    "goodsId":"商品编号",
    "goods":"商品",
    "spec":"规格属性",
    "num":"数量",
    "price":"单品价格",
    "discount":"会员折扣价",
    "singleSubtotal":"单品小计",
    "orderSum":"订单总额：",
    "record":"操作记录",
    "operator":"操作者",
    "payStatus":"付款状态",
    "deliverStatus":"发货状态",
    "describe":"描述",
    "remark":"备注",
    "selfRaised":"已自提",
    "toSelfRaise":"待自提",
  },
  "storeRemittanceList":{
    "remittanceRecord":"商家汇款记录",
    "indexAndManagement":"网站系统商家汇款索引与管理",
    "toTransferList":"待转账列表",
    "transferredList":"已转账列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"支付宝，微信在线转账会接收到付款成功通知，自动完成转账，银行卡转账则需要手动确认完成",
    "state2":"支付宝，微信支付接口支持在线向用户付款完成转账，银行卡提现请通过网银在线转账或者其他方式处理",
    "state3":"手动完成转账是指通过银行打款或其他转款方式处理了该笔提现申请，手动这一步操作只是标示该申请提现流程已处理完",
    "hideTips":"收起提示",
    "list":"商家汇款记录列表",
    "total":"共",
    "records":"条记录",
    "export":"导出Excel",
    "onlineTransferred":"在线完成转账",
    "manualTransferred":"手动完成转账",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "storeId":"店铺ID",
    "bankAccount1":"银行账户",
    "bankAccount2":"银行账号",
    "search":"搜索",
    "recordId":"记录ID",
    "storeName":"店铺名称",
    "bankName":"银行名称",
    "applyAmount":"申请金额",
    "status":"状态",
    "operateTime":"操作时间",
    "remark":"备注",
    "operate":"操作",
    "onlineTransfer":"在线转账",
    "manualTransfer":"手动转账",
  },
  "storeWithdrawals":{
    "management":"提现申请管理",
    "indexAndManagement":"网站系统提现申请索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"支付宝，微信在线转账需要申请相关支付接口以及设置管理员支付密码",
    "state2":"拒绝提现的申请记录才能删除",
    "state3":"审核通过的提现申请会进入待付款列表",
    "hideTips":"收起提示",
    "list":"店铺列表",
    "total":"共",
    "records":"条记录",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "status":"状态",
    "storeId":"店铺ID",
    "bankAccount1":"银行账户",
    "bankAccount2":"银行账号",
    "search":"搜索",
    "applyId":"申请ID",
    "storeName":"店铺名称",
    "applyTime":"申请时间",
    "applyAmount":"申请金额",
    "bankName":"银行名称",
    "operate":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "storeWithdrawalsDetails":{
    "backToList":"返回列表",
    "management":"财务管理",
    "apply":"提现申请",
    "webManagementApply":"网站系统财务管理提现申请",
    "storeId":"店铺ID",
    "storeName":"店铺名",
    "withdrawalBalance":"店铺可提现余额",
    "withdrawalMoney":"申请提现金额",
    "withdrawalBank":"申请提现银行",
    "bankAccount":"银行账号",
    "bankAccountName":"银行账户名",
    "applyTime":"申请时间",
    "status":"状态",
    "handle":"处理",
    "toAudit":"待审核",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "remark":"备注",
    "toInput":"请输入内容",
    "process":"提现流程",
    "state1":"1:店家前台申请提现",
    "state2":"2:管理员审核通过",
    "state3":"3:进入财务待转款列表",
    "state4":"4:财务管理员给用户转账(转帐时自动扣除用户平台余额 )",
    "submit":"确认提交",
  },
  "userRemittance":{
    "remittanceRecord":"会员汇款记录",
    "indexAndManagement":"网站系统会员汇款索引与管理",
    "list":"会员汇款转账列表",
    "userId":"用户ID",
    "accountName":"账户开户名",
    "collectionAccount":"收款账号",
    "nickname":"用户昵称",
    "accountNum":"账户号码",
    "institutionName":"银行机构名称",
    "toTransfer":"待转账",
    "transferred":"已转账",
    "auditTime":"审核时间",
    "withdrawalType":"提现类型",
  },
  "userReport":{
    "table":"统计报表",
    "statistics":"会员统计",
    "webStatistics":"网站系统会员统计",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析新增会员数量, 会员历史走势.",
    "hideTips":"收起提示",
    "todayMember":"今日新增会员：",
    "monthMember":"本月新增会员：",
    "totalMembers":"会员总数：",
    "totalBalance":"会员余额总额：",
    "consumerMembersNum":"产生消费会员数：",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
  },
  "userTop":{
    "table":"统计报表",
    "memberRanking":"会员排行",
    "webMemberRanking":"网站系统会员排行",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"统计分析会员贡献订单数量和消费金额",
    "hideTips":"收起提示",
    "rankingList":"会员排行榜",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "phoneNum":"手机号码",
    "search":"搜索",
    "ranking":"排行",
    "name":"会员名称",
    "phone":"会员手机",
    "email":"会员邮箱",
    "orderNum":"订单数",
    "purchaseAmount":"购物金额",
    "operation":"操作",
    "check":"查看",
  },
  "userWithdrawals":{
    "records":"会员提现申请记录",
    "indexAndManagement":"网站系统会员提现申请记录索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"支付宝，微信在线转账需要申请相关支付接口以及设置管理员支付密码",
    "state2":"拒绝提现的申请记录才能删除",
    "state3":"审核通过的提现申请会进入待付款列表",
    "hideTips":"收起提示",
    "list":"会员提现申请记录列表",
    "total":"共",
    "records":"条记录",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"下单日期起始",
    "endTime":"下单日期截止",
    "status":"状态",
    "userId":"用户ID",
    "realName":"收款账号真实姓名",
    "bankAccount":"银行账号",
    "search":"搜索",
    "applyId":"申请ID",
    "nickname":"用户昵称",
    "applyTime":"申请时间",
    "applyAmount":"申请金额",
    "institutionName":"银行机构名称",
    "accountNum":"账户号码",
    "accountName":"账户开户名",
    "type":"提现类型",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "userWithdrawalsDetails":{
    "backToList":"返回列表",
    "management":"财务管理",
    "apply":"提现申请",
    "webManagementApply":"网站系统财务管理提现申请",
    "userId":"用户id",
    "username":"用户名",
    "platformBanlance":"用户平台余额",
    "freeze":"冻结资金",
    "withdrawalAmount":"申请提现金额",
    "serviceCharge":"扣除手续费",
    "actualReceived":"实际到账金额",
    "institutionName":"提现银行机构名称",
    "accountNum":"提现账户号码",
    "accountName":"提现账户开户名",
    "applyTime":"申请时间",
    "status":"状态",
    "toAudit":"待审核",
    "adopt":"审核通过",
    "fail":"审核失败",
    "void":"无效作废",
    "remark":"备注",
    "toInput":"请输入内容",
    "process":"提现流程",
    "state1":"1:用户前台申请提现",
    "state2":"2:管理员审核通过",
    "state3":"3:进入财务待转款列表",
    "state4":"4:财务管理员给用户转账(转帐时自动扣除用户平台余额 )",
    "submit":"确认提交",
  },
}
