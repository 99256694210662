export const en = {
  "explain":{
    "intorduce":"Process Introduction",
    "illustration":"Platform main process description",
    "role":"role introduction",
    "resource":"Member Source",
    "quickLogin":"Quick Login",
    "settlement":"Business settled in",
    "shopping":"Shopping Process",
    "makeGroup":"Group Joining Process",
    "afterSale": "Order After Sale",
    "withdrawal":"User withdrawal",
    "settleAccount":"Merchant Settlement",
    "distributSetting":"DistributSetting",
    "tips":"Operation Tips",
    "tipsNode": "Points to pay attention to when prompting related setting operations",
    "hideTips":"HideTips",
    "state1": "Consumers can apply for an account through the platform's PC terminal, APP terminal, and WeChat mall entrance; the platform can add member information, adjust funds, etc.",
    "state2": "The merchant needs to become a member of the platform when applying for entry, and then fill in the entry information and wait for the platform to review; the platform can add its own store (joint operation).",
    "state3":"The platform can set the merchant’s settlement date and settlement commission ratio, and merchants can check the commission ratio they can allocate through their own background to facilitate reconciliation.",
  },
  "index":{
    "overview":"Overview",
    "backstage":"System Backstage",
    "process":"Business Process",
  },
  "system":{
    "center":"Management Center",
    "individual":"piece",
    "dailyMenu":"Number of new orders added today",
    "place":"place",
    "dailyMember":"Number of new members added today",
    "strip":"strip",
    "dailyComment":"Number of comments pending review today",
    "order":"order",
    "dailyVisit":"Today's Visits",
    "audit":"Store opening audit",
    "apply":"Brand Application",
    "goods":"Quantity of Goods",
    "article":"Number of Articles",
    "member":"Total number of members",
    "message":"Personal Member Information",
    "company":"Unit",
    "todayAdd": "Today Add",
    "yesterdayAdd":"Yesterday Add",
    "monthAdd":"Add this month",
    "menu":"Order Statistics",
    "sale":"Sales Statistics",
  },
}

export const zh = {
  "explain":{
    "intorduce":"流程介绍",
    "illustration":"平台主要流程说明",
    "role":"角色介绍",
    "resource":"会员来源",
    "quickLogin":"快捷登陆",
    "settlement":"商家入驻",
    "shopping":"购物流程",
    "makeGroup":"拼团流程",
    "afterSale":"订单售后",
    "withdrawal":"用户提现",
    "settleAccount":"商家结算",
    "distributSetting":"分销设置",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "hideTips":"收起提示",
    "state1":"消费者可以通过平台的PC端、APP端、微商城等前台入口申请账号；平台可自行添加会员信息、调整资金等。",
    "state2":"商家在进行入驻申请时需要先成为平台的会员，然后填写入驻信息、等待平台审核；平台可自行添加自营店铺（联营）。",
    "state3":"平台可设置商家的结算日期和结算佣金比例，商家可通过自己的后台查看自己能分到的佣金比例，方便对账。",
  },
  "index":{
    "overview":"概览",
    "backstage":"系统后台",
    "process":"业务流程",
  },
  "system":{
    "center":"管理中心",
    "individual":"个",
    "dailyMenu":"今日新增订单数",
    "place":"位",
    "dailyMember":"今日新增会员数",  
    "strip":"条",
    "dailyComment":"今日待审评论数",  
    "order":"次",
    "dailyVisit":"今日访问量",  
    "audit":"开店审核",  
    "apply":"品牌申请",  
    "goods":"商品数量",  
    "article":"文章数量",  
    "member":"会员总数",
    "message":"个人会员信息",  
    "company":"单位",  
    "todayAdd":"今日新增",  
    "yesterdayAdd":"昨日新增",  
    "monthAdd":"本月新增",   
    "menu":"订单统计",  
    "sale":"销售统计",   
  },
}
