
export const en = {
    "inputOldPassword":"请输入旧密码",
    "inputNewPassword":"请输入新密码",
    "inputAgain":"请再次输入新密码",
    "atypism":"两次输入密码不一致",
    "exitFailReason":"退出失败，原因:",
    "modifyFailReason":"修改失败,失败原因:",
    "defaultDistributor":"默认分销商",
    "noAutoUpgrade":"不自动升级",
    "addLevel":"新增分销商等级",
    "editLevel":"编辑分销商等级",
    "savedSuccessfully":"保存成功",
    "saveFailed":"保存失败",
    "saveFailReason":"保存失败,失败原因:",
    "paramError":"参数错误",    
    "uploadImageFormat":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
    "uploadImageSize":"上传图片大小不能超过 2MB!",
    "uploadImageSize2":"上传图片大小不能超过 10MB!",
    "refreshSuccessfully":"刷新成功",
    "abuilding":"施工中...",
    "isDeleteDistribution":"确定删除该分销商品？ 将恢复成普通商品",
    "tip":"提示",
    "determine":"确定",  
    "cancel":"取消",  
    "deleteSuccessfully":"删除成功", 
    "deleteFailed":"删除失败",  
    "cancelledDelete":"已取消删除",  
    "unPaid":"未支付",
    "paid":"已支付",
    "unDivided":"等待分成",
    "divided":"已分成",	
    "cancelled":"已取消",
    "maxValue":"最大值",
    "minValue":"最小值",    
    "averageValue":"平均值",  
    "loginFailReason":"登录失败,失败原因:",
    "dateDistribution":"日期分布图",
    "fail":"失败",
    "success":"成功",
    "successScale":"成功比例图",
    "visitSource":"访问来源",
    "editSuccessfully":"编辑成功",
    "addSuccessfully":"添加成功",
    "addFailed":"添加失败",
    "executeSuccessfully":"执行成功",
    "isStart":"确定启动?",
    "startSuccessfully":"启动成功",
    "isStop":"确定停止?",
    "stopSuccessfully":"停止成功",
    "isDelete":"确定删除?",
    "isDelete2":"确定删除",
    "selectDeleteLog":"请选择需要删除的日志",
    "integerGreaterThan1":"所有值只能输入大于1的整数",
    "operateFailed":"操作失败",
    "operateSuccessfully":"操作成功",
    "phoneOrEmail":"手机和邮箱至少填一项",
    "normal":"正常",
    "auditing":"审核中",
    "auditSuccessfully":"审核成功",
    "auditFailed":"审核失败",
    "updateSuccessfully":"更新成功",
    "updateFailed":"更新失败",
    "updateFailReason":"修改失败,失败原因:",
    "selectDeleteBrand":"请选择需要删除的品牌!",
    "offshelfSuccessfully":"下架成功",
    "operateFailReason":"操作失败,失败原因",
    "batchOperationSuccessfully":"批量操作成功",
    "batchOperationFailReason":"批量操作失败,失败原因",
    "modifiedSuccessfully":"修改成功",
    "modifiedFailed":"修改失败",
    "isDeleteClassification":"是否删除该分类?",
    "unbindErrorReason":"解除绑定失败，错误原因：",
    "deleteErrorReason":"删除失败，错误原因：",
    "isDeleteMessage":"是否删除该信息?",
    "conformToChangeSuperior":"符合更改上级",
    "changeSuperiorSuccessfully":"更改上级成功",
    "noEmptyPhone":"手机号码不能为空",
    "phoneFormatError":"手机格式错误",
    "sendSuccessfully":"发送成功",
    "warehousing":"入库",
    "warehouseOut":"出库",
    "adopt":"通过？",
    "reject":"拒绝？",
    "yes":"是",
    "no":"否",
    "onShelf":"上架",
    "offShelf":"下架",
    "yesOrNo":"是否",
    "package":"该套餐?",
    "isDeleteCoupon":"是否删除该优惠卷?",
    "toBeAudited":"待审核",
    "pass":"通过",
    "notPass":"未通过",
    "refuse":"拒绝",
    "administratorShutdown":"管理员关闭",
    "soldOut":"已售罄",
    "isCancelActivity":"是否取消该活动?",
    "cancelSuccessfully":"取消成功",
    "seckill":"抢购/秒杀",
    "seckillExplain":"快速抢购引导顾客更多消费",
    "group":"团购",
    "groupExplain":"批量促销清理库存",
    "preferentialPromotion":"优惠促销",
    "preferentialPromotionExplain":"对商品本身进行的促销方式",
    "orderPromotion":"订单促销",
    "orderPromotionExplain":"对用户订单进行优惠的促销方式",
    "preSale":"预售",
    "preSaleExplain":"新品销售预估和预热",
    "share":"分享团",
    "shareExplain":"拼团基础版，引流传播利器",
    "commission":"佣金团",
    "commissionExplain":"团长赚拼团佣金，积极性更高",
    "luckDraw":"抽奖团",
    "luckDrawExplain":"设置不同奖品达到趣味营销效果",
    "coupon":"优惠劵",
    "couponExplain":"不同类型优惠劵针对性营销",
    "newCoupons":"新人优惠券",
    "newCouponsExplain":"新人专属优惠劵针对性营销",
    "notEmptySpecifiedProduct":"指定商品不能为空",
    "notEmptySpecifiedClassification":"指定分类不能为空",
    "finished":"已结束",
    "expired":"已过期",
    "noType":"无类型",
    "type":"类型",
    "openedPrice":"已开奖",
    "inProgress":"进行中",
    "noStatus":"无状态",
    "hour":"小时",
    "selectModifyRecord":"请选择需要修改的记录",
    "selectDeleteRecord":"请选择需要删除的记录",
    "selectOperateRecord":"请选择需要操作的记录",
    "shieldSuccessfully":"屏蔽成功",
    "shieldFailed":"屏蔽失败",
    "publishSuccessfully":"发布成功",
    "publishErrorReason":"发布失败,错误原因:",
    "closeSuccessfully":"关闭成功",
    "closeErrorReason":"关闭失败,错误原因:",
    "complaintDaysLimitation":"投诉时效天数必须为大于0",
    "lastWeek":"最近一周",
    "lastMonth":"最近一个月",
    "lastThreeMonth":"最近三个月",
    "waitForGroup":"待成团",
    "formedGroup":"已成团",
    "notFormedGroup":"未成团",
    "toBeOpened":"待开团",
    "opened":"已开团",
    "groupBuildingSuccessfully":"拼团成功",
    "groupBuildingFailed":"拼团失败",
    "toBeOpened2":"待开",
    "opened2":"已开",
    "unionpay":"银联在线支付",
    "tenpay":"PC端财付通",
    "alipay":"支付宝支付",
    "wxpay":"微信支付",
    "miniAppPay":"微信小程序支付",
    "notDelivered":"未发货",
    "delivered":"已发货",
    "partDelivered":"部分发货",
    "toBeConfirmed":"待确认",
    "confirmed":"已确认",
    "confirm":"确认",
    "received":"已收货",
    "evaluated":"已评价",
    "closed":"已关闭",
    "storeName":"店铺名称",
    "consignee":"收货人",
    "orderId":"订单编号",
    "noExportData":"当前条件没有可导出的数据",
    "goodsName":"商品名称",
    "exchange":"换货",
    "repair":"维修",
    "disagree":"不同意",
    "agree":"同意",
    "untreated":"未处理",
    "treating":"处理中",
    "completed":"已完成",
    "refundOnly":"仅退款",
    "returnRefund":"退货退款",
    "leagueLeader":"拼主",
    "leagueMember":"团员",
    "allAdPosition":"广告位置 - 所有",
    "selectStartDate":"请选择开始日期",
    "selectEndDate":"请选择结束日期",
    "dateLimit":"开始日期不能大于结束日期",
    "loginPage":"登录页",
    "pcPage":"PC首页",
    "mobilePage":"手机首页",
    "selectFile1":"当前限制选择 1 个文件，本次选择了",
    "selectFile2":"个文件，共选择了",
    "selectFile3":"个文件",
    "fillInPosterName":"请填写海报名称",
    "selectStartTime":"请选择开始时间",
    "selectEndTime":"请选择结束时间",
    "uploadImage":"请上传图片",
    "reenterThePage":"请重新进入该页面",
    "submitSuccessfully":"提交成功",
    "addClassification":"新增分类",
    "topClassification":"顶级分类",
    "editClassification":"编辑分类",
    "newest":"最新",
    "popular":"热门",
    "recommend":"推荐",
    "boutique":"精品",
    "selectDeleteNew":"请选择需要删除的新闻",
    "numberOnly":"只能填写数字",
    "numberRange":"数字范围",
    "nameCannotBeEmpty":"版块名称不能为空",
    "colorCannotBeEmpty":"色彩风格不能为空",
    "sortCannotBeEmpty":"排序不能为空",
    "setupSuccessfully":"设置成功",
    "setupErrorReason":"设置楼层，错误原因:",
    "updateErrorReason":"更新楼层，错误原因:",
    "getFloorListErrorReason":"获取楼层列表，错误原因:",
    "updateFloorListErrorReason":"更新楼层列表，错误原因:",
    "tip1":"请按照操作注释要求，上传设置板块区域左上角的标题图片。",
    "tip2":"请按照操作注释要求，上传设置板块区域左侧的活动图片。",
    "tip3":"小提示：双击分类名称可删除不想显示的分类",
    "tip4":"小提示：单击候选品牌选中，双击已选择的可以删除，最多10个，保存后生效",
    "tip5":"小提示：单击查询出的商品选中，双击已选择的可以删除，最多10个，保存后生效。",
    "tip6":"单击图片选中修改，拖动可以排序，最少保留1个，最多可加5个，保存后生效。",
    "addUpToFive":"最多可添加5个",
    "addTemplate":"添加模板",
    "titleSetup":"标题设置",
    "activityImage":"活动图片",
    "recommendClassification":"推荐分类",
    "recommendBrand":"推荐品牌",
    "goodsRecommend":"商品推荐",
    "switchAdPic":"切换广告图片",
    "addUpToEight":"最多可添加8个",
    "imageCannotBeEmpty":"图片不能为空",
    "activityLinkCannotBeEmpty":"活动链接不能为空",
    "activityNameCannotBeEmpty":"活动名字不能为空",
    "join":"加入",
    "exit":"退出",
    "approvalPassed":"审核通过",
    "applyApproval":"服务申请审核",
    "applyDetail":"服务申请详情",
    "prohibitUse":"禁止使用",
    "notApply":"未申请",
    "close":"关闭",
    "open":"开启",
    "aboutToExpire":"即将到期",
    "platformExists":"已存在默认平台",
    "setupPlatform":"确定要设置默认平台(可以给店铺代买商品)？只能设置一次，设置之后不能更改，以防数据混乱，慎选！！！",
    "isDeleteStore":"是否删除该店铺?",
    "approval":"通过审核",
    "rejectApplication":"拒绝该申请",
    "completeForm":"请填写完整表单",
    "fillInLegalMemberAccount":"请填写合法的会员账号",
    "passwordLength":"密码长度为6-16位",
    "unSelect":"未选择",
    "flagship":"旗舰店",
    "exclusive":"专卖店",
    "franchise":"专营店",
    "selectBusinessCategory":"请选择经营类目",
    "select":"请选择",
    "longTerm":"长期",
    "expirationDateOfBusinessLicense":"请选择营业执照有效截至日期",
    "deleted":"已删除",
    "allSubordinateAreas":"与其所有下级地区?",
    "copiedToClipboard":"内容已复制到剪切板！请粘贴到邮件内容",
    "copyFailed":"抱歉，复制失败",
    "applets":"小程序",
    "appletsLoginConfiguration":"小程序登录也在此配置",
    "shipFromName":"发货点-名称",
    "shipFromContact":"发货点-联系人",
    "shipFromPhone":"发货点-电话",
    "shipFromProvince":"发货点-省份",
    "shipFromCity":"发货点-城市",
    "shipFromDistrict":"发货点-区县",
    "shipFromMobile":"发货点-手机",
    "shipFromAddress":"发货点-详细地址",
    "recipientName":"收件人-姓名",
    "recipientMobile":"收件人-手机",
    "recipientPhone":"收件人-电话",
    "recipientProvince":"收件人-省份",
    "recipientCity":"收件人-城市",
    "recipientDistrict":"收件人-区县",
    "recipientPostcode":"收件人-邮编",
    "recipientAddress":"收件人-详细地址",
    "timeYear":"时间-年",
    "timeMonth":"时间-月",
    "timeDay":"时间-日",
    "timeCurDate":"时间-当前日期",
    "orderOrderNum":"订单-订单号",
    "orderRemark":"订单-备注",
    "orderDistributionCosts":"订单-配送费用",
    "selectDeleteCompany":"请选择需要删除的物流公司",
    "pageRefreshed":"页面被刷新",
    "firstLoaded":"首次被加载",
    "isEnable":"是否启用",
    "userReg":"用户注册时",
    "userRetrievePassword":"用户找回密码时",
    "onAuthentication":"身份验证时",
    "orderNotice":"用户下单时通知",
    "sendMessageToMerchant":"客户支付时是否发消息给商家",
    "deliverGoodsNotice":"发货时通知",
    "noticeAfterPayment":"购买虚拟商品支付成功后,是否通知",
    "captchaTimeout":"验证码超时时间",
    "captcha":"验证码",
    "input":"请输入",
    "imageLoadFailed":"图片加载失败",
    "uploadSuccessfully":"上传成功",
    "graphicMaterialLimit":"图文素材最多包含8篇文章",
    "inputTitle":"请输入标题",
    "titleMaxLength":"标题最长30个字",
    "uploadVideoSize":"视频大小不能超过10MB",
    "uploadVoiceSize":"音频大小不能超过2MB",
    "inputKeywords":"请输入关键字",
    "halfMatching":"半匹配",
    "fullMatching":"全匹配",
    "autoReplyEdit":"关键词自动回复编辑",
    "autoReplyAdd":"关键词自动回复添加",
    "inputReplyContent":"请输入回复内容",
    "selectReplyImageText":"请选择回复的图文素材",
    "selectReplyImage":"请选择回复的图片素材",
    "selectReplyVideo":"请选择回复的视频素材",
    "selectReplyVoice":"请选择回复的音频素材",
    "link":"链接",
    "triggerKeywords":"触发关键字",
    "scanCode":"扫码",
    "waitingForMessage":"等待信息",
    "systemTakeSend":"系统拍照发图",
    "albumSend":"拍照或者相册发图",
    "wechatAlbumSend":"微信相册发图",
    "geographicalPosition":"地理位置",
    "officialAccountThreeMenus":"公众号最多三个一级菜单",
    "firstMenuFiveSubmenus":"一级菜单下最多五个子菜单",
    "menuGeneratedSuccessfully":"菜单已成功生成",
    "updateSavedEffective":"更新成功，保存后生效",
    "subscriptionNum":"订阅号",
    "authenticateSubscriptionNum":"认证订阅号",
    "serviceNum":"服务号",
    "authenticateServiceNum":"认证服务号",
    "inputWebsiteName":"请输入网站域名",
    "inputoOfficialAccountName":"请输入公众号名称",
    "inputOfficialAccountId":"请输入公众号原始ID",
    "inputWechatNum":"请输入微信号",
    "inputUserName":"请输入用户名",
    "inputMailBox":"请输入邮箱",
    "inputPassword":"请输入密码",
    "isDeleteAdministrator":"是否删除该管理员?",
    "selectMember":"选择会员",
    "noUserSelected":"没有选中用户",
    "selectedUserWithoutMailbox":"选中的用户没有邮箱",
    "selectItemsLimit":"选中的商品不可以超过5条",
    "price":"价格",
    "noEmptyEmailTitle":"邮件标题不能为空",
    "selectedMemberWithoutMailbox":"选择会员邮箱不能为空",
    "notSelectMember":"没有选择会员",
    "tooManyMembersSent":"发送的会员数过多",
    "noEmptySelectItem":"选择商品不能为空",
    "noEmptySendContent":"发送的内容不能为空",
    "regularEmail":"普通邮件",
    "notice":"通知",
    "emailLog":"邮件日志",
    "administrator":"管理员",
    "addAllSmsScene":"已添加了所有短信场景，没得加了",
    "totalMerchandise":"商品总额",
    "preferentialAmount":"优惠金额",
    "commodityCost":"商品成本",
    "logisticsExpenses":"物流费用",
    "transactionAmount":"交易金额",
    "orderNum":"订单数",
    "customerUnitPrice":"客单价",
    "amount":"金额",
    "void":"无效作废",
    "applyFailed":"申请失败",
    "applying":"申请中",
    "applySuccessfully":"申请成功",
    "noEmptyRemark":"备注不能为空",
    "newMemberTrend":"会员新增趋势",
    "addMember":"新增会员",
    "people":"人",
    "isdeletetrend":"是否删除",
	"scaledFormat":"抽成比例只能输入100以内的正整数"
}

export const zh = {
    "inputOldPassword":"请输入旧密码",
    "inputNewPassword":"请输入新密码",
    "inputAgain":"请再次输入新密码",
    "atypism":"两次输入密码不一致",
    "exitFailReason":"退出失败，原因:",
    "modifyFailReason":"修改失败,失败原因:",
    "defaultDistributor":"默认分销商",
    "noAutoUpgrade":"不自动升级",
    "addLevel":"新增分销商等级",
    "editLevel":"编辑分销商等级",
    "savedSuccessfully":"保存成功",
    "saveFailed":"保存失败",
    "saveFailReason":"保存失败,失败原因:",
    "paramError":"参数错误",    
    "uploadImageFormat":"上传图片只能是 JPG、JPEG、PNG、GIF 格式!",
    "uploadImageSize":"上传图片大小不能超过 2MB!",
    "uploadImageSize2":"上传图片大小不能超过 10MB!",
    "refreshSuccessfully":"刷新成功",
    "abuilding":"施工中...",
    "isDeleteDistribution":"确定删除该分销商品？ 将恢复成普通商品",
    "tip":"提示",
    "determine":"确定",  
    "cancel":"取消",  
    "deleteSuccessfully":"删除成功", 
    "deleteFailed":"删除失败",  
    "cancelledDelete":"已取消删除",  
    "unPaid":"未支付",
    "paid":"已支付",
    "unDivided":"等待分成",
    "divided":"已分成",	
    "cancelled":"已取消",
    "maxValue":"最大值",
    "minValue":"最小值",    
    "averageValue":"平均值",  
    "loginFailReason":"登录失败,失败原因:",
    "dateDistribution":"日期分布图",
    "fail":"失败",
    "success":"成功",
    "successScale":"成功比例图",
    "visitSource":"访问来源",
    "editSuccessfully":"编辑成功",
    "addSuccessfully":"添加成功",
    "addFailed":"添加失败",
    "executeSuccessfully":"执行成功",
    "isStart":"确定启动?",
    "startSuccessfully":"启动成功",
    "isStop":"确定停止?",
    "stopSuccessfully":"停止成功",
    "isDelete":"确定删除?",
    "isDelete2":"确定删除",
    "selectDeleteLog":"请选择需要删除的日志",
    "integerGreaterThan1":"所有值只能输入大于1的整数",
    "operateFailed":"操作失败",
    "operateSuccessfully":"操作成功",
    "phoneOrEmail":"手机和邮箱至少填一项",
    "normal":"正常",
    "auditing":"审核中",
    "auditSuccessfully":"审核成功",
    "auditFailed":"审核失败",
    "updateSuccessfully":"更新成功",
    "updateFailed":"更新失败",
    "updateFailReason":"修改失败,失败原因:",
    "selectDeleteBrand":"请选择需要删除的品牌!",
    "offshelfSuccessfully":"下架成功",
    "operateFailReason":"操作失败,失败原因",
    "batchOperationSuccessfully":"批量操作成功",
    "batchOperationFailReason":"批量操作失败,失败原因",
    "modifiedSuccessfully":"修改成功",
    "modifiedFailed":"修改失败",
    "isDeleteClassification":"是否删除该分类?",
    "unbindErrorReason":"解除绑定失败，错误原因：",
    "deleteErrorReason":"删除失败，错误原因：",
    "isDeleteMessage":"是否删除该信息?",
    "conformToChangeSuperior":"符合更改上级",
    "changeSuperiorSuccessfully":"更改上级成功",
    "noEmptyPhone":"手机号码不能为空",
    "phoneFormatError":"手机格式错误",
    "sendSuccessfully":"发送成功",
    "warehousing":"入库",
    "warehouseOut":"出库",
    "adopt":"通过？",
    "reject":"拒绝？",
    "yes":"是",
    "no":"否",
    "onShelf":"上架",
    "offShelf":"下架",
    "yesOrNo":"是否",
    "package":"该套餐?",
    "isDeleteCoupon":"是否删除该优惠卷?",
    "toBeAudited":"待审核",
    "pass":"通过",
    "notPass":"未通过",
    "refuse":"拒绝",
    "administratorShutdown":"管理员关闭",
    "soldOut":"已售罄",
    "isCancelActivity":"是否取消该活动?",
    "cancelSuccessfully":"取消成功",
    "seckill":"抢购/秒杀",
    "seckillExplain":"快速抢购引导顾客更多消费",
    "group":"团购",
    "groupExplain":"批量促销清理库存",
    "preferentialPromotion":"优惠促销",
    "preferentialPromotionExplain":"对商品本身进行的促销方式",
    "orderPromotion":"订单促销",
    "orderPromotionExplain":"对用户订单进行优惠的促销方式",
    "preSale":"预售",
    "preSaleExplain":"新品销售预估和预热",
    "share":"分享团",
    "shareExplain":"拼团基础版，引流传播利器",
    "commission":"佣金团",
    "commissionExplain":"团长赚拼团佣金，积极性更高",
    "luckDraw":"抽奖团",
    "luckDrawExplain":"设置不同奖品达到趣味营销效果",
    "coupon":"优惠劵",
    "couponExplain":"不同类型优惠劵针对性营销",
    "newCoupons":"新人优惠券",
    "newCouponsExplain":"新人专属优惠劵针对性营销",
    "notEmptySpecifiedProduct":"指定商品不能为空",
    "notEmptySpecifiedClassification":"指定分类不能为空",
    "finished":"已结束",
    "expired":"已过期",
    "noType":"无类型",
    "type":"类型",
    "openedPrice":"已开奖",
    "inProgress":"进行中",
    "noStatus":"无状态",
    "hour":"小时",
    "selectModifyRecord":"请选择需要修改的记录",
    "selectDeleteRecord":"请选择需要删除的记录",
    "selectOperateRecord":"请选择需要操作的记录",
    "shieldSuccessfully":"屏蔽成功",
    "shieldFailed":"屏蔽失败",
    "publishSuccessfully":"发布成功",
    "publishErrorReason":"发布失败,错误原因:",
    "closeSuccessfully":"关闭成功",
    "closeErrorReason":"关闭失败,错误原因:",
    "complaintDaysLimitation":"投诉时效天数必须为大于0",
    "lastWeek":"最近一周",
    "lastMonth":"最近一个月",
    "lastThreeMonth":"最近三个月",
    "waitForGroup":"待成团",
    "formedGroup":"已成团",
    "notFormedGroup":"未成团",
    "toBeOpened":"待开团",
    "opened":"已开团",
    "groupBuildingSuccessfully":"拼团成功",
    "groupBuildingFailed":"拼团失败",
    "toBeOpened2":"待开",
    "opened2":"已开",
    "unionpay":"银联在线支付",
    "tenpay":"PC端财付通",
    "alipay":"支付宝支付",
    "wxpay":"微信支付",
    "miniAppPay":"微信小程序支付",
    "notDelivered":"未发货",
    "delivered":"已发货",
    "partDelivered":"部分发货",
    "toBeConfirmed":"待确认",
    "confirmed":"已确认",
    "confirm":"确认",
    "received":"已收货",
    "evaluated":"已评价",
    "closed":"已关闭",
    "storeName":"店铺名称",
    "consignee":"收货人",
    "orderId":"订单编号",
    "noExportData":"当前条件没有可导出的数据",
    "goodsName":"商品名称",
    "exchange":"换货",
    "repair":"维修",
    "disagree":"不同意",
    "agree":"同意",
    "untreated":"未处理",
    "treating":"处理中",
    "completed":"已完成",
    "refundOnly":"仅退款",
    "returnRefund":"退货退款",
    "leagueLeader":"拼主",
    "leagueMember":"团员",
    "allAdPosition":"广告位置 - 所有",
    "selectStartDate":"请选择开始日期",
    "selectEndDate":"请选择结束日期",
    "dateLimit":"开始日期不能大于结束日期",
    "loginPage":"登录页",
    "pcPage":"PC首页",
    "mobilePage":"手机首页",
    "selectFile1":"当前限制选择 1 个文件，本次选择了",
    "selectFile2":"个文件，共选择了",
    "selectFile3":"个文件",
    "fillInPosterName":"请填写海报名称",
    "selectStartTime":"请选择开始时间",
    "selectEndTime":"请选择结束时间",
    "uploadImage":"请上传图片",
    "reenterThePage":"请重新进入该页面",
    "submitSuccessfully":"提交成功",
    "addClassification":"新增分类",
    "topClassification":"顶级分类",
    "editClassification":"编辑分类",
    "newest":"最新",
    "popular":"热门",
    "recommend":"推荐",
    "boutique":"精品",
    "selectDeleteNew":"请选择需要删除的新闻",
    "numberOnly":"只能填写数字",
    "numberRange":"数字范围",
    "nameCannotBeEmpty":"版块名称不能为空",
    "colorCannotBeEmpty":"色彩风格不能为空",
    "sortCannotBeEmpty":"排序不能为空",
    "setupSuccessfully":"设置成功",
    "setupErrorReason":"设置楼层，错误原因:",
    "updateErrorReason":"更新楼层，错误原因:",
    "getFloorListErrorReason":"获取楼层列表，错误原因:",
    "updateFloorListErrorReason":"更新楼层列表，错误原因:",
    "tip1":"请按照操作注释要求，上传设置板块区域左上角的标题图片。",
    "tip2":"请按照操作注释要求，上传设置板块区域左侧的活动图片。",
    "tip3":"小提示：双击分类名称可删除不想显示的分类",
    "tip4":"小提示：单击候选品牌选中，双击已选择的可以删除，最多10个，保存后生效",
    "tip5":"小提示：单击查询出的商品选中，双击已选择的可以删除，最多10个，保存后生效。",
    "tip6":"单击图片选中修改，拖动可以排序，最少保留1个，最多可加5个，保存后生效。",
    "addUpToFive":"最多可添加5个",
    "addTemplate":"添加模板",
    "titleSetup":"标题设置",
    "activityImage":"活动图片",
    "recommendClassification":"推荐分类",
    "recommendBrand":"推荐品牌",
    "goodsRecommend":"商品推荐",
    "switchAdPic":"切换广告图片",
    "addUpToEight":"最多可添加8个",
    "imageCannotBeEmpty":"图片不能为空",
    "activityLinkCannotBeEmpty":"活动链接不能为空",
    "activityNameCannotBeEmpty":"活动名字不能为空",
    "join":"加入",
    "exit":"退出",
    "approvalPassed":"审核通过",
    "applyApproval":"服务申请审核",
    "applyDetail":"服务申请详情",
    "prohibitUse":"禁止使用",
    "notApply":"未申请",
    "close":"关闭",
    "open":"开启",
    "aboutToExpire":"即将到期",
    "platformExists":"已存在默认平台",
    "setupPlatform":"确定要设置默认平台(可以给店铺代买商品)？只能设置一次，设置之后不能更改，以防数据混乱，慎选！！！",
    "isDeleteStore":"是否删除该店铺?",
    "approval":"通过审核",
    "rejectApplication":"拒绝该申请",
    "completeForm":"请填写完整表单",
    "fillInLegalMemberAccount":"请填写合法的会员账号",
    "passwordLength":"密码长度为6-16位",
    "unSelect":"未选择",
    "flagship":"旗舰店",
    "exclusive":"专卖店",
    "franchise":"专营店",
    "selectBusinessCategory":"请选择经营类目",
    "select":"请选择",
    "longTerm":"长期",
    "expirationDateOfBusinessLicense":"请选择营业执照有效截至日期",
    "deleted":"已删除",
    "allSubordinateAreas":"与其所有下级地区?",
    "copiedToClipboard":"内容已复制到剪切板！请粘贴到邮件内容",
    "copyFailed":"抱歉，复制失败",
    "applets":"小程序",
    "appletsLoginConfiguration":"小程序登录也在此配置",
    "shipFromName":"发货点-名称",
    "shipFromContact":"发货点-联系人",
    "shipFromPhone":"发货点-电话",
    "shipFromProvince":"发货点-省份",
    "shipFromCity":"发货点-城市",
    "shipFromDistrict":"发货点-区县",
    "shipFromMobile":"发货点-手机",
    "shipFromAddress":"发货点-详细地址",
    "recipientName":"收件人-姓名",
    "recipientMobile":"收件人-手机",
    "recipientPhone":"收件人-电话",
    "recipientProvince":"收件人-省份",
    "recipientCity":"收件人-城市",
    "recipientDistrict":"收件人-区县",
    "recipientPostcode":"收件人-邮编",
    "recipientAddress":"收件人-详细地址",
    "timeYear":"时间-年",
    "timeMonth":"时间-月",
    "timeDay":"时间-日",
    "timeCurDate":"时间-当前日期",
    "orderOrderNum":"订单-订单号",
    "orderRemark":"订单-备注",
    "orderDistributionCosts":"订单-配送费用",
    "selectDeleteCompany":"请选择需要删除的物流公司",
    "pageRefreshed":"页面被刷新",
    "firstLoaded":"首次被加载",
    "isEnable":"是否启用",
    "userReg":"用户注册时",
    "userRetrievePassword":"用户找回密码时",
    "onAuthentication":"身份验证时",
    "orderNotice":"用户下单时通知",
    "sendMessageToMerchant":"客户支付时是否发消息给商家",
    "deliverGoodsNotice":"发货时通知",
    "noticeAfterPayment":"购买虚拟商品支付成功后,是否通知",
    "captchaTimeout":"验证码超时时间",
    "captcha":"验证码",
    "input":"请输入",
    "imageLoadFailed":"图片加载失败",
    "uploadSuccessfully":"上传成功",
    "graphicMaterialLimit":"图文素材最多包含8篇文章",
    "inputTitle":"请输入标题",
    "titleMaxLength":"标题最长30个字",
    "uploadVideoSize":"视频大小不能超过10MB",
    "uploadVoiceSize":"音频大小不能超过2MB",
    "inputKeywords":"请输入关键字",
    "halfMatching":"半匹配",
    "fullMatching":"全匹配",
    "autoReplyEdit":"关键词自动回复编辑",
    "autoReplyAdd":"关键词自动回复添加",
    "inputReplyContent":"请输入回复内容",
    "selectReplyImageText":"请选择回复的图文素材",
    "selectReplyImage":"请选择回复的图片素材",
    "selectReplyVideo":"请选择回复的视频素材",
    "selectReplyVoice":"请选择回复的音频素材",
    "link":"链接",
    "triggerKeywords":"触发关键字",
    "scanCode":"扫码",
    "waitingForMessage":"等待信息",
    "systemTakeSend":"系统拍照发图",
    "albumSend":"拍照或者相册发图",
    "wechatAlbumSend":"微信相册发图",
    "geographicalPosition":"地理位置",
    "officialAccountThreeMenus":"公众号最多三个一级菜单",
    "firstMenuFiveSubmenus":"一级菜单下最多五个子菜单",
    "menuGeneratedSuccessfully":"菜单已成功生成",
    "updateSavedEffective":"更新成功，保存后生效",
    "subscriptionNum":"订阅号",
    "authenticateSubscriptionNum":"认证订阅号",
    "serviceNum":"服务号",
    "authenticateServiceNum":"认证服务号",
    "inputWebsiteName":"请输入网站域名",
    "inputoOfficialAccountName":"请输入公众号名称",
    "inputOfficialAccountId":"请输入公众号原始ID",
    "inputWechatNum":"请输入微信号",
    "inputUserName":"请输入用户名",
    "inputMailBox":"请输入邮箱",
    "inputPassword":"请输入密码",
    "isDeleteAdministrator":"是否删除该管理员?",
    "selectMember":"选择会员",
    "noUserSelected":"没有选中用户",
    "selectedUserWithoutMailbox":"选中的用户没有邮箱",
    "selectItemsLimit":"选中的商品不可以超过5条",
    "price":"价格",
    "noEmptyEmailTitle":"邮件标题不能为空",
    "selectedMemberWithoutMailbox":"选择会员邮箱不能为空",
    "notSelectMember":"没有选择会员",
    "tooManyMembersSent":"发送的会员数过多",
    "noEmptySelectItem":"选择商品不能为空",
    "noEmptySendContent":"发送的内容不能为空",
    "regularEmail":"普通邮件",
    "notice":"通知",
    "emailLog":"邮件日志",
    "administrator":"管理员",
    "addAllSmsScene":"已添加了所有短信场景，没得加了",
    "totalMerchandise":"商品总额",
    "preferentialAmount":"优惠金额",
    "commodityCost":"商品成本",
    "logisticsExpenses":"物流费用",
    "transactionAmount":"交易金额",
    "orderNum":"订单数",
    "customerUnitPrice":"客单价",
    "amount":"金额",
    "void":"无效作废",
    "applyFailed":"申请失败",
    "applying":"申请中",
    "applySuccessfully":"申请成功",
    "noEmptyRemark":"备注不能为空",
    "newMemberTrend":"会员新增趋势",
    "addMember":"新增会员",
    "people":"人",
    "isdeletetrend":"是否删除",
	"scaledFormat":"抽成比例只能输入100以内的正整数"
}
  