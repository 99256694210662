import Vue from 'vue'
import VueI18n from 'vue-i18n'
import ElementLocale from 'element-ui/lib/locale'
import enLocale from 'element-ui/lib/locale/lang/en';
import zhLocale from 'element-ui/lib/locale/lang/zh-CN';
import {zh as commonZh, en as commonEn} from './common'
import {zh as loginZh, en as loginEn} from './login'
import {zh as componentsZh, en as componentsEn} from './components'
import {zh as distributionZh, en as distributionEn} from './distribution'
import {zh as homeZh, en as homeEn} from './home'
import {zh as jobsZh, en as jobsEn} from './jobs'
import {zh as marketZh, en as marketEn} from './market'
import {zh as marketingZh, en as marketingEn} from './marketing'
import {zh as orderZh, en as orderEn} from './order'
import {zh as pageZh, en as pageEn} from './page'
import {zh as sellerZh, en as sellerEn} from './seller'
import {zh as setupZh, en as setupEn} from './setup'
import {zh as statisticsZh, en as statisticsEn} from './statistics'
import {zh as jsZh, en as jsEn} from './javascript'
Vue.use(VueI18n)

let EN = {
  lang: 'en',
  common: commonEn,
  login: loginEn,
  components: componentsEn,
  distribution: distributionEn,
  home: homeEn,
  jobs: jobsEn,
  market: marketEn,
  marketing: marketingEn,
  order: orderEn,
  page: pageEn,
  seller: sellerEn,
  setup: setupEn,
  statistics: statisticsEn,
  js: jsEn,
  ...enLocale,
}
let ZH = {
  lang: 'zh',
  common: commonZh,
  login: loginZh,
  components: componentsZh,
  distribution: distributionZh,
  home: homeZh,
  jobs: jobsZh,
  market: marketZh,
  marketing: marketingZh,
  order: orderZh,
  page: pageZh,
  seller: sellerZh,
  setup: setupZh,
  statistics: statisticsZh,
  js: jsZh,
  ...zhLocale,
}
const i18n = new VueI18n({
  locale: localStorage.getItem('language')||'zh_CN', 
  messages: {
    en_US: EN,
    zh_CN: ZH
  }
})
ElementLocale.i18n((key, value) => i18n.t(key, value))

export default i18n