export const en = {
  "collocation":{
    "management":"搭配购套餐管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"搭配购套餐管理, 平台可强制下架套餐,下架后商家不可重新上架，只有平台有权限重新上架.",
    "hideTips":"收起提示",
    "list":"搭配购套餐列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "goodsName":"商品名称",
    "collocationName":"搭配购套餐名称",
    "goodsNum":"套餐内商品数量",
    "storeName":"店铺名称",
    "startTime":"开始时间",
    "endTime":"结束时间",
    "status":"状态",
    "started":"开始中",
    "unstart":"未开始",
    "overdue":"已过期",
    "onShelf":"上架",
    "offShelf":"下架",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "adopt":"通过",
    "reject":"拒绝",
    "detail":"详情",
    "delete":"删除",
  },
  "collocationInfo":{
    "backToList":"返回列表",
    "management":"搭配购套餐管理",
    "edit":"编辑套餐",
    "checkOrEdit":"查看/编辑搭配购套餐",
    "packageName":"套餐名称",
    "startTime":"开始时间",
    "endTime":"结束时间",
    "selectTime":"选择日期时间",
    "describe":"套餐描述",
    "goods":"套餐商品",
    "goodsId":"商品ID",
    "goodsName":"商品名称",
    "goodsImg":"商品图片",
    "goodsType":"商品类型",
    "main":"主商品",
    "side":"副商品",
    "spec":"规格",
    "stock":"库存",
    "costPrice":"成本价格",
    "shopPrice":"商城价格",
  },
  "coupon":{
    "backToList":"返回列表",
    "voucher":"店铺代金券",
    "edit":"编辑代金券",
    "checkOrEdit":"查看/编辑商家发布的代金券",
    "storeName":"店铺名称",
    "voucherName":"代金券名称",
    "voucherType":"优惠券类型",
    "order":"下单赠送",
    "distributionByUser":"按用户发放",
    "freeDraw":"免费领取",
    "offlineDistribution":"线下发放",
    "termOfValidity":"有效期",
    "denomination":"面额",
    "issuableNum":"可发放总数",
    "consumption":"消费金额",
    "describe":"代金券描述",
    "drawed":"已领取",
    "used":"已使用",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
  },
  "couponList":{
    "voucher":"店铺代金券",
    "mallCouponList":"商城店铺代金券领取列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"手工设置代金券失效后,用户将不能领取该代金券,但是已经领取的代金券仍然可以使用.",
    "hideTips":"收起提示",
    "couponList":"店铺代金券领取列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "couponId":"优惠券编号",
    "type":"发放类型",
    "menuId":"订单号",
    "useMember":"使用会员",
    "useTime":"使用时间",
    "status":"状态",
    "operation":"操作",
    "delete":"删除",
  },
  "coupons":{
    "voucher":"店铺代金券",
    "settingAndManagement":"商城店铺代金券活动设定与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"手工设置代金券失效后,用户将不能领取该代金券,但是已经领取的代金券仍然可以使用.",
    "hideTips":"收起提示",
    "voucherList":"店铺代金券列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "voucherName":"优惠券名称",
    "search":"搜索",
    "storeName":"店铺名称",
    "voucherType":"优惠券类型",
    "order":"下单赠送",
    "distributionByUser":"按用户发放",
    "freeDraw":"免费领取",
    "offlineDistribution":"线下发放",
    "newCoupon":"新人优惠卷",
    "denomination":"面额",
    "required":"使用需满金额",
    "totalCirculation":"总发行量",
    "issued":"已发放",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
    "deadline":"使用截止日期",
    "operation":"操作",
    "check":"查看",
    "detail":"详细",
  },
  "flashSale":{
    "management":"抢购管理",
    "auditAndManagement":"网站系统抢购活动审核与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"抢购管理, 由平台设置管理.",
    "state2":"抢购活动开始结束时间跨度必须为两个小时，因为前台抢购活动展示是以每两个小时为一场，否则前台无法展示",
    "state3":"抢购列表页可以让管理员了解活动目前状态，活动商品已购买数以及订单数等一些重要的活动数据。",
    "state4":"抢购活动删除：秒杀活动在没有订单的情况下，是可以删除的，但是一旦有客户下了订单，活动将无法删除。",
    "state5":"将秒杀活动时间设置到需要秒杀的那个时间段，前台也会同步显示到那一个时间段，当时间到达那个时间段后，会自动开始秒杀活动。",
    "hideTips":"收起提示",
    "activityList":"抢购活动列表",
    "total":"共",
    "records":"条记录",
    "help":"帮助",
    "refresh":"刷新数据",
    "to":"至",
    "startTime1":"起始时间",
    "endTime1":"截止时间",
    "activityStatus":"活动状态",
    "activityName":"活动名称",
    "search":"搜索",
    "sum":"抢购总量",
    "price":"抢购价格",
    "activityGoods":"活动商品",
    "startTime2":"开始时间",
    "endTime2":"结束时间",
    "purchased":"已购买",
    "menuNum":"订单数量",
    "status":"状态",
    "recommend":"推荐",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "delete":"删除",
  },
  "groupBuy":{
    "management":"团购管理",
    "auditAndManagement":"网站系统抢购活动审核与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"团购管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "groupBuyList":"团购活动列表",
    "total":"共",
    "records":"条记录",
    "to":"至",
    "startTime1":"起始时间",
    "endTime1":"截止时间",
    "auditStatus":"审核状态",
    "activityName":"活动名称",
    "search":"搜索",
    "goodsName":"商品名称",
    "startTime2":"开始时间",
    "endTime2":"结束时间",
    "isFinished":"是否结束",
    "recommend":"推荐",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "delete":"删除",
  },
  "index":{
    "commonPromotion":"常用促销",
    "marketingMenu":"营销菜单",
    "rushPurchase":"抢购管理",
    "groupBuy":"团购管理",
    "preferential":"优惠促销",
    "menu":"订单促销",
    "preSale":"预售管理",
    "matchingPurchase":"搭配购管理",
    "groupBuying":"拼团购",
    "sharingGroup":"分享团",
    "commissionGroup":"佣金团",
    "luckyDraw":"抽奖团",
    "coupon":"优惠券",
    "newCoupon":"新人优惠券",
  },
  "menu":{
    "commonPromotion":"常用促销",
    "state1":"吸粉、老客带新客，提高下单转化率",
    "groupBuying":"拼团购",
    "state2":"最火爆的引流、拉新和促销的方式",
    "coupon":"优惠劵",
    "state3":"不同类型优惠劵针对性营销",
  },
  "newbieCoupons":{
    "newCoupon":"新人优惠券",
    "settingAndManagement":"商城新人优惠券设定与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"注册7天以内未下过单，并且没有领取过优惠券的用户视为新人，一个新人只允许领取一次新人优惠券.",
    "state2":"新人优惠券使用起始日期为领取时的日期，发放数量无限制，新用户仅限领取一次.",
    "hideTips":"收起提示",
    "newCouponList":"新人优惠券列表",
    "total":"共",
    "records":"条记录",
    "addCoupon":"新增优惠券",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "voucherName":"优惠券名称",
    "search":"搜索",
    "voucherType":"优惠券类型",
    "denomination":"面额",
    "required":"使用需满金额",
    "totalCirculation":"总发行量",
    "issued":"已发放",
    "status":"状态",
    "operation":"操作",
    "check":"查看",
    "edit":"编辑",
    "detail":"详细",
    "delete":"删除",
  },
  "newbieCouponsDetails":{
    "backToList":"返回列表",
    "newCoupon":"新人优惠券",
    "checkCoupon":"查看代金券",
    "release":"查看发布新人代金券",
    "voucherName":"代金券名称",
    "voucherType":"优惠券类型",
    "order":"下单赠送",
    "distributionByUser":"按用户发放",
    "freeDraw":"免费领取",
    "offlineDistribution":"线下发放",
    "newCoupon":"新人优惠卷",
    "denomination":"面额",
    "issuableNum":"可发放总数",
    "describe":"代金券描述",
    "drawed":"已领取",
    "used":"已使用",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
  },
  "newbieCouponsEdit":{
    "backToList":"返回列表",
    "management":"优惠券管理",
    "edit":"编辑新人优惠券",
    "webManagement":"网站系统新人优惠券管理",
    "voucherName":"优惠券名称",
    "denomination":"优惠券面额",
    "consumption":"消费金额",
    "startTime":"发放起始日期",
    "selectTime":"选择日期时间",
    "endTime":"发放结束日期",
    "validityPeriod":"使用有效期",
    "day":"天",
    "state1":"可使用商品：",
    "storeWide":"全店通用",
    "specifiedGood":"指定商品",
    "specifiedSort":"指定分类",
    "specifiedList":"指定商品列表",
    "id":"商品ID",
    "goodsName":"商品名称",
    "price":"价格",
    "stock":"库存",
    "operation":"操作",
    "delete":"删除",
    "state2":"限制商品分类使用：",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
    "submit":"确认提交",
    "select":"选择商品",
    "goodsSort":"商品分类",
    "brand":"品牌",
    "search":"搜索",
  },
  "preSell":{
    "management":"店铺拼团活动管理",
    "mallManagement":"商城店铺拼团活动列表与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"预售活动管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "activityList":"店铺预售活动列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "activityName":"活动名称",
    "search":"搜索",
    "preSaleGoods":"预售商品",
    "activityTime":"活动时间",
    "payTime":"支付时间",
    "orderQuantity":"订购数量",
    "menuNum":"订单数",
    "store":"所属店铺",
    "auditStatus":"审核状态",
    "operation":"操作",
    "detail":"详情",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "menuData":"订单数据",
  },
  "promGoods":{
    "management":"优惠促销管理",
    "auditAndManagement":"网站系统优惠促销审核与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"优惠促销管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "promotionList":"优惠促销列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime1":"起始时间",
    "endTime1":"截止时间",
    "auditStatus":"审核状态",
    "activityName":"活动名称",
    "search":"搜索",
    "activityType":"活动类型",
    "startTime2":"开始时间",
    "endTime2":"结束时间",
    "status":"状态",
    "recommend":"推荐",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "check":"查看商品",
    "cancel":"取消",
    "delete":"删除",
    "title":"标题",
    "goodsId":"商品id",
    "goodsName":"商品名字",
    "price":"价格",
    "store":"货存",
    "administratorShutdown":"管理员关闭",
    "finished":"已结束",
  },
  "promOrder":{
    "management":"订单促销管理",
    "auditAndManagement":"网站系统订单促销审核与管理",
    "state":"订单促销管理, 由总平台设置管理.",
    "promotionList":"订单促销列表",
  },
  "shareTeam":{
    "management":"店铺拼团活动管理",
    "state1":"拼团活动管理, 由总平台设置管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state2":"抢购管理, 由平台设置管理.",
    "hideTips":"收起提示",
    "groupBuyList":"店铺拼团活动列表",
    "total":"共",
    "records":"条记录",
    "help":"帮助",
    "refresh":"刷新数据",
    "title":"拼团标题",
    "search":"搜索",
    "goods":"拼团商品",
    "type":"拼团类型",
    "termOfValidity":"成团有效期",
    "peopleNum":"成团人数",
    "purchaseLimit":"购买限制数",
    "groupNum":"已成团个数",
    "store":"所属店铺",
    "status":"状态",
    "operation":"操作",
    "detail":"详情",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "menuData":"订单数据",
  },
  "teamActivity":{
    "backToList":"返回列表",
    "management":"拼团管理",
    "detail":"拼团详情",
    "checkOrEdit":"查看/编辑商家发布的拼团活动",
    "storeName":"店铺名称",
    "title":"拼团标题",
    "activityType":"活动类型",
    "goods":"拼团商品",
    "peopleNum":"成团人数",
    "purchaseLimit":"购买限制数",
    "salesBase":"虚拟销售基数",
    "sharedTitle":"分享标题",
    "sharedDescribe":"分享描述",
    "status":"状态",
  },
}

export const zh = {
  "collocation":{
    "management":"搭配购套餐管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"搭配购套餐管理, 平台可强制下架套餐,下架后商家不可重新上架，只有平台有权限重新上架.",
    "hideTips":"收起提示",
    "list":"搭配购套餐列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "goodsName":"商品名称",
    "collocationName":"搭配购套餐名称",
    "goodsNum":"套餐内商品数量",
    "storeName":"店铺名称",
    "startTime":"开始时间",
    "endTime":"结束时间",
    "status":"状态",
    "started":"开始中",
    "unstart":"未开始",
    "overdue":"已过期",
    "onShelf":"上架",
    "offShelf":"下架",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "adopt":"通过",
    "reject":"拒绝",
    "detail":"详情",
    "delete":"删除",
  },
  "collocationInfo":{
    "backToList":"返回列表",
    "management":"搭配购套餐管理",
    "edit":"编辑套餐",
    "checkOrEdit":"查看/编辑搭配购套餐",
    "packageName":"套餐名称",
    "startTime":"开始时间",
    "endTime":"结束时间",
    "selectTime":"选择日期时间",
    "describe":"套餐描述",
    "goods":"套餐商品",
    "goodsId":"商品ID",
    "goodsName":"商品名称",
    "goodsImg":"商品图片",
    "goodsType":"商品类型",
    "main":"主商品",
    "side":"副商品",
    "spec":"规格",
    "stock":"库存",
    "costPrice":"成本价格",
    "shopPrice":"商城价格",
  },
  "coupon":{
    "backToList":"返回列表",
    "voucher":"店铺代金券",
    "edit":"编辑代金券",
    "checkOrEdit":"查看/编辑商家发布的代金券",
    "storeName":"店铺名称",
    "voucherName":"代金券名称",
    "voucherType":"优惠券类型",
    "order":"下单赠送",
    "distributionByUser":"按用户发放",
    "freeDraw":"免费领取",
    "offlineDistribution":"线下发放",
    "termOfValidity":"有效期",
    "denomination":"面额",
    "issuableNum":"可发放总数",
    "consumption":"消费金额",
    "describe":"代金券描述",
    "drawed":"已领取",
    "used":"已使用",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
  },
  "couponList":{
    "voucher":"店铺代金券",
    "mallCouponList":"商城店铺代金券领取列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"手工设置代金券失效后,用户将不能领取该代金券,但是已经领取的代金券仍然可以使用.",
    "hideTips":"收起提示",
    "couponList":"店铺代金券领取列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "couponId":"优惠券编号",
    "type":"发放类型",
    "menuId":"订单号",
    "useMember":"使用会员",
    "useTime":"使用时间",
    "status":"状态",
    "operation":"操作",
    "delete":"删除",
  },
  "coupons":{
    "voucher":"店铺代金券",
    "settingAndManagement":"商城店铺代金券活动设定与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"手工设置代金券失效后,用户将不能领取该代金券,但是已经领取的代金券仍然可以使用.",
    "hideTips":"收起提示",
    "voucherList":"店铺代金券列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "voucherName":"优惠券名称",
    "search":"搜索",
    "storeName":"店铺名称",
    "voucherType":"优惠券类型",
    "order":"下单赠送",
    "distributionByUser":"按用户发放",
    "freeDraw":"免费领取",
    "offlineDistribution":"线下发放",
    "newCoupon":"新人优惠卷",
    "denomination":"面额",
    "required":"使用需满金额",
    "totalCirculation":"总发行量",
    "issued":"已发放",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
    "deadline":"使用截止日期",
    "operation":"操作",
    "check":"查看",
    "detail":"详细",
  },
  "flashSale":{
    "management":"抢购管理",
    "auditAndManagement":"网站系统抢购活动审核与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"抢购管理, 由平台设置管理.",
    "state2":"抢购活动开始结束时间跨度必须为两个小时，因为前台抢购活动展示是以每两个小时为一场，否则前台无法展示",
    "state3":"抢购列表页可以让管理员了解活动目前状态，活动商品已购买数以及订单数等一些重要的活动数据。",
    "state4":"抢购活动删除：秒杀活动在没有订单的情况下，是可以删除的，但是一旦有客户下了订单，活动将无法删除。",
    "state5":"将秒杀活动时间设置到需要秒杀的那个时间段，前台也会同步显示到那一个时间段，当时间到达那个时间段后，会自动开始秒杀活动。",
    "hideTips":"收起提示",
    "activityList":"抢购活动列表",
    "total":"共",
    "records":"条记录",
    "help":"帮助",
    "refresh":"刷新数据",
    "to":"至",
    "startTime1":"起始时间",
    "endTime1":"截止时间",
    "activityStatus":"活动状态",
    "activityName":"活动名称",
    "search":"搜索",
    "sum":"抢购总量",
    "price":"抢购价格",
    "activityGoods":"活动商品",
    "startTime2":"开始时间",
    "endTime2":"结束时间",
    "purchased":"已购买",
    "menuNum":"订单数量",
    "status":"状态",
    "recommend":"推荐",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "delete":"删除",
  },
  "groupBuy":{
    "management":"团购管理",
    "auditAndManagement":"网站系统抢购活动审核与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"团购管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "groupBuyList":"团购活动列表",
    "total":"共",
    "records":"条记录",
    "to":"至",
    "startTime1":"起始时间",
    "endTime1":"截止时间",
    "auditStatus":"审核状态",
    "activityName":"活动名称",
    "search":"搜索",
    "goodsName":"商品名称",
    "startTime2":"开始时间",
    "endTime2":"结束时间",
    "isFinished":"是否结束",
    "recommend":"推荐",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "delete":"删除",
  },
  "index":{
    "commonPromotion":"常用促销",
    "marketingMenu":"营销菜单",
    "rushPurchase":"抢购管理",
    "groupBuy":"团购管理",
    "preferential":"优惠促销",
    "menu":"订单促销",
    "preSale":"预售管理",
    "matchingPurchase":"搭配购管理",
    "groupBuying":"拼团购",
    "sharingGroup":"分享团",
    "commissionGroup":"佣金团",
    "luckyDraw":"抽奖团",
    "coupon":"优惠券",
    "newCoupon":"新人优惠券",
  },
  "menu":{
    "commonPromotion":"常用促销",
    "state1":"吸粉、老客带新客，提高下单转化率",
    "groupBuying":"拼团购",
    "state2":"最火爆的引流、拉新和促销的方式",
    "coupon":"优惠劵",
    "state3":"不同类型优惠劵针对性营销",
  },
  "newbieCoupons":{
    "newCoupon":"新人优惠券",
    "settingAndManagement":"商城新人优惠券设定与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"注册7天以内未下过单，并且没有领取过优惠券的用户视为新人，一个新人只允许领取一次新人优惠券.",
    "state2":"新人优惠券使用起始日期为领取时的日期，发放数量无限制，新用户仅限领取一次.",
    "hideTips":"收起提示",
    "newCouponList":"新人优惠券列表",
    "total":"共",
    "records":"条记录",
    "addCoupon":"新增优惠券",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始日期",
    "endTime":"截止日期",
    "voucherName":"优惠券名称",
    "search":"搜索",
    "voucherType":"优惠券类型",
    "denomination":"面额",
    "required":"使用需满金额",
    "totalCirculation":"总发行量",
    "issued":"已发放",
    "status":"状态",
    "operation":"操作",
    "check":"查看",
    "edit":"编辑",
    "detail":"详细",
    "delete":"删除",
  },
  "newbieCouponsDetails":{
    "backToList":"返回列表",
    "newCoupon":"新人优惠券",
    "checkCoupon":"查看代金券",
    "release":"查看发布新人代金券",
    "voucherName":"代金券名称",
    "voucherType":"优惠券类型",
    "order":"下单赠送",
    "distributionByUser":"按用户发放",
    "freeDraw":"免费领取",
    "offlineDistribution":"线下发放",
    "newCoupon":"新人优惠卷",
    "denomination":"面额",
    "issuableNum":"可发放总数",
    "describe":"代金券描述",
    "drawed":"已领取",
    "used":"已使用",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
  },
  "newbieCouponsEdit":{
    "backToList":"返回列表",
    "management":"优惠券管理",
    "edit":"编辑新人优惠券",
    "webManagement":"网站系统新人优惠券管理",
    "voucherName":"优惠券名称",
    "denomination":"优惠券面额",
    "consumption":"消费金额",
    "startTime":"发放起始日期",
    "selectTime":"选择日期时间",
    "endTime":"发放结束日期",
    "validityPeriod":"使用有效期",
    "day":"天",
    "state1":"可使用商品：",
    "storeWide":"全店通用",
    "specifiedGood":"指定商品",
    "specifiedSort":"指定分类",
    "specifiedList":"指定商品列表",
    "id":"商品ID",
    "goodsName":"商品名称",
    "price":"价格",
    "stock":"库存",
    "operation":"操作",
    "delete":"删除",
    "state2":"限制商品分类使用：",
    "status":"状态",
    "effective":"有效",
    "invalid":"无效",
    "submit":"确认提交",
    "select":"选择商品",
    "goodsSort":"商品分类",
    "brand":"品牌",
    "search":"搜索",
  },
  "preSell":{
    "management":"店铺拼团活动管理",
    "mallManagement":"商城店铺拼团活动列表与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"预售活动管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "activityList":"店铺预售活动列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "activityName":"活动名称",
    "search":"搜索",
    "preSaleGoods":"预售商品",
    "activityTime":"活动时间",
    "payTime":"支付时间",
    "orderQuantity":"订购数量",
    "menuNum":"订单数",
    "store":"所属店铺",
    "auditStatus":"审核状态",
    "operation":"操作",
    "detail":"详情",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "menuData":"订单数据",
  },
  "promGoods":{
    "management":"优惠促销管理",
    "auditAndManagement":"网站系统优惠促销审核与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"优惠促销管理, 由总平台设置管理.",
    "hideTips":"收起提示",
    "promotionList":"优惠促销列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime1":"起始时间",
    "endTime1":"截止时间",
    "auditStatus":"审核状态",
    "activityName":"活动名称",
    "search":"搜索",
    "activityType":"活动类型",
    "startTime2":"开始时间",
    "endTime2":"结束时间",
    "status":"状态",
    "recommend":"推荐",
    "yes":"是",
    "no":"否",
    "operation":"操作",
    "check":"查看商品",
    "cancel":"取消",
    "delete":"删除",
    "title":"标题",
    "goodsId":"商品id",
    "goodsName":"商品名字",
    "price":"价格",
    "store":"货存",
    "administratorShutdown":"管理员关闭",
    "finished":"已结束",
  },
  "promOrder":{
    "management":"订单促销管理",
    "auditAndManagement":"网站系统订单促销审核与管理",
    "state":"订单促销管理, 由总平台设置管理.",
    "promotionList":"订单促销列表",
  },
  "shareTeam":{
    "management":"店铺拼团活动管理",
    "state1":"拼团活动管理, 由总平台设置管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state2":"抢购管理, 由平台设置管理.",
    "hideTips":"收起提示",
    "groupBuyList":"店铺拼团活动列表",
    "total":"共",
    "records":"条记录",
    "help":"帮助",
    "refresh":"刷新数据",
    "title":"拼团标题",
    "search":"搜索",
    "goods":"拼团商品",
    "type":"拼团类型",
    "termOfValidity":"成团有效期",
    "peopleNum":"成团人数",
    "purchaseLimit":"购买限制数",
    "groupNum":"已成团个数",
    "store":"所属店铺",
    "status":"状态",
    "operation":"操作",
    "detail":"详情",
    "adopt":"通过",
    "reject":"拒绝",
    "cancel":"取消",
    "menuData":"订单数据",
  },
  "teamActivity":{
    "backToList":"返回列表",
    "management":"拼团管理",
    "detail":"拼团详情",
    "checkOrEdit":"查看/编辑商家发布的拼团活动",
    "storeName":"店铺名称",
    "title":"拼团标题",
    "activityType":"活动类型",
    "goods":"拼团商品",
    "peopleNum":"成团人数",
    "purchaseLimit":"购买限制数",
    "salesBase":"虚拟销售基数",
    "sharedTitle":"分享标题",
    "sharedDescribe":"分享描述",
    "status":"状态",
  },
}
