export const en = {
  "userSign":{
    "signConfig":{
        "tips":"操作提示",
        "tipsNode":"提示相关设置操作时应注意的要点",
        "state1":" 签到列表：签到人员记录，签到记录最多保持一个月",
        "state2":"签到规则：可设置功能开启关闭，签到积分和连续签到额外奖励",
        "hideTips":"收起提示",
        "integralSwitch":"签到送积分开关",
        "integral":"签到送积分",
        "state3":"会员每次签到赠送的积分",
        "continuousSignIn":"连续签到",
        "day":"天",
        "state4":"会员连续签到可作为额外积分奖励",
        "signInIntegral":"连续签到积分奖励",
        "state5":"会员连续签到积分奖励，满足连续签将按此积分设置每天增加此积分，直到断签为止",
        "submit":"确认提交",    
    },
    "userSignList":{
        "tips":"操作提示",
        "tipsNode":"提示相关设置操作时应注意的要点",
        "state1":" 签到列表：签到人员记录，签到记录最多保持一个月",
        "state2":"签到规则：可设置功能开启关闭，签到积分和连续签到额外奖励",
        "hideTips":"收起提示",
        "signList":"签到列表",
        "phone":"手机号码",
        "search":"搜索",
        "signId":"签到id",
        "username":"用户名称",
        "total":"累计签到天数",
        "continuity":"连续签到天数",
        "last":"最后签到时间",
        "history":"历史签到",
        "monthIntegral":"本月积分",
        "totalIntegral":"累计签到积分",
    },
  },
  "addAccount":{
    "backToList":"返回列表",
    "management":'会员管理',
    "adjust":"资金调节",
    "webManagement":'网站系统会员管理',
    "amount":"金额",
    "increase":"增加",
    "decrease":"减少",
    "userableBalance":"可用余额",
    "userableIntegral":"可用积分",
    "unit":"单位",
    "integral":"积分",
    "int":"整数",
    "freeze":"冻结资金",
    "increaseFreeze":"增加冻结资金",
    "decreaseFreeze":"减少冻结资金",
    "state":"当操作冻结资金时，金额那一栏不用填写数值。单位",
    "operateAndRemark":"操作备注",
    "toOperateAndRemark":"请输入操作备注",
    "submit":"确认提交",
  },
  "addMember":{
    "backToList":"返回列表",
    "management":'会员管理',
    "add":"添加会员",
    "webAdd":'网站系统添加会员',
    "nickname":"会员昵称",
    "password":"登录密码",
    "combination":"位字母数字符号组合",
    "phone":"手机号码",
    "state":"前台登陆账号，手机邮箱任意一项都可以",
    "email":"邮件地址",
    "gender":"性别",
    "secrecy":"保密",
    "male":"男",
    "female":"女",
    "submit":"确认提交",
  },
  "brand":{
    "backToList":"返回列表",
    "management":"品牌管理",
    "addAndModify":"品牌添加与修改",
    "brandName":"品牌名称",
    "brandSite":"品牌网址",
    "categoryParent":"上级分类",
    "state":"最多成为第三级,如果设置为第二级, 只选择第一级即可",
    "brandLogo":"品牌logo",
    "upload":"请上传图片格式文件,建议尺寸大小",
    "sort":"排序",
    "describe":"品牌描述",
    "submit":"确认提交",
  },
  "brands":{
    "brandList":"品牌列表",
    "management":"网站系统品牌管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"同一个品牌可以添加多次.",
    "state2":"比如卖笔记本下面一个苹果品牌. 卖手机下面也有苹果牌,卖箱包下面也有苹果牌.",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "add":"新增品牌",
    "batchDelete":"批量删除",
    "refresh":"刷新数据",
    "status":"状态",
    "brandName":"品牌名称",
    "search":"搜索",
    "brandClassification":"品牌分类",
    "recommend":"推荐",
    "sort":"排序",
    "operation":"操作",
    "yes":"是",
    "no":"否",
    "edit":"编辑",
    "delete":"删除",
  },
  "goods":{
    "goodsManagement":"商品管理",
    "allGoodsManagement":"商城所有商品索引及管理",
    "allGoods":"所有商品",
    "waitReview":"等待审核",
    "passReview":"审核通过",
    "failReview":"审核失败",
    "removal":"违规下架",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"平台可以强制下架商家违规的产品，对于商家发布的商品需要审核",
    "state2":"上架，当商品处于非上架状态时，前台将不能浏览该商品，店主可控制商品上架状态",
    "state3":"违规下架，当商品处于违规下架状态时，前台将不能购买该商品，只有管理员可控制商品违规下架状态，并且商品只有重新编辑后才能上架",
    "hideTips":"收起提示",
    "goodsList":"商品列表",  
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "goodsSort":"商品分类",
    "brand":"品牌",
    "searchContent":"搜索内容",
    "searchType":"搜索类型",
    "goodsName":"商品名称",
    "artNo":"货号",
    "search":"搜索",
    "goodsID":"商品ID",
    "sort":"分类",
    "price":"价格",
    "store":"库存",
    "shelfTime":"上架时间",
    "goodsStatus":"商品状态",
    "reviewStatus":"审核状态",
    "storeName":"店铺名称",
    "storeType":"店铺类型",
    "operation":"操作",
    "top":"顶置",
    "offShelf":"下架",
    "check":"查看",
    "reason":"违规下架理由",
  },
  "goodsCategories":{
    "sortManagement":"商品分类管理",
    "addAndManage":"网站文章分类添加与管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"温馨提示：顶级分类（一级大类）设为推荐时才会在首页楼层中显示",
    "state2":"最多只能分类到三级",
    "state3":"佣金比例填写0-99整数",
    "hideTips":"收起提示",
    "sortList":"商品分类列表",
    "addSort":"新增分类",
    "refresh":"刷新数据",
    "sortID":"分类ID",
    "sortName":"分类名称",
    "model":"模型",
    "isHot":"是否热卖",
    "isShow":"是否显示",
    "rate":"佣金比例",
    "commissionRate":"分佣比例",
    "group":"分组",
    "sort":"排序",
    "operation":"操作",
    "taxation":"税费",
    "edit":"编辑",
    "addChild":"新增下级",
    "delete":"删除",
  },
  "goodsCategory":{
    "backToList":"返回列表",
    "goodsSort":"商品分类",
    "addAndModify":"添加修改分类",
    "addOrEdit":"添加或编辑商品分类",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"温馨提示：顶级分类（一级大类）设为推荐时才会在首页楼层中显示",
    "state2":"最多只能分类到三级",
    "state3":"佣金比例填写0-99整数",
    "hideTips":"收起提示",
    "sortName":"分类名称",
    "mobileSortName":"移动端分类名称",
    "categoryParent":"上级分类",
    "categoryTop":"顶级分类",
    "nav":"导航显示",
    "sortGroup":"分类分组",
    "toSortGroup":"请选择分类分组",
    "sortImg":"分类图片",
    "sort":"排序",
    "proportion":"抽成比例",
    "submit":"确认提交",
  },
  "goodsType":{
    "backToList":"返回列表",
    "modelManagement":"模型管理",
    "addAndModify":"添加修改模型",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"商品模型是用来规定某一类商品共有规格和属性的集合，其中规格会影响商品价格，同一个商品不同的规格价格会不同，而属性仅仅是商品的属性特质展示",
    "state2":"商品模型用于管理某一类商品的规格、属性",
    "state3":"发布某一个商品时，选中商品分类,如果该商品分类绑定了某个商品模型,那么该商品就有这个模型的所有规格和属性<",
    "hideTips":"收起提示",
    "modelName":"模型名称",
    "toModelName":"请输入模型名称",
    "bindSort":"绑定分类",
    "addBindSort":"新增绑定分类",
    "specSetting":"规格设置",
    "sort":"排序",
    "toEnter":"请输入内容",
    "specName":"规格名称",
    "isUpload":"是否可上传规格图",
    "operation":"操作",
    "addSpec":"新增规格",
    "propertySetting":"属性设置",
    "propertyName":"属性名称",
    "isShow":"是否显示",
    "property":"属性值",
    "addProperty1":"新增属性值",
    "addProperty2":"新增属性",
    "customProperty":"自定义属性设置",
    "addCustomProperty":"新增自定义属性",
    "brand":"品牌",
    "goodsSort":"商品分类",
    "submit":"确认提交",
  },
  "goodsTypes":{
    "goodsModel":"商品模型",
    "goodsModelList":"商品模型列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"商品模型是用来规定某一类商品共有规格和属性的集合，其中规格会影响商品价格，同一个商品不同的规格价格会不同，而属性仅仅是商品的属性特质展示.",
    "state2":"抢如果要删除某个商品模型, 请先手动解除关联的'品牌'、'规格'、'属性'后再删除",
    "hideTips":"收起提示",
    "modelList":"模型列表",
    "addModel":"新增商品模型",
    "refresh":"刷新数据",
    "modelName":"模型名称",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "index":{
    "goods":"商品",
    "goodsList":"商品列表",
    "goodsSort":"商品分类",
    "inventoryLog":"库存日志",
    "goodsModel":"商品模型",
    "brandList":"品牌列表",
	"inventory":"商品清单",
    "member":"会员",
    "memberList":"会员列表",
    "memberLevel":"会员等级",
    "recharge":"充值记录",
    "memberSignIn":"会员签到",
	"forum":"论坛",
	"trendRelease":"动态发布",
	"dynamicManage":"动态管理",
	"dynamicType":"动态类型",
	"dynamicExamine":"动态审核",
	"ExamineSetting":"审核设置"
  },
  "memberAccount":{
    "fundList":"账户资金记录列表",
    "webFundList":"网站系统会员账户资金记录列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员账户资金记录列表, 由总平台设置管理.",
    "hideTips":"收起提示",
    "memberFundList":"会员账户资金记录列表",
    "total":"共",
    "records":"条记录",
    "adjust":"资金调节",
    "backToList":"返回会员列表",
    "refresh":"刷新数据",
    "change":"变动时间",
    "describe":"描述",
    "fund":"用户资金",
    "freeze":"冻结资金",
    "integral":"用户积分",
  },
  "memberAddress":{
    "addressList":"会员收货地址列表",
    "webAddressList":"网站系统会员收货地址列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员收货地址列表，由总品台设置管理",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "backToList":"返回会员列表",
    "refresh":"刷新数据",
    "consignee":"收货人",
    "contact":"联系方式",
    "postalCode":"邮政编码",
    "address":"地址",
  },
  "memberDetails":{
    "backToList":"返回列表",
    "management":"会员管理",
    "message":"会员信息",
    "webManagement":"网站系统会员管理会员信息",
    "nickname":"会员昵称",
    "state1":"会员昵称不可修改。",
    "userIntegral":"用户积分",
    "integral":"积分",
    "balance":"账户余额",
    "phoneNum":"手机号码",
    "state2":"请输入常用的手机号码，将用来找回密码、接受订单通知等。",
    "email":"电子邮箱",
    "state3":"请输入常用的邮箱，将用来找回密码、接受订单通知等。",
    "gender":"性别",
    "secrecy":"保密",
    "male":"男",
    "female":"女",
    "regTime":"注册时间",
    "freeze":"冻结会员",
    "state4":"如果冻结会员，会员将不能操作资金。",
    "distribution":"分销功能",
    "state5":"如果开启，会员参与分销。",
    "changeSuperior":"更改上级",
    "toSearch":"请输入上级手机号码搜索",
    "testing":"检测",
    "toChange":"确认更改",
    "state6":"该办法只适合用于三级关系，不能用4级，请慎重操作",
    "surplus":"用户余额",
    "firstNum":"上一级编号",
    "secondNum":"上二级编号",
    "thirdNum":"上三级编号",
    "sumOfMoney":"累积分佣金额",
    "firstOffline":"一级下线数",
    "secondOffline":"二级下线数",
    "thirdOffline":"三级下线数",
    "submit":"确认提交",
  },
  "memberLevel":{
    "backToList":"返回列表",
    "management":"会员等级管理",
    "editLevel":"编辑会员等级",
    "webManagement":"网站系统抢会员等级管理",
    "levelName":"等级名称",
    "setName":"设置等级名称",
    "limit":"消费额度",
    "state1":"设置会员等级所需要的消费额度,单位:",
    "discountRate":"折扣率",
    "state2":"折扣率单位为百分比，如输入90，表示该会员等级的用户可以以商品原价的90%购买",
    "describe":"等级描述",
    "describeMessage":"会员等级描述信息",
    "submit":"确认提交",
  },
  "memberLevels":{
    "management":"会员等级管理",
    "webManagement":"网站系统会员等级索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员等级管理，由总品台设置管理",
    "hideTips":"收起提示",
    "levelList":"会员等级列表",
    "total":"共",
    "records":"条记录",
    "addLevel":"新增会员等级",
    "refresh":"刷新数据",
    "level":"等级",
    "levelName":"等级名称",
    "limit":"消费额度",
    "discountRate":"折扣率",
    "describe":"等级描述",
	"isCod":"是否支持货到付款",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "members":{
    "backToList":"返回列表",
    "management":"会员管理",
    "webManagement":"网站系统会员索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"通过会员管理，你可以进行查看、编辑会员资料等操作",
    "state2":"你可以根据条件搜索会员，然后选择相应的操作",
    "hideTips":"收起提示",
    "memberList":"会员列表",
    "total":"共",
    "records":"条记录",
    "addMember":"添加会员",
    "state3":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出会员",
    "sendMail":"发送站内信",
    "mail":"站内信",
    "refresh":"刷新数据",
    "id":"会员ID",
    "account":"会员账户",
    "nickname":"会员昵称",
    "search":"搜索",
    "level":"会员等级",
    "addUp":"累计消费",
    "email":"邮件地址",
    "firstOffline":"一级下线",
    "secondOffline":"二级下线",
    "thirdOffline":"三级下线",
    "phoneNum":"手机号码",
    "balance":"余额",
    "integral":"积分",
    "regTime":"注册日期",
    "operation":"操作",
    "detail":"详情",
    "capital":"资金",
    "address":"收货地址",
    "range":"发送范围：",
    "below":"以下列表",
    "all":"全体会员",
    "list":"会员列表：",
    "title":"发送标题：",
    "state4":"发送标题不填默认为输入框中提示的文字",
    "content":"发送内容：",
    "state5":"发送内容不填默认为输入框中提示的文字，占位符会被相应数据替换",
    "send":"发 送",
  },
  "rechargeRecord":{
    "backToList":"返回列表",
    "management":"会员管理",
    "webManagement":"网站系统会员索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员充值记录, 由总平台设置管理.",
    "hideTips":"收起提示",
    "historyList":"会员充值记录列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "nickname":"会员昵称",
    "search":"搜索",
    "id":"会员ID",
    "menuNum":"充值单号",
    "fund":"充值资金",
    "time":"提交时间",
    "method":"支付方式",
    "status":"支付状态",
    "serialNum":"交易流水号",
  },
  "stockLog":{
    "management":"商品管理",
    "inventoryLog":"库存日志",
    "webInventoryLog":"网站系统库存日志",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startDate":"下单日期起始",
    "endDate":"下单日期截止",
    "all":"全部",
    "search":"搜索",
    "id":"编号",
    "goodsName":"商品名称",
    "goodsSpec":"商品规格",
    "storeName":"商家名称",
    "menuId":"订单号",
    "store":"库存",
    "type":"库存类型",
    "cargoStore":"货品库存",
    "goodsStore":"商品库存",
    "logTime":"日志时间",
  },
  "tax":{
    "backToList":"返回列表",
    "taxRateByRegions":"商品分类地区税率",
    "addAndModify":"添加修改税率",
    "addOrEdit":"添加或编辑税率",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"商品分类地区税率设置",
    "state2":"如分类未设置,使用父类的税率",
    "hideTips":"收起提示",
    "parentTaxRate":"父类的税率",
    "region":"地区",
    "taxRate":"税率",
    "addRegion":"添加地区",
    "toTaxRate":"请输入税率",
    "submit":"确认提交",
  },
  "_userSign":{
    "management":"签到管理",
    "signAndSetting":"会员签到及管理设置",
    "signList":"签到列表",
    "signRule":"签到规则",
  },
  "trend":{
	  "dyModify":"修改",
	  "dyTitle":"动态标题",
	  "dyImage":"动态图片",
	  "dyContent":"动态内容",
	  "upload":"建议图片大小的尺寸为",
	  "submit":"修改提交",
	  "backToList":"动态管理",
	  "dyVideo":"动态视频",
  },
}

export const zh = {
  "userSign":{
    "signConfig":{
        "tips":"操作提示",
        "tipsNode":"提示相关设置操作时应注意的要点",
        "state1":" 签到列表：签到人员记录，签到记录最多保持一个月",
        "state2":"签到规则：可设置功能开启关闭，签到积分和连续签到额外奖励",
        "hideTips":"收起提示",
        "integralSwitch":"签到送积分开关",
        "integral":"签到送积分",
        "state3":"会员每次签到赠送的积分",
        "continuousSignIn":"连续签到",
        "day":"天",
        "state4":"会员连续签到可作为额外积分奖励",
        "signInIntegral":"连续签到积分奖励",
        "state5":"会员连续签到积分奖励，满足连续签将按此积分设置每天增加此积分，直到断签为止",
        "submit":"确认提交",    
    },
    "userSignList":{
        "tips":"操作提示",
        "tipsNode":"提示相关设置操作时应注意的要点",
        "state1":" 签到列表：签到人员记录，签到记录最多保持一个月",
        "state2":"签到规则：可设置功能开启关闭，签到积分和连续签到额外奖励",
        "hideTips":"收起提示",
        "signList":"签到列表",
        "phone":"手机号码",
        "search":"搜索",
        "signId":"签到id",
        "username":"用户名称",
        "total":"累计签到天数",
        "continuity":"连续签到天数",
        "last":"最后签到时间",
        "history":"历史签到",
        "monthIntegral":"本月积分",
        "totalIntegral":"累计签到积分",
    },
  },
  "addAccount":{
    "backToList":"返回列表",
    "management":'会员管理',
    "adjust":"资金调节",
    "webManagement":'网站系统会员管理',
    "amount":"金额",
    "increase":"增加",
    "decrease":"减少",
    "userableBalance":"可用余额",
    "userableIntegral":"可用积分",
    "unit":"单位",
    "integral":"积分",
    "int":"整数",
    "freeze":"冻结资金",
    "increaseFreeze":"增加冻结资金",
    "decreaseFreeze":"减少冻结资金",
    "state":"当操作冻结资金时，金额那一栏不用填写数值。单位",
    "operateAndRemark":"操作备注",
    "toOperateAndRemark":"请输入操作备注",
    "submit":"确认提交",
  },   
  "addMember":{
    "backToList":"返回列表",
    "management":'会员管理',
    "add":"添加会员",
    "webAdd":'网站系统添加会员',
    "nickname":"会员昵称",
    "password":"登录密码",
    "combination":"位字母数字符号组合",
    "phone":"手机号码",
    "state":"前台登陆账号，手机邮箱任意一项都可以",
    "email":"邮件地址",
    "gender":"性别",
    "secrecy":"保密",
    "male":"男",
    "female":"女",
    "submit":"确认提交",  
  },
  "brand":{
    "backToList":"返回列表",
    "management":"品牌管理",
    "addAndModify":"品牌添加与修改",
    "brandName":"品牌名称",
    "brandSite":"品牌网址",
    "categoryParent":"上级分类",
    "state":"最多成为第三级,如果设置为第二级, 只选择第一级即可",
    "brandLogo":"品牌logo",
    "upload":"请上传图片格式文件,建议尺寸大小",
    "sort":"排序",
    "describe":"品牌描述",
    "submit":"确认提交",
  },
  "brands":{
    "brandList":"品牌列表",
    "management":"网站系统品牌管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"同一个品牌可以添加多次.",
    "state2":"比如卖笔记本下面一个苹果品牌. 卖手机下面也有苹果牌,卖箱包下面也有苹果牌.",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "add":"新增品牌",
    "batchDelete":"批量删除",
    "refresh":"刷新数据",
    "status":"状态",
    "brandName":"品牌名称",
    "search":"搜索",
    "brandClassification":"品牌分类",
    "recommend":"推荐",
    "sort":"排序",
    "operation":"操作",
    "yes":"是",
    "no":"否",
    "edit":"编辑",
    "delete":"删除",
  },
  "goods":{
    "goodsManagement":"商品管理",
    "allGoodsManagement":"商城所有商品索引及管理",
    "allGoods":"所有商品",
    "waitReview":"等待审核",
    "passReview":"审核通过",
    "failReview":"审核失败",
    "removal":"违规下架",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"平台可以强制下架商家违规的产品，对于商家发布的商品需要审核",
    "state2":"上架，当商品处于非上架状态时，前台将不能浏览该商品，店主可控制商品上架状态",
    "state3":"违规下架，当商品处于违规下架状态时，前台将不能购买该商品，只有管理员可控制商品违规下架状态，并且商品只有重新编辑后才能上架",
    "hideTips":"收起提示",
    "goodsList":"商品列表",  
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "goodsSort":"商品分类",
    "brand":"品牌",
    "searchContent":"搜索内容",
    "searchType":"搜索类型",
    "goodsName":"商品名称",
    "artNo":"货号",
    "search":"搜索",
    "goodsID":"商品ID",
    "sort":"分类",
    "price":"价格",
    "store":"库存",
    "shelfTime":"上架时间",
    "goodsStatus":"商品状态",
    "reviewStatus":"审核状态",
    "storeName":"店铺名称",
    "storeType":"店铺类型",
    "operation":"操作",
    "top":"顶置",
    "offShelf":"下架",
    "check":"查看",
    "reason":"违规下架理由",
  },
  "goodsCategories":{
    "sortManagement":"商品分类管理",
    "addAndManage":"网站文章分类添加与管理",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"温馨提示：顶级分类（一级大类）设为推荐时才会在首页楼层中显示",
    "state2":"最多只能分类到三级",
    "state3":"佣金比例填写0-99整数",
    "hideTips":"收起提示",
    "sortList":"商品分类列表",
    "addSort":"新增分类",
    "refresh":"刷新数据",
    "sortID":"分类ID",
    "sortName":"分类名称",
    "model":"模型",
    "isHot":"是否热卖",
    "isShow":"是否显示",
    "rate":"佣金比例",
    "commissionRate":"分佣比例",
    "group":"分组",
    "sort":"排序",
    "operation":"操作",
    "taxation":"税费",
    "edit":"编辑",
    "addChild":"新增下级",
    "delete":"删除",
  },
  "goodsCategory":{
    "backToList":"返回列表",
    "goodsSort":"商品分类",
    "addAndModify":"添加修改分类",
    "addOrEdit":"添加或编辑商品分类",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"温馨提示：顶级分类（一级大类）设为推荐时才会在首页楼层中显示",
    "state2":"最多只能分类到三级",
    "state3":"佣金比例填写0-99整数",
    "hideTips":"收起提示",
    "sortName":"分类名称",
    "mobileSortName":"移动端分类名称",
    "categoryParent":"上级分类",
    "categoryTop":"顶级分类",
    "nav":"导航显示",
    "sortGroup":"分类分组",
    "toSortGroup":"请选择分类分组",
    "sortImg":"分类图片",
    "sort":"排序",
    "proportion":"抽成比例",
    "submit":"确认提交",
  },
  "goodsType":{
    "backToList":"返回列表",
    "modelManagement":"模型管理",
    "addAndModify":"添加修改模型",
    "tips":"操作提示",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "state1":"商品模型是用来规定某一类商品共有规格和属性的集合，其中规格会影响商品价格，同一个商品不同的规格价格会不同，而属性仅仅是商品的属性特质展示",
    "state2":"商品模型用于管理某一类商品的规格、属性",
    "state3":"发布某一个商品时，选中商品分类,如果该商品分类绑定了某个商品模型,那么该商品就有这个模型的所有规格和属性<",
    "hideTips":"收起提示",
    "modelName":"模型名称",
    "toModelName":"请输入模型名称",
    "bindSort":"绑定分类",
    "addBindSort":"新增绑定分类",
    "specSetting":"规格设置",
    "sort":"排序",
    "toEnter":"请输入内容",
    "specName":"规格名称",
    "isUpload":"是否可上传规格图",
    "operation":"操作",
    "addSpec":"新增规格",
    "propertySetting":"属性设置",
    "propertyName":"属性名称",
    "isShow":"是否显示",
    "property":"属性值",
    "addProperty1":"新增属性值",
    "addProperty2":"新增属性",
    "customProperty":"自定义属性设置",
    "addCustomProperty":"新增自定义属性",
    "brand":"品牌",
    "goodsSort":"商品分类",
    "submit":"确认提交",
  },
  "goodsTypes":{
    "goodsModel":"商品模型",
    "goodsModelList":"商品模型列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"商品模型是用来规定某一类商品共有规格和属性的集合，其中规格会影响商品价格，同一个商品不同的规格价格会不同，而属性仅仅是商品的属性特质展示.",
    "state2":"抢如果要删除某个商品模型, 请先手动解除关联的'品牌'、'规格'、'属性'后再删除",
    "hideTips":"收起提示",
    "modelList":"模型列表",
    "addModel":"新增商品模型",
    "refresh":"刷新数据",
    "modelName":"模型名称",
    "search":"搜索",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "index":{
    "goods":"商品",
    "goodsList":"商品列表",
    "goodsSort":"商品分类",
    "inventoryLog":"库存日志",
    "goodsModel":"商品模型",
    "brandList":"品牌列表",
	"inventory":"商品清单",
    "member":"会员",
    "memberList":"会员列表",
    "memberLevel":"会员等级",
    "recharge":"充值记录",
    "memberSignIn":"会员签到",
	"forum":"论坛",
	"trendRelease":"动态发布",
	"dynamicManage":"动态管理",
	"dynamicType":"动态类型",
	"dynamicExamine":"动态审核",
	"ExamineSetting":"审核设置"
	
  },
  "memberAccount":{
    "fundList":"账户资金记录列表",
    "webFundList":"网站系统会员账户资金记录列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员账户资金记录列表, 由总平台设置管理.",
    "hideTips":"收起提示",
    "memberFundList":"会员账户资金记录列表",
    "total":"共",
    "records":"条记录",
    "adjust":"资金调节",
    "backToList":"返回会员列表",
    "refresh":"刷新数据",
    "change":"变动时间",
    "describe":"描述",
    "fund":"用户资金",
    "freeze":"冻结资金",
    "integral":"用户积分",
  },
  "memberAddress":{
    "addressList":"会员收货地址列表",
    "webAddressList":"网站系统会员收货地址列表",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员收货地址列表，由总品台设置管理",
    "hideTips":"收起提示",
    "total":"共",
    "records":"条记录",
    "backToList":"返回会员列表",
    "refresh":"刷新数据",
    "consignee":"收货人",
    "contact":"联系方式",
    "postalCode":"邮政编码",
    "address":"地址",
  },
  "memberDetails":{
    "backToList":"返回列表",
    "management":"会员管理",
    "message":"会员信息",
    "webManagement":"网站系统会员管理会员信息",
    "nickname":"会员昵称",
    "state1":"会员昵称不可修改。",
    "userIntegral":"用户积分",
    "integral":"积分",
    "balance":"账户余额",
    "phoneNum":"手机号码",
    "state2":"请输入常用的手机号码，将用来找回密码、接受订单通知等。",
    "email":"电子邮箱",
    "state3":"请输入常用的邮箱，将用来找回密码、接受订单通知等。",
    "gender":"性别",
    "secrecy":"保密",
    "male":"男",
    "female":"女",
    "regTime":"注册时间",
    "freeze":"冻结会员",
    "state4":"如果冻结会员，会员将不能操作资金。",
    "distribution":"分销功能",
    "state5":"如果开启，会员参与分销。",
    "changeSuperior":"更改上级",
    "toSearch":"请输入上级手机号码搜索",
    "testing":"检测",
    "toChange":"确认更改",
    "state6":"该办法只适合用于三级关系，不能用4级，请慎重操作",
    "surplus":"用户余额",
    "firstNum":"上一级编号",
    "secondNum":"上二级编号",
    "thirdNum":"上三级编号",
    "sumOfMoney":"累积分佣金额",
    "firstOffline":"一级下线数",
    "secondOffline":"二级下线数",
    "thirdOffline":"三级下线数",
    "submit":"确认提交",
  },
  "memberLevel":{
    "backToList":"返回列表",
    "management":"会员等级管理",
    "editLevel":"编辑会员等级",
    "webManagement":"网站系统抢会员等级管理",
    "levelName":"等级名称",
    "setName":"设置等级名称",
    "limit":"消费额度",
    "state1":"设置会员等级所需要的消费额度,单位",
    "discountRate":"折扣率",
    "state2":"折扣率单位为百分比，如输入90，表示该会员等级的用户可以以商品原价的90%购买",
    "describe":"等级描述",
    "describeMessage":"会员等级描述信息",
    "submit":"确认提交",
  },
  "memberLevels":{
    "management":"会员等级管理",
    "webManagement":"网站系统会员等级索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员等级管理，由总品台设置管理",
    "hideTips":"收起提示",
    "levelList":"会员等级列表",
    "total":"共",
    "records":"条记录",
    "addLevel":"新增会员等级",
    "refresh":"刷新数据",
    "level":"等级",
    "levelName":"等级名称",
    "limit":"消费额度",
    "discountRate":"折扣率",
    "describe":"等级描述",
	"isCod":"是否支持货到付款",
    "operation":"操作",
    "edit":"编辑",
    "delete":"删除",
  },
  "members":{
    "backToList":"返回列表",
    "management":"会员管理",
    "webManagement":"网站系统会员索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"通过会员管理，你可以进行查看、编辑会员资料等操作",
    "state2":"你可以根据条件搜索会员，然后选择相应的操作",
    "hideTips":"收起提示",
    "memberList":"会员列表",
    "total":"共",
    "records":"条记录",
    "addMember":"添加会员",
    "state3":"选定行数据导出excel文件,如果不选中行，将导出列表所有数据",
    "export":"导出会员",
    "sendMail":"发送站内信",
    "mail":"站内信",
    "refresh":"刷新数据",
    "id":"会员ID",
    "account":"会员账户",
    "nickname":"会员昵称",
    "search":"搜索",
    "level":"会员等级",
    "addUp":"累计消费",
    "email":"邮件地址",
    "firstOffline":"一级下线",
    "secondOffline":"二级下线",
    "thirdOffline":"三级下线",
    "phoneNum":"手机号码",
    "balance":"余额",
    "integral":"积分",
    "regTime":"注册日期",
    "operation":"操作",
    "detail":"详情",
    "capital":"资金",
    "address":"收货地址",
    "range":"发送范围：",
    "below":"以下列表",
    "all":"全体会员",
    "list":"会员列表：",
    "title":"发送标题：",
    "state4":"发送标题不填默认为输入框中提示的文字",
    "content":"发送内容：",
    "state5":"发送内容不填默认为输入框中提示的文字，占位符会被相应数据替换",
    "send":"发 送",
  },
  "rechargeRecord":{
    "backToList":"返回列表",
    "management":"会员管理",
    "webManagement":"网站系统会员索引与管理",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state":"会员充值记录, 由总平台设置管理.",
    "hideTips":"收起提示",
    "historyList":"会员充值记录列表",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startTime":"起始时间",
    "endTime":"截止时间",
    "nickname":"会员昵称",
    "search":"搜索",
    "id":"会员ID",
    "menuNum":"充值单号",
    "fund":"充值资金",
    "time":"提交时间",
    "method":"支付方式",
    "status":"支付状态",
    "serialNum":"交易流水号",
  },
  "stockLog":{
    "management":"商品管理",
    "inventoryLog":"库存日志",
    "webInventoryLog":"网站系统库存日志",
    "total":"共",
    "records":"条记录",
    "refresh":"刷新数据",
    "to":"至",
    "startDate":"下单日期起始",
    "endDate":"下单日期截止",
    "all":"全部",
    "search":"搜索",
    "id":"编号",
    "goodsName":"商品名称",
    "goodsSpec":"商品规格",
    "storeName":"商家名称",
    "menuId":"订单号",
    "store":"库存",
    "type":"库存类型",
    "cargoStore":"货品库存",
    "goodsStore":"商品库存",
    "logTime":"日志时间",
  },
  "tax":{
    "backToList":"返回列表",
    "taxRateByRegions":"商品分类地区税率",
    "addAndModify":"添加修改税率",
    "addOrEdit":"添加或编辑税率",
    "tipsNode":"提示相关设置操作时应注意的要点",
    "tips":"操作提示",
    "state1":"商品分类地区税率设置",
    "state2":"如分类未设置,使用父类的税率",
    "hideTips":"收起提示",
    "parentTaxRate":"父类的税率",
    "region":"地区",
    "taxRate":"税率",
    "addRegion":"添加地区",
    "toTaxRate":"请输入税率",
    "submit":"确认提交",
  },
  "_userSign":{
    "management":"签到管理",
    "signAndSetting":"会员签到及管理设置",
    "signList":"签到列表",
    "signRule":"签到规则",
  },
  "trend":{
  	  "dyModify":"修改",
  	  "dyTitle":"动态标题",
  	  "dyImage":"动态图片",
  	  "dyContent":"动态内容",
	  "upload":"建议图片大小的尺寸为",
	  "submit":"修改提交",
	  "backToList":"动态管理",
	  "dyVideo":"动态视频",
  },
}
