export const en = {
  "title": "Management center",
  "note": "Please enter the name of the business you applied for when you register the store. The login password is the general password of the front desk member of the mall.",
  "username": "username",
  "password": "password",
  "verification": "verification code",
  "login": "Sign in",
  "error": {
  	  "name": "Enter one user name",
	  "vcode": "Please enter the verification code",
	  "password": "Please input a password"
  },
  "swiper":"轮播图",
  "change":"换一张",
  "account":"账号",
}

export const zh = {
  "title": "管理中心",
  "note": "请输入您注册店铺时申请的商家名称，登录密码为商城前台会员通用密码。",
  "username": "用户名",
  "password": "密码",
  "verification": "验证码",
  "login": "登 录",
  "error": {
	  "name": "请输入用户名",
	  "vcode": "请输入验证码",
	  "password": "请输入密码"
  },
  "swiper":"轮播图",
  "change":"换一张",
  "account":"账号",
}
